
<div class="container-fliud">
    <div class="container outer">
        <div  *ngFor="let notify of notifications">
            <div class="inner" [ngClass]="{'active':!notify.read_status}">
                <h5>{{notify.title}}</h5>
                <p>{{notify.description}}</p>
                <div>{{notify.created_at|date}}</div>
            </div>
        </div>
    </div>
 
</div> 

