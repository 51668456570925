import { Component, OnInit } from '@angular/core';
import { CmsService } from 'src/app/core/services/cms/cms.service';
declare var bootbox:any;
@Component({
  selector: 'app-how-we-stand-apart',
  templateUrl: './how-we-stand-apart.component.html',
  styleUrls: ['./how-we-stand-apart.component.scss']
})
export class HowWeStandApartComponent implements OnInit {
  standapart :any = [];
  page :String ='how-we-stand-apart';
  message:'';
  constructor(private cmsService:CmsService) { }

  ngOnInit(): void {
    this.getStandApart();
  }
  getStandApart(){

		this.cmsService.getStandApart().subscribe(res=>{  
      console.log("res",res)   
      this.standapart = res['standapart'];
		})
  }
  messageHandler(message){
    console.log("message",message);
    // this.message = message;
    var dialog = bootbox.dialog({
      message: message,
      closeButton: true
    });
    // document.getElementById('successMessage').style.display='block';
    // setTimeout(function(){
    //   this.message="hi everybody";
    //   document.getElementById('successMessage').style.display='none';
    // },3000);    
  }
}
