
    <div class="container-fluid" style="padding:0px">
<app-banner [page]="page" class="about-page"></app-banner>

        <div class="container-deliverys">
            <div class="row" style="margin:0px">
                <div class="col-md-6 box-misn">
                    <div class="boxes left-box">
                         <h4>{{vision.title}}</h4>
                         <p   [innerHTML]="sanitizer.bypassSecurityTrustHtml(vision?.description)"></p> 
 

                    </div>
                </div>
                <div class="col-md-6 box-misn">
                    <div class="boxes box-misn-right" >
                        <h4 >{{mission?.title}}</h4>
                        <p   [innerHTML]="sanitizer.bypassSecurityTrustHtml(mission?.description)"></p> 
                    </div>
                </div> 

            </div>
            <div class="row " style="margin:0px">
                <div class="col-md-6 col-tset" >                
                    <div >
                    <i class="fa fa-quote-left fa-4x" aria-hidden="true"></i>
                    <div class="test-ps" style="font-style: italic;font-weight: 600;font-size: larger;">
                        You can't go back and change the beginning, but you can start where you are and change the ending.
                        <i class="fa fa-quote-right" aria-hidden="true"></i>
                    </div>
                    <div class="test-ps" style="text-align: end;font-weight: 600;font-size: larger;">
                        -C S Lewis 
                    </div>
                    </div>
                </div>
            </div>

    
</div>
<div class="container-delivery abt-cols" style="margin-bottom:40px">
        <div class="row" style="margin: 0px 0px;
        border: 1px solid #00000017;">
            <div class="col-lg-6 str-colsin" style="background: url({{story?.image}});">
            
            </div>
            <div class="col-lg-6 inncol-abt" style="background-color:#19324ce6; color:#fff;background-color: #19324ce6;
            ">
            <h3>{{story?.title}}</h3>
            <div class="underline" style="margin-left:0px!important"></div>
            <div style="padding:0px">
                <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(story?.description)"></div>          

                <h1 >Our Service</h1>
               
                <button type="submit" routerLink="/our-services" class="btn join"  style="font-size: large!important;" >Explore  <i class="fa fa-arrow-circle-right"></i></button>
               
            </div>
            </div>
        </div> 
    </div>
</div>
    <div class="container-delivery" style="padding-bottom:0px!important;">
        <div class="col-md-12 change" >
            <app-find-career></app-find-career>
        </div>  
    </div>
