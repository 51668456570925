import { Component, OnInit } from '@angular/core';
import { CmsService } from 'src/app/core/services/cms/cms.service';

@Component({
  selector: 'app-university-pathways',
  templateUrl: './university-pathways.component.html',
  styleUrls: ['./university-pathways.component.scss']
})
export class UniversityPathwaysComponent implements OnInit {
  page :String ='university-pathway';
  details='progression-option';
  universityPathway:any;
  // universityProgression:any;
  examples:any;
  constructor(private cmsService:CmsService) { }

  ngOnInit(): void {
    this.getUniversityPathway();
  }
  getUniversityPathway(){
    this.cmsService.getPageInro(this.page).subscribe(res=>{     
      this.universityPathway= res['cms'];  
      console.log("universityPathway",res)
		})
  }


}
