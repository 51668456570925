<app-banner [page]="'scholarship'"></app-banner> 
<!-- <div class="banner-item">
  <div  class="picsum-img-wrapper box">
      <img src="../../../../assets/images/banner/scholarship.jpg" alt="">
  </div>
</div> -->
<div class="container-delivery" style="padding-top:40px">
        <div class="row apply colsaply" style="padding:0px 15px!important">               
            <div class="col-md-7" >             
                <h3 style="margin-top:0px!important">{{list?.title}} </h3>
                <div [innerHTML]="list?.description"></div>
            </div>
            <div class="col-md-5 colsng"   >
              <div class="scolor-col"   >
                <div class="col-md-12 signup" >
                    <form class="form" #signupform="ngForm" 
                    (ngSubmit)="onSubmit(signupform)" novalidate>
                      <h5>Amazing Opportunity to earn a Scholarship.
                        Apply Now!</h5>
                      <div class="row">
                        <div class="col-md-12 ">                      
                          <input type="text" class=" text" [(ngModel)]="model.first_name" name="first_name"
                            #first_name="ngModel" placeholder="First Name *" pattern="[a-zA-Z\s]*$"  required
                            (change) ="model.first_name = model.first_name.trim()">
                          <span class="text-danger"
                            *ngIf="(first_name.touched || signupform.submitted) && first_name.errors?.required">
                            First Name is required
                          </span>
                          <span class="text-danger"
                          *ngIf="(first_name.touched || signupform.submitted) && first_name.errors?.pattern">
                          Only Alphabets are allowed
                        </span>
                        </div>
                        <div class=" col-md-12 ">
                          <input type="text" class=" text" pattern="[a-zA-Z\s]*$"   [(ngModel)]="model.last_name" name="last_name"
                            #last_name="ngModel" placeholder="Last Name *" required  (change) ="model.last_name = model.last_name.trim()">
                          <span class="text-danger" *ngIf="(last_name.touched || signupform.submitted) && last_name.errors?.required">
                            Last Name is required
                          </span>
                          <span class="text-danger" *ngIf="(last_name.touched || signupform.submitted) && last_name.errors?.pattern">
                            Only Alphabets are allowed
                          </span>
                        </div>
                        <div class=" col-md-12 ">
                          <input type="text" class=" text" [(ngModel)]="model.email" name="email"
                            #email="ngModel" placeholder="Email *" required email (change) ="model.email = model.email.trim()">
                          <span class="text-danger" *ngIf="(email.touched || signupform.submitted) && email.errors?.required">
                            Email is required
                          </span>
                          <span class="text-danger" *ngIf="email.touched && email.errors?.email">
                            Enter a valid email address
                          </span>
                        </div>  
                        <div class=" col-md-12 ">
                            <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                            [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                            [phoneValidation]="true" [separateDialCode]="separateDialCode" 
                            [numberFormat]="PhoneNumberFormat.National" name="phone" #phone="ngModel" required   [(ngModel)]="model.phone">
                        </ngx-intl-tel-input>
                        <span
                        class="text-danger"
                        *ngIf="
                          (phone.touched || signupform.submitted) &&
                          phone.errors?.required
                        "
                      >
                      Phone is required
                      </span>
                      <span class="text-danger"
                      *ngIf="(phone.touched || signupform.submitted) &&!phone.errors?.required &&  phone.errors?.validatePhoneNumber?.valid===false">
                      Invalid Phone number
                    </span>
                        </div>
                   
                      <div class="col-md-12">
                          <textarea class="text" name="message" [(ngModel)]="model.message" placeholder="Additional Information"  style="height:100px;resize: none;"></textarea>
                      </div>               
                      </div>
                      <div>
                          <input type="submit"  class="btn join"  value="Submit">
                      </div>
                    </form>
                  </div>          
                </div>
            </div>
    </div>
 
</div>  
