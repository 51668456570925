import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'dynamic-form-builder',
  template:`
    <form (ngSubmit)="onSubmitForm(this.form.value)" [formGroup]="form" class="form-horizontal">
      <div *ngFor="let field of fields">
          <field-builder [field]="field" [submitted]="submitted" [form]="form"></field-builder>
      </div>
      <div class="form-row">
      </div>
      <div class="form-group row"> 
   
        <div class="col-md-12" style="padding:0px 0px 5px">
          <button type="submit"  class="btn upload float-right ">Submit</button>
        </div>
      </div>
    </form>
  `,
  
    styles:[
       ` .upload{
            width: auto;
            margin-top:5px;
            margin-bottom: 3px;
            color: #fff;
            background-color:rgba(230, 90, 11, 0.9);
          }
      `
    ]
})
export class DynamicFormBuilderComponent implements OnInit {
  @Output() onSubmit = new EventEmitter();
  @Input() fields: any[] = [];
  @Input() documents: any[] = [];

  form: FormGroup;
  submitted:boolean = false;
  list:any =[];
  constructor() { }
  ngOnInit() {
    // this.submitted = false;
    let fieldsCtrls = {};
    console.log("documents",this.documents)
    for (let f of this.fields) {
      console.log("f---->",f)
      // if (f.type != 'checkbox') {
        fieldsCtrls[f.name] = new FormControl(f.value || '', Validators.required)   
      // } else {
      //   let opts = {};
      //   for (let opt of f.options) {
      //     opts[opt.key] = new FormControl(opt);
      //   }
      //   // fieldsCtrls[f.name] = new FormGroup(opts);
      //   fieldsCtrls[f.name] = new FormControl(opts);
      // }
    }
    this.form = new FormGroup(fieldsCtrls);
    this.form['submitted']= false;
  }
  onSubmitForm(form){
    console.log("formvalid",form)
    console.log("form",form,this.submitted)
    this.onSubmit.emit(form);
    this.form['submitted']=true;
    this.submitted = true
  }

}
