import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { NavComponent } from './nav/nav.component';
import { UserService } from './services/user/user.service';
import { ApiService } from './services/api/api.service';
import { UtilService } from './services/util/util.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/auth/auth.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from '../modules/pages/register/register.component';
import { HomeModule } from '../modules/pages/pages.module';
// import { LoginComponent } from '../modules/pages/login_/login_.component';

@NgModule({
  declarations: [
    FooterComponent,
    TopBarComponent,
    NavComponent,
    // LoginComponent
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
    UserService,
    ApiService,
    UtilService
],
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HomeModule
  ],
  exports: [
    FooterComponent,
    TopBarComponent,
    NavComponent,

   
  ],
  bootstrap: []
})
export class CoreModule { }
