<div class="row banner">
    <div class="col-md-12">
        <div class="card-image">
            <app-banner [page]="'refer-and-earn'"></app-banner>
        </div>
    </div>
</div>

<div class="container-fluid ">  

    <div class="container-delivery">
        <img [src]="main?.image" alt="" class="center-block">
        <h4 class="text-center" style="margin-top:30px;"> {{main?.title}}
        </h4>
        <p class="text-center" [innerHTML]="main?.Description"></p>
            <h4  *ngIf="userService.isLoggedIn()" class="text-center head" style="margin-top:0px">SHARE & REFER</h4>
            <div  *ngIf="userService.isLoggedIn()" class="underline"></div>   
            <h4  *ngIf="userService.isLoggedIn()" class="text-center head" style="margin-top:0px">Refer the code with your friends</h4>
<div *ngIf="userService.isLoggedIn()">

    <form #referForm="ngForm" (ngSubmit)="referForm.valid &#038;&#038; onSubmit(referForm)" >
        <div class="row">
            <div class="col-6">
                <label for="">Select Program</label>
                <select class="text" name="course" style="background-color: #fff;" [(ngModel)]="model.course" #course (change)=onCourseChange($event)>
                    <option value="" disabled selected> Course</option>
                    <option *ngFor="let course of courses" [value]="course.id">
                    {{course.title}} 
                    </option>
                    <span class="text-danger" *ngIf="!selectedCourse">
                        {{course_err}}
                    </span>
                    </select>
            </div>
            <div class="col-6">
                <label for="">Share Referral Code</label>
                <!-- <div>{{selectedCourse?.referal_code}}</div> -->
                <input type="text" class="text" disabled [(ngModel)]="selectedCourse.referal_code"  name="code"  #code="ngModel" required >
                <ng-template #tipContent1 let-greeting="greeting">{{greeting}}</ng-template>
                <span class=" copy" role="button" [ngbTooltip]="tipContent1"
                triggers="manual"
                #t2="ngbTooltip"
                (mouseover)="openTooltip(t2, 'Copy code')"
                (mouseout)="closeTooltip(t2)"
                (click)="openTooltip(t2, 'Code Copied')"
                (click)="copyToClipboard(selectedCourse.referal_code)" 
                >
                <i class="fa fa-clipboard"></i>
                </span>
            </div>

            <div class="col-12">
                <label for="">Share the Link</label>
                <input type="text" class="text" disabled  [(ngModel)]="url" name="link1" 
                 #link="ngModel" required  #link value="link">                 
                  <ng-template #tipContent let-greeting="greeting">{{greeting}}</ng-template>
                    <span class=" copy" role="button" [ngbTooltip]="tipContent"
                            triggers="manual"
                            #t1="ngbTooltip"
                            (mouseover)="openTooltip(t1, 'Copy Link')"
                            (mouseout)="closeTooltip(t1)"
                            (click)="openTooltip(t1, 'Link Copied')"
                            (click)="copyToClipboard(url)" 
                            >
                            <i class="fa fa-clipboard"></i>
                            </span>
                  
            </div>

        </div>
        
      </form>  
      <h4 class="text-center head" >Or Share Via</h4>
      <div class="row" style="margin-bottom:50px;">
        <!-- <div class="col-1"></div> -->
       <div class="s-100 col-6 m-auto text-center"  style=" cursor:pointer; height: 50px;">
        <!-- <span class="instagram" (click)="shareOnInstagram(baseUrl+'/')">
        <img src="assets/images/refer/instagram.png"  alt="">
       </span> -->
       <span  class="instagram" (click)="shareOnFacebook(url)">
        <img src="assets/images/refer/Facebook.png" style="width:20px" (click)="shareOnFacebook(url)" alt="">

           <!-- <fa-icon [icon]="faFacebook"></fa-icon> -->
          
       </span>
       <!-- <span class="instagram"(click)="shareOnInstagram(url)">
        <img  style="width:55px" src="assets/images/refer/Email.png"  alt="">
        </span>   -->
       <span class="instagram"(click)="shareOnTwitter(url)">
        <img style="width:40px" src="assets/images/refer/Twitter.png"  alt="">
        </span>  
        <span class="instagram"(click)="shareOnWhatsapp(url)">
            <img style="width:40px" src="assets/images/refer/Whatsapp.png"  alt="">
            </span>            
    <span  class="instagram" (click)="shareOnLinkedin(url)" >
        <img style="width:40px" src="assets/images/refer/In.png"  alt="">
    </span>

       </div>

      </div>
</div>
              
            <div class="container-fluid">
            <h4 *ngIf="!userService.isLoggedIn()" class="text-center" style="margin-top:30px;">Signup and Refer</h4>
            <h4 *ngIf="userService.isLoggedIn()" class="text-center" style="margin-top:30px;">How do I gift USD?</h4>

            <div class="underline"></div>  
            <p class="text-center" >How do I gift USD ?
                A simple three steps to support your friend’s career!</p>
                <div class="row">
                    <div class="col-4 text-center s-100" *ngFor="let s of steps">
                        <div >
                            <img class="center-block" [src]="s.image"  alt="">

                        </div>
                        <h4>{{s.title}}</h4>
                        <p [innerHTML]="s.Description"></p>

                    </div>
                    
                </div>

            </div>
              <div class="container-delivery faq-col faq-container">
                <div class="row faq" id="faq" #faq *ngIf="courses.length">
                    <div class="col-md-12">
                            <div class="text-center">
                            <h4 style="margin-top:30px;">Benefits</h4>
                            <div class="underline"></div>  
                        </div>
                      
                        <ngb-tabset class="set mobile-hide">
                           
                            <ngb-tab *ngFor="let items of benefits | keyvalue" >
                                <ng-template ngbTabTitle><span class="tab-title">{{items.key}}</span>
                                </ng-template>    
                                <ng-template ngbTabContent>                                
                                    <div class="box">
                                        <table class="table" style="background:#fff;">
                      
                                            <tbody> 
                                              <tr>
                                                <th>Course</th>
                                                
                                                <th>Benefits</th>
                            
                                              </tr>      
                              
                                              <tr *ngFor="let details of items.value ">
                                                <td> {{details?.title}} </td>
                                                <td>{{details?.refer_and_earn_offer_usd}}</td>
                            
                                             
                                              </tr>
                                              
                                            </tbody>
                                          </table>                             
                                    </div>                              
                                </ng-template>
                            </ngb-tab>
                        </ngb-tabset>

                        <div class="accordion mobile-visible" id="accordionExample">
                            <div class="cards" *ngFor="let items of benefits | keyvalue">
                                <div class="card-header cardheader" id="{{items.key}}">
                                    <div class="row faqt collapsed" data-toggle="collapse" [attr.data-target]="'#news' + items.value[0].category_slu" aria-expanded="false" aria-controls="collapseTwo">
                                        <div class="col-11 float-left" style="padding:10px">
                                            <h5 class="quest" style="cursor: pointer;">{{items.key}}</h5>
                                        </div>
                                        <div class="col-1" style="cursor: pointer;text-align:right;padding:0px;margin-top: 5px;">
                                            <span class=" float-right plus1" data-toggle="collapse" [attr.data-target]="'#new' + items.value[0].category_slug" aria-expanded="false" aria-controls="collapseTwo" style="font-size:16px">
                                            +
                                        </span>
                                            <span class=" float-right minus1" data-toggle="collapse" [attr.data-target]="'#new' + items.value[0].category_slug" aria-expanded="false" aria-controls="collapseTwo" style="font-size:16px;">
                                        -
                                    </span>
                                        </div>
                                    </div>
                                    <!-- <p class="faq-under-line"> </p> -->
                                </div>
                                <div [id]="'new'+items.value[0].category_slug" class="collapse " aria-labelledby="headingOne" data-parent="#accordionExample" style="width: 100%;">
                                    <div class="card-body">
                                        <div class="box">
                                            <table class="table" style="background:#fff;">
                          
                                                <tbody> 
                                                  <tr>
                                                    <th>Course</th>
                                                    
                                                    <th>Benefits</th>
                                
                                                  </tr>      
                                  
                                                  <tr *ngFor="let details of items.value ">
                                                    <td> {{details?.title}} </td>
                                                    <td>{{details?.refer_and_earn_offer_usd}}</td>
                                
                                                 
                                                  </tr>
                                                  
                                                </tbody>
                                              </table>                             
                                        </div>                                     </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
              
    
          <div class="container-delivery faq-col faq-container">
            <div class="row faq" id="faq" #faq *ngIf="eligibility_faqs.length||payout_faqs.length">
                <div class="col-md-12">
                    <div class="text-center">
                        <h4 style="margin-top:30px;">Frequently Asked Questions</h4>
                        <div class="underline"></div>  
                    </div>
                  
                    <h4 class="text-center">Eligibility</h4>
                    <div class="accordion" id="accordionExample">
                        <div class="cards" *ngFor="let faq of eligibility_faqs">
                            <div class="card-header cardheader" id="{{faq.id}}">
                                <div class="row faqt collapsed" data-toggle="collapse" [attr.data-target]="'#news' + faq.id" aria-expanded="false" aria-controls="collapseTwo">
                                    <div class="col-11 float-left" >
                                        <h5 class="quest" style="cursor: pointer; margin: 0;">{{faq.title}}</h5>
                                    </div>
                                    <div class="col-1" style="cursor: pointer;text-align:right;padding:0px">
                                        <span class=" float-right plus1" data-toggle="collapse" [attr.data-target]="'#new' + faq.id" aria-expanded="false" aria-controls="collapseTwo" style="font-size:16px">
                                            <i class="fa fa-angle-down" style="font-size:24px"></i>

                                    </span>
                                        <span class=" float-right minus1" data-toggle="collapse" [attr.data-target]="'#new' + faq.id" aria-expanded="false" aria-controls="collapseTwo" style="font-size:16px;">
                                    <i class="fa fa-angle-up" style="font-size:24px"></i>

                                </span>
                                    </div>
                                </div>
                                <!-- <p class="faq-under-line"> </p> -->
                            </div>
                            <div [id]="'news'+faq.id" class="collapse " aria-labelledby="headingOne" data-parent="#accordionExample" style="width: 100%;">
                                <div class="card-body">
                                    <div [innerHTML]="faq.Description"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 class="text-center">Payouts</h4>
                    <div class="accordion" id="accordionExample">
                        <div class="cards" *ngFor="let faq of payout_faqs">
                            <div class="card-header cardheader" id="{{faq.id}}">
                                <div class="row faqt collapsed" data-toggle="collapse" [attr.data-target]="'#news' + faq.id" aria-expanded="false" aria-controls="collapseTwo">
                                    <div class="col-11 float-left" >
                                        <h5 class="quest" style="cursor: pointer; margin: 0;">{{faq.title}}</h5>
                                    </div>
                                    <div class="col-1" style="cursor: pointer;text-align:right;padding:0px">
                                        <span class=" float-right plus1" data-toggle="collapse" [attr.data-target]="'#new' + faq.id" aria-expanded="false" aria-controls="collapseTwo" style="font-size:16px">
                                            <i class="fa fa-angle-down" style="font-size:24px"></i>

                                    </span>
                                        <span class=" float-right minus1" data-toggle="collapse" [attr.data-target]="'#new' + faq.id" aria-expanded="false" aria-controls="collapseTwo" style="font-size:16px;">
                                    <i class="fa fa-angle-up" style="font-size:24px"></i>

                                </span>
                                    </div>
                                </div>
                                <!-- <p class="faq-under-line"> </p> -->
                            </div>
                            <div [id]="'news'+faq.id" class="collapse " aria-labelledby="headingOne" data-parent="#accordionExample" style="width: 100%;">
                                <div class="card-body">
                                    <div [innerHTML]="faq.Description"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><br>
  
    </div>
</div>
