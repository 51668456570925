import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bootcamp-apply',
  templateUrl: './bootcamp-apply.component.html',
  styleUrls: ['./bootcamp-apply.component.scss']
})
export class BootcampApplyComponent implements OnInit {

  @Output() childEvent = new EventEmitter();
  @ViewChild('modal')
  private modalContent: TemplateRef<BootcampApplyComponent>;
  @Input() fromParent;

  faTimesCircle=faTimesCircle
  ngOnInit(): void {
  }
  constructor(
    private activeModal: NgbActiveModal,
    private _activatedRoute: ActivatedRoute,
   
  ) {}


  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}

