import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { CourseService } from 'src/app/core/services/course/course.service';
declare var bootbox;
@Component({
  selector: 'app-download-brochure',
  templateUrl: './download-brochure.component.html',
  styleUrls: ['./download-brochure.component.scss']
})

export class DownloadBrochureComponent implements OnInit {
  @ViewChild('modal') private modalContent: TemplateRef<DownloadBrochureComponent>
  @Input() fromParent;

  @Output() message: EventEmitter<number> =   new EventEmitter();
  faTimesCircle=faTimesCircle
  model ={
    first_name:'',
    second_name:'',
    phone:'',
    email :'',
    country:'',
    work_experience:''
  };
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  email_err=''
  pdf: any;
  country: any;
  constructor(    public activeModal: NgbActiveModal,
    private courseService:CourseService) { }
  
  ngOnInit(): void {
    console.log("fromParent",this.fromParent)
    
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
    
  }
  selectCountry(event){
    
    this.country = event.target.value;
  }
  onSubmit(form){

    let data = form.value;
    data.course_id = this.fromParent.details.id;
    data.country = this.country;
    data.phone = form.value.phone?.e164Number;


  this.courseService.requestBrochure(form.value).subscribe(async (resp: any) =>{
  
    var dialog = bootbox.dialog({
      message: resp.message,
      closeButton: true
    });
    form.resetForm();
    var file = new Blob([resp.data.brochure], { type: 'application/pdf' })
          var fileURL = URL.createObjectURL(file);

    var a = document.createElement("a");
    let fileName = 'brochure';
    a.href = resp.data.brochure;
    a.target='_blank'
    a.download = resp.data.brochure;
    document.body.appendChild(a);
    a.click();
    // window.URL.revokeObjectURL(resp.data.brochure);
    // a.remove();
    this.pdf = resp.data.brochure;
    this.closeModal('dismiss');
    });		
  }
}
