import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

// components
import { DynamicFormBuilderComponent } from './dynamic-form-builder.component';
import { TextBoxComponent } from './atoms/textbox';
import { DropDownComponent } from './atoms/dropdown';
import { RadioComponent } from './atoms/radio';
import { FieldBuilderComponent } from './field-bulder/field-builder.component';
import { CheckBoxComponent } from './atoms/checkbox';
import { TextAreaComponent } from './atoms/textarea';
import { DateComponent } from './atoms/date';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  declarations: [
    DynamicFormBuilderComponent,
    FieldBuilderComponent,
    TextBoxComponent,
    TextAreaComponent,
    DropDownComponent,
    CheckBoxComponent,
    RadioComponent,
    DateComponent
  ],
  exports: [DynamicFormBuilderComponent,FieldBuilderComponent],
  providers: []
})
export class DynamicFormBuilderModule { }
