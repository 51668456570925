import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { MasterService } from 'src/app/core/services/master/master.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  @Input('popups') popups: any=[];
 
    err: string = '';
  course_id: any;
  advance: any;
  unsubcribe: any;

  separateDialCode = false;

  constructor(
    private masterService: MasterService,
  ) {
    console.log('field', this.fields);
  }

  ngDistroy() {
    this.unsubcribe();
  }

  list = [];
  documents = [];
  fields = [];
  faTimesCircle = faTimesCircle;
  formFields = [];
  showModal1: boolean;
  ngOnInit(): void {
    console.log("")
  
    // this.getEnroll();

   
  }

  show(event) {
    console.log('data');

    this.list = [];
    this.showModal1 = true;

    // this.course_id =id;
  }
  hide() {
    this.showModal1 = false;
    this.err = '';
    this.fields = [];
    this.list = [];
  }

}
