<div class="row container-delivery" style="background: #f1f1f1;">
    <div class="row">
        <div class="col-md-6" style="padding:40px 15px 0px">
            <div> GET STARTED</div>
            <h3>
                Speak with a Learning Advisor</h3>
            <div class="underline" style="height: 2px;border: 2px solid #e45b0b;display: inline-block;"></div>
            <div class="col-md-12" style="padding:0px">
                <p>Join a network of those professionals who have transformed their career through Edubex.</p>
                <ul>
                    <li>
                        Discover new courses and programs
                    </li>
                    <li>
                        Learn about tuition, payment plans, and scholarships
                    </li>
                    <li>
                        Get access to our events and workshops
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-6">
            <div class="cah-pswd align-self-center">
                <div class="loginbox bookform">
                    <h4 style="color: #223f31 !important;"><b>Speak with a Learning Advisor</b></h4>
                    <p>Fill out the form below and a Learning Advisor will reach out at a time convenient for you.</p>
                    <form #callForm="ngForm" (ngSubmit)="onSubmit(callForm)">
                        <div class="form-group">
                            <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                [selectedCountryISO]="CountryISO.India" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National" name="phone" #phone="ngModel" required (ngModel)="model.phone">
                            </ngx-intl-tel-input>
                            <div class="text-danger" *ngIf="(phone.touched || callForm.submitted) && phone.errors?.required">
                                Phone is required
                            </div>
                            <div class="text-danger" *ngIf="(phone.touched || callForm.submitted) &&!phone.errors?.required &&  phone.errors?.validatePhoneNumber?.valid===false">
                                Invalid Phone number
                            </div>
                        </div>

                        <div class="form-group">
                            <input type="text" class="form-control" (ngModel)="model.first_name" name="first_name" placeholder="First name" #first_name="ngModel" required first_name>

                            <div class="text-danger" *ngIf="(first_name.touched || callForm.submitted) && first_name.errors?.required">
                                First name is required
                            </div>
                        </div>


                        <div class="form-group">
                            <input type="text" class="form-control" (ngModel)="model.last_name" name="last_name" placeholder="Last name" #last_name="ngModel" required>
                            <div class="text-danger" *ngIf="(last_name.touched || callForm.submitted) && last_name.errors?.required">
                                Last name is required
                            </div>
                        </div>

                        <div class="form-group">
                            <input type="text" class="form-control" (ngModel)="model.email" name="email" placeholder="Email" #email="ngModel" required email>
                            <div class="text-danger" *ngIf="(email.touched || callForm.submitted) && email.errors?.required">
                                Email is required
                            </div>
                            <div class="text-danger" *ngIf="(email.touched || callForm.submitted) && email.errors?.email">
                                Invalid email
                            </div>
                        </div>

                        <p>By clicking "Book a call," you accept our Terms and will also receive exclusive offers and updates about new courses, workshops and events.

                        </p>
                        <input type="submit" class="btn btn-login" value="Book a Call">
                    </form>

                </div>

            </div>
        </div>
    </div>



</div>