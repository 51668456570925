import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { CourseService } from 'src/app/core/services/course/course.service';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { UserService } from 'src/app/core/services/user/user.service';
import { DynamicFormBuilderComponent } from '../../dynamic-form-builder/dynamic-form-builder.component';
import { FieldBuilderComponent } from '../../dynamic-form-builder/field-bulder/field-builder.component';

declare var bootbox: any;

@Component({
  selector: 'app-course-apply',
  templateUrl: './course-apply.component.html',
  styleUrls: ['./course-apply.component.scss']
})
export class CourseApplyComponent implements OnInit {
  @Output() childEvent = new EventEmitter();

  err: string='';
  course_id: any;
  user_id: any;
  public form: FormGroup;
  unsubcribe: any;
  submitted=false;
  check_submit =false;
  referral_code;
 
  constructor(private courseService:CourseService,private userService:UserService) { 
   
  }
  onSubmitForm(form){
    console.log("formvalid",form,this.check_submit)
    console.log("form",form,this.submitted)
    // this.onSubmit.emit(form);
    this.form['submitted']=true;
    this.submitted = true
    this.submit(form)
  }
  onUpload(e) {
    console.log(e);
  }

  getFields() {
    return this.fields;
  }

  ngDistroy() {
    this.unsubcribe();
  }

  list = [];
  documents=[];
  fields = [];
  faTimesCircle=faTimesCircle;
  formFields = [];
  showModal1:boolean
  ngOnInit(): void {
    // this.getEnroll();

   if(this.userService.isLoggedIn()) {
      this.userService.getUser().subscribe(data=>{
        console.log("user",data)
        this.user_id = data.profile.id
      })
    }   
  }

  onSubmit(fields,error){
  console.log("list",this.list[0],this.documents)
    // if(this.list.length===this.documents.length && !this.list.includes(undefined) && !error){
      if( !error){

      this.err='';
      console.log("error",this.list);  
      this.check_submit = true;
      // console.log("formData",this.formData)    
      this.courseService.saveEnrollDetails({
        user_id:this.user_id,
        course_id:this.course_id,
        documents:this.list,
        fields,
        referral_code:this.referral_code       
      }).subscribe(data=>{
        console.log("data",data);
        this.hide();
        this.list=[];
        this.fields=[];
        this.childEvent.emit('success');
        var dialog = bootbox.dialog({
          message: "Thank you for applying to the course.The enrollment will be completed once the admin verifies your request. You will receive an email confirmation once the admin approves your request.",
          closeButton: true
        });
        // this.check_submit = false;
      }) 
    }else this.err="Upload document.";
  }

  submit(event){
    console.log("event",event,this.formFields,this.list)
    let fields =[];
    let error =  false;
    if(event)
    {
      for (const key in event) {
        if(event[key]!==""){
          fields.push({field:key,value:event[key]})
        }else{
        this.formFields.forEach(element => {
          if(key===element.name){
            console.log("name",element.name)
            if(element.required===1){
              error = true; 
            }
          }
          });        
        }
      } 
      console.log("error",error)
      // if(!error)
      this.onSubmit(fields,error)
    }
  }
removeDoc(i){
  console.log(i,)
  if (i > -1) {
    delete this.list[i];
    console.log("list",this.list,this.documents)
  }
}
  goToRegister(){

  }

  show(data,fields,id,code){
    console.log("data",data,id,fields);
    this.documents = data;
    this.formFields = fields;
    this.fields = fields;
    this.check_submit =false;
    this.referral_code=code
    this.form = new FormGroup({
      fields: new FormControl(JSON.stringify(fields))
    })
    console.log("formf---->",this.formFields,this.check_submit)
    this.list = [];
    
    this.course_id =id;
    let fieldsCtrls = {};
    for (let f of this.fields) {
      
        fieldsCtrls[f.name] = new FormControl(f.value || '', Validators.required)   
 
    }
    this.form = new FormGroup(fieldsCtrls);
    this.form['submitted']= false;
    this.showModal1 =true;
  }
  hide(){
    this.showModal1 = false;
    this.err='';
    this.fields=[];
    this.list=[];
  }
  onSelect(event,slug,i){
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
    
      let index = this.list.findIndex(x => x?.slug === slug);
      console.log("index",index,i)
      if(index==-1){
        this.list[i]={slug:slug,file:file};
      
      }else this.list[index] =  {
        slug:slug,
        file:file
      }
   
      // const reader = new FileReader();
     
      // reader.readAsDataURL(file);
    }
  }

}
