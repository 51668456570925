import { Component, EventEmitter,  HostListener,  Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CourseService } from 'src/app/core/services/course/course.service';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent implements OnInit {
  @Output() scrollingFinished = new EventEmitter<void>();
  @Input() dues: Array<string> = [];

  constructor(private router:Router,private courseService:CourseService) {}

  onScrollingFinished() {
    this.scrollingFinished.emit();
  }
  ngOnInit(){

  }
  makePayment(due){
    this.courseService.makeOrder(due.id)
    .subscribe(result=>{
      console.log("result",result)
      this.router.navigate(['/course-payment/'+result.order_id]);    
    })
  }
    

}
