import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './modules/pages/home/home.component';
import { NotFoundComponent } from './modules/pages/not-found/not-found.component';

const routes: Routes = [
 
  {
    path: '', loadChildren: () => import('./modules/pages/pages.module').then(m => m.HomeModule),
    data: { showHeader: true, showFooter: true }
  },
  // {
  //   path: 'home', loadChildren: () => import('./modules/pages/pages.module').then(m => m.HomeModule),
  //   data: { showHeader: true, showFooter: true }
  // },
  {path: '**' , component : NotFoundComponent},
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
    // scrollPositionRestoration: 'top',
    scrollPositionRestoration: 'enabled'  
  }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
