import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { CourseService } from 'src/app/core/services/course/course.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { GetRegisteredComponent } from '../../components/get-registered/get-registered.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var bootbox;
@Component({
  selector: 'app-webinar-detail',
  templateUrl: './webinar-detail.component.html',
  styleUrls: ['./webinar-detail.component.scss']
})
export class WebinarDetailComponent implements OnInit {
	slug;
webinar:any;
others:any=[];
user_id:any;
  termOptions: OwlOptions = {
		loop: false,
		autoplay:true,
		autoWidth:true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
		navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
		responsive: {
		0: {
			items: 1
		},
		400: {
			items:2
			// items:this.scheduled_courses.length<3?this.scheduled_courses.length:5
		},
		740: {
			items: 4
			// items: this.scheduled_courses.length<3?this.scheduled_courses.length:5,
		},
		940: {
			items: 4,
			// loop:false
		}
		},
		nav: true
	  }
	  speakerOptions: OwlOptions = {
		loop: false,
		autoplay:true,
		autoWidth:true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
		navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
		responsive: {
		0: {
			items: 1
		},
		400: {
			items:1
			// items:this.scheduled_courses.length<3?this.scheduled_courses.length:5
		},
		740: {
			items: 1
			// items: this.scheduled_courses.length<3?this.scheduled_courses.length:5,
		},
		940: {
			items: 1,
			loop:false
		}
		},
		nav: true
	  }
	user: any;
  constructor(private userService:UserService,    public modalService: NgbModal,
    private _activatedRoute:ActivatedRoute,private router:Router, private courseService:CourseService,) { }

  ngOnInit(): void {

	this._activatedRoute.params.subscribe(params => {
		console.log("params--------------------------------",params);      
		this.slug = params.id
		this.courseService.getWebinarDetails({slug:this.slug}).subscribe((res) => {
			console.log('res', res);
			this.webinar=res.webinar;
			// this.allCategories = res.Dcategories;
			
	  
			});
			this.courseService.getWebinar().subscribe((res) => {
				console.log('res', res);
				// this.allCategories = res.categories;
				let courses = res.webinar.upcoming_webinars;
				this.others=courses.filter(course=>course.slug!==this.slug)
			});
	  })   
  }

  enterOtp(form,err){
    console.log("form-----------------",form)
    if(this.userService.isLoggedIn()){
      this.userService.getUser().subscribe(data=>{
        console.log(data)        
        this.user = data.profile;
        let details={
          email:this.user.email,
          webinar_slug:form
        }
        this.courseService.getWebinarOtp(details).subscribe(
          res => {
            console.log("otpppppp",res)
            if(res.status===3){ 
              // this.router.navigateByUrl('/webinar-details/'+form);
              bootbox.dialog({ message:"Course applied successfully",
                closeButton: true})
                window.location.reload();

            }
          })
      });

  
    }else{

      const modalRef = this.modalService.open(GetRegisteredComponent,
        {
        scrollable: false,
        windowClass: 'myCustomModalClass',
        // keyboard: false,
        // backdrop: 'static'
        });
    
      let data = {
        prop1: 'webinar-details',
        prop2: form.slug,
        // course: course,
      }
    
      modalRef.componentInstance.fromParent = data;
      modalRef.componentInstance.childEvent.subscribe((emmitedValue) => {
        // do sth with emmitedValue
        console.log("emmitedValue",emmitedValue)
        let details={
          ...emmitedValue,
          webinar_slug:form

        }
        this.courseService.getWebinarOtp(details).subscribe(
          res => {
            console.log("otpppppp",res)
            this.user_id =res.user_id
            console.log("userid",this.user_id)


            if(res.status===3){ 
              // that.userService.setToken(res.token); 
              localStorage.setItem('JWT_TOKEN', res.token);
              //  that.onSubmit(form,'close')
              this.router.navigateByUrl('/webinar-details/'+form);
              bootbox.dialog({ message:"Course applied successfully",
              closeButton: true})
              window.location.reload();

            }else{
          this.reenterOtp(form,err)
            }
          
  
          },
          err => {
            console.log("err", err)
          });
      });
     

    //   bootbox.prompt({
    //     title: "Enter Email id",
    //     message:err?err:'Please enter email!',
    //     centerVertical: true,
    //     callback: function(result){
    //       if(result){
    //         console.log(result);
            
  
    //       }else{
  
    //         bootbox.hideAll();
  
    //         if(result!==null)
    //         that.enterOtp(form,"<span style='color: red;'>Enter valid Mail id. Try again!</span>");
    //         return false;
    //         }
  
  
    //     }
    // });
    }
  
  }

  reenterOtp(form,err){
    let that    =this;
    bootbox.prompt({
      title: "Enter Verification Code",
      message:err?err:'Please check your email for the verification code!',
      inputType: 'number',
      centerVertical: true,
      callback: function(result){
          console.log("shoe",this.user_id,result);
          if(result){

            that.courseService.verifyWebinarOtp({
              otp:result,
              user_id:that.user_id,
            webinar_slug:form
            }).subscribe(
              res => {
                console.log("res--->",res)
                 if(res.status==1){
                  localStorage.setItem('JWT_TOKEN', res.token);
                  bootbox.dialog({ message:"Course applied successfully",
                  closeButton: true})
                  //  that.onSubmit(form,'close')
                  window.location.reload();

                  // that.router.navigateByUrl('/webinar-details/'+form);

                }else if(res.status==2){
                  var dialog = bootbox.dialog({
                    className: 'block',
                    message: "You have exceeded the limit of verification code tries. Please contact Admin !!",
                    closeButton: true
                  });
                }else
                     that.reenterOtp(form,"<span style='color: red;'>Verification failed. Try again!</span>");

              },
              err => {
                console.log("err", err)
              });
          }else{
            bootbox.hideAll();

            if(result!==null)
            that.reenterOtp(form,"<span style='color: red;'>Enter valid verification code. Try again!</span>");
            return false;
          }

      }
  });

  }
//   goToThis(slug){
// 	  this.router.navp
//   }

}
