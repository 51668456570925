<form class="form" #signupform="ngForm" 
(ngSubmit)="onSubmit(signupform)" novalidate>
  <h5>Sign up to reach Edubex and we will be in touch</h5>
  <div class="row">
    <div class="col-md-6 ">                      
      <input type="text" class=" text" [(ngModel)]="model.first_name" name="first_name"
        #first_name="ngModel" placeholder="First Name *" pattern="[a-zA-Z\s]*$"  required
        (change) ="model.first_name = model.first_name.trim()">
      <span class="text-danger"
        *ngIf="(first_name.touched || signupform.submitted) && first_name.errors?.required">
        First Name is required
      </span>
      <span class="text-danger"
      *ngIf="(first_name.touched || signupform.submitted) && first_name.errors?.pattern">
      Only Alphabets are allowed
    </span>
    </div>
    <div class=" col-md-6 ">
      <input type="text" class=" text" pattern="[a-zA-Z\s]*$"   [(ngModel)]="model.second_name" name="second_name"
        #second_name="ngModel" placeholder="Second Name *" required  (change) ="model.second_name = model.second_name.trim()">
      <span class="text-danger" *ngIf="(second_name.touched || signupform.submitted) && second_name.errors?.required">
        Second Name is required
      </span>
      <span class="text-danger" *ngIf="(second_name.touched || signupform.submitted) && second_name.errors?.pattern">
        Only Alphabets are allowed
      </span>
    </div>
    <div class=" col-md-6 ">
      <input type="text" class=" text" [(ngModel)]="model.email" name="email"
        #email="ngModel" placeholder="Email *" required email (change) ="model.email = model.email.trim()">
      <span class="text-danger" *ngIf="(email.touched || signupform.submitted) && email.errors?.required">
        Email is required
      </span>
      <span class="text-danger" *ngIf="email.touched && email.errors?.email">
        Enter a valid email address
      </span>
    </div>  
    <div class=" col-md-6 ">
      <input type="text" class=" text" [(ngModel)]="model.linkedin" name="linkedin"                                    
        #linkedin="ngModel"  placeholder="Linkedin Profile *" required linkedin (change) ="model.linkedin = model.linkedin.trim()">
      <span class="text-danger" *ngIf="(linkedin.touched || signupform.submitted) && linkedin.errors?.required">
        Linkedin profile is required
      </span>
      <!-- <span class="text-danger" *ngIf="linkedin.errors?.apppattern
        appPattern="^(https?:\/\/)*[a-z0-9-]+(\.[a-z0-9-]+)+(\/[a-z0-9-]+)*\/?$"  && linkedin.touched"> URL is Invalid </span>  -->
    </div>
    <!-- <div class="col-md-6">
      <select  [(ngModel)]="model.campus" class="form-control text" name="campus"  (change)=onCampusChange($event) >
          <option selected disabled  value="" >Campus *</option>
          <option  value="Campus1" >Campus1</option>
          <option  value="Campus2" >Campus2</option>
          <option  value="Campus3" >Campus3</option>
      </select>
      <span class="text-danger" >
      {{campus_err}}
      </span>
  </div> -->
  <!-- <div class="col-md-6">
      <select   name="discipline" [(ngModel)]="model.discipline" class="form-control text" (change)=onDisciplineChange($event)>
          <option value="" disabled  > Discipline *</option>
          <option value="Discipline1"> Discipline1</option>
          <option value="Discipline2"> Discipline2</option>
          <option value="Discipline3"> Discipline3</option>
          <option value="Discipline4"> Discipline4</option>
      </select>
      <span class="text-danger" >
        {{discipline_err}}
      </span>
  </div> -->
  <div class="col-md-12">
      <textarea class="text" name="message" [(ngModel)]="model.message" placeholder="Additional Information"  style="height:100px;resize: none;"></textarea>
  </div>               
  </div>
  <div>
      <input type="submit"  class="btn join"  value="Submit">
  </div>
</form>



