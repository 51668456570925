<app-top-bar *ngIf="showTopBar"></app-top-bar>
<app-nav *ngIf="showNav"></app-nav>

<div>
    <div class="content">
        <router-outlet (deactivate)="scrollToTop()"></router-outlet>
    </div>
</div>

<app-footer *ngIf="showFooter"></app-footer>
