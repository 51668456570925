import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { VgCoreModule } from 'ngx-videogular';
import {  NgxLoadingModule } from 'ngx-loading';
import { DynamicFormBuilderModule } from './modules/dynamic-form-builder/dynamic-form-builder.module';
import { CarouselSwipeDirective } from './shared/directives/carousel-swipe.directive';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { TawkService } from './core/services/common/tawk.service';
import { ImagekitioAngularModule } from 'imagekitio-angular';
// import { LazyLoadImagesModule } from 'ngx-lazy-load-images';
// import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    AppComponent,
    

  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserModule,
    
    // LazyLoadImagesModule,
  
    // LazyLoadImageModule,
    NgxLoadingModule.forRoot({
   
  }), 
    RouterModule.forRoot([]),
    AppRoutingModule,
    CoreModule,
    NgbModule,
    
    HttpClientModule,
    BrowserAnimationsModule,
    SelectDropDownModule,
    VgCoreModule,
    DynamicFormBuilderModule,
    
      ],
  providers: [
    NgbActiveModal,
    TawkService,
    // { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
