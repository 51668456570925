import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MasterService } from 'src/app/core/services/master/master.service';

@Component({
  selector: 'app-our-story',
  templateUrl: './our-story.component.html',
  styleUrls: ['./our-story.component.scss']
})
export class OurStoryComponent implements OnInit {
  _id: any;
  status=0;
   list = []
  constructor(private _activatedRoute:ActivatedRoute, private masterService:MasterService,
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(params => {
      console.log(params);      
        this._id = params.id;
        // if(this._id==='stories'){
          this.masterService.getStoriesAll().subscribe(res=>{ 
            this.status=1;
            this.list =res.stories; 
            console.log("details",res);
          })
        // }
    
      
        if(this._id==='news'){
          this.masterService.getNewsAll().subscribe(res=>{    
            this.list =res.news; 
            console.log("details",res);
          })  
        }
    })
  }

}
