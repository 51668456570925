import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class MultiSelectService {
  private duesSubject = new BehaviorSubject<Array<string>>([]);
  dues$ = this.duesSubject.asObservable();
  dues: Array<any> = [];
  allDues:Array<any>=[] ;

  constructor(private userService:UserService) {
  
    this.load();
  
  }
  load(){
    this.dues=[];
    this.allDues=[];
    this.userService.getPaymentDues()
    .subscribe(result=>{
      this.allDues = result;
      this.getNextItems();
      this.duesSubject.next(this.dues);
    })
  }

  loadMore(): void {
    if (this.getNextItems()) {
      this.duesSubject.next(this.dues);
    }
  }

  getNextItems(): boolean {
    if (this.dues.length >= this.allDues.length) {
      return false;
    }
    const remainingLength = Math.min(10, this.allDues.length - this.dues.length);
    this.dues.push(...this.allDues.slice(this.dues.length, this.dues.length + remainingLength));

    return true;
  }
}