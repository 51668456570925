<div class="row dashboard">
    <div class="container" *ngIf="approvedCourses.length">
        <div class="personal">
            <div class="row">
                <div class="col-md-6 main" *ngFor="let course of approvedCourses">
                    <div class="user">
                        <h6 class="heading font-demi">Approved Courses</h6>
                        <div class="content title" *ngIf="course?.is_canvas" style="cursor: pointer;" (click)="goToLink(course.link)">
                            {{course?.title}}
                        </div>
                        <div class="content" *ngIf="!course?.is_canvas">
                            {{course?.title}}
                        </div>
                        <div class="content">
                            <div class="float-left ">{{course?.date}}</div>
                            <div class="float-right " style="cursor: pointer;" routerLink="/program-details/{{course?.master_slug}}">View More</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container text-center" style="font-size:14" *ngIf="!approvedCourses.length" style="padding: 40px 0px;margin: 20px auto;background: #fff;">
        <span>No Approved Courses</span>
    </div>
</div>