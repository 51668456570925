import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-approved-courses',
  templateUrl: './approved-courses.component.html',
  styleUrls: ['./approved-courses.component.scss']
})
export class ApprovedCoursesComponent implements OnInit {
  approvedCourses: any=[];

  constructor(private userService:UserService) { }

  ngOnInit(): void {
    this.getApprovedCourses()
  }
  goToLink(link){
    window.location.href=link;

  }
  getApprovedCourses(){
    this.userService.getApprovedCourses()
    .subscribe(result=>{
      console.log("result",result)
      this.approvedCourses = result
    })
  }

}
