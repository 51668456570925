<div class="modal-body ">
    <div class="close" (click)="closeModal('dismiss')">
        <fa-icon style=" margin:5px;cursor:pointer;color: #000" [icon]="faTimesCircle"></fa-icon>
    </div>
<div class="card-sec " style="padding:20px">
    <div class="card-body" style="border-radius: 6px;padding-top: 0px;">
        <div class="container">
            <form
            class="form"
            #signupform="ngForm"
            (ngSubmit)="signupform.form.valid && onSubmit(signupform)"
            novalidate
          >
            <h5> Register with Details</h5>
            <div class="row teleform">
              <div class="col-md-12">
                <input
                  type="text"
                  class="text"
                  [(ngModel)]="model.first_name"
                  name="first_name"
                  #first_name="ngModel"
                  placeholder="First Name"
                  pattern="[a-zA-Z\s]*$"
                  required (change) ="model.first_name = model.first_name.trim()"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (first_name.touched || signupform.submitted) &&
                    first_name.errors?.required
                  "
                >
                  First Name is required
                </span>
                <span
                  class="text-danger"
                  *ngIf="
                    (first_name.touched || signupform.submitted) &&
                    first_name.errors?.pattern
                  "
                >
                  Only Alphabets are allowed
                </span>
              </div>
              <div class="col-md-12">
                <input
                  type="text"
                  class="text"
                  pattern="[a-zA-Z\s]*$"
                  [(ngModel)]="model.second_name"
                  name="second_name"
                  #second_name="ngModel"
                  placeholder="Second Name"
                  required
                  (change) ="model.second_name = model.second_name.trim()"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (second_name.touched || signupform.submitted) &&
                    second_name.errors?.required
                  "
                >
                  Second Name is required
                </span>
                <span
                  class="text-danger"
                  *ngIf="
                    (second_name.touched || signupform.submitted) &&
                    second_name.errors?.pattern
                  "
                >
                  Only Alphabets are allowed
                </span>
              </div>
              <div class=" col-md-12 ">
                  <input type="text" class=" text" [(ngModel)]="model.email" name="email"
                    #email="ngModel" placeholder="Email *" required email (change) ="model.email = model.email.trim()">
                  <span class="text-danger" *ngIf="(email.touched || signupform.submitted) && email.errors?.required">
                    Email is required
                  </span>
                  <span class="text-danger" *ngIf="email.touched && email.errors?.email">
                    Enter a valid email address
                  </span>
                </div>  
              <div class="col-md-12 telephone" style="margin-top:10px;">
                  <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                  [phoneValidation]="true" [separateDialCode]="separateDialCode" 
                  [numberFormat]="PhoneNumberFormat.National" name="phone" #phone="ngModel" required   [(ngModel)]="model.phone">
              </ngx-intl-tel-input>
                <span
                  class="text-danger"
                  *ngIf="
                    (phone.touched || signupform.submitted) &&
                    phone.errors?.required
                  "
                >
                Phone is required
                </span>
                <span class="text-danger"
                *ngIf="(phone.touched || signupform.submitted) &&!phone.errors?.required &&  phone.errors?.validatePhoneNumber?.valid===false">
                Invalid Phone number
              </span>
              </div>
           
            </div>
            <div>
              <input type="submit" class="btn join" value="Submit" />
            </div>
            <span class="text-danger" *ngIf="this.err_message"> {{ err_message }}</span>
          </form>
        </div>
    </div>
</div>
</div>



