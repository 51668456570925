<form
  class="form"
  #signupform="ngForm"
  (ngSubmit)="signupform.form.valid && onSubmit(signupform)"
  novalidate
>
  <h5>Sign up to reach Edubex and we will be in touch</h5>
  <div class="row teleform">
    <div class="col-md-6">
      <input
        type="text"
        class="text"
        [(ngModel)]="model.first_name"
        name="first_name"
        #first_name="ngModel"
        placeholder="First Name"
        pattern="[a-zA-Z\s]*$"
        required (change) ="model.first_name = model.first_name.trim()"
      />
      <span
        class="text-danger"
        *ngIf="
          (first_name.touched || signupform.submitted) &&
          first_name.errors?.required
        "
      >
        First Name is required
      </span>
      <span
        class="text-danger"
        *ngIf="
          (first_name.touched || signupform.submitted) &&
          first_name.errors?.pattern
        "
      >
        Only Alphabets are allowed
      </span>
    </div>
    <div class="col-md-6">
      <input
        type="text"
        class="text"
        pattern="[a-zA-Z\s]*$"
        [(ngModel)]="model.second_name"
        name="second_name"
        #second_name="ngModel"
        placeholder="Second Name"
        required
        (change) ="model.second_name = model.second_name.trim()"
      />
      <span
        class="text-danger"
        *ngIf="
          (second_name.touched || signupform.submitted) &&
          second_name.errors?.required
        "
      >
        Second Name is required
      </span>
      <span
        class="text-danger"
        *ngIf="
          (second_name.touched || signupform.submitted) &&
          second_name.errors?.pattern
        "
      >
        Only Alphabets are allowed
      </span>
    </div>
    <div class="col-md-6">
      <input
        type="text"
        class="text"
        [(ngModel)]="model.company"
        name="company"
        #company="ngModel"
        placeholder="Company"
        required
        (change) ="model.company = model.company.trim()"
      />
      <span
        class="text-danger"
        *ngIf="
          (company.touched || signupform.submitted) && company.errors?.required
        "
      >
      Company is required
      </span>
   
    </div>
    <div class="col-md-6 telephone" style="margin-top:10px;">
        <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
        [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
        [selectedCountryISO]="CountryISO.India" [maxLength]="15"
        [phoneValidation]="true" [separateDialCode]="separateDialCode" 
        [numberFormat]="PhoneNumberFormat.National" name="phone" #phone="ngModel" required   [(ngModel)]="model.phone">
    </ngx-intl-tel-input>
      <span
        class="text-danger"
        *ngIf="
          (phone.touched || signupform.submitted) &&
          phone.errors?.required
        "
      >
      Phone is required
      </span>
      <span class="text-danger"
      *ngIf="(phone.touched || signupform.submitted) &&!phone.errors?.required &&  phone.errors?.validatePhoneNumber?.valid===false">
      Invalid Phone number
    </span>
    </div>
    <!-- <div class="col-md-12">
        <input
        type="text"
        class="text"
        [(ngModel)]="model.campus"
        name="campus"
        #campus="ngModel"
        placeholder="Campus"
        required
      />
      <span
      class="text-danger"
      *ngIf="
        (campus.touched || signupform.submitted) && campus.errors?.required
      "
    >
    Campus is required
    </span>
 
    </div> -->
    <div class="col-md-6"></div>
    <div class="col-md-12">
      <textarea
        class="text"
        name="description" required
        [(ngModel)]="model.description"
        #description="ngModel"
        placeholder="How can we help?"
        style="height: 100px"         (change) ="model.description = model.description.trim()"

      ></textarea>
      <span
      class="text-danger"
      *ngIf="
        (description.touched || signupform.submitted) && description.errors?.required
      "
    >
    How we can help is required
    </span>
    </div>
  </div>
  <div>
    <input type="submit" class="btn join" value="Submit" />
  </div>
  <span class="text-danger" *ngIf="this.err_message"> {{ err_message }}</span>
</form>
