import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-bootcamp-history',
  templateUrl: './bootcamp-history.component.html',
  styleUrls: ['./bootcamp-history.component.scss']
})
export class BootcampHistoryComponent implements OnInit {
  bootcamps:any=[];
  constructor( private userService:UserService) { }

  ngOnInit(): void {

    this.userService.getUser().subscribe(data=>{
      console.log(data)
     
      this.bootcamps=data.applied_bootcamp;
  })
}

}
