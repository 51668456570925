import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
headers;
  constructor(public http: HttpClient) { 
  
  }

  getHomeShortTermCourses(token): any {
    const data = this.http.get('https://edubex.arlo.co/api/2012-02-01/pub/resources/eventsearch/?fields=EventID,EventTemplateID,Name,Code,Summary,Description,StartDateTime,EndDateTime,StartTimeZoneAbbr,EndTimeZoneAbbr,TimeZoneID,TimeZone,Sessions,Location,IsFull,PlacesRemaining,Categories,AdvertisedOffers,SessionsDescription,Presenters,Notice,ViewUri,Tags,RegistrationInfo,Provider,TemplateCode,TemplateTags,Credits,IsPrivate,ClientOrganisation,ClientKeyContact,isConfirmed&includeTotalCount=true&format=json&facets=locname(top=10,orderby=count),presenter(top=10)'); 
    return data;
  }
  getLinkedinToken(data): any {
     return this.http.post(' https://www.linkedin.com/uas/oauth2/accessToken',data);    
  }
 

 
}
