import { Component,  ElementRef,  OnInit, ViewChild  } from '@angular/core';
  import { NgbCarouselConfig ,NgbModal,NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
  import {faRupeeSign,faUsers,faArrowRight, faDollarSign,faChevronRight,faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
  import{faClock,} from '@fortawesome/free-regular-svg-icons';
  import { ActivatedRoute, Router } from '@angular/router';
  import { MasterService } from 'src/app/core/services/master/master.service';
  import * as _ from 'lodash';
  import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';

  import { CourseService } from 'src/app/core/services/course/course.service';
import * as moment from 'moment-timezone';
import { trigger, state, style, transition, animate, group } from '@angular/animations';
import { LoginComponent } from '../login/login.component';
import { UserService } from 'src/app/core/services/user/user.service';
import { BootcampEnrollmentComponent } from '../bootcamp-enrollment/bootcamp-enrollment.component';
declare var bootbox;
@Component({
  selector: 'app-bootcamp',
  templateUrl: './bootcamp.component.html',
  styleUrls: ['./bootcamp.component.scss']
})
export class BootcampComponent implements OnInit {

  faRupeeSign = faRupeeSign;
  faChevronCircleDown = faChevronCircleDown;
  faArrowRight = faArrowRight;
  faClock = faClock;
  faUsers= faUsers;
  faChevronRight= faChevronRight;
  faDollarSign = faDollarSign;
  page :String ='program-list';
  longTerm:any;
  courseList: any=[];
  cat:any =[];
  partner:any=[];
  country:any =[];
  guest_experts:any=[]
  allCategories:any = [];
  allCountries:any = [];
  courses = [];
  allCourses=[];
  offsetTmz=[];
  title:String ="All Categories";
  live_online = 0;
  clicked;
  myIndex=-1
  user: any;
  gallery:any=[];
  // title = 'mdb-angular-ui-kit-free';

  @ViewChild('container', { static: true }) container: ElementRef<HTMLElement>;

  constructor(public router: Router,config: NgbCarouselConfig,configure: NgbTabsetConfig,
    private masterService:MasterService,private courseService:CourseService,public modalService:NgbModal,
    private _activatedRoute:ActivatedRoute,private userService:UserService) {
   
    let timeZones = moment.tz.names();      
    for(var i in timeZones)
      {
        this.offsetTmz.push(" (UTC"+moment.tz(timeZones[i]).format('Z')+") " + timeZones[i]);
      }
      this.clicked=this.courseService.countrycode;
  }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(params => {
      console.log(params);  
      this.getBootcampCourses();
      this.setup();

        
    }) 
  }



  setup() {
    const container = this.container.nativeElement;

    Array.from(container.children).forEach((child: any, i) => {
      const order = i % 3;
      child.style.order = order;
    });
  }
  changeCountry(country){
    this.clicked =country
    this.courses.forEach(course=>{
      // list.forEach(course=>{
        console.log("getcourse--------------->",course)
        // if(Object.keys(course.time_zone).some(function(k){ return ~k.indexOf(country) })){
          course['start_time']=course.time_zone[country+"_start"];
          course['end_time']=course.time_zone[country+"_end"]
          console.log("course---------",course)

      //  }
      // })
    })
  }
  getBootcampCourses(){
    this.courseService.getBootcamps().subscribe(res=>{
      this.allCategories = res.all_categories; 
      let courses = res.bootcamp;
      this.guest_experts=res.guest_experts;
      this.partner=res.partner;
      this.gallery=res.gallery;
      console.log("code",this.courseService.countrycode);

      if(courses)
      courses.forEach(ele => {
      
        ele['start_time'] = ele.time_zone[this.courseService.countrycode+"_start"];
        ele['end_time'] = ele.time_zone[this.courseService.countrycode+"_end"];
        const [d, M, y] = ele.date.match(/\d+/g);
        const D = new Date(d,M-1,y);
        console.log(y,M,d);
        ele.date = D;

      });
      // this.courseList= res.data;
      this.courses=courses;
      this.allCourses=courses;
    // this.courses= this.itemsGroupedByMonth(courses);
        console.log("group",this.courses) 
      // console.log("res",this.allCategories,this.allCountries)
    })
    // for (const key of this.allCategories) {
    
    //     key.active=false;;
    // }
    // if (~c.indexOf("Yes"))
  }
  reload(event){
    this.getBootcampCourses();
    // window.location.reload();

  }
  enterOtp(form,err){
    console.log("form-----------------",form)
    if(this.userService.isLoggedIn()){
      this.userService.getUser().subscribe(data=>{
        console.log(data)        
        this.user = data.profile;
        let details={
          email:this.user.email,
          course_slug:form
        }
        this.courseService.getOtp(details).subscribe(
          res => {
            console.log("otpppppp",res)
            if(res.status===3){ 
              this.router.navigateByUrl('/bootcamp-details/'+form);
              bootbox.dialog({ message:"Course applied successfully",
                closeButton: true})
            }
          })
      });

        

    }else{
      let that = this;
      bootbox.prompt({
        title: "Enter Email id",
        message:err?err:'Please enter email!',
        centerVertical: true,
        callback: function(result){
          if(result){
            console.log(result);
            let details={
              email:result,
              course_slug:form
            }
            that.courseService.getOtp(details).subscribe(
              res => {
                console.log("otpppppp",res)
                if(res.status===3){
                  // that.userService.setToken(res.token); 
                  localStorage.setItem('JWT_TOKEN', res.token);
  
                  //  that.onSubmit(form,'close')
                  that.router.navigateByUrl('/bootcamp-details/'+form);
                  bootbox.dialog({ message:"Course applied successfully",
                  closeButton: true})
                }else{
                  bootbox.prompt({
                    title: "Enter Verification Code",
                    message:err?err:'Please check your email for the verification code!',
                    inputType: 'number',
                    centerVertical: true,
                    callback: function(result){
                        console.log(result);
                        if(result){
    
                          that.courseService.verifyOtp({
                            otp:result,
                            user_id:res.user_id,
                            course_slug:form
                          }).subscribe(
                            res => {
                              console.log("res--->",res)
                               if(res.status==1){
                                localStorage.setItem('JWT_TOKEN', res.token);
    
                                //  that.onSubmit(form,'close')
                                that.router.navigateByUrl('/bootcamp-details/'+form);
    
                              }else if(res.status==2){
                                var dialog = bootbox.dialog({
                                  className: 'block',
                                  message: "You have exceeded the limit of verification code tries. Please contact Admin !!",
                                  closeButton: true
                                });
                              }else
                                   that.enterOtp(form,"<span style='color: red;'>Verification failed. Try again!</span>");
    
                            },
                            err => {
                              console.log("err", err)
                            });
                        }else{
                          bootbox.hideAll();
    
                          if(result!==null)
                          that.enterOtp(form,"<span style='color: red;'>Enter valid verification code. Try again!</span>");
                          return false;
                        }
    
                    }
                });
                }
              
  
              },
              err => {
                console.log("err", err)
              });
  
          }else{
  
            bootbox.hideAll();
  
            if(result!==null)
            that.enterOtp(form,"<span style='color: red;'>Enter valid Phone Number. Try again!</span>");
            return false;
            }
  
  
        }
    });
    }
  
  }
  saveOrder(course){
    this.userService.getUser().subscribe(data=>{
      console.log("user",data)
      this.courseService.saveEnrollDetails({
        course_id:course.id,
        documents:[],
        user_id:data.profile.id,
        fields:[]     
    
      }).subscribe(async (resp: any) =>{
        console.log("res",resp)  
        var dialog = bootbox.dialog({
          message: "Thank you for applying to the course.The enrollment will be completed once the admin verifies your request. You will receive an email confirmation once the admin approves your request.",
          closeButton: true
        }); 
        window.location.reload();

        // this.getLongTermCourses();
      }, (err: any) => {
        console.log(err);
      })
    })
  }
  chckMonth(start,end){
    if(new Date(start).getMonth()===new Date(end).getMonth())
    return true;
    else return false;
  }
  goToCheckout(course){
    console.log("currency",course)
    var _this = this;
    bootbox.confirm({
      message:"<h5>Confirm Payment!</h5><h6>Course Fees:"+course.currency+ ' '+course.price+"</h6>", 
        buttons: {
          confirm: {
            label: 'Submit',
            className: 'success'
          },
            cancel: {            
              className: 'fail'
          }
      },
        callback:function(result){      
        if(result){
          _this.saveOrder(course)
        }  
      }   
    });
  }
  openModal(slug) {
    const modalRef = this.modalService.open(LoginComponent,
      {
      scrollable: true,
      windowClass: 'myModalClass',
      // keyboard: false,
      // backdrop: 'static'
      });
  
    let data = {
      prop1: 'bootcamp',
      prop2: slug,
      prop3: 'This Can be anything'
    }
  
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
    }
    selectIndex(i){
      this.myIndex=i;
    }

  getSchedule(cat,country,live_online){
    console.log("cat",cat)
   let courses= this.allCourses.filter(course=>{
     console.log("catttttt",course)
     return course.category && course.category.title==cat[0]
   })
   if(courses.length)     
      // this.courses= this.itemsGroupedByMonth(courses);
      this.courses=courses;
    else this.courses =[];    

   
  }
   itemsGroupedByMonth = (items)=> {
    let monthLabels = ["Jan", "Feb", "March",
    "April", "May", "June",
    "July", "Aug", "Sep",
    "Oct", "Nov", "Dec"];
        let groups = [[], [], [], [], [], [], [], [], [], [], [], [],],
        itemGroupedByMonths = [];
    if(items){
      for (var i = 0; i < items.length; i++) {
        const [d, M, y] = items[i].date.match(/\d+/g);
        const D = new Date(y, M-1, d); 
        console.log(D)
        items[i].date =D;

        groups[D.getMonth()].push(items[i]);
      }
      for (var i = 0; i < groups.length; i++) {
        if (groups[i].length) {
          console.log("get",groups[i][0].date)

          itemGroupedByMonths.push({
            month: monthLabels[i]+' '+new Date(groups[i][0]['date']).getFullYear(),
            items: groups[i]
          });
  
        }
      }
    }
  
    return itemGroupedByMonths;
  }; 

  aluminiOptions: OwlOptions = {
    loop: true,
    autoplay:true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
      items: 2 
      },
      400: {
      items: 2
      },
      767: {
      items: 3
      },
      992: {
      items: 4,
      loop:false
      },
      1200: {
      items: 5,
      loop:false
      }
    },
    nav: false
    }

  selectCat(cat){
    if(cat){
      this.cat= [];
      console.log("cat",cat,this.allCategories);
      // this.allCategories.forEach(element => {
      //   if(element.id===cat.id){
      //     element.active =true
      //   }else element.active = false;
        
      // });
    let selectCategory=this.allCategories.filter(cate=>cate.slug===cat)[0]
      console.log("select",selectCategory)
      // if(event.target.value !=="")
      this.cat.push(selectCategory.title)
      this.getSchedule(this.cat,this.country,this.live_online)
    }else this.getBootcampCourses();
    

  }
  selectCountry(event,title){
    this.country = [];
    console.log("cat",event.target.value,title);
    if(event.target.value!=="")
    this.country.push(event.target.value)
    this.getSchedule(this.cat,this.country,this.live_online)
  }
  
  setLive(){
    console.log("online",this.live_online);
    if(this.live_online===1)
    this.live_online = 0;
    else this.live_online=1;
    this.getSchedule(this.cat,this.country,this.live_online)
  }
  openApplyModal(course) {
		const modalRef = this.modalService.open(BootcampEnrollmentComponent,
		  {
			scrollable: true,
			// windowClass: 'myCustomModalClass',
			// keyboard: false,
			// backdrop: 'static'
		  });
	
		let data = {
		  prop1: 'bootcamp-details',
		  prop2: course.slug,
		  course: course,
		}
	
		modalRef.componentInstance.fromParent = data;
		modalRef.result.then((result) => {
		}, (reason) => {
		});
	  }
}

