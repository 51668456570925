import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { CourseService } from 'src/app/core/services/course/course.service';
declare var bootbox;
@Component({
  selector: 'app-book-call',
  templateUrl: './book-call.component.html',
  styleUrls: ['./book-call.component.scss']
})
export class BookCallComponent implements OnInit {
model:{
  first_name:'',
  last_name:'',
  email:'',
  phone:''
}

separateDialCode = false;
SearchCountryField = SearchCountryField;
CountryISO = CountryISO;
PhoneNumberFormat = PhoneNumberFormat;
preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  constructor(public courseService:CourseService,private router:Router) { }

  ngOnInit(): void {
  }
  onSubmit(form){
    console.log("form",form)
    // if(form.val)
    if(form.valid){
      let data = form.value;
      data.phone = form.value.phone?.e164Number;
  
      this.courseService.bookACall(data)
      .subscribe(result=>{
        console.log("result",result);
        if(result.status){
          // var dialog = bootbox.dialog({
          //   message: "Call booked successfully",
          //   closeButton: true
          // });

          form.resetForm()
          this.router.navigate(['/calendly']);
        }
  
        // this.router.navigate(['/course-payment/'+result.order_id]);
      
      })
      
    }
    }
  

}
