<div *ngIf="router.url !== '/login'" class="continer-fluid footer">
    <div class="container-delivery">
        <div class="row last-ffot-row">
            <div class="col-lg-6 col-md-12">
                <div class="col-md-12">
                    <div class="col-md-4 col-sm-6 col-6 float-left">
                        <div class="row">
                            <h4 class="head"><b>About</b></h4>
                            <a role="button" (click)="goToOurStory()">Our Story</a>
                            <a role="button" (click)="goToBlog()">Blogs</a>
                            <!-- <a role="button" (click)="goToSynapse()" >Synapse</a> -->
                            <a role="button" (click)="goToUniversityPathway()">University Pathways</a>
                            <a role="button" (click)="goToBecomeInstructor()">Become an Instructor</a>
                            <a role="button" (click)="goToCareers()">Careers</a>
                            <a role="button" (click)="goToContactUs()">Contact Us</a>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 col-6 float-left">
                        <div class="row">
                            <h4 class="head"><b>Resources</b></h4>
                            <a role="button" (click)="goToPrivacyPolicy()">Privacy Policy</a>
                            <!-- <a role="button" (click)="goToCookiePolicy()">Cookie Policy</a> -->
                            <a role="button" (click)="goToGdpr()">GDPR</a>
                            <a role="button" (click)="goToTermsOfService()">Terms of Service</a>
                            <a role="button" (click)="goToSchedule()">Schedule</a>
                            <a role="button" (click)="goToWebinars()">Webinars</a>
                            <!-- <a role="button" (click)="goToEvents()">Events</a> -->
                            <a>
                                <fa-icon [icon]="faCopyright"></fa-icon> {{year}} Edubex
                            </a>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 float-left">
                        <div class="row">
                            <h4 class="head"><b>Latest Courses</b></h4>

                            <div *ngFor="let course of courses|slice:0:4">
                                <a role="button" (click)=goToProgram(course?.master_slug)>{{course?.title}}</a>

                            </div>

                            <!-- <h4 class="head"><b>Students and Alumni</b></h4>
                            <a role="button" (click)=goToAcademicPolicies()>Academic Policies</a>
                            <a role="button" (click)="goToExamGrades()">Examination & Grades</a> 
                            <a role="button" (click)="goToNetworkBenefits()">Edubex Network Benefits</a> 
                            <a role="button" (click)="goToParticipantSpeaks()">Participant Speaks</a> -->

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 text-right left767">
                <div class="row" style="margin:0px">
                    <div class="col-lg-3 col-md-4 col-sm-6 float-left">
                        <div class="row">
                            <h4 class="head"><b>More</b></h4>
                            <!-- <a role="button" (click)="goToOnDemand()">On Demand</a> -->
                            <a role="button" (click)="goToCalendar()">Calendar</a>
                            <!-- <a role="button" (click)="goToPresenters()">Presenters</a> -->
                            <a role="button" (click)="goToCatalog()">Catalog</a>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-4 col-sm-6 float-left">
                        <div class="row">
                            <h4 class="head"><b>Learn on the Go</b></h4>
                            <div class="row">
                                <div class="col-6 float-left ">
                                    <a href="https://edubex.instructure.com/login/canvas" target="_blank"><img class="mob-applogo" src="../../../assets/images/logo/google-play.png" alt="google-play"></a>
                                </div>
                                <div class="col-6 float-left ">
                                    <a href="https://edubex.instructure.com/login/canvas" target="_blank"><img class="mob-applogo" src="../../../assets/images/logo/app-store.png"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 last-col-foot">
                        <a role="button" (click)="goHome()"><img src="../../../assets/images/logo_white.svg" class="footer-whitelogo"></a>
                        
                        <div class="row social-icons" style="margin:0px">
                            <div class="col-md-12" style="padding:0px">
                                <span class="edubex-social-instagram" (click)="goToInsta()">
                                <fa-icon [icon]="faInstagram"></fa-icon>
                                </span>
                                <span class="edubex-social-facebook" (click)="goToFb()">
                                    <fa-icon [icon]="faFacebook"></fa-icon>
                                
                                </span>
                                <span class="edubex-social-twitter" (click)="goToTwitter()">
                                <fa-icon [icon]="faTwitter"></fa-icon>
                                </span>
                                <span class="edubex-social-whatsapp" (click)="goToWhatsapp()">
                                    <fa-icon [icon]="faWhatsapp"></fa-icon>                          
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin:0px">
                    <div class="col-md-12">
                        <!-- <p >Sign up to receive exclusive updates on courses and events</p> -->
                    </div>
                    <div class="col-lg-4"></div>
                    <div class="col-lg-8 col-10">
                        <!-- <div class="input-group mycustom">
                            <input type="text" class="rounded-0" placeholder="email" aria-describedby="inputGroupPrepend2">
                            <div class="input-group-prepend">
                              <input type="submit" value="Sign Up" class="btn more btn-sm rounded-0" id="inputGroupPrepend2">
                            </div>
                          </div> -->

                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <p class="text-center">Copyright
                <fa-icon [icon]="faCopyright"></fa-icon> edubex {{year}}</p>
        </div>
    </div>


</div>