 <div class="row dashboard">
    <div class="container-delivery">
        <div class="personal">
            <div class="row" style="margin:0px">
                <div class="col-lg-6 main">
                    <div class=" user">
                        <h6 class="heading">Transaction History</h6>
                        <div>
                            <app-history [history]="history$ | async" (scrolling)="onScrolling()">
                            </app-history>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 main">
                    <div class="user">
                        <h6 class="heading">Payment Dues</h6>
                        <div class="course-applied">
                            <div>
                                <app-multiselect [dues]="dues$ | async" (scrollingFinished)="onScrollingFinished()">
                                </app-multiselect>

                                <!-- <div class="group ">
                              <div></div> 
                              <div class="float-left det"></div> 
                               <div class="float-right det"routerLink="/" >View More</div>  -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 main" *ngIf="bootcamp_history.length">
                    <div class=" user">
                        <h6 class="heading">Bootcamp History</h6>
                        <div >
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>
                                            Bootcamp
                                        </th>
                                        <th>
                                            Amount
                                        </th>
                        
                                        <th style="width:81px">
                                            Date
                        
                                        </th>
                        
                                    </tr>
                                </thead>
                                <tbody>
                        
                        
                                    <tr *ngFor="let his of bootcamp_history">
                                        <td  > {{his.bootcamp?.title}}</td>
                                        <td> {{his?.bootcamp.currency}} {{his?.amount| number : '1.2-2'}}</td>
                                        <td> {{his?.created_at|date:'dd/MM/yyyy'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 main" *ngIf="webinar_history.length">
                    <div class=" user">
                        <h6 class="heading">Webinar History</h6>
                        <div >
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>
                                            Webinar
                                        </th>
                                        <th>
                                            Amount
                                        </th>
                        
                                        <th style="width:81px">
                                            Date
                        
                                        </th>
                        
                                    </tr>
                                </thead>
                                <tbody>
                        
                        
                                    <tr *ngFor="let his of webinar_history">
                                        <td  > {{his.webinar?.title}}</td>
                                        <td> {{his?.webinar.currency}} {{his?.amount| number : '1.2-2'}}</td>
                                        <td> {{his?.created_at|date:'dd/MM/yyyy'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 main" *ngIf="freecourse_history.length">
                    <div class=" user">
                        <h6 class="heading">Freecourse History</h6>
                        <div >
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>
                                            Freecourse
                                        </th>
                                        <th>
                                            Amount
                                        </th>
                        
                                        <th style="width:81px">
                                            Date
                        
                                        </th>
                        
                                    </tr>
                                </thead>
                                <tbody>
                        
                        
                                    <tr *ngFor="let his of freecourse_history">
                                        <td  > {{his.freecourse?.title}}</td>
                                        <td> {{his?.freecourse.currency}} {{his?.amount| number : '1.2-2'}}</td>
                                        <td> {{his?.created_at|date:'dd/MM/yyyy'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>


    </div>