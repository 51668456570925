import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import {faArrowCircleRight} from '@fortawesome/free-solid-svg-icons';
import { CmsService } from 'src/app/core/services/cms/cms.service';
import {NgForm} from '@angular/forms';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { CourseService } from 'src/app/core/services/course/course.service';
import { isThisQuarter } from 'date-fns';
declare var bootbox;

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit {
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef; 

  faArrowCircleRight = faArrowCircleRight;
  page :String ='our-service';
  allotted_time=["10:00 am","11:00 am","12:00 pm","1:00 pm","2:00 pm","3:00 pm", "4:00 pm", "5:00 pm"]
  message:'';
  model ={
    date:'',
    time:'',
    first_name:'',
    last_name:'',
    phone :'',
    email:''
  };
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  email_err='';
  date_err='';
  time_err='';
  selectedDate;
  selectedTime;
  constructor(private cmsService:CmsService, private courseService:CourseService) {
    this.getServices();
    this.getStandApartInro();
   }
 
  services:any;
  stand_apart:any;
  service:any;
  ngOnInit(): void {
  

  }
  toggleVideo(event: any) {
    console.log("event", this.videoplayer.nativeElement.play())
    this.videoplayer.nativeElement.play();
  }
  getStandApartInro(){
    this.cmsService.getPageInro('how-we-stand-apart').subscribe(res=>{     
      this.stand_apart = res['cms'];
     

		})
  }

  getServices(){
		this.cmsService.getServices().subscribe(res=>{     
      this.services = res['stories'];
		})
  }
  onTimeChange(event){
    this.selectedTime = event.target.value;
    this.time_err=""; 

  }
  onSubmit(form){
    console.log("form",form,this.selectedDate,this.date_err)
     if(!this.selectedDate) this.date_err="Select Date" ;
     if(!this.selectedTime) this.time_err="Select Time" ;

    // if(form.val)
    if(form.valid){
      let data = form.value;
      data.phone = form.value.phone?.e164Number;
  
      this.courseService.bookACall(data)
      .subscribe(result=>{
        console.log("result",result);
        if(result.status){
          var dialog = bootbox.dialog({
            message: "Call booked successfully",
            closeButton: true
          });

          form.resetForm()
        }
  
        // this.router.navigate(['/course-payment/'+result.order_id]);
      
      })
      
    }
  }
  // messageHandler(message){
  //   console.log("message",message)
  //   this.message = message;
  //     }

      messageHandler(message){
        console.log("message",message);
        // this.message = message;
        var dialog = bootbox.dialog({
          message: message,
          closeButton: true
        });
        // document.getElementById('successMessage').style.display='block';
        // setTimeout(function(){
        //   // this.message="hi everybody";
        //   document.getElementById('successMessage').style.display='none';
        // },3000);    
      }

    }


