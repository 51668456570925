import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class CmsService {

  constructor(private api: ApiService) { }

  getBanner(page): any {
    const data = this.api.get('api/v1/cms/banner/get', { slug: page });
    return data;
  }
  addContact(user){
    const data = this.api.post('api/v1/contact/add',user);
    return data;
  }
  addSponserContact(user){
    const data = this.api.post('api/v1/sponsor/add',user);
    return data;
  }
  getAlumini(){
    return this.api.get('api/v1/alumini/all');
  }
  getServices(){
    return this.api.get('api/v1/service/all');
  }
  getPageInro(page):any{
   
    return this.api.get('api/v1/cms/pages/get', { slug: page });
  }
  getStandApart(){
    return this.api.get('api/v1/standapart/all');
  }
  signupEdubex(data){
    return this.api.post('api/v1/instructor/apply',data);
  }
  scholarshipApply(data){
    return this.api.post('api/v1/scholarship/apply',data);
  }
  
  signupTeachEdubex(data){
    return this.api.post('api/v1/contact/corporate-training',data);
  }
  careerApply(data){
    return this.api.postApply('api/v1/career/apply',data);
  }
  getCourseDetails(data){
    return this.api.post('api/v1/course/get',data);
  }
 





}
