<div class="row" style="margin:0px">
    <app-banner [page]="page" style="width:100%"></app-banner>
    <div class="container-fluid">
        <div class="container-delivery">
            <div class="row degree" style="margin-left:0px;margin-right:0px">
                <div class="col-md-6 " style="padding:0px">
                    <img  style="width:100%" src="{{universityPathway?.image}}" alt="{{universityPathway?.image_alt}}">
                </div>
                <div class="col-md-6 " style="padding:0px">
                <div class="degreetxt">
                    <h4>{{universityPathway?.title}}</h4>
                    <div class="underline"></div>
                   <div [innerHTML]="universityPathway?.description" class="div-p" style="margin-top:10px;display:inline-block"></div>
                </div>
                </div>
            </div>
            <div class="row" style="width:100%;margin:0px">
                <div class="col-md-12" style="padding:0px">
                    <app-page-details [page]='details'></app-page-details>                
                </div>
            </div>
        </div>
    </div>
 <div style="width:100%">
    <app-university-progression-examples></app-university-progression-examples>
 </div>
</div>

<div class="row container-delivery">
    <div class="col-md-12">
        <app-find-career></app-find-career>
    </div>
</div>

<div class="container-delivery">
    <div class="col-fixed">
        <div class="bottom" >
            <app-bottom-bar></app-bottom-bar>
    
        </div>
    </div>
    </div>