import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { CmsService } from 'src/app/core/services/cms/cms.service';
declare var bootbox;
@Component({
  selector: 'app-get-registered',
  templateUrl: './get-registered.component.html',
  styleUrls: ['./get-registered.component.scss']
})
export class GetRegisteredComponent implements OnInit {

  @Output() childEvent = new EventEmitter();
  @ViewChild('modal') private modalContent: TemplateRef<GetRegisteredComponent>
  @Input() fromParent;
  faTimesCircle=faTimesCircle
  model ={
    first_name:'',
    second_name:'',
    phone:'',
   email:''
  };
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  campus: any;
  discipline: any;
  err_message: string;
    constructor(private cmsService:CmsService,public activeModal: NgbActiveModal) { }
  
    ngOnInit(): void { 
    }
    closeModal(sendData) {
      console.log("send",sendData)
      this.activeModal.close(sendData);
    }
  onSubmit(form){
    console.log(form.value);
 
    let data = form.value;
    data.phone = form.value.phone?.e164Number;
    this.childEvent.emit(data);
    this.closeModal('dismiss');
  }

 

}
