import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    new Promise(resolve => {
      this.loadScript();
    });
  }

  public loadScript() {
    const node = document.createElement('script');
    node.src = 'https://assets.calendly.com/assets/external/widget.js'; // put there your js file location
    node.type = 'text/javascript';
    node.async = true;
    // node.charset = 'utf-8';
   document.getElementsByTagName('head')[0].appendChild(node);

}
}