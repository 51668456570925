import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CmsService } from 'src/app/core/services/cms/cms.service';

@Component({
  selector: 'app-corporate-training',
  templateUrl: './corporate-training.component.html',
  styleUrls: ['./corporate-training.component.scss']
})
export class CorporateTrainingComponent implements OnInit {


  page :String ='corporate-training';
 
  instructor: any;
  expect: any;
  subject: any;
  options: any;
  // encapsulation: ViewEncapsulation.None
  constructor(public sanitizer: DomSanitizer,private cmsService:CmsService) { }

  ngOnInit(): void {
    this.getPrograms();
    this.getTrainingOptions();
    this.getTeachingOptions();
  }

 getTrainingOptions(){
    this.cmsService.getPageInro('training-options').subscribe(res=>{     
      this.expect= res['cms'];  
      console.log("expect",res)
		})
  }
  getPrograms(){ 
    this.cmsService.getPageInro('rewamp-your-work-programs').subscribe(res=>{     
      this.subject= res['cms'];  
      console.log("teach",res)
		})
  }
  getTeachingOptions(){
    this.cmsService.getPageInro('flexible-teaching-options').subscribe(res=>{     
      this.options= res['cms'];  
      console.log("options",res)
		})
  }
}
