
<div class="container-delivery univ-col">
    <div class="row sunbcol" style="margin:0px 15px">
    <div class="col-md-12 subn-col">
        <div class="row ">
            <div class="col-md-7">
                <div class="">
                    <div class="card-text">
                        <h4 style="margin-top:50px">{{examples?.title}}</h4>
                        <div class="underline" style="margin-left:0px!important"></div>
                        <ul>
                            <div class="col-md-12" style="padding:0px">
                                <div class="list" [innerHTML]="examples?.description"></div>
                            </div>                           
                        </ul>           
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <img src="{{examples?.image}}"  class="card-img"  alt="{{examples?.image_alt}}">
            </div>
        </div>
    </div>
</div>
</div>