import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Router } from "@angular/router";
import { UserService } from '../user/user.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private userService : UserService,private router : Router){}

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        
        // if (req.headers.get('noauth'))
        //     return next.handle(req.clone());
        // else {
        //     const clonedreq = req.clone({
        //         headers: req.headers.set("Authorization", "Bearer " + this.userService.getToken())
        //     });
            return next.handle( this.addAuthenticationToken(req)).pipe(
                tap(
                    event => { },
                    err => {
                        if (err.error.auth == false) {
                           this.router.navigateByUrl('/');
                        }
                        if(err.status===401){                           
                            this.userService.deleteToken();
                            this.router.navigateByUrl('/');
                        }
                    })
            );
        }
        addAuthenticationToken(request) {
            
            const accessToken = this.userService.getToken();
            if (!accessToken || request.url.includes('https://edubex.arlo.co')) {                
            return request;
            }

            return request.clone({
                setHeaders: {
                    Authorization:"Bearer " +accessToken
                }
            });
        }
    }