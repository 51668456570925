<ngx-loading [show]="page_load" [config]="{  fullScreenBackdrop:true,backdropBackgroundColour:'#edecec80' }" [template]="customLoadingTemplate"></ngx-loading>
<div class="banner-item">
    <app-course-apply (childEvent)="reload($event)" #apply></app-course-apply>
    <div *ngIf="page==='home'">
        <ngb-carousel *ngIf="banners.length > 0" [showNavigationArrows]="false">
            <ng-template *ngFor="let banner of banners" ngbSlide>
                <div >
                    <div  class="picsum-img-wrapper box">
                        <!-- <ik-image [path]="banner.image" alt="{{banner?.image_alt}}"></ik-image>               -->
                        <img src="{{ banner?.image }}" alt="">
                        <div class="col-md-12" style="position: unset;">
                            <div class="head">
                                <h1><b><div [innerHTML]="sanitizer.bypassSecurityTrustHtml(banner.title)"></div></b></h1>
                                <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(banner.description)" class="none767"></div>
                                <div *ngIf="page=='home'">
                                    <div class="started">
                                        <button class="btn more" style=" font-weight: 500; " (click)="getPrograms()">Explore Programs</button>
                                        <button class="btn start" style="color: #fff;background:#095e37" (click)="openBookcall()">Get Started</button>
            
                                        <!-- <app-register #reg></app-register> -->
                                    </div>
                                </div>
                            </div>
                        </div>
            
                    </div>
                  
                </div>
                </ng-template>
            </ngb-carousel>
    </div>
   
     
    <div *ngFor="let banner of banners| slice:0:1;">
        <div *ngIf="page!=='program-details' && page !=='home'" class="picsum-img-wrapper box">
            <!-- <ik-image [path]="banner.image" alt="{{banner?.image_alt}}"></ik-image>               -->
            <img src="{{ banner?.image }}" alt="">
            <div class="col-md-12" style="position: unset;">
                <div class="head">
                    <h1><b><div [innerHTML]="sanitizer.bypassSecurityTrustHtml(banner.title)"></div></b></h1>
                    <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(banner.description)" class="none767"></div>
                    <div *ngIf="page=='home'">
                        <div class="started">
                            <button class="btn more" style=" font-weight: 500; " (click)="getPrograms()">Explore Programs</button>
                            <button class="btn start" style="color: #fff;background:#095e37" (click)="openBookcall()">Get Started</button>

                            <!-- <app-register #reg></app-register> -->
                        </div>
                    </div>

                    <div *ngIf="page=='refer-and-earn'">
                        <div class="started">
                            <!-- <button class="btn more" style=" font-weight: 500; " (click)="getPrograms()">Explore Programs</button> -->
                            <button *ngIf="!userService.isLoggedIn()" class="btn start" (click)="openReferLoginModal()">Sign Up</button>

                            <!-- <app-register #reg></app-register> -->
                        </div>
                    </div>                </div>
            </div>

        </div>
        <div class="col-md-12 col-pgmbg">
            <div *ngIf="page==='program-details'">
                <div class="pgm" [ngStyle]="{ 'background-image': 'url(' + course?.banner_image + ')'}">
                    <div class="row banns-col">
                        <div class="col-xl-4 col-lg-5 col-md-6">
                            <div class="card-sec">
                                <div class="card-body title" id="banner-form">
                                    <!-- <h4 class=""> <b> Fundamentals of</b> </h4> -->
                                    <h2 class="" style="font-size: 1.5em;"> <b> {{course?.title}}</b></h2>
                                    <div class="row details main-col-frm">
                                        <!-- <div class="col-md-4"><i class="fa fa-map-marker" aria-hidden="true"></i> London <i class="fa fa-caret-down" aria-hidden="true"></i>
                                    </div> -->
                                        <div class="col-md-4" *ngIf="course?.is_online==='1'"> Live Online</div>
                                        <div class="col-md-4" *ngIf="course?.is_online==='2'"> Online</div>
                                        <div class="col-md-4 cole4" style="cursor:pointer" *ngIf="course?.is_online==='1'||course?.is_online==='2'">
                                            <span class="enroll" *ngIf="userService.isLoggedIn() && !course?.is_applied && (course?.documents.length||course?.formfields.length)" (click)="apply.show(course.documents,course.formfields,course?.encrypted_id)">Enroll Now</span>
                                            <span class="enroll" *ngIf="userService.isLoggedIn() && !course?.is_applied && !course?.formfields.length  &&  !course?.documents.length " (click)="goToCheckout(course)">Enroll Now</span>
                                            <!-- <span disabled *ngIf="userService.isLoggedIn() && course?.is_applied" value="Applied"></span> -->
                                            <span class="enroll" *ngIf="!userService.isLoggedIn()" (click)="openLoginModal()">Enroll Now</span>
                                            <span class="enroll" *ngIf="userService.isLoggedIn() && course?.is_applied " (click)="openAppliedModal()">Enroll Now</span>


                                        </div>
                                        <div class="col-md-4" *ngIf="course?.is_online==='3'">
                                            <span class="enroll" *ngIf="userService.isLoggedIn() && !course?.is_applied && (course?.documents.length||course?.formfields.length)" (click)="apply.show(course.documents,course.formfields,course?.encrypted_id)">Enroll Now</span>
                                            <span class="enroll" *ngIf="userService.isLoggedIn() && !course?.is_applied && !course?.formfields.length  &&  !course?.documents.length " (click)="goToCheckout(course)">Enroll Now</span>
                                            <!-- <span disabled *ngIf="userService.isLoggedIn() && course?.is_applied" value="Applied"></span> -->
                                            <span class="enroll" *ngIf="!userService.isLoggedIn()" (click)="openLoginModal()">Enroll Now</span>
                                            <span class="enroll" *ngIf="userService.isLoggedIn() && course?.is_applied " (click)="openAppliedModal()">Enroll Now</span>

                                        </div>
                                        <div class="col-md-4" *ngIf="course?.is_online===''">
                                            <span class="enroll" *ngIf="userService.isLoggedIn() && !course?.is_applied && (course?.documents.length||course?.formfields.length)" (click)="apply.show(course.documents,course.formfields,course?.encrypted_id)">Enroll Now</span>
                                            <span class="enroll" *ngIf="userService.isLoggedIn() && !course?.is_applied && !course?.formfields.length  &&  !course?.documents.length " (click)="goToCheckout(course)">Enroll Now</span>
                                            <!-- <span disabled *ngIf="userService.isLoggedIn() && course?.is_applied" value="Applied"></span> -->
                                            <span class="enroll" *ngIf="!userService.isLoggedIn()" (click)="openLoginModal()">Enroll Now</span>
                                            <span class="enroll" *ngIf="userService.isLoggedIn() && course?.is_applied " (click)="openAppliedModal()">Enroll Now</span>

                                        </div>

                                    </div>
                                    <div class="row details">
                                        <div class="col-md-12">
                                            <b>{{course?.duration}}
                                            <!-- <span>| Applications Close Today</span>  -->
                                            </b>
                                        </div>
                                    </div>
                                    <div class="row details">
                                        <div class="col-md-12" style="font-size: smaller;">
                                            View the {{course?.title }} course packages for pricing and curriculum details
                                        </div>
                                    </div>
                                    <form class="form" #courseForm="ngForm" (ngSubmit)="courseForm.form.valid && onSubmit(courseForm)" novalidate>
                                        <div class="row">
                                            <div class="col-md-6 ">
                                                <input type="text" class=" text" [(ngModel)]="model.first_name" name="first_name" #first_name="ngModel" placeholder="First Name" required pattern="[a-zA-Z\s]*$" (change)="model.first_name = model.first_name.trim()">
                                                <span class="text-danger" *ngIf="(first_name.touched || courseForm.submitted) && first_name.errors?.required">
                                                First Name is required
                                            </span>
                                                <span class="text-danger" *ngIf="(first_name.touched || courseForm.submitted) && first_name.errors?.pattern">
                                            Only Alphabets are allowed
                                        </span>
                                            </div>
                                            <div class=" col-md-6 ">
                                                <input type="text" class=" text" [(ngModel)]="model.second_name" name="second_name" #second_name="ngModel" placeholder="Second Name" pattern="[a-zA-Z\s]*$" required (change)="model.second_name = model.second_name.trim()">
                                                <span class="text-danger" *ngIf="(second_name.touched || courseForm.submitted) && second_name.errors?.required">
                                                Second Name is required
                                            </span>
                                                <span class="text-danger" *ngIf="(second_name.touched || courseForm.submitted) && second_name.errors?.pattern">
                                            Only Alphabets are allowed
                                        </span>
                                            </div>

                                            <div class=" col-md-12 ">
                                                <input type="text" class=" text" [(ngModel)]="model.email" name="email" #email="ngModel" placeholder="Email" required email (change)="model.email = model.email.trim()">
                                                <span class="text-danger" *ngIf="(email.touched || courseForm.submitted) && email.errors?.required">
                                                Email is required
                                            </span>
                                                <span class="text-danger" *ngIf="email.touched && email.errors?.email">
                                                Enter a valid email address
                                            </span>
                                            </div>
                                            <div class=" col-md-12 bannerform" style="margin-top: 10px;
                                        ">

                                                <!-- <input type="text" class=" text" [(ngModel)]="model.phone" name="phone"
                                                #phone="ngModel" placeholder="+91" required phone pattern="[0-9]+"> -->
                                                <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                                    [selectedCountryISO]="CountryISO.India" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National" name="phone" #phone="ngModel" required
                                                    [(ngModel)]="model.phone">
                                                </ngx-intl-tel-input>
                                                <span class="text-danger" *ngIf="(phone.touched || courseForm.submitted) && phone.errors?.required">
                                                Phone is required
                                            </span>
                                                <!-- <span class="text-danger"
                                                *ngIf="(phone.touched || courseForm.submitted) && phone.errors?.pattern">
                                                Only numbers are allowed
                                            </span> -->
                                                <span class="text-danger" *ngIf="(phone.touched || courseForm.submitted) &&!phone.errors?.required &&  phone.errors?.validatePhoneNumber?.valid===false">
                                            Invalid Phone number
                                        </span>
                                            </div>
                                        </div>
                                        <div>
                                            <b><p class="terms" style="font-size: small;">By Clicking "View Course Package",
                                                you accept our terms
                                        </p></b>
                                            <div>
                                                <input type="submit" class="btn download" id="download" value="View Course Package">
                                                <!-- <input type="button" class="btn apply" *ngIf="userService.isLoggedIn() && !course?.is_applied && (course?.documents.length||course?.formfields.length)" (click)="apply.show(course.documents,course.formfields,course?.encrypted_id)" value="Apply Now">
                                <input type="button" class="btn apply" *ngIf="userService.isLoggedIn() && !course?.is_applied && !course?.formfields.length  &&  !course?.documents.length " (click)="goToCheckout(course)" value="Apply Now">
                                <input type="button" class="btn apply" disabled *ngIf="userService.isLoggedIn() && course?.is_applied" value="Applied">
                                <input type="button" class="btn apply" *ngIf="!userService.isLoggedIn()" (click)="openModal()" value="Apply Now"> -->

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-1 col-md-0"></div>
                        <div class="col-xl-5 col-lg-6 col-md-6 img-pgm">
                            <div class="video" *ngIf="course?.video">
                                <!-- <vg-player>
                                <video #media [vgMedia]="media" id="singleVideo" src="{{stand_apart?.video}}" preload="auto" controls>
                                
                                 
                                </video>
                              </vg-player> -->
                                <video width="100%" height="280" class="stand-video" controlsList="nodownload" src="{{course?.video}}" controls (click)="toggleVideo($event)" autoplay #videoPlayer>
                                
                                Browser not supported
                            </video>
                            </div>
                            <!-- <img src="../../../../assets/images/img143.jpg" alt="{{ banner.title }}"> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ng-template #customLoadingTemplate>
    <div class="custom-class" style="width:100%;min-height:400px;">
    
    </div>
</ng-template>