import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  constructor(private api: ApiService) {}
  showToggle = false;
  programTitle:any;
  localStorage
  articulate:any;
  countrycode=localStorage.getItem('COUNTRY')?(localStorage.getItem('COUNTRY')).toLowerCase():'us';

  country=localStorage.getItem('COUNTRY_CODE')?parseInt(localStorage.getItem('COUNTRY_CODE')):236;
  getHomeLongTermCourses(): any {
    const data = this.api.get('api/v1/course/all?country='+this.country);
    return data;
  }
  getUpcomingCourses(option): any {
    const data = this.api.get('api/v1/course/all?displayOption='+option+'&country='+this.country)
    return data;
  }
verifyOtp(data): any {
  return this.api.post('api/v1/freecourse/register-otpcheck',data);

}
verifyWebinarOtp(data): any {
  return this.api.post('api/v1/webinar/register_otpcheck',data);

}
  getOtp(course): any {
    return this.api.post('api/v1/freecourse/register-freecourse',course);
  }
  getWebinarOtp(data): any {
    return this.api.post('api/v1/webinar/register',data);
  }

  getFreeCourses(...args): any {
    console.log("args",args)
    if(args.length){
      var data = this.api.get('api/v1/freecourse/all?country='+this.country+'&category='+args[0]);

    }else{
      var data = this.api.get('api/v1/freecourse/all?country='+this.country);

    }


    return data;
  }
  getBookmark(user_id,course_id){
    let data1={user_id:user_id,course_id:course_id}
    const data = this.api.post('api/v1/bookmark/get',data1);
    return data;
  }
  updateBookmark(body){
    const data = this.api.post('api/v1/bookmark/update',body);
    return data;
  }
  getFreecourseDetails(slug){
    const data = this.api.get('api/v1/freecourse/get?slug='+slug);
    return data;
  }
  getWebinar(): any {
    let webinar =1;
    const data = this.api.get('api/v1/webinar/all?country='+this.country);
    return data;
  }
  getWebinarDetails(slug): any {
    let webinar =1;
    const data = this.api.post('api/v1/webinar/get',slug);
    return data;
  }
  getBootcamps(): any {
    const data = this.api.get('api/v1/bootcamp/all?country='+this.country);
    return data;
  }
  getBootcampDetails(slug): any {
    let webinar =1;
    const data = this.api.post('api/v1/bootcamp/get',slug);
    return data;
  }
  saveWebinarWatch(body): any {

    const data = this.api.post('api/v1/webinar/save_watch',body);
    return data;
  }
  saveBootcampEnrollment(body): any {

    const data = this.api.post('api/v1/bootcamp/enrollment',body);
    return data;
  }
  bootcampFollow(body):any{
    const data = this.api.post('api/v1/bootcamp/follower',body);
    return data;
  }
  bootcampLatest():any{
    const data = this.api.get('api/v1/bootcamp/getlatest');
    return data;
  }
  bootcampInvoice(id){
    return this.api.get('api/v1/bootcamp/showInvoice/'+id);

  }
  bootcampPayment(body){

    return this.api.post('api/v1/bootcamp_order/payment',body);

  }

  webinarInvoice(id){
    return this.api.get('api/v1/webinar/showInvoice/'+id);

  }
  webinarPayment(body){

    return this.api.post('api/v1/webinar_order/payment',body);

  }
  freecourseInvoice(id){
    return this.api.get('api/v1/freecourse/showInvoice/'+id);

  }
  freecoursePayment(body){

    return this.api.post('api/v1/freecourse/payment',body);

  }

  getHomeShortTermCourses(): any {
    const data = this.api.get('api/v1/course/scheduledshortcourses?country='+this.country);
    return data;
  }
  shortTermCourses():any {
    const data = this.api.get('api/v1/course/scheduledshortcourses?country='+this.country+'&trending=1&new=1');
    return data;
  }
  longTermCourses(){
    return this.api.get('api/v1/course/all?country='+this.country+'&trending=1&new=1');
  }
  submitEnquiry(data){
    return this.api.post('api/v1/course/enquiry',data);
  }
  requestBrochure(data){
     return this.api.post('api/v1/course/brochure/request',data)
  }
  getLearningExperiences(){
    return this.api.get('api/v1/course/experiences');
  }
  getHomeLearningExperiences(){
    return this.api.get('api/v1/learningexperience/all');
  }
  getShortSchedule(data){
    console.log("scheduled----",data)
    if (!data.hasOwnProperty('country')) {
      data['country']=this.country;
    }
    return this.api.post('api/v1/course/scheduledshortcourses',data);
  }
  getSchedule(data){
    console.log("scheduled----",data)
    if (!data.hasOwnProperty('country')) {
      data['country']=this.country;
    }
    return this.api.post('api/v1/course/scheduledcourses',data);
  }
  getAllSchedule(data){
    console.log("scheduled----",data)
    if (!data.hasOwnProperty('country')) {
      data['country']=this.country;
    }
    return this.api.post('api/v1/course/scheduledallcourses',data);
  }
  saveOrder(data){
    console.log("data",data)
    return this.api.post('api/v1/order/save',data);
  }
  getOrders(id){
    return this.api.get('api/v1/order/all?id='+id);
  }
  saveEnrollDetails(data){
    console.log("dtaa",data);
    return this.api.postFile('api/v1/course/saveenrollment',data);
  }
  savePayment(data){
    return this.api.post('api/v1/order/payment',data);
  }
  makeOrder(data){
    return this.api.get('api/v1/order/make_order/'+data);
  }
  bookACall(data){
    return this.api.post('api/v1/contact/book-call',data);
  }
  getTrendingCourse(){
    return this.api.get('api/v1/course/all?country='+this.country+'&trending=1');
  }
  getFreeCourseCategory(){
    return this.api.get('api/v1/freecourse/all-category?country='+this.country+'&trending=1&new=1');
  }
  getCoursesByUser(id){
    return this.api.get('api/v1/student/get?user_id='+id)
  }
  sendDataFreecourse(type,content){
    return this.api.get('api/v1/discusswithadvisor/add?type='+type+'&content='+content)




  }
  applyPromocode(data){
    return this.api.post('api/v1/order/apply_promocode',data)
  }
  generateInvoice(data){
    return this.api.post('api/v1/order/generate_invoice',data)
  }
 referAndEarn(id){
    return this.api.get('api/v1/refer_and_earn/get?user_id='+id);
  }
  referAndEarnContent(user){
    if(user)
    return this.api.get('api/v1/refer_and_earn/get_all?user='+user)
    else
    return this.api.get('api/v1/refer_and_earn/get_all')

  }
  // https://staging-admin.edubex.com/api/v1/refer_and_earn/get?id=245
  getWeken = function(fromDate,ToDate) {

		let tijd = 0;
		let weken = 0;
		var een_week = 1000 * 60 * 60 * 24 * 7;
		var d1ms = new Date(fromDate).getTime();
		var d2ms = new Date(ToDate).getTime();
		var verschilms = Math.abs(d1ms - d2ms);
		weken = Math.floor(verschilms / een_week);
    let months = Math.trunc(weken/4);
    let weeks= weken%4;
    console.log("weeks and months",months,weeks)
    let week ="";
    let month=""
    if(weeks==1) week=", " +weeks+" week"
    else if(weeks<1) week="";
    else week =", " +weeks+ " weeks";
    if(months==1) month=months+" month"
    else if(months<1) month="";
    else month= months+ " months";
    let duration = month +week;

		return duration;
	}
}
