<app-banner [page]="'calendar'"></app-banner>
 <div class="container-delivery" style="padding-top:30px;padding-bottom:30px">
<div class="row text-center">
    <div class="col-md-4">
      <div class="btn-group">
        <div
          class="btn calendar-btn"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
          (click)="getToday(viewDate)"
        >
        <i class="fa fa-chevron-left"></i>

          <!-- Previous -->
        </div>
        <div
          class="btn btnToday "
          mwlCalendarToday
          [(viewDate)]="viewDate"
          [class.active]="today"
          (click)="getToday(viewDate)"
        >
          Today
        </div>
        <div
          class="btn calendar-btn"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
          (click)="getToday(viewDate)"
        >
        <i class="fa fa-chevron-right"></i>
          <!-- Next -->
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
    </div>
    <div class="col-md-4">
      <div class="btn-group">
        <div
          class="btn calendar-btn"
          (click)="setView(CalendarView.Month)"
          [class.active]="view === CalendarView.Month"
        >
          Month
        </div>
        <div
          class="btn calendar-btn"
          (click)="setView(CalendarView.Week)"
          [class.active]="view === CalendarView.Week"
        >
          Week
        </div>
        <div
          class="btn calendar-btn"
          (click)="setView(CalendarView.Day)"
          [class.active]="view === CalendarView.Day"
        >
          Day
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="container-delivery" > 
    <div class="d-flex justify-content-center div-cls">                  
        <select  class="text form-control "  (change)=filterByCategory($event) style="margin:30px">
            <option selected value="">All Categories</option>
            <option  *ngFor="let cat of allCategories" [value]="cat.slug" >{{cat.title}}</option>
        </select>  
        <select  class="text form-control" (change)="getLive($event)"  style="margin:30px">
            <option selected value="all">All Locations</option>
            <option value="live" >Live Online</option>
        </select> 
       
    </div>
</div>
  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="CalendarView.Day"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-day-view>
  </div>
  

   <ng-template #modalContent let-close="close">
    <div class="modal-header">
      <h5 class="modal-title">Event action occurred</h5>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        Action:
        <pre>{{ modalData?.action }}</pre>
      </div>
      <div>
        Event:
        <pre>{{ modalData?.event | json }}</pre>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="close()">
        OK
      </button>
    </div>
  </ng-template>
  </div>