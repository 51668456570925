import { Country } from '../country/country.model';
import { Deserializable } from '../deserializable.model';

export class User implements Deserializable {
    // id: number;
    first_name: string;
    last_name:string;
    address: string;
    email: string;
    phone: string;
    dob:string;
    gender:string="0";
    city:string;
    state:string;
    country:string;
    hear_about:string;
    institute:string;
    qualification:string;
    degree_mark:string;    
    month_graduation:string;
    year_graduation:string;
    work_experince_time:string;
    password:string;
    confirmPassword:string;
    referred_by:string;
    deserialize(input: any) {
        Object.assign(this, input);      
        return this;
    }

    // getFullName(): string {
    //     return this.first_name + ' ' + this.last_name;
    // }


}
