import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CourseService } from 'src/app/core/services/course/course.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-articulate',
  templateUrl: './articulate.component.html',
  styleUrls: ['./articulate.component.scss']
})
export class ArticulateComponent implements AfterViewInit {
firstInput = 5;
doc;
url;
slug;
freecourse
user_id:any;
course_id:any;
progress:any;
current_lesson:any;
current_url:any;
constructor(private userService:UserService,private _activatedRoute:ActivatedRoute,private elementRef:ElementRef, public courseService:CourseService, private sanitizer: DomSanitizer) {
  console.log("articulate",this.courseService.articulate);

  this._activatedRoute.params.subscribe(params => {
		console.log("params--------------------------------",params);      
		this.slug = params.id
    if(this.userService.isLoggedIn()){
      this.userService.getUser().subscribe(data=>{
        console.log("user",data.user_id)
        this.user_id=data.user_id
        this.courseService.getFreecourseDetails(this.slug).subscribe((res) => {
          console.log('res', res);
          this.freecourse=res.freecourse;
          this.course_id = this.freecourse.id;
          let url ="/assets/storage/public"+this.freecourse.articulate_content;
          console.log("url",url)
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          this.courseService.getBookmark(this.user_id,this.course_id).subscribe(result=>{
            console.log("result",result)
            url += "/"+result.bookmark[0].current_lesson
            this.url=this.sanitizer.bypassSecurityTrustResourceUrl(url)
          })
    
    
        })
      })
    }

	
  })
  if (window.addEventListener) {
    window.addEventListener("message", this.handleMessage.bind(this), false);
  }
  else {
    (<any>window).attachEvent("onmessage", this.handleMessage.bind(this));
  }
}

ngAfterViewInit() {
  // this.elementRef.nativeElement.querySelector('.nav-sidebar__outline-item__link  .active')
  //                               .addEventListener('click', this.onClick.bind(this));
}

handleMessage(event: Event) {
  let message = event as MessageEvent;
  console.log("message",message)
  console.log(message.data);
  if(message.data.progress!=""){
    let body={
      user_id:this.user_id,
      course_id:this.course_id,
      progress:message.data.progress.p,
      current_url:message.data.url.split('#')[1],
      current_lesson:message.data.url


    }
    this.courseService.updateBookmark(body).subscribe(result=>{
      console.log("result",result)
    })
  }
}

onClick(event) {
  console.log(event);
}

}
