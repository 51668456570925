import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-applied-courses',
  templateUrl: './applied-courses.component.html',
  styleUrls: ['./applied-courses.component.scss']
})
export class AppliedCoursesComponent implements OnInit {
  appliedCourses: any=[];
  constructor(private userService:UserService) { }
  ngOnInit(): void {
    this.getAppliedCourses()
  }

  getAppliedCourses(){
    this.userService.getAppliedCourses()
    .subscribe(result=>{
      console.log("result",result)
      result.forEach(element => {
        if(element)
        this.appliedCourses.push(element);        
      });
    })
  }

}
