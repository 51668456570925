import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'radio',
    template: `
      <div [formGroup]="form">
        <div class="form-check" *ngFor="let opt of field.options" [formControlName]="field.name" ngDefaultControl>
          <input class="form-check-input" [name]="field.name" type="radio" value="opt"(change)="onSelectionChange(opt)">
          <label class="form-check-label">
            {{opt}}
          </label>
        </div>
      </div> 
    `
})
export class RadioComponent {
    @Input() field:any = {};
    @Input() form:FormGroup;
    onSelectionChange(option){
      // field['value']=option;
      // this.form.value[this.field.name]=option;
      this.form.controls[this.field.name]?.setValue(option)
      console.log("field radio-->",this.form)
    }
}