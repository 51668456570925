<div class="container-fluid">
    <div class="container-delivery ">
    <div class="jumbotron text-center" style="margin-top:30px">
        Payment Success!!
        <div routerLink="/" class="backome" style="cursor:pointer">
            Go Back To Home
        </div>
       
    </div>
 
    </div>
</div>