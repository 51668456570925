import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registered-bootcamps',
  templateUrl: './registered-bootcamps.component.html',
  styleUrls: ['./registered-bootcamps.component.scss']
})
export class RegisteredBootcampsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
