<div class="container-fluid">
    <div class="container-delivery">
        <div class="row">
            <div class="col-md-12" style="padding:30px 15px">
             Coming soon!!
            </div>
        </div>

    </div>
</div>
