<form class="form" #contactForm="ngForm" 
(ngSubmit)="contactForm.form.valid && onSubmit(contactForm,page)" novalidate>
<div class="row">
  <div class="col-md-6 ">                      
    <input type="text" class=" text" [(ngModel)]="model.name" name="name"
      #name="ngModel" placeholder="First Name" required pattern="[a-zA-Z\s]*$"  (change) ="model.name = model.name.trim()"
      >
    <span class="text-danger"
      *ngIf="(name.touched || contactForm.submitted) && name.errors?.required">
      First Name is required
    </span>
    <span class="text-danger"
    *ngIf="(name.touched || contactForm.submitted) && name.errors?.pattern">
    Only alphabets are allowed
  </span>
  </div>
  <div class=" col-md-6 ">
    <input type="text" class=" text"  [(ngModel)]="model.last_name" name="last_name"
      #last_name="ngModel" placeholder="Second Name" required pattern="[a-zA-Z\s]*$" (change) ="model.last_name = model.last_name.trim()">
    <span class="text-danger" *ngIf="(last_name.touched || contactForm.submitted) && last_name.errors?.required">
      Second Name is required
    </span>
    <span class="text-danger"
    *ngIf="(last_name.touched || contactForm.submitted) && last_name.errors?.pattern">
    Only alphabets are allowed
  </span>
  </div>
  <div class=" col-md-12 ">
    <input type="text" class=" text" [(ngModel)]="model.company" name="company"
      #company="ngModel"  placeholder="Company" required company (change) ="model.company = model.company.trim()">
    <span class="text-danger" *ngIf="(company.touched || contactForm.submitted) && company.errors?.required">
      Company is required
    </span>    
  </div> 
  <div class=" col-md-12 ">
    <input type="text" class=" text" [(ngModel)]="model.email" name="email"
      #email="ngModel" placeholder="Email" required email (change) ="model.email = model.email.trim()">
    <span class="text-danger" *ngIf="(email.touched || contactForm.submitted) && !email_err && email.errors?.required">
      Email is required
    </span>
    <span class="text-danger" *ngIf="email.touched && email.errors?.email">
      Enter a valid email address
    </span>
    <span class="text-danger" *ngIf="email.touched && email_err">
      The email has already been taken.
    </span>
  </div>                
</div>
<div *ngIf="page==='hiring-partner'; else getintouch">
    <b>  <p class="terms">By Clicking 'Join Our Talent Network',
        I confirm that I have reviewed and I agree to
        Edubex's Terms
    </p></b>
    <div>
        <input type="submit"  class="btn join"  value="Join Our Talent Network">       
    </div>
</div>
<ng-template #getintouch>
  <div>
     <p class="terms" >By Clicking 'Submit' I confirm that i have reviewed and I agree to Edubex's terms
    </p>
    <div>
        <input type="submit"  class="btn join"  value="Submit">       
    </div>
  </div>
</ng-template>
</form>
