<div class="row dashboard">
    <div class="container-deliverys" *ngIf="webinars.length">
        <div class="personal">
            <div class="row">
                <div class="col-md-6 main" *ngFor="let course of webinars">
                    <div class="user">
                        <h6 class="heading">Applied Webinars</h6>
                        <div class="content">
                            {{course?.title}}
                        </div>
                        <div class="content">
                            <div class="float-left ">{{course?.date|date}}</div>
                            <div class="float-right" style="cursor: pointer;" routerLink="/webinar-details/{{course?.slug}}">View More</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container text-center" style="font-size:14" *ngIf="!webinars.length" style="padding: 40px 0px;margin: 20px auto;background: #fff;">
        <span>No Applied Courses</span>

    </div>
</div>
