import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CmsService } from 'src/app/core/services/cms/cms.service';
declare var bootbox:any;
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  list :any;
 model ={
  name:'',
  last_name:'',
  email :'',
  message:''
};
banner:any;
url: string = "https://edtou.com/edubex/scrom-courses/course1/scormdriver/indexAPI.html";
urlSafe: SafeResourceUrl;
  constructor(private cmsService:CmsService,public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

    this.cmsService.getBanner('our-story').subscribe((resp: any) => {
      this.banner = resp.banners[0];
      console.log(this.banner);
    }, (err: any) => {
      console.log(err);
    });
  }



 
onSubmit(form){
  console.log("select-->",form.value);
  
  if(form.valid ){
    let data = form.value;
    this.cmsService.addContact(data).subscribe(
      res => {
        console.log("res",res)  
        var dialog = bootbox.dialog({
          message: "Application Submitted!",
          closeButton: true
        });
        
        form.resetForm(); 
             
      });
  }
}
}
