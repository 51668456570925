import { Component, OnInit } from '@angular/core';
import { CmsService } from 'src/app/core/services/cms/cms.service';

@Component({
  selector: 'app-university-progression-examples',
  templateUrl: './university-progression-examples.component.html',
  styleUrls: ['./university-progression-examples.component.scss']
})
export class UniversityProgressionExamplesComponent implements OnInit {
  examples: any;

  constructor(private cmsService:CmsService) { }

  ngOnInit(): void {
    this.getUniversityExample();
  }
  getUniversityExample(){
    this.cmsService.getPageInro('eg-university-progressions').subscribe(res=>{     
      this.examples= res['cms'];  
      console.log("examples",res)
		})
  }

}
