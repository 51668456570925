import { Component, OnInit } from '@angular/core';
import {
  NgbCarouselConfig,
  NgbModal,
  NgbTabsetConfig,
} from '@ng-bootstrap/ng-bootstrap';
import {
  faRupeeSign,
  faUsers,
  faArrowRight,
  faDollarSign,
  faChevronRight,
  faChevronCircleDown,
} from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from 'src/app/core/services/master/master.service';
import * as _ from 'lodash';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';

import { CourseService } from 'src/app/core/services/course/course.service';
import * as moment from 'moment-timezone';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  group,
} from '@angular/animations';
import { LoginComponent } from '../login/login.component';
import { UserService } from 'src/app/core/services/user/user.service';
import { PaginationInstance } from 'ngx-pagination';
import { th } from 'date-fns/locale';
import { BootcampEnrollmentComponent } from '../bootcamp-enrollment/bootcamp-enrollment.component';
import { GetRegisteredComponent } from '../../components/get-registered/get-registered.component';
declare var bootbox;
@Component({
  selector: 'app-webinars',
  templateUrl: './webinars.component.html',
  styleUrls: ['./webinars.component.scss'],
})
export class WebinarsComponent implements OnInit {
  faRupeeSign = faRupeeSign;
  faChevronCircleDown = faChevronCircleDown;
  faArrowRight = faArrowRight;
  faClock = faClock;
  faUsers = faUsers;
  faChevronRight = faChevronRight;
  faDollarSign = faDollarSign;
  page: String = 'program-list';
  longTerm: any;
  courseList: any = [];
  cat: any = [];
  myIndex=-1;
  country: any = [];
  allCategories: any = [];
  allCountries: any = [];
  courses = [];
  old_webinar =[];
  allCourses=[];
  offsetTmz = [];
  title: String = 'All Categories';
  live_online = 0;
   clicked;
   public config: PaginationInstance = {
    id: "custom",
    itemsPerPage: 9,
    currentPage: 1,
  };
  user: any;
  user_id: any;
  constructor(
    public router: Router,
    config: NgbCarouselConfig,
    configure: NgbTabsetConfig,
    private masterService: MasterService,
    private courseService: CourseService,
    public modalService: NgbModal,
    private _activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {
    this.clicked=this.courseService.countrycode;

    let timeZones = moment.tz.names();
    for (var i in timeZones) {
      this.offsetTmz.push(
        ' (UTC' + moment.tz(timeZones[i]).format('Z') + ') ' + timeZones[i]
      );
    }
  }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe((params) => {
      console.log(params);
      this.getWebinarCourses();
    });
  }
  getWebinarCourses() {
    this.courseService.getWebinar().subscribe((res) => {
      console.log('res', res);
      this.allCategories = res.all_categories;
      let courses = res.webinar.upcoming_webinars;
      this.old_webinar=res.webinar.old_webinar;
      console.log("web",this.old_webinar)
      if (courses)
      courses.forEach(ele => {
      
        ele['start_time'] = ele.time_zone[this.courseService.countrycode+"_start"];
        ele['end_time'] = ele.time_zone[this.courseService.countrycode+"_end"];
        const [d, M, y] = ele.date.match(/\d+/g);
        const D = new Date(d, M - 1, y);
        console.log(D);
        ele.date = D;

      });
        // courses.forEach(ele => {

        //   ele.duration = this.courseService.getWeken(ele.start_date,ele.end_date);
        // });
        // this.courseList= res.data;
        // this.courses = this.itemsGroupedByMonth(courses);
        this.courses =courses
        this.allCourses=courses;
      console.log('group', this.courses);
      // console.log("res",this.allCategories,this.allCountries)
    });
    // for (const key of this.allCategories) {

    //     key.active=false;;
    // }
  }
  reload(event) {
    this.getWebinarCourses();
    // window.location.reload();
  }

  chckMonth(start, end) {
    if (new Date(start).getMonth() === new Date(end).getMonth()) return true;
    else return false;
  }

  selectIndex(i){
    this.myIndex=i;
  }
  openModal(slug) {
    const modalRef = this.modalService.open(LoginComponent, {
      scrollable: true,
      windowClass: 'myModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });

    let data = {
      prop1: 'webinar',
      prop2: slug,
      prop3: 'This Can be anything',
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {
        console.log(result);
      },
      (reason) => {}
    );
  }

  getSchedule(cat, country, live_online) {
    let courses = this.allCourses.filter((course) => {
      return course.category.title == cat[0];
    });
    if (courses.length) this.courses= courses
    //  this.courses = this.itemsGroupedByMonth(courses);
    else this.courses = [];
  }
  itemsGroupedByMonth = (items) => {
    let monthLabels = [
      'Jan',
      'Feb',
      'March',
      'April',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let groups = [[], [], [], [], [], [], [], [], [], [], [], []],
      itemGroupedByMonths = [];
    if (items) {
      for (var i = 0; i < items.length; i++) {
        const [d, M, y] = items[i].date.match(/\d+/g);
        const D = new Date(y, M - 1, d);
        console.log(D);
        items[i].date = D;
        groups[new Date(items[i].date).getMonth()].push(items[i]);
      }
      console.log('items', groups);

      for (var i = 0; i < groups.length; i++) {
        if (groups[i].length) {
          console.log('get', groups[i][0].start_date);

          itemGroupedByMonths.push({
            month:
              monthLabels[i] +
              ' ' +
              new Date(groups[i][0]['date']).getFullYear(),
            items: groups[i],
          });
        }
      }
    }
    return itemGroupedByMonths;
  };

  aluminiOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      767: {
        items: 3,
      },
      992: {
        items: 4,
        loop: false,
      },
      1200: {
        items: 5,
        loop: false,
      },
    },
    nav: false,
  };

  selectCat(cat){
    if(cat){
      this.cat= [];
      console.log("cat",cat,this.allCategories);
      // this.allCategories.forEach(element => {
      //   if(element.id===cat.id){
      //     element.active =true
      //   }else element.active = false;
        
      // });
    let selectCategory=this.allCategories.filter(cate=>cate.slug===cat)[0]
      console.log("select",selectCategory)
      // if(event.target.value !=="")
      this.cat.push(selectCategory.title)
      this.getSchedule(this.cat,this.country,this.live_online)
    }else this.getWebinarCourses()


  }
  selectCountry(event, title) {
    this.country = [];
    console.log('cat', event.target.value, title);
    if (event.target.value !== '') this.country.push(event.target.value);
    this.getSchedule(this.cat, this.country, this.live_online);
  }

  setLive() {
    console.log('online', this.live_online);
    if (this.live_online === 1) this.live_online = 0;
    else this.live_online = 1;
    this.getSchedule(this.cat, this.country, this.live_online);
  }
  changeCountry(country){
    this.clicked =country
    this.courses.forEach(course=>{
      // console.log("list",list)
      // list.items.forEach(course=>{
        console.log("getcourse--------------->",course)
        // if(Object.keys(course.time_zone).some(function(k){ return ~k.indexOf(country) })){
          course['start_time']=course.time_zone[country+"_start"];
          course['end_time']=course.time_zone[country+"_end"]
          console.log("course---------",course)

      //  }
      // })
    })
  }
  regWebinar(course){
    if(!this.userService.isLoggedIn()){
      this.enterOtp(course.slug,'')
    }else{
    this.userService.getUser().subscribe((data) => {
      let details={
        email:data.profile.email,
        webinar_slug:course.slug
      }

      this.courseService.getWebinarOtp(details).subscribe(
        res => {
          console.log("otpppppp",res)
          if(res.status===3){
            // that.userService.setToken(res.token); 
            // localStorage.setItem('JWT_TOKEN', res.token);

            //  that.onSubmit(form,'close')
            this.router.navigateByUrl('webinar-payment/'+res.webinar_order_id)

           
          }
        

        },
        err => {
          console.log("err", err)
        });
      });

    }
  }
  enterOtp(form,err){
    console.log("form-----------------",form)
    if(this.userService.isLoggedIn()){
      this.userService.getUser().subscribe(data=>{
        console.log(data)        
        this.user = data.profile;
        let details={
          email:this.user.email,
          webinar_slug:form
        }
        this.courseService.getWebinarOtp(details).subscribe(
          res => {
            console.log("otpppppp",res)
            if(res.status===3){ 
              this.router.navigateByUrl('/webinar-details/'+form);
              bootbox.dialog({ message:"Course applied successfully",
                closeButton: true})
            }
          })
      });

  
    }else{
      let that = this;
        const modalRef = this.modalService.open(GetRegisteredComponent,
          {
          scrollable: false,
          windowClass: 'registermyCustomModalClass',
          // keyboard: false,
          // backdrop: 'static'
          });
      
        let data = {
          prop1: 'webinar-details',
          prop2: form.slug,
          // course: course,
        }
      
        modalRef.componentInstance.fromParent = data;
        modalRef.componentInstance.childEvent.subscribe((emmitedValue) => {
          // do sth with emmitedValue
          console.log("emmitedValue",emmitedValue)
          let details={
            ...emmitedValue,
            webinar_slug:form
          }

          this.courseService.getWebinarOtp(details).subscribe(
            res => {
              this.user_id= res.user_id; 
              console.log("otpppppp",res)
              if(res.status===3){ 
                // that.userService.setToken(res.token); 
                localStorage.setItem('JWT_TOKEN', res.token);

                //  that.onSubmit(form,'close')
                this.router.navigateByUrl('/webinar-details/'+form);
                bootbox.dialog({ message:"Course applied successfully",
                closeButton: true})
              }else{
             this.reenterOtp(form,err)
              }
            

            },
            err => {
              console.log("err", err)
            });

      });
        modalRef.result.then((result) => {
       

          
        }, (reason) => {
         
        });
    //   bootbox.prompt({
    //     title: "Enter Email id",
    //     message:err?err:'Please enter email!',
    //     centerVertical: true,
    //     callback: function(result){
    //       if(result){
    //         console.log(result);
           
  
    //       }else{
  
    //         bootbox.hideAll();
  
    //         if(result!==null)
    //         that.enterOtp(form,"<span style='color: red;'>Enter valid Mail id. Try again!</span>");
    //         return false;
    //         }
  
  
    //     }
    // });
    }
  
  }
  reenterOtp(form,err){
    let that = this;
    bootbox.prompt({
      title: "Enter Verification Code",
      message:err?err:'Please check your email for the verification code!',
      inputType: 'number',
      centerVertical: true,
      callback: function(result){
          console.log(result);
          if(result){

            that.courseService.verifyWebinarOtp({
              otp:result,
              user_id:that.user_id,
            webinar_slug:form
            }).subscribe(
              res => {
                console.log("res--->",res)
                 if(res.status==1){
                  localStorage.setItem('JWT_TOKEN', res.token);
                  bootbox.dialog({ message:"Course applied successfully",
                  closeButton: true})
                  //  that.onSubmit(form,'close')
                  that.router.navigateByUrl('/webinar-details/'+form);

                }else if(res.status==2){
                  var dialog = bootbox.dialog({
                    className: 'block',
                    message: "You have exceeded the limit of verification code tries. Please contact Admin !!",
                    closeButton: true
                  });
                }else
                     that.reenterOtp(form,"<span style='color: red;'>Verification failed. Try again!</span>");

              },
              err => {
                console.log("err", err)
              });
          }else{
            bootbox.hideAll();

            if(result!==null)
            that.reenterOtp(form,"<span style='color: red;'>Enter valid verification code. Try again!</span>");
            return false;
          }

      }
  });
  }
  openApplyModal(course) {
	  }
}
