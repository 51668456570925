<div class="row " *ngIf="showModal1">
    <div class="col-md-3 main">
    </div>
</div>
<div [style.display]="showModal1 ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg " style="margin: 0px auto; height: auto;">
        <div class="modal-content" style="padding:0px">
            <div class="modal-body" style="padding:0px!important">
                <div class="close" (click)="hide()" style="top: 5px;z-index: 999;right: 0px;">
                    <fa-icon style=" margin:5px;cursor:pointer;color: #000" [icon]="faTimesCircle"></fa-icon>
                </div>
                <div class="row">
                    <div class="col-sm-12" style="padding:0px">
                        <div class="card content">
                            <div class="card-body">
                                <div class="col-md-12 box" style="padding:15px 0px!important">

                                    <div class="col-flexpop">
                                        <h3 class="mt-0">Credit Point Summary</h3>
                                        <table class="table table-responsive" style="background:#fff;">

                                            <tbody>
                                                <tr>
                                                    <th>Activities</th>
                                                    <th>Activity Code</th>

                                                    <th>Credit Point</th>
                                                    <th>Offer (%)</th>

                                                </tr>

                                                <tr *ngFor="let details of point_summary ">
                                                    <td> {{details?.activity}} </td>
                                                    <td>{{details?.activity_code}}</td>

                                                    <td>{{details?.credit_point}}</td>
                                                    <td>{{details?.offer_percentage}}</td>


                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>