<ngx-loading [show]="page_load" [config]="{  fullScreenBackdrop:true,backdropBackgroundColour:'#edecec80' }" [template]="customLoadingTemplate"></ngx-loading>

<div class="container-fluid" style="background:#f1f1f1;">
<div class="container-deliverys">   
    <div class="row" *ngIf="_id && !paid && !unpaid">
      <div class="col-xs-12 col-md-12 mr-auto ml-auto">            
        <div class="panel panel-default" *ngIf="!status">
          <form [formGroup]="customStripeForm" >
            <div class="row panel-body" style="margin-top:30px">
    
              <div class="row" style="margin:0px;width: 100%;">
                <div class="col-md-1 col-sm-12">                  
                </div>
                <div class="col-md-11">
                  <div class="form-group">
                   
                    <div class="input-group">
                      <label>NAME</label>:
                      {{user_details?.user.first_name|uppercase}} {{user_details?.user.last_name|uppercase}}                     
                    </div>                  
                  </div>
                  <div class="form-group">                   
                    <div class="input-group">
                      <label>COURSE</label>:
                      {{user_details?.course.title|uppercase}}                     
                    </div>                   
                  </div>
                  <div class="form-group">                   
                    <div class="input-group">
                      <label>TOTAL COURSE FEE</label>:
                     {{user_details?.course.currency}} {{user_details?.course.price}}                     
                    </div>                  
                  </div>
                  <div class="form-group" *ngIf="offer_amount && !promocode.length">                   
                    <div class="input-group">
                      <label>AMOUNT TO BE PAID</label>:
                      <span class="discount-price"> {{user_details?.course.currency}} {{user_details?.amount}}  </span>
                     {{user_details?.course.currency}} {{offer_amount}}                     
                    </div>                  
                  </div>
                  <div class="form-group" *ngIf="usd_50.length">                   
                    <div class="input-group">
                      <label>OFFER APPLIED</label>:
                    GIFT USD                    
                    </div>                  
                  </div>
               
                  <div class="form-group" *ngIf="credit">                   
                    <div class="input-group">
                      <label>OFFER APPLIED</label>:
CREDIT POINTS                    </div>                  
                  </div>
                  <!-- <div class="form-group">                   
                    <div class="input-group">
                      <label>AMOUNT TO BE PAID</label>:
                     {{user_details?.course.currency}} {{user_details?.amount}}                     
                    </div>                  
                  </div> -->
                </div>
                <div class="col-md-1 col-sm-12">
                  
                </div>
                <div class="col-md-5 col-sm-6">
                  <div class=""style="width:100%;display:inline-block;padding: 30px;background: #fff;margin-bottom: 30px;">
                  
                  <div class="col-md-12" style="padding:0px">
                    <!-- <h4>Stripe </h4>     -->
                    <div *ngIf="message">
                      <!-- <p><b>{{ message }}</b></p> -->
                    </div>
                  
                  </div>
             
                  <div class="form-group">   
                    <label>ADDRESS</label>:                
                    <div class="input-group">                     
                      <input type="text" formControlName="address" required class="form-control" placeholder="Address" name="address" />
                      
                    </div> 
                    <div *ngIf="submitted &amp;&amp; customStripeForm.controls.address.errors; else error_address" class="text-danger">
                      <div *ngIf="customStripeForm.controls.address.errors.required ">Address is required</div>
                    </div>   
                    <ng-template #error_address>
                      <div class="text-danger" *ngIf="submitted && customStripeForm.controls.address.value.trim().length === 0;">Address is required</div>
                    </ng-template>               
                  </div>
                  <div class="form-group">   
                    <label>POSTAL CODE</label>:                
                    <div class="input-group">                     
                      <input type="text" formControlName="postal_code" required class="form-control" placeholder="Postal Code" name="postal_code" />
                   
                    </div>  
                    <div *ngIf="submitted &amp;&amp; customStripeForm.controls.postal_code.errors ; else error_postal" class="text-danger">
                      <div *ngIf="customStripeForm.controls.postal_code.errors.required">Postal Code is required</div>
                    </div>
                    <ng-template #error_postal>
                      <div class="text-danger" *ngIf="submitted && customStripeForm.controls.postal_code.value.trim().length === 0;">Postal Code is required</div>
                    </ng-template>                  
                  </div>
                  <div class="form-group">   
                    <label>CITY</label>:                
                    <div class="input-group">                     
                      <input type="text" formControlName="city" required class="form-control" placeholder="City" name="city" />
                    </div>  
                    <div *ngIf="submitted &amp;&amp; customStripeForm.controls.city.errors; else error_city" class="text-danger">
                      <div *ngIf="customStripeForm.controls.city.errors.required">City is required</div>
                    </div>  
                    <ng-template #error_city>
                      <div class="text-danger" *ngIf="submitted && customStripeForm.controls.city.value.trim().length === 0;">City is required</div>
                    </ng-template>                
                  </div>
                  <div class="form-group">   
                    <label>STATE</label>:                
                    <div class="input-group">                     
                      <input type="text" formControlName="state" required class="form-control" placeholder="State" name="state" />
                     </div>   
                     <div *ngIf="submitted &amp;&amp; customStripeForm.controls.state.errors ; else error_state" class="text-danger">
                      <div *ngIf="customStripeForm.controls.state.errors.required">State is required</div>
                    </div>  
                    <ng-template #error_state>
                      <div class="text-danger" *ngIf="submitted && customStripeForm.controls.state.value.trim().length === 0;">State is required</div>
                    </ng-template>               
                  </div>
                  <div class="form-group">   
                    <label>COUNTRY</label>:                
                    <div class="input-group"> 
                      <ngx-select-dropdown [config]="config"  id="country" class="country" name="country" formControlName="country" (change)="selectCountry($event)"  [options]="countries"></ngx-select-dropdown>
                    
                      <!-- <input type="text" formControlName="country" required class="form-control" placeholder="Country" name="country" /> -->
                    </div>
                    <span class="text-danger" *ngIf="submitted">
                      {{country_err}} </span>           
                    <div *ngIf="submitted &amp;&amp; customStripeForm.controls.country.errors" class="text-danger">
                      <div *ngIf="customStripeForm.controls.country.errors.required">Country is required</div>
                    </div> 
                      
                  </div>
                </div>
                </div>
                <div class="col-md-5 col-sm-6" >
                  <div class=""style="width:100%;display:inline-block;padding: 30px;background: #fff;margin-bottom: 30px;">
                  <div class="form-group">
                    <label>CARD NUMBER</label>
                    <div class="input-group">
                      <input type="text" formControlName="cardNumber" required class="form-control" maxlength="18" placeholder="Valid Card Number" name="cardNumber" pattern="[0-9\-]*"/>
                      <span class="input-group-addon">
                        <span class="fa fa-credit-card"></span>
                      </span>
                    </div>
                    <div *ngIf="(customStripeForm.controls.cardNumber.touched || submitted) &amp;&amp; customStripeForm.controls.cardNumber.errors" class="text-danger">
                      <div *ngIf="customStripeForm.controls.cardNumber.errors.required">Card number is required</div>
                      <div *ngIf="customStripeForm.controls.cardNumber.errors?.pattern ">Invalid Card Number</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label><span class="hidden-xs">EXPIRATION</span> DATE</label>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group selmont"  style="margin:0px">
                          <select formControlName="expMonth" class="form-control" required>
                            <option value="">Select Month</option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select>
                        </div>                           
                        <div *ngIf="submitted &amp;&amp; customStripeForm.controls.expMonth.errors" class="text-danger">
                          <div *ngIf="customStripeForm.controls.expMonth.errors.required">Expiration month is required</div>
                        </div>
                      </div>
                      <div class="col-lg-6 pull-right">
                      <div class="form-group" style="margin:0px">
                        <select formControlName="expYear" class="form-control" required>
                          <option value="">Select Year</option>
                          <option value="21">2021</option>
                          <option value="22">2022</option>
                          <option value="23">2023</option>
                          <option value="24">2024</option>
                          <option value="25">2025</option>
                          <option value="26">2026</option>
                          <option value="27">2027</option>
                          <option value="28">2028</option>
                          <option value="29">2029</option>
                          <option value="30">2030</option>
                          <option value="31">2031</option>
                          <option value="32">2032</option>
                          <option value="33">2033</option>
                          <option value="34">2034</option>
                          <option value="35">2035</option>
                          <option value="36">2036</option>
                          <option value="37">2037</option>
                          <option value="38">2038</option>
                          <option value="39">2039</option>
                          <option value="40">2040</option>
                          <option value="41">2041</option>
                          <option value="42">2042</option>
                          <option value="43">2043</option>
                          <option value="44">2044</option>
                          <option value="45">2045</option>
                          <option value="46">2046</option>
                          <option value="47">2047</option>
                          <option value="48">2048</option>
                          <option value="49">2049</option>
                          <option value="50">2050</option>
                        </select>
                      </div>
                      <div *ngIf="submitted &amp;&amp; customStripeForm.controls.expYear.errors" class="text-danger">
                        <div *ngIf="customStripeForm.controls.expYear.errors.required">Expiration year is required</div>
                      </div>
                 
                    </div>
                  
                  </div>
   

                </div> 
                <!-- <div class="col-xs-12 col-md-12 pull-right"> -->
                  <div class="form-group">
                    <label>CVV</label>
                    <input type="text" formControlName="cvv" class="form-control" required placeholder="CVV" minlength="3" pattern="[0-9]+" maxlength="4" />
                    <div *ngIf="(customStripeForm.controls.cvv.touched || submitted) &amp;&amp; customStripeForm.controls.cvv.errors" class="text-danger">
                      <div *ngIf="customStripeForm.controls.cvv.errors?.required">CVV is required</div>
                      <div *ngIf="customStripeForm.controls.cvv.errors?.pattern || customStripeForm.controls.cvv.errors?.minlength">Invalid CVV</div>
                      <!-- <div *ngIf="customStripeForm.controls.cvv.errors?.pattern"></div> -->

                    </div>
                  </div>   
                  <div class="form-group" *ngIf="promocode.length">
                    <div class="row">
                      <div class="col-lg-6">
                        <label>Promo Code</label>
                        <input  type="text" formControlName="promocode" class="form-control"  placeholder="Promocode" />
                        </div>
                        <div class="col-lg-6">
                          <button (click)="applyPromo()" style="margin-top:30px;background-color:#e45b0b" class="btn btn-block" >Apply</button>

                        </div>
                        </div>
                        <div class="row">
                          <div *ngIf="offer_price>0" class="col-lg-12" style="color:#095e37">{{offer_message}}</div>
                          <div *ngIf="offer_price<=0" class="col-lg-12" style="color:#e45b0b">{{offer_message}}</div>

                        </div>
                        <div class="row" *ngIf="discount">
                          <div class="col-lg-12" style="color:#095e37">
                            Discount:{{discount}}%
                       
                          </div>
                        </div>
                        <div class="row" *ngIf="offer_price">
                          <div class="col-lg-8" style="color:#095e37">
                            <b>   Amount Due ({{user_details?.course.currency}} )</b>
                         
                          </div>
                          <div class="col-lg-4" style="color:#095e37">
                            {{offer_fee}}
                          </div>
                          <div class="col-lg-8" style="color:#E65A0B;">
                            <b>   Pay Now({{user_details?.course.currency}} )</b>
                         
                          </div>
                          <div class="col-lg-4" style="color:#E65A0B;">
                            {{offer_price}}
                          </div>
                        </div>
                  </div>               
                  <button class="btn btn-warning btn-lg  btn-block" (click)="pay(customStripeForm.value)" *ngIf="!formProcess">Process payment </button>
                  <span class="btn btn-warning btn-lg btn-block" (click)="pay(customStripeForm.value)" *ngIf="formProcess">Processing please wait...</span>
                  <button class="btn btn-warning btn-lg  btn-block" (click)="generateInvoice()" >Generate Invoice </button>

                </div>       
                </div>
                <div class="col-md-1 col-sm-12"></div>                    
              <!-- </div> -->
          
            </div>
          </div>
        </form>
        <!-- <p class="mt-2">Try it out using the test card number 4242 4242 4242 4242, a random three-digit CVC number and any expiration date in the future.</p> -->
      </div>
      <div *ngIf="status">
          Payment Success!!
      </div>
    </div>
  </div>

<ngx-loading [show]="loading" [config]="{  fullScreenBackdrop:true,backdropBackgroundColour:'#edecec80' }" [template]="customLoadingTemplate"></ngx-loading>

  </div>
</div>
    <div class="container jumbotron text-center" style="font-size:14"  *ngIf="_id=='' && !page_load">
      <span>To Complete this payment, Please </span>
     
        <span class="log-span" style="cursor: pointer;padding: 7px 12px;background: #e86a22;color: #fff;" (click)="openModal()">Login </span>
     
  </div>
  <div class="container jumbotron text-center" style="font-size:14"  *ngIf="paid">
    <span>Payment for this course is already done!!</span>
   
      <div  style="cursor:pointer" routerLink="/" class="backome">Home </div>
   
</div>
<div class="container jumbotron text-center" style="font-size:14"  *ngIf="unpaid">
  <span>Course application is rejected!!</span>
 
    <div  style="cursor:pointer" routerLink="/" class="backome">Home </div>
 
</div>
  <ng-template #customLoadingTemplate id="load">
    <div class="custom-class">
      
    </div>
</ng-template>