import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[scrollTracker]'
})
export class ScrollTrackerDirective {
  ngOnInit() {
    console.log('lets track ths scroll');
}

constructor() {}

 
  @Output() scrollingFinished = new EventEmitter<void>();

  emitted = false;
  @HostListener('scroll', ['$event']) private onScroll($event: Event): void {
    console.log("event",$event);
    
    if( $event.target['scrollTop']>= ($event.target['scrollHeight'] - $event.target['offsetHeight'])&& !this.emitted){
      this.emitted = true;
      console.log("if")
      this.scrollingFinished.emit();
      
    }else if( $event.target['scrollTop']< ($event.target['scrollHeight'] - $event.target['offsetHeight'])){
      this.emitted = false;
      console.log("else")
    }
} 

  // @HostListener("window:scroll", [])
  // onScroll(): void {
  //   console.log("enter",window.innerHeight + window.scrollY,document.body.offsetHeight)
  //   if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && !this.emitted) {
  //     this.emitted = true;
  //     this.scrollingFinished.emit();
  //   } else if ((window.innerHeight + window.scrollY) < document.body.offsetHeight) {
  //     this.emitted = false;
  //   }
  // }
}