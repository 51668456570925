<div>
    <app-banner [page]="page" class="apart-page"></app-banner>
    <div class="container-delivery serv-col" style="padding-top:25px">
    <div *ngFor="let stand of standapart let i= index;let isOdd=odd;let isEven=even">
        <div class="row item">
            <div class="col-md-6 col-xl-5 how-img dis767" style="display:none">
                <img src="{{stand.image}}" style="width: 100%;" class="card-img" alt="{{stand?.image_alt}}">               
            </div>
            <div class="col-md-6 col-xl-5 how-img none767" *ngIf="isOdd">
                <img src="{{stand.image}}" style="width: 100%;" class="card-img" alt="{{stand?.image_alt}}">               
            </div>
            <div class="col-md-6 col-xl-7 how-padd">
                <h1 class="index">{{i+1 < 10 ? '0' : ''}}{{i+1}}</h1>
                <h4 style="font-weight: 600;">{{stand.name}}</h4>   
                <div class="underline" style="margin-left:0px!important"></div> 
                <!-- <p>{{stand?.description}}</p> -->
                <p [innerHTML]="stand?.description"></p>         
            </div>
            <div class="col-md-6 col-xl-5 how-img none767" *ngIf="isEven">
                <img src="{{stand.image}}" style="width: 100%;" class="card-img" alt="{{stand?.image_alt}}">               
            </div>    
        </div>
    </div>
    </div>
    <div class="how-colpad apply" style="padding-bottom:0px" >
        <div class="col-md-12 container-delivery">
            <div class="row discuss-section" style="margin:0px"> 
                <div class="col-lg-6 col-md-12" style="margin-top: 25px;">
                <p class="discuss" style="margin-left:0px">Discuss how we can help. </p>           
            </div>
            <div class="col-lg-1 col-md-1"></div>
            <div class="col-lg-5 col-md-12 ">
                <div class="signup">
                    <div class="message" id="successMessage" style="margin-left:0px;width: 100%;position: relative;margin: 10px 0px;">{{message}}</div> 
                    <div class="card-body">                       
                        <h6>                            
                            <b> Get in Touch</b>
                        </h6>
                        <app-join-network [page]="page" (message)="messageHandler($event)"></app-join-network>             
                    </div>
                </div>           
            </div>
        </div>  
        </div>       
    </div>
    
    <div class="how-colpad apply" style="padding-bottom:0px;background: #34495e;" >
        <div class="col-md-12 container-delivery">
  
            <div class="change" style="margin-top:0px">
                <app-find-career></app-find-career>
            </div>  
            </div>       
        </div>
    <div class="container-delivery">
    <div class="col-fixed">
        <div class="bottom" >
            <app-bottom-bar></app-bottom-bar>
    
        </div>
    </div>
    </div>
    
</div>




