<div class="modal-body ">
    <div class="close" (click)="closeModal('dismiss')">
        <fa-icon style=" margin:5px;cursor:pointer;color: #000" [icon]="faTimesCircle"></fa-icon>
    </div>
<div class="card-sec " style="padding:20px">
    <div class="card-body" style="border-radius: 6px;border: 1px solid #003C3E;padding: 0;width: 96%;margin: auto;">
        <div class="login-head">
            <img width="100%" src="../../../assets/images/logo_white.svg">
          </div>
          <div class="login-status">
            <img width="100%" src="../../../assets/images/login-status.png">
          </div>
          <hr style="width: 90%; border-color: #D6560D; margin-bottom: 30px;">
          <div class="apply-text">
                <img src="../../../assets/images/success-tick.png">

                    <h3 style="margin-top: 20px !important;font-weight: 600;font-size: 24px;padding: 10px;margin-bottom: 10px;">Thank you for applying to the course.</h3> 
            <p style="padding: 0px 20px;padding-bottom: 20px;">
                The enrollment will be completed once the admin verifies your request. You will receive an email confirmation once the admin approves your request.
        
            </p>
          </div>
      


    </div>
</div>
</div>