import { Component, OnInit } from '@angular/core';
import { LoginComponent } from '../login/login.component';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MasterService } from 'src/app/core/services/master/master.service';
import { CourseService } from 'src/app/core/services/course/course.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/core/services/user/user.service';
declare var bootbox;
@Component({
  selector: 'app-webinar-payment',
  templateUrl: './webinar-payment.component.html',
  styleUrls: ['./webinar-payment.component.scss']
})
export class WebinarPaymentComponent implements OnInit {


  customStripeForm = new FormGroup({
    address: new FormControl(''),
    postal_code: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    country: new FormControl(''),
    cardNumber: new FormControl(''),
    expMonth: new FormControl(''),
    expYear: new FormControl(''),
    cvv: new FormControl(''),
    promocode:new FormControl('')
  });
  user_details:any;
  handler:any = null;
  submitted=false;
  message='';
  promocode:any=[];
  offer_price =0;
  offer_fee=0;
  discount=0;
  status=0;
  code:any;
  offer_message='';
  countries = [
    {country: "Algeria",code:	"DZ"},
    {country:'Angola',code:'AO'},
    {country: 'Australia', code: 'AU'},
    {country: 'Austria', code: 'AT'},
    {country: 'Belgium', code: 'BE'},
    {country: 'Benin', code: 'BJ'},
    {country: 'Botswana', code: 'BW'},
    {country: 'Bulgaria', code: 'BG'},
    {country: 'Burkina Faso', code: 'BF'},
    {country: 'Burundi', code: 'BI'},
    {country: 'Brazil ', code: 'BR'},
    {country: 'Canada', code: 'CA'},
    {country: 'Cameroon', code: 'CM'},
    {country: 'Cape Verde', code: 'CV'},
    {country: 'Central African Republic', code: 'CF'},
    {country: 'Chad', code: 'TD'},
    {country: 'Comoros', code: 'KM'},
    {country: 'Congo', code: 'CG'},
    {country: 'Cote D`Ivoire', code: 'CI'},
    {country: 'Cyprus', code: 'CY'},
    {country: 'Czech Republic', code: 'CZ'},
    {country: 'Denmark', code: 'DK'},
    {country: 'Djibouti', code: 'DJ'},
    {country: 'Egypt', code: 'EG'},
    {country: 'Equatorial Guinea', code: 'GQ'},
    {country: 'Eritrea', code: 'ER'},

    {country: 'Estonia', code: 'EE'},
    {country: 'Ethiopia', code: 'ET'},

    {country: 'Finland', code: 'FI'},
    {country: 'France', code: 'FR'},
    {country: 'Gabon', code: 'GA'},
    {country: 'Gambia', code: 'GM'},
    {country: 'Ghana', code: 'GH'},

    {country: 'Germany', code: 'DE'},
    {country: 'Greece', code: 'GR'},
    {country: 'Guinea', code: 'GN'},
    {country: 'Guinea-Bissau', code: 'GW'},
    {country: 'Hong Kong', code: 'HK'},
    {country: 'Hungary', code: 'HU'},
    {country: 'India', code: 'IN'},
    {country: 'Ireland', code: 'IE'},
    {country: 'Italy', code: 'IT'},
    {country: 'Japan', code: 'JP'},
    {country: 'Keniya', code: 'KE'},

    {country: 'Latvia', code: 'LV'},
    {country: 'Lesotho', code: 'LS'},
    {country: 'Liberia', code: 'LR'},
    {country: 'Libyan Arab Jamahiriya', code: 'LY'},
    {country: 'Lithuania', code: 'LT'},
    {country: 'Luxembourg', code: 'LU'},
    {country: 'Madagascar', code: 'MG'},

    {country: 'Malaysia', code: 'MY'},
    {country: 'Mali', code: 'ML'},
    {country: 'Malawi', code: 'MW'},

    {country: 'Malta', code: 'MT'},
    {country: 'Mauritania', code: 'MR'},
    {country: 'Mauritius', code: 'MU'},
    {country: 'Mayotte', code: 'YT'},

    {country: 'Mexico ', code: 'MX'},
    {country: 'Morocco', code: 'MA'},
    {country: 'Mozambique', code: 'MZ'},
    {country: 'Namibia', code: 'NA'},

    {country: 'Netherlands', code: 'NL'},
    {country: 'New Zealand', code: 'NZ'},
    {country: 'Niger', code: 'NE'},

    {country: 'Nigeria', code: 'NG'},
    {country: 'Norway', code: 'NO'},
    {country: 'Poland', code: 'PL'},
    {country: 'Portugal', code: 'PT'},
    {country: 'Reunion Island', code: 'RE'},

    {country: 'Romania', code: 'RO'},
    {country: 'Rwanda', code: 'RW'},
    {country: 'Sao Tome And Principe', code: 'ST'},
    {country: 'Senegal', code: 'SN'},
    {country: 'Seychelles', code: 'SC'},
    {country: 'Sierra Leone ', code: 'SL'},
    {country: 'Somalia', code: 'SO'},
    {country: 'South Africa', code: 'ZA'},
    {country: 'South Sudan', code: 'SS'},
    {country: 'Sudan', code: 'SD'},
    {country: 'Singapore', code: 'SG'},
    {country: 'Slovakia', code: 'SK'},
    {country: 'Slovenia', code: 'SI'},
    {country: 'Spain', code: 'ES'},
    {country: 'Swaziland', code: 'SZ'},
    {country: 'Sweden', code: 'SE'},
    {country: 'Switzerland', code: 'CH'},
    {country: 'Swaziland', code: 'SZ'},
    {country: 'Tanzania', code: 'TZ'},
    {country: 'Togo', code: 'TG'},
    {country: 'Tunisia', code: 'TN'},
    {country: 'Uganda', code: 'UG'},
    {country: 'United Kingdom', code: 'GB'},
    {country: 'United States', code: 'US'},
    {country: 'Western Sahara', code: 'EH'},
    {country: 'Zambia', code: 'ZM'},
    {country: 'Zimbawe', code: 'ZW'},  
    ];
  country='';
  country_err='';
  formProcess=false;
  _id: any='';
  user_id: any;
  id:any;
  loading = false;
  paid= false;
  unpaid=false;
  page_load=false;
  config = {
    displayFn:(item: any) => { return item.country; } ,//to support flexible text displaying for each item
    displayKey:"item.country", //if objects array passed which key to be displayed defaults to description
    search:true ,//true/false for the search functionlity defaults to false,
    height: '200px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder:'Select Country', // text to be displayed when no item is selected defaults to Select,
    customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // limitTo:3, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: 'more' ,// text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!' ,// text to be displayed when no items are found while searching
    searchPlaceholder:'Search' ,// label thats displayed in search input,
    searchOnKey: 'country', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: false ,// clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
  }

  constructor(private _activatedRoute:ActivatedRoute,private masterService:MasterService,
    private courseService:CourseService,public activeModal: NgbActiveModal,
    private modalService: NgbModal,public userService:UserService) { 
    this._activatedRoute.params.subscribe(params => {
       console.log('params',this._id);
       
        this.id = params.id  
        if(this.userService.isLoggedIn()){ 
            this.page_load=true;       
          this.courseService.webinarInvoice(this.id).subscribe(res=>{     
          
           if(res.status){
            //  this.promocode= res.promocodes;
          
             this.user_details=res.data;
             console.log("user_details", this.user_details.status)
             if(this.user_details.status===1)
             this.paid = true
             if(this.user_details.status===2)
             this.unpaid = true
             console.log("paid",this.unpaid)
             this._id = params.id
             this.page_load=false;
             this.loadStripe();
           } else{
            this._id = '';
            this.page_load=false;
            this.userService.logout().subscribe(resp=>{
              console.log("resp",resp);             
              this.userService.deleteToken();
              this.openModal();
            })   
           }
           console.log("user",res)
         })
       
        }      
        // targetElement.classList.add("hidden");  
           
    });
  }


  selectCountry(event){
    console.log("country",event.value,this.userService.selectedUser.country)
    let targetElement = document.getElementsByClassName('nsdicon-close')[0];
    console.log("element",targetElement)
    this.country = event.value.code;
    if(event.value.country!=='')
    this.country_err = '';
  }
  noWhitespace(control: FormControl) {
    console.log("value",control.value)
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    console.log("value--->",isValid,isWhitespace)
    let value =isValid ? null : { 'whitespace': true }
    console.log("result",value)
    return isValid ? null : { 'whitespace': true }
}
 
  ngOnInit(): void {

    if(!this.userService.isLoggedIn()){
      this.openModal();
    }
  }

  loadStripe() {
    if(!window.document.getElementById('stripe-custom-form-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-custom-form-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        // (<any>window).Stripe.setPublishableKey('pk_live_SGam7l3MOSOZfq1hjuLN0XCc00Vlj60EKB');
        (<any>window).Stripe.setPublishableKey('pk_test_51GrolcL8tQqTApprvvH13LGj6zDBpq3jdICjgcX5zYvpczoJLm8KT9nZ0CezkYJDyNMurJr8RggupqKcFD3tUWqu00fzLfZ3Ao');
      }       
      window.document.body.appendChild(s);
    }
    // if(!window.document.getElementById('stripe-script')) {
    //   var s = window.document.createElement("script");
    //   s.id = "stripe-script";
    //   s.type = "text/javascript";
    //   s.src = "https://checkout.stripe.com/checkout.js";
    //   window.document.body.appendChild(s);
    // }
}
generateInvoice(){
  this.submitted = false;
  this.loading =true;
  this.courseService.generateInvoice({order_id:this._id,
    promocode:this.code}).subscribe(result=>{
    console.log("final",result)
  this.loading = false;
    this.customStripeForm.reset();  
    if (result) {  
      // this.status =result.status;
      this.message = result.message;
      if(result.status)
      var dialog = bootbox.dialog({
        message: "Generated invoice sent to your email successfully!",
        closeButton: true
      });
     
    } 
  }, (err: any) => {
    console.log(err.error.message);
    window.location.reload()
    alert(err.error.message);
  })
}
pay(form) {
  console.log("getdata",this.customStripeForm.value,this.country);

  if(!window['Stripe']) {
    alert('Oops! Stripe did not initialize properly.');
    return;
  }
  this.submitted = true; 
  if(this.country===''){
    this.country_err ="Select a Country";
    console.log("err",this.country_err)
    return;     
  }else{
    this.country_err =''
  }
  
  console.log("second",this.customStripeForm.valid,this.country_err);
  if (this.customStripeForm.invalid) {      
    return;
  }  
  // form.value.country = this.country;
  console.log("formdata------>",form.value)
  
  this.loading =true;
  this.formProcess = true;
  console.log("form");
  console.log(form);
  if(!(<any>window).Stripe) {
    alert('Oops! Stripe did not initialize properly.');
    return;
  }
  (<any>window).Stripe.card.createToken({
    number: form.cardNumber,
    exp_month: form.expMonth,
    exp_year: form.expYear,
    cvc: form.cvc,
    address_city:form.city,
    address_country:this.country,
    address_state:form.state,
    address_zip:form.postal_code,
    address_line1:form.address
  }, (status: number, response: any) => {
  // this.setLoading();
  console.log("1",this.loading)
  // this.loading =false;
  console.log("2",this.loading,response)
  

  if(status===402){
    
    this.message = response.error.message;
    // this.customStripeForm.reset();
    alert(response.error.message);
    window.location.reload()   
    // bootbox.confirm(response.error.message, function(result){      
		//   // if(result){
    
		// 	that.loading =false;
    //   // } 
    // });
    
  }
  if(status=== 400){
    this.message = response.error.message;
    console.log("message",this.message); 
    alert(response.error.message);
    // this.customStripeForm.reset(); 
    window.location.reload()     
  }
  if(status===200){
    var data = {
      stripeToken: response.id,
      fullName: response.card.name,
      address: response.card.address_line1,
      postal_code: response.card.address_zip,
      state: response.card.address_state,
      city: response.card.address_city,
      country: response.card.country,
      last4: response.card.last4,
      amount: this.user_details.webinar.amount,
    webinar_order_id:this._id
  };
  if(this.user_details.webinar.offer){
    data.amount=this.user_details.webinar.amount*(100-this.user_details.webinar.offer)/100;
    data['offer']=this.user_details.webinar.offer;
    // data['promocode']=this.code;

  }
  console.log("final",data)
  this.courseService.webinarPayment(data).subscribe(result=>{
    console.log("final",result,status)
    this.submitted = false;
    this.customStripeForm.reset();
  
    if (result) {  
      this.status =result.status;
      this.message = result.message;
      window.location.href = "/success-payment";
    } else {
      this.message = response.error.message;
    }
  }, (err: any) => {
    console.log(err.error.message);
    window.location.reload()
    alert(err.error.message);
  })
}

  // send to server
  // $http.post('/checkout', data).then(function(result){
  //   // here you can redirect yourself.
  //   window.location.href = "/#/order-complete";
  // });

   
  });
}


// pay(amount) {    
 
//   var handler = (<any>window).StripeCheckout.configure({
//     key: 'pk_test_51GrolcL8tQqTApprvvH13LGj6zDBpq3jdICjgcX5zYvpczoJLm8KT9nZ0CezkYJDyNMurJr8RggupqKcFD3tUWqu00fzLfZ3Ao',
//     locale: 'auto',
//     token: function (token: any) {
//       // You can access the token ID with `token.id`.
//       // Get the token ID to your server-side code for use.
//       console.log(token)
//       alert('Token Created!!');
//     }
//   });

//   handler.open({
//     name: 'Demo Site',
//     description: '2 widgets',
//     amount: amount * 100
//   });

// }
  onSubmit(){
    
  }
  closeModal(sendData) {
    console.log("send",sendData)
    this.activeModal.close(sendData);
  }
  openModal() {
    this.closeModal('dismiss')
    const modalRef = this.modalService.open(LoginComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
        // keyboard: false,
        // backdrop: 'static'
      });

    let data = {
      prop1: 'webinar-payment',
      prop2:  this.id ,
      prop3: 'This Can be anything'
    }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
}

}
