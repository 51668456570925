<div style="height:400px;
min-height: 1em;
overflow-y: auto;" scrollTracker (scrollingFinished)="onScrollingFinished()">
    <table class="table">
        <thead>
            <tr>
                <th>
                    Course
                </th>
                <th>
                    Amount
                </th>

                <th style="width:81px">
                    Date

                </th>

            </tr>
        </thead>
        <tbody>


            <tr *ngFor="let his of history">
                <td > {{his.course?.title}}</td>
                <td> {{his?.course.currency}} {{his?.amount| number : '1.2-2'}}</td>
                <td> {{his?.created_at|date:'dd/MM/yyyy'}}</td>
            </tr>
        </tbody>
    </table>
</div>