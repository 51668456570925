import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/core/services/master/master.service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
notifications:any =[];
_id:any;
  constructor(private masterService:MasterService, private userService:UserService) { }

  ngOnInit(): void {
    let all =0;
    
    this.masterService.getNotifications(all).subscribe(not=>{
      console.log("notiy",not)
      this.notifications = not.data;
      this.userService.getUser().subscribe(data=>{
        console.log(data)
        this._id= data.user_id;
        this.masterService.updateNotifications().subscribe(data=>{
          console.log("data",data )
        })
      })
    })
  }

}
