import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './pages-routing.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule ,ReactiveFormsModule } from '@angular/forms';


import { ApiService } from 'src/app/core/services/api/api.service';
import { UtilService } from 'src/app/core/services/util/util.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { CmsService } from 'src/app/core/services/cms/cms.service';
import { MasterService } from 'src/app/core/services/master/master.service';


import { HomeComponent } from './home/home.component';
import { HiringPartnerComponent } from './hiring-partner/hiring-partner.component';
import { ProgramsComponent } from './programs/programs.component';
import { ProgramDetailComponent } from './program-detail/program-detail.component';
import { FindCareerComponent } from '../components/find-career/find-career.component';
import { CareersComponent } from './careers/careers.component';
import { CareerListComponent } from './career-list/career-list.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { BecomeInstructorComponent } from './become-instructor/become-instructor.component';
import { HowWeStandApartComponent } from './how-we-stand-apart/how-we-stand-apart.component';
import { UniversityPathwaysComponent } from './university-pathways/university-pathways.component';
import { BannerComponent } from '../components/banner/banner.component';
import { AuthGuard } from 'src/app/core/services/auth/auth.guard';
// import { LoginComponent } from './login_/login_.component';
// import { RegisterComponent } from './register_/register_.component';
import { MatchPasswordDirective } from 'src/app/shared/directives/match-password.directive';
import { PasswordPatternDirective } from 'src/app/shared/directives/password-pattern.directive';
import { AuthInterceptor } from 'src/app/core/services/auth/auth.interceptor';
import { JoinNetworkComponent } from '../components/join-network/join-network.component';
import { PageDetailsComponent } from '../components/page-details/page-details.component';
import { SignupEdubexComponent } from '../components/signup-edubex/signup-edubex.component';
import { PatternValidatorDirective } from 'src/app/shared/directives/pattern-validator.directive';
import { UniversityProgressionExamplesComponent } from '../components/university-progression-examples/university-progression-examples.component';
import { OurStoryComponent } from './our-story/our-story.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { OnDemandComponent } from './on-demand/on-demand.component';
import { CalendarComponent } from './calendar/calendar.component';
import { PresentersComponent } from './presenters/presenters.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { GdprComponent } from './gdpr/gdpr.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { WebinarsComponent } from './webinars/webinars.component';
import { EventsComponent } from './events/events.component';
import { AcademicPoliciesComponent } from './academic-policies/academic-policies.component';
import { ExaminationGradesComponent } from './examination-grades/examination-grades.component';
import { NetworkBenefitsComponent } from './network-benefits/network-benefits.component';
import { ParticipantSpeaksComponent } from './participant-speaks/participant-speaks.component';
import { BottomBarComponent } from '../components/bottom-bar/bottom-bar.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NotFoundComponent } from './not-found/not-found.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { ApprovedCoursesComponent } from './approved-courses/approved-courses.component';
import { AppliedCoursesComponent } from './applied-courses/applied-courses.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { CourseApplyComponent } from './course-apply/course-apply.component';
import { CoursePaymentComponent } from './course-payment/course-payment.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { VgCoreModule } from 'ngx-videogular';
import { SuccessPaymentComponent } from './success-payment/success-payment.component';
import { SynapseComponent } from './synapse/synapse.component';

import { ChangePasswordComponent } from './change-password/change-password.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DynamicFormBuilderModule } from '../dynamic-form-builder/dynamic-form-builder.module';
import { BookCallComponent } from './book-call/book-call.component';
import { CorporateTrainingComponent } from './corporate-training/corporate-training.component';
import { SignupTeachEdubexComponent } from '../components/signup-teach-edubex/signup-teach-edubex.component';
import { ShowProgramComponent } from './show-program/show-program.component';
import { CoursePackageComponent } from './course-package/course-package.component';
import { FlexiblePaymentComponent } from './flexible-payment/flexible-payment.component';
import { CarouselSwipeDirective } from 'src/app/shared/directives/carousel-swipe.directive';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { ScholarshipComponent } from './scholarship/scholarship.component';
import { SuccessStoryComponent } from './success-story/success-story.component';
import { SupportServiceComponent } from './support-service/support-service.component';
import { CalendarListComponent } from './calendar-list/calendar-list.component';
import { DateAdapter ,CalendarModule} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NotificationsComponent } from './notifications/notifications.component';
import { TawkService } from 'src/app/core/services/common/tawk.service';
import { ScrollTrackerDirective } from 'src/app/shared/directives/scroll-tracker.directive';
import { MultiSelectService } from 'src/app/core/services/common/multiselect.service';
import { MultiselectComponent } from '../components/multiselect/multiselect.component';
import { HistoryComponent } from '../components/history/history.component';
import { InstructorComponent } from './instructor/instructor.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ImagekitioAngularModule } from 'imagekitio-angular';

import { BootcampComponent } from './bootcamp/bootcamp.component';
import { FreeCoursesComponent } from './free-courses/free-courses.component';
import { DownloadBrochureComponent } from '../components/download-brochure/download-brochure.component';
import {NgApexchartsModule  } from "ng-apexcharts";
import { FreeDashboardComponent } from './free-dashboard/free-dashboard.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CircleProgressComponent } from '../components/circle-progress/circle-progress.component';
import { ReferEarnComponent } from './refer-earn/refer-earn.component';
import { ArticulateComponent } from './articulate/articulate.component';
import { WebinarDetailComponent } from './webinar-detail/webinar-detail.component';
import { WebinarRecordingComponent } from './webinar-recording/webinar-recording.component';
import { BootcampDetailComponent } from './bootcamp-detail/bootcamp-detail.component';
import { BootcampEnrollmentComponent } from './bootcamp-enrollment/bootcamp-enrollment.component';
import { BootcampPaymentComponent } from './bootcamp-payment/bootcamp-payment.component';
import { WebinarPaymentComponent } from './webinar-payment/webinar-payment.component';
import { CreditPointsComponent } from './credit-points/credit-points.component';
import { FreecoursePaymentComponent } from './freecourse-payment/freecourse-payment.component';
import { RegisteredWebinarsComponent } from './registered-webinars/registered-webinars.component';
import { RegisteredBootcampsComponent } from './registered-bootcamps/registered-bootcamps.component';
import { BootcampHistoryComponent } from './bootcamp-history/bootcamp-history.component';
import { WebinarHistoryComponent } from './webinar-history/webinar-history.component';
import { AppliedFreecoursesComponent } from './applied-freecourses/applied-freecourses.component';
import { GetRegisteredComponent } from '../components/get-registered/get-registered.component';
import { CreditPointSummaryComponent } from './credit-point-summary/credit-point-summary.component';
import { PopupComponent } from './popup/popup.component';
import { BootcampApplyComponent } from './bootcamp-apply/bootcamp-apply.component';
// import { DpDatePickerModule } from 'ng2-date-picker';

@NgModule({
    imports: [
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        DynamicFormBuilderModule,
        CommonModule,
        HomeRoutingModule,
        HttpClientModule,
        FontAwesomeModule,
        CarouselModule,   
        SelectDropDownModule,
        VgCoreModule,
        NgxIntlTelInputModule,
        LazyLoadImageModule,
        NgxPaginationModule,
        NgApexchartsModule,
        ImagekitioAngularModule.forRoot( {
            publicKey : "public_vwOaS5QyaBqvpvsuNDmVp9g7zqA=",
            urlEndpoint :"https://ik.imagekit.io/jar7w1ok5wk",
            authenticationEndpoint:"http://www.edubex.com/auth"
        }),        
        NgxLoadingModule
        .forRoot({
            animationType: ngxLoadingAnimationTypes.wanderingCubes,
            backdropBackgroundColour:  '#fff', 
            
            backdropBorderRadius: '4px',
            fullScreenBackdrop:true,
            // primaryColour: '#ffffff', 
            // secondaryColour: '#ffffff', 
            // tertiaryColour: '#ffffff'
        }), 
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),

        
    ],
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      },
        ApiService,
        TawkService,
        UtilService,
        UserService,
        CmsService,
        MasterService,
        AuthGuard,
        MultiSelectService
    ],
    exports: [
        RegisterComponent,
        LoginComponent,
        ShowProgramComponent      
      ],
    declarations: [
        
        HomeComponent,
        HiringPartnerComponent,
        ProgramsComponent,
        ProgramDetailComponent,
        FindCareerComponent,
        JoinNetworkComponent,
        CareersComponent,
        CareerListComponent,
        OurServicesComponent,
        BecomeInstructorComponent,
        HowWeStandApartComponent,
        UniversityPathwaysComponent,
        BannerComponent,
        PageDetailsComponent,
        LoginComponent,
        RegisterComponent,  
        CarouselSwipeDirective,  
        MatchPasswordDirective ,
        ScrollTrackerDirective,
        PasswordPatternDirective,
        PatternValidatorDirective,
        SignupEdubexComponent,
        UniversityProgressionExamplesComponent,
        OurStoryComponent,
        CircleProgressComponent,
        ContactUsComponent,
        OnDemandComponent,
        CalendarComponent,
        PresentersComponent,
        PrivacyPolicyComponent,
        CookiePolicyComponent,
        GdprComponent,
        TermsOfServiceComponent,
        ScheduleComponent,
        WebinarsComponent,
        EventsComponent,
        AcademicPoliciesComponent,
        ExaminationGradesComponent,
        NetworkBenefitsComponent,
        ParticipantSpeaksComponent,
        BottomBarComponent,
        NotFoundComponent,
        DashboardComponent,
        TransactionHistoryComponent,
        ApprovedCoursesComponent,
        AppliedCoursesComponent,
        CourseApplyComponent,
        CoursePaymentComponent,
        ForgotPasswordComponent,
        SuccessPaymentComponent,
        SynapseComponent,
        ChangePasswordComponent,
        AboutUsComponent,
        ResetPasswordComponent,
        BookCallComponent,
        CorporateTrainingComponent,
        SignupTeachEdubexComponent,
        ShowProgramComponent,
        CoursePackageComponent,
        FlexiblePaymentComponent,
        BlogComponent,
        BlogDetailsComponent,
        ScholarshipComponent,
        SuccessStoryComponent,
        SupportServiceComponent,
        CalendarListComponent,
        NotificationsComponent,
        MultiselectComponent ,
        HistoryComponent,
        InstructorComponent,
        BootcampComponent,
        GetRegisteredComponent,
        FreeCoursesComponent,
        DownloadBrochureComponent,
        FreeDashboardComponent,
        ReferEarnComponent,
        ArticulateComponent,
        WebinarDetailComponent,
        WebinarRecordingComponent,
        BootcampDetailComponent,
        BootcampEnrollmentComponent,
        BootcampPaymentComponent,
        WebinarPaymentComponent,
        CreditPointsComponent,
        FreecoursePaymentComponent,
        RegisteredWebinarsComponent,
        RegisteredBootcampsComponent,
        BootcampHistoryComponent,
        WebinarHistoryComponent,
        AppliedFreecoursesComponent,
        CreditPointSummaryComponent,
        PopupComponent,
        BootcampApplyComponent
    ],

})
export class HomeModule { }
