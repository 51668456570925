<div class="modal-body ">
    <div class="close" (click)="closeModal('dismiss')">
        <fa-icon style=" margin:5px;cursor:pointer;color: #000" [icon]="faTimesCircle"></fa-icon>
    </div>
<div class="card-sec " style="padding:20px">
    <div class="card-body" style="border-radius: 6px;padding-top: 0px;">
        <form class="form" #courseForm="ngForm" (ngSubmit)="courseForm.form.valid && onSubmit(courseForm)" novalidate>
            <h4 class="card-title"><b>Get Your Brochure</b></h4>
            <div class="row">
                <div class="col-md-12 ">
                    <input type="text" class=" text" [(ngModel)]="model.first_name" name="first_name" #first_name="ngModel" placeholder="First Name" pattern="[a-zA-Z\s]*$" required (change)="model.first_name = model.first_name.trim()">
                    <span class="text-danger" *ngIf="(first_name.touched || courseForm.submitted) && first_name.errors?.required">
                    First Name is required
                </span>
                    <span class="text-danger" *ngIf="(first_name.touched || courseForm.submitted) && first_name.errors?.pattern">
                Only Alphabets are allowed
            </span>
                </div>
                <div class=" col-md-12 ">
                    <input type="text" class=" text" [(ngModel)]="model.second_name" name="second_name" #second_name="ngModel" placeholder="Second Name" pattern="[a-zA-Z\s]*$" required (change)="model.second_name = model.second_name.trim()">
                    <span class="text-danger" *ngIf="(second_name.touched || courseForm.submitted) && second_name.errors?.required">
                    Second Name is required
                </span>
                    <span class="text-danger" *ngIf="(second_name.touched || courseForm.submitted) && second_name.errors?.pattern">
                Only Alphabets are allowed
            </span>
                </div>
                <div class=" col-md-12 ">
                    <input type="text" class=" text name" [(ngModel)]="model.email" name="email" #email="ngModel" placeholder="Email" required email (change)="model.email = model.email.trim()">
                    <span class="text-danger" *ngIf="(email.touched || courseForm.submitted) && email.errors?.required">
                    Email is required
                </span>
                    <span class="text-danger" *ngIf="email.touched && email.errors?.email">
                    Enter a valid email address
                </span>
                </div>
                <div class=" col-md-12 " style="margin: 2px 0px;">
                    <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                        [selectedCountryISO]="CountryISO.India" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National" name="phone" #phone="ngModel" required [(ngModel)]="model.phone">
                    </ngx-intl-tel-input>
                    <!-- <input type="text" class=" text"  pattern="^[- +()0-9]+$" [(ngModel)]="model.phone" name="phone" #phone="ngModel"
                    placeholder="+91" required phone> -->
                    <span class="text-danger" *ngIf="(phone.touched || courseForm.submitted) && phone.errors?.required">
                    Phone is required
                </span>
                    <!-- <span class="text-danger"
                *ngIf="(phone.touched || courseForm.submitted) && phone.errors?.pattern">
                Only Numbers are allowed
                </span> -->
                    <span class="text-danger" *ngIf="(phone.touched || courseForm.submitted) &&!phone.errors?.required &&  phone.errors?.validatePhoneNumber?.valid===false">
                            Invalid Phone number
                </span>
                </div>
                <div class=" col-md-12 ">
                    <select class="text" name="Country" style="background-color: #fff;" [(ngModel)]="model.country" #country (change)=selectCountry($event)>
                    <option value="" disabled selected> Country</option>
                    <option *ngFor="let country of fromParent.countries" [value]="country.name">
                    <div>
                        <img [src]="country.image" alt="{{country?.image_alt}}"> {{country.name}}
                    </div>  
                    </option>
                </select>
                </div>
                <div class=" col-md-12 ">
                    <select class="text" name="work_experience" style="background-color: #fff;" [(ngModel)]="model.work_experience" #work_experience >
                <option value="" disabled selected> Work Experience</option>
                <option value="0-1 year">0-1 Year </option>
                <option value="2 years">2 Years </option>
                <option value="3 years">3 Years </option>
                <option value="4 years">4 Years </option>
                <option value="5 years">5+ Years </option>
            </select>
                    <!-- <input type="text" class=" text" [(ngModel)]="model.work_experience" name="work_experience"
                    #work_experience="ngModel" placeholder=" Work Experience" required work_experience>
                <span class="text-danger"
                    *ngIf="(work_experience.touched || courseForm.submitted) && work_experience.errors?.required">
                    Work Experience is required
                </span> -->
                </div>
            </div>
            <div>
                <b>
                <p class="terms">By Clicking the button below, you agree to receive communications from
                    Edubex about this program and other relevant programs. Privacy Policy
                    Edubex's Terms 
                </p>
            </b>
                <div>
                    <input type="submit" class="btn download" value="Download Brochure">
                    <!-- <a target="_blank" href="{{pdf}}" download="file_new_name.pdf">Download Brochure</a> -->

                    <!--  <a href="#" class="btn download"></a>  -->
                </div>
               
            </div>
        </form>
    </div>
</div>
</div>