<div class="row " *ngIf="showModal1">
    <div class="col-md-3 main">
    </div>
</div>
<div [style.display]="showModal1 ? 'block' : 'none'" class="modal apply-mod" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg " style="margin: 0px auto;">
        <div class="modal-content" style="padding:0px">
            <div class="modal-body" style="padding:0px!important">
                <div class="close" (click)="hide()" style="top: 5px;z-index: 999;right: 0px;">
                    <fa-icon style=" margin:5px;cursor:pointer;color: #000" [icon]="faTimesCircle"></fa-icon>
                </div>
                <div class="row">
                    <div class="col-sm-12" style="padding:0px">
                        <div class="card content">
                            <div class="card-body">
                                <div class="col-md-12 box" style="padding:15px 0px!important">

                                    <div class="col-flexpop" style="background-color: #fff;">
                                        <div class="item cardblock">
                                            <div class="inst-img">
                                                <img class="float-left instructor" style="object-fit: contain;" src="{{instructor?.image}}" alt="{{instructor?.image_alt}}">
                                            </div>
                                            <div class="instruct" style="text-align: center;">
                                                <h4>{{instructor?.name}} </h4>
                                                <div>{{instructor?.designation}}</div>
                                                <div class="instr_logo">
                                                    <img style=height:50px class="center" [src]="instructor?.logo" />
                                                </div>
                                                <div>{{instructor?.description}} </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>