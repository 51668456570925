import { Component, Input, OnInit } from '@angular/core';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { CmsService } from 'src/app/core/services/cms/cms.service';
declare var bootbox;
@Component({
  selector: 'app-signup-teach-edubex',
  templateUrl: './signup-teach-edubex.component.html',
  styleUrls: ['./signup-teach-edubex.component.scss']
})
export class SignupTeachEdubexComponent implements OnInit {
  @Input('page') page: string;
  model ={
    first_name:'',
    second_name:'',
    company :'',
    phone:'',
    // campus:'',
    description:''
  };
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  campus: any;
  discipline: any;
  err_message: string;
    constructor(private cmsService:CmsService) { }
  
    ngOnInit(): void { 
    }
  
  onSubmit(form){
    console.log(form.value);
 
    let data = form.value;
    data.phone = form.value.phone?.e164Number;
    this.cmsService.signupTeachEdubex(data).subscribe(
      res => {
        console.log("res",res)  
        var dialog = bootbox.dialog({
          message: res.message,
          closeButton: true
        });
        this.err_message="";
        form.resetForm();    
       
      });
  }

   onCampusChange(event): void { 
    this.campus = event.target.value;    
  }

}
