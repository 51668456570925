import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-webinar-history',
  templateUrl: './webinar-history.component.html',
  styleUrls: ['./webinar-history.component.scss']
})
export class WebinarHistoryComponent implements OnInit {

  webinars:any=[];
  constructor( private userService:UserService) { }

  ngOnInit(): void {

    this.userService.getUser().subscribe(data=>{
      console.log(data)
     
      this.webinars=data.applied_webinar;
  })
}

}
