<div class="row dashboard">
    <div class="container-delivery">
        <div class="personal dashper">
            <div class="text-center">
                <h3>Hi!! {{user_details?.first_name}} {{user_details?.last_name}}</h3>
                <app-credit-point-summary #credits [point_summary]="point_summary"></app-credit-point-summary>

            </div>
            <div class="row">
                <div class="col-4 s-full dashboard-side">
                    <div class="block text-center block-height">
                        <h4 class="font-demi"> Your Overall Progress</h4>
                        <div>Good Start! Long way to go!</div>
                        <div class="row d-flex justify-content-center ">
                            <div class="col-md-6">
                                <div class="row">
                                    <p style="margin:0 auto">
                                        <span> <app-circle-progress [id]="'main'" [progress]="noOfFiles"></app-circle-progress></span>
                                    </p>
                                </div>
                                <br/>
                                <div class="row" *ngIf="completed">{{progress}}</div>
                                <!-- <div class="progress blue">
                                    <span class="progress-left">
                                        <span class="progress-bar"></span>
                                    </span>
                                    <span class="progress-right">
                                        <span class="progress-bar"></span>
                                    </span>
                                <div class="progress-value">90%</div>
                                </div>-->
                            </div>
                        </div>
                        <h5>Total Earned Credits:{{credit}}</h5>
                        <h3 role="button" class="font-demi" (click)="credits.show('open')">Click here to know how to boost your credit</h3>

                        <h3 class="font-demi">Enrollments</h3>
                        <hr>
                        <div class="row">
                            <!-- <div class="col-4">Paid Courses</div> -->
                            <div class="col-6">Free Courses</div>
                            <div class="col-6">Total Completed</div>
                        </div>
                        <div class="row m-20">

                            <div class="col-4 d-none">
                                <span class="value">
                            </span>
                            </div>
                            <div class="col-6">
                                <span class="value">
                                {{applied}}


                            </span>
                            </div>
                            <div class="col-6">
                                <span class="value">
                                {{free_courses}}
                            </span>
                            </div>
                        </div>
                        <h3 class="font-demi">Certificates</h3>
                        <hr>
                        <div *ngIf="freecourse_certificate.length">
                            <div class=" m-20" *ngFor="let certificate of freecourse_certificate">

                                <div class="value" style="cursor: pointer;" (click)="open(certificate.certificate_issued)">
                                    {{certificate?.freecourse_title}}
                                </div>
                            </div>
                        </div>

                        <div class=" m-20" *ngIf="!freecourse_certificate.length">
                            No Certificates
                        </div>

                    </div>
                </div>
                <div class="col-8 p-0 s-full">
                    <div class="row">
                        <div class="col-6 s-full social-contr">
                            <div class="block block-cn">
                                <h4 class="font-demi">Still Have Trouble</h4>
                                <h3 class="font-demi">Let's Discuss With Advisor</h3>
                                How would you like to be interacted?
                                <div class="row">
                                    <div class="col-4 text-center s-full">
                                        <input id="email" (change)="onChange($event.target.value)" type="radio" value="1" name="message" [(ngModel)]="contact">
                                        <label for="email"> Email</label>
                                    </div>
                                    <div class="col-4 text-center s-full">
                                        <input id="whatsapp" (change)="onChange($event.target.value)" type="radio" value="2" name="message" [(ngModel)]="contact">
                                        <label for="whatsapp">Phone </label>
                                    </div>
                                    <div class="col-4 text-center s-full">
                                        <input id="num" (change)="onChange($event.target.value)" type="radio" value="3" name="message" [(ngModel)]="contact">
                                        <label for="num"> Whatsapp</label>
                                    </div>
                                </div>
                                <div class="row" *ngIf="contact">
                                    <div class="col-12">
                                        <form #forgetForm="ngForm">
                                            <div *ngIf="contact==1">
                                                <input type="text" [(ngModel)]="email" name="mail" placeholder="Email" #mail="ngModel" required email id="msg" class="text">
                                                <div class="text-danger" *ngIf="(mail.touched || forgetForm.submitted) && mail.errors?.required">
                                                    Email is required
                                                </div>
                                                <div class="text-danger" *ngIf="mail.touched && mail.errors?.email">
                                                    Enter a valid email address
                                                </div>
                                            </div>
                                            <div *ngIf="contact==2">
                                                <input type="number" [(ngModel)]="phone" name="call" placeholder="Phone" #call="ngModel" required id="msg" class="text">
                                                <div class="text-danger" *ngIf="(call.touched || forgetForm.submitted) && call.errors?.required">
                                                    Phone is required
                                                </div>
                                            </div>
                                            <div *ngIf="contact==3">
                                                <input type="number" [(ngModel)]="whatsapp" name="whats" placeholder="Whatspp" #whats="ngModel" required id="msg" class="text">
                                                <div class="text-danger" *ngIf="(whats.touched || forgetForm.submitted) && whats.errors?.required">
                                                    Whatsapp is required
                                                </div>
                                            </div>



                                            <button type="submit" class="btn join" (click)="sendData(forgetForm)">Submit</button>
                                        </form>
                                        <!-- <input id="msg" type="test" class="text" name="txtField" [(ngModel)]="message"> -->

                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 s-full">
                            <div class="block block-cn">
                                <h4>{{course_offer?.title}}</h4>
                                <hr>
                                <b [innerHTML]="course_offer?.description"></b>
                                <b>Course fee:{{course_offer?.fee}}</b>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="block" style="margin-top:20px;">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-md-12 p-0">
                                            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                                                <li [ngbNavItem]="1">
                                                    <a ngbNavLink>Free Courses</a>
                                                    <ng-template ngbNavContent>
                                                        <div class="row m-0 progress-scroll">
                                                            <div class="col-4 m-full" *ngFor="let course of free_list">
                                                                <div class="block1" *ngIf="course">
                                                                    <div *ngIf="course.bookmark_progress!=100" class="title p-10" style="cursor: pointer;" (click)="goFreecourse(course)">
                                                                        <p>{{course?.title}}</p>
                                                                    </div>
                                                                    <div *ngIf="course.bookmark_progress==100" class="title p-10">
                                                                        <p>{{course?.title}}</p>
                                                                    </div>
                                                                    <div class="content-course">
                                                                        <div class="set-progress" *ngIf="!course?.claim_freecourse_certificate_link">
                                                                            <app-circle-progress [id]="course.slug" [progress]="course.bookmark_progress"></app-circle-progress>
                                                                        </div>
                                                                        <div class="set-progress" *ngIf="course?.claim_freecourse_certificate_link">
                                                                            <app-circle-progress [id]="course.slug" [progress]="'100'"></app-circle-progress>
                                                                        </div>

                                                                        <a *ngIf="course?.claim_freecourse_certificate_link" target="_blank" class="view  font-demi free-course-know" href="{{course.claim_freecourse_certificate_link}}">Claim Your Certificate</a>

                                                                        <!-- <p class="p-10" [innerHTML]="course.description">

                                                                        </p> -->
                                                                    </div>

                                                                    <hr>
                                                                </div>

                                                            </div>
                                                            <div class="block1" *ngIf="!free_list.length">
                                                                No Free Courses
                                                            </div>


                                                        </div>
                                                    </ng-template>
                                                </li>
                                                <!-- <li [ngbNavItem]="2">
                                                    <a ngbNavLink>Paid Courses</a>
                                                    <ng-template ngbNavContent>
                                                        <div class="row">
                                                            <div class="col-4"  *ngFor="let course of paid_list">
                                                                <div class="block1">
                                                                    <div class="title p-10">
                                                                        {{course?.title}}                                                                    </div>
                                                                        <p class="p-10" [innerHTML]="course?.overview">
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                            <div class="col-4"></div>
                                                            <div class="col-4"></div>
                                                        </div>
                                                    </ng-template>
                                                </li>                                              -->
                                            </ul>

                                            <div [ngbNavOutlet]="nav" class="mt-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>