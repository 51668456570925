import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { UserService } from 'src/app/core/services/user/user.service';
declare var bootbox;
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @Input('show') show: boolean;
  signup: boolean = false;
  banner = "login"
  page_load=false;
  faTimesCircle = faTimesCircle;
  id;
  active = true;
  //showModal:boolean;
  constructor(private _activatedRoute:ActivatedRoute,public userService:UserService, public router:Router) { 
    this._activatedRoute.params.subscribe(params => {
     
        this.id = params.id  
        console.log('params',this.id);
        this.page_load = true;
        this.userService.checkExpiry({         
          encripted_userid:this.id
        }).subscribe(
          res => {
            if(res.status){

              this.active = true
            }else{
              this.active = false
            }
            this.page_load = false;
          });
        if(userService.isLoggedIn()){
        //  
            this.userService.deleteToken();
          //   this.userService.logout().subscribe(res=>{
          //     console.log("resp",res)
          // })
          // this.userService.getUser().subscribe(user=>{
          //   console.log("user",user.user_id)
          // })
        }
        console.log(this.router.url,userService.isLoggedIn());


           
    });
  }
  showErrorMessage = "";
  ngOnInit(): void {
   
  }


  onSubmit(form:NgForm) {
    console.log("form",form.value)
    this.userService.resetPassword({     
      password: form.value.new_password,
      encripted_userid:this.id
    }).subscribe(
      res => {
        if(res.status===1){
          var dialog = bootbox.dialog({
            message: "Password changed Successfully!!",
            closeButton: true
          });
        }else{
          var dialog = bootbox.dialog({
            className: 'block',
            message: "Link expired!!",
            closeButton: true
          });
         
        }
      
        this.show = false;
        this.router.navigate(['/']);
      },
      err => {
        console.log("err", err)
        if (err.status === 400) {
          form.resetForm()
          this.showErrorMessage = "Incorrect Password";
        }

      }
    );
  }
  showForm() {
    this.show = true;

  }
  //Bootstrap Modal Close event
  hide(form) {
    this.show = false;
  form.resetForm();
    console.log("show", this.show);
    
  }

}
