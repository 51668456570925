import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-applied-freecourses',
  templateUrl: './applied-freecourses.component.html',
  styleUrls: ['./applied-freecourses.component.scss']
})
export class AppliedFreecoursesComponent implements OnInit {

  appliedCourses: any=[];
  constructor(private userService:UserService) { }
  ngOnInit(): void {
    this.getAppliedCourses()
  }

  getAppliedCourses(){
    this.userService.getAppliedFreeCourses()
    .subscribe(result=>{
      console.log("result",result)
      result.forEach(element => {
        if(element)
        this.appliedCourses.push(element);        
      });
    })
  }


}
