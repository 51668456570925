<div class="container-fluid" style="padding:0px">
    <div class="row">
        <div class="col-md-12">
            <!-- <div class="banner-item">
                <div  class="picsum-img-wrapper box">
                    <img src="../../../../assets/images/banner/outspurcung service.jpg" alt="">
                   
                    
                </div>
            </div> -->
            <app-banner [page]="'support-services'"></app-banner>
        </div>
    </div>
</div>

<div  class="container-delivery serv-col" style="padding-top:25px">
    <!-- <div *ngFor="let stand of list let i= index;let isOdd=odd;let isEven=even"> -->
        <div class="row item" style="margin:20px 0px">
            <div class="col-md-6 col-xl-5 ">
                <img src="../../../../assets/images/service-left.png" style="width: 100%;" class="card-img" alt="...">               
            </div>
            <div class="col-xl-1"></div>
            <div class="col-md-6">
                <!-- <h1 class="index">{{i+1 < 10 ? '0' : ''}}{{i+1}}</h1> -->
                <h4>Are you studying at Edubex or wish to join us?</h4> 
                <div class="underline" style="margin-left:0px!important"></div> 
                <p>We offer support services and advice to our learners in many ways. In addition to the scheduled lessons, our learners receive ongoing academic and employability support. Enrichment workshops help develop their language and employability skills.</p>         
            </div>
        </div>
        <div class="row item" style="margin:20px 0px">
                <div class="col-md-6 col-xl-5 dis767" style="display:none">
                    <img src="../../../../assets/images/service-left.png" style="width: 100%;" class="card-img" alt="...">
                </div>
                <div class="col-md-6">
                <!-- <h1 class="index">{{i+1 < 10 ? '0' : ''}}{{i+1}}</h1> -->
                <h4>Advice and Guidance </h4> 
                <div class="underline" style="margin-left:0px!important"></div> 
                <p>We offer a welcoming learning environment to our learners by providing the necessary information, advice and guidance to all learners, starting from the point of application, registration, through the chosen programme of study to the award of qualification. Moreover, each course is designed to include an induction programme.</p>         
                <p>If any of our courses interests you, please contact our course advisors who can give you information about the courses, entry requirements, tuition fees and loans and guide you with the application processes.</p>
            </div> 
            <div class="col-xl-1" ></div>
            <div class="col-md-6 col-xl-5 none767" >
                <img src="../../../../assets/images/service-right.png" style="width: 100%;" class="card-img" alt="...">               
            </div>   
          
        </div>
    <!-- </div> -->
    <div class="row item" style="margin:20px 0px">
        <div class="col-md-6 col-xl-5">
            <img src="../../../../assets/images/service-left.png" style="width: 100%;" class="card-img" alt="...">               
        </div>
        <div class="col-xl-1"></div>
        <div class="col-md-6">
            <!-- <h1 class="index">{{i+1 < 10 ? '0' : ''}}{{i+1}}</h1> -->
            <h4>Induction</h4> 
            <div class="underline" style="margin-left:0px!important"></div> 
            <p>Once you are ready to begin your learning journey with us, you will attend your induction module. The induction activities include, but are not limited to:</p> 
               
            <ul>
                <li>An introduction to the chosen course, course tutor, course timetable, assignment submission procedures and assessment methods</li>
           <li> Information on how to access course materials online with log-in details</li>
        <li> Information on Policies and Complaints and Appeals Procedures</li>    
        </ul>     
        </div>
    </div>
    <div class="row item" style="margin:20px 0px">
        <div class="col-md-6 col-xl-5 dis767" style="display:none">
            <img src="../../../../assets/images/service-left.png" style="width: 100%;" class="card-img" alt="...">
        </div>
        <div class="col-md-6">
            <!-- <h1 class="index">{{i+1 < 10 ? '0' : ''}}{{i+1}}</h1> -->
            <h4>Academic Support</h4> 
            <div class="underline" style="margin-left:0px!important"></div> 
            <p>We support you in achieving your personal and professional goals as you progress in your studies, whether it is assistance regarding course work, assessments or professional development, through face-to-face meetings, over the phone discussions or email. Our LMS helps you access all the course materials and interactive activities from anywhere at any time.
               </p><p> In addition to the scheduled tutorials and workshops, we offer additional support and guidance to you via our online learning platform and one-to-one support sessions.
            </p><p> You just need to book one-to-one consultations with your personal tutors for additional assistance.
                </p>         
        </div> 
        <div class="col-xl-1" ></div>
        <div class="col-md-6 col-xl-5 none767" >
            <img src="../../../../assets/images/service-right.png" style="width: 100%;" class="card-img" alt="...">               
        </div>   
      
    </div>
    <div class="row item" style="margin:20px 0px">
        <div class="col-md-6 col-xl-5">
            <img src="../../../../assets/images/service-left.png" style="width: 100%;" class="card-img" alt="...">               
        </div>
        <div class="col-xl-1"></div>
        <div class="col-md-6">
            <!-- <h1 class="index">{{i+1 < 10 ? '0' : ''}}{{i+1}}</h1> -->
            <h4>Employability and Carrier Support</h4> 
            <div class="underline" style="margin-left:0px!important"></div> 
            <p>Our academic programmes provide you with a globally accepted qualification, as well as an opportunity to get good references and work experience (ICT programs), which are mandatory for a successful career. Nowadays, employers are looking for highly skilled and committed workforce who could help boost business productivity. We, at Edubex, aim to bridge the gap between education and business by embedding state-of-the-art Employability Workshops in addition to the degree-specific academic curriculum.
               </p><p> Employability workshops embedded into the academic programmes offer opportunities for self- development, personally and professionally, which will be a life-changing experience that you can take away with you on completion of the course. In addition, we help you build your CV and pass your interviews and psychometric tests through our online learning techniques and professional discussions. We provide personalized career advice through 1-1 sessions with industry mentors. We also help you with your job or university applications for higher studies at the successful completion of the course.
                </p>         
        </div>
   
      
    </div>
    </div>
    <div class="row container-delivery">
        <div class="col-md-12">
            <app-find-career></app-find-career>
        </div>
    </div>
    
    <div class="container-delivery">
        <div class="col-fixed">
            <div class="bottom" >
                <app-bottom-bar></app-bottom-bar>    
            </div>
        </div>
        </div>
