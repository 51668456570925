import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { MasterService } from './core/services/master/master.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showTopBar = false;
  showNav = false;
  showFooter = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,  private titleService: Title,
    private metaService: Meta, private masterService:MasterService) {}

 
  ngOnInit() {
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
    )
      .subscribe(() => {
        var route = this.getChild(this.activatedRoute)
        var data = route.snapshot.routeConfig.path;
        console.log(typeof(data))
        let terms =["program-details","course-payment","career-list","reviews-and-testimonials","scheduledcourses","scheduledshortcourses","blog-details"]
        const check= terms.some(term => data.includes(term))
        console.log("check",check)
        if(data!==''&& data!=='**' && !check){
          console.log("route",data)
          this.masterService.getSeo(data).subscribe((resp: any) => { 
            console.log("resp",resp)  
            if(resp.status)     			
      
            // if(this.page==='hiring-partner'){
              this.titleService.setTitle(resp.data.title); // <-- Update the title
      
              this.metaService.updateTag({ 
                name: 'title',
                content: resp.data.meta_title
            });
            this.metaService.updateTag({ 
              name: 'description',
              content: resp.data.meta_description
          });
            // }
            
          }, (err: any) => {
            console.log(err);
          });
        }
       
        // if (data.descrption) {
        //   this.metaService.updateTag({ name: 'description', content: data.descrption })
        // } else {
        //   this.metaService.removeTag("name='description'")
        // }
      })
  

    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          //background: "#000",
          text:"#fff",
         
        },
        button: {
          background: "transparent",
          text: "#fff",
          border: "#e26a27"
        }
      },
      theme: "classic",
      // "type": "opt-out",
      layout: 'my-custom-layout',
      layouts: {
        "my-custom-layout": '{{buttons}}{{deny}}{{dismiss}}'
      },
      elements:{
        buttons: `
        <span id="cookieconsent:desc" class="cc-message" style="margin-left:20px;font-size:12px;">{{message}} 
        <a style="color:#fff;border-bottom:1px solid #fff" href="/privacy-policy">{{link}}</a>       
        </span>        
        `,
      },
      content: {
        message: "We use cookies to make interactions with our websites and services easy and meaningful.For more information about the cookies we use or to find out how you can disable the cookies.",
        dismiss: "ACCEPT COOKIES",
        deny: "Disable cookies",
   
        link: "click here",
        href: "/privacy-policy" ,
        policy: "Cookie Policy"
      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0)
        this.showTopBar = this.activatedRoute.firstChild.snapshot.data.showTopBar !== false;
        this.showNav = this.activatedRoute.firstChild.snapshot.data.showNav !== false;
        this.showFooter = this.activatedRoute.firstChild.snapshot.data.showFooter !== false;
      }
    });
  }
  getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild)
      return this.getChild(activatedRoute.firstChild);
    else
      return activatedRoute;
  }
  scrollToTop() {
   
    window.scrollTo(0, 0)
  }
}
