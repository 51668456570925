 <div class="row banner">
    <div class="row" style="width:100%;position: relative;margin: 0px;">
        <div class="card-image">
            <app-banner [page]="page"></app-banner>
        </div>
        <div class="row container-delivery hirpt">
            <div class="card-sec sig-form">
                <div class="card-body">
                    <app-signup-edubex></app-signup-edubex>
                </div>
            </div>
        </div>   
    </div>
    <div class="container-fluid">
        <div class="container-delivery">   
            <div class="row">
                <div class="col-md-12 col-instr" style="margin-top:30px;">
                    <h3 class="text-center">{{options?.title}}</h3>
                    <div class="underline" style="margin-bottom:25px"></div>
                </div>
            </div>
            <div class="row row-eq-height">
                <div class="col-md-4 time">
                    <div class="row ">
                        <div class="col-md-4 float-left">
                            <img style="width:100%" src="../../../../assets/images/images/join-our-team-ico01.jpg">                
                        </div>
                        <div class="col-md-8 float-left">
                            <h5>Events</h5>
                            <p>Conduct Industry relevant events from the comfort of your home, where you can share your experience
                                and expertise in related fields through forums, discussions, workshops and seminars.
                            </p>
                        </div>
                    </div>                
                </div>
                <div class="col-md-4 time ">
                    <div class="row ">
                        <div class="col-md-4 float-left">
                            <img style="width:100%" src="../../../../assets/images/images/join-our-team-ico02.jpg">                
                        </div>
                        <div class="col-md-8 float-left">
                            <h5>Part-time</h5>
                            <p>Teach a course part time at your convenience, either in the evening or at weekends.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 time ">
                    <div class="row">
                        <div class="col-md-4 float-left">
                            <img style="width:90%;" src="../../../../assets/images/edubex-icon.png">                
                        </div>
                        <div class="col-md-8 float-left">
                            <h5>Full-time</h5>
                            <p>Teach full time at one of your premises.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
         
            <div class="row">
                <div class="col-md-12">
                    <app-page-details [page]='page'></app-page-details>                
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="row"  style="margin-top:0px;">
                        <div class="col-lg-5 none991">
                            <img src={{expect?.image}}  class="card-img" alt="{{expect?.image_alt}}">
                        </div>
                        <div class="col-lg-7">
                            <div class="">
                                <div class="card-text bec-ins-col">
                                    <h4 style="margin-top:30px;margin-left: 0px;">{{expect?.title}}</h4>
                                    <div class="underline-" style="margin:0px 0px 25px"></div>
                                    <div class="row" style="font-size: 16px;">
                                        <div class="col-md-12">
                                            <ul style="list-style-type:square;padding:0px">
                                                <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(expect?.description)"></div>                                        
                                            </ul> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h3 class="text-center">{{subject?.title}}</h3>
                    <div class="underline" style="margin-top:5px;margin-bottom:30px"></div>
                </div>
            </div>

        <div style="margin-bottom:30px" class="sub-col" [innerHTML]="sanitizer.bypassSecurityTrustHtml(subject?.description)"></div>

            <div class="row apply colsaply">               
                <div class="col-md-7" style="margin-top: 20px;">
                    <h1>Apply Now to teach at Edubex and we will get in touch </h1>
                    <div class="under-line"></div>
                    <h3>Looking forward to working with you! </h3>
                </div>
                <div class="col-md-5 colsng"   >
                    <div class="col-md-12 signup" >
                        <app-signup-edubex></app-signup-edubex>                      
                    </div>
                </div>
            </div> 
        </div>
    </div>
</div>
<div class="container-delivery">
<div class="col-fixed">
    <div class="bottom" >
        <app-bottom-bar></app-bottom-bar>
    </div>
</div>
</div>
