<!-- <div class="modal-header">

    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button> 
    
</div> -->
<div class="modal-body">
  <div class="close" (click)="closeModal('dismiss')">
    <fa-icon style=" margin:5px;cursor:pointer;color: #000" [icon]="faTimesCircle"></fa-icon>
</div>
    <div class="row">      
        <div class="col-md-5 col-sm-5 d-none" style="padding: 0px;background: url(../assets/images/box-bg.png);background-size: 100% 100%;background-repeat: no-repeat;">
          <div class=" box">
            <!-- <h1 class=" align-self-center">            -->
               <img width="100%" src="../../../assets/images/logo_white.svg">
            <!-- </h1> -->
            <!-- <h6>Lorem ipsum is placeholder industries for previewingdolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et</h6> -->
        
          </div> 
         </div>
        <div class="col-md-12 col-sm-12 align-self-center p-0">
          <!-- <div class="float-right" routerLink="/"><fa-icon style=" margin:5px;color: #000" [icon]="faTimesCircle"></fa-icon></div> -->
<ng-template #login>
  <div class="loginbox">
    <div class="login-head">
      <img width="100%" src="../../../assets/images/logo_white.svg">
    </div>
    <div *ngIf="fromParent.prop1==='bootcamp' || fromParent.prop1==='bootcamp-details'" class="login-status">
      <img width="100%" src="../../../assets/images/login-status.png">
    </div>
    <div class="text-danger" >{{showErrorMessage}}</div>
    <h4 class="login-title"><b>Login</b></h4> 
    <span style="color: #223f31 !important;">New to Edubex?</span>
     <span  (click)="openModal()" class="signup"> Sign Up</span>
      <app-forgot-password #forgot></app-forgot-password>
    <form #signInForm="ngForm" (ngSubmit)="signInForm.valid &#038;&#038; onSubmit(signInForm,'dismiss')">
      <div class="col-md-12 col-logi"  style="padding:0px">
        <i class="fa fa-envelope" aria-hidden="true"></i>
       <input type="text"  [(ngModel)]="model.email" name="email" placeholder="Email"
       #email="ngModel" required email>
     <div class="text-danger" *ngIf="(email.touched || signInForm.submitted) && email.errors?.required">
       Email is required
     </div>
     <div class="text-danger" *ngIf="email.touched && email.errors?.email">
       Enter a valid email address
     </div></div>
     <div class="col-md-12 col-logi"  style="padding:0px">
      <i class="fa fa-lock" aria-hidden="true"></i>
     <input type="password" [(ngModel)]="model.password" name="password" placeholder="Password"
     #password="ngModel" required >
   <div class="text-danger" *ngIf="(password.touched || signInForm.submitted) && password.errors?.required">
     Password is required
   </div>           </div>
      <div class="remember">
           <input type="checkbox"> Remember me
        </div>
        <ng-template #customLoadingTemplate>
          <div class="custom-class">
          </div>
      </ng-template>
      <ngx-loading [show]="loading" [config]="{    fullScreenBackdrop:true,backdropBackgroundColour:'#edecec80'}" [template]="customLoadingTemplate"></ngx-loading>

       <input type="submit"  class="btn btn-login"  value="Log In">
     </form>
    
    <p class="signup" (click)="forgot.showForm()">Forgot your password?</p> 
  </div> 
</ng-template>
         
          <div class="loginbox"
           *ngIf="fromParent.prop1==='home'||fromParent.prop1==='program-details'||fromParent.prop1==='program' ||fromParent.prop1==='home'; else login">
           <div class="login-head" style="margin: 10px 0px;">
            <img width="100%" src="../../../assets/images/logo_white.svg">
            </div>
           <div class="text-danger" >{{showErrorMessage}}</div>
            <div  style=" color: rgba(230, 90, 11, 0.9) !important;">Applying to a Program?</div>
             <button  (click)="openModal()"  class="btn btn-login"> Create Account</button>
             <div style=" color: rgba(230, 90, 11, 0.9)!important;">Already have an account?</div>

            <!-- <div *ngIf=signup> -->
              <app-forgot-password #forgot></app-forgot-password>
            <form #signInForm="ngForm" (ngSubmit)="signInForm.valid &#038;&#038; onSubmit(signInForm,'dismiss')">
              <div class="col-md-12 col-logi"  style="padding:0px">
                <i class="fa fa-envelope" aria-hidden="true"></i>
               <input type="text"  [(ngModel)]="model.email" name="email" placeholder="Email"
               #email="ngModel" required email>
             <div class="text-danger" *ngIf="(email.touched || signInForm.submitted) && email.errors?.required">
               Email is required
             </div>
             <div class="text-danger" *ngIf="email.touched && email.errors?.email">
               Enter a valid email address
             </div></div>
             <div class="col-md-12 col-logi"  style="padding:0px">
              <i class="fa fa-lock" aria-hidden="true"></i>
             <input type="password" [(ngModel)]="model.password" name="password" placeholder="Password"
             #password="ngModel" required >
           <div class="text-danger" *ngIf="(password.touched || signInForm.submitted) && password.errors?.required">
             Password is required
           </div>           </div>
              <div class="remember">
                   <input type="checkbox"> Remember me
                </div>
                <ng-template #customLoadingTemplate>
                  <div class="custom-class">
                  </div>
              </ng-template>
              <ngx-loading [show]="loading" [config]="{fullScreenBackdrop:true,backdropBackgroundColour:'#edecec80'}" [template]="customLoadingTemplate"></ngx-loading>

               <input type="submit"  class="btn btn-login"  value="Log In">
             </form>
            
            <p class="signup" (click)="forgot.showForm()">Forgot your username & password?</p> 
          </div>
        </div>

      </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
    <button type="button" class="btn btn-primary" (click)="closeModal('changes saved!')">Save changes</button>
</div> -->