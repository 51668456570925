<app-banner [page]="'terms-services'"></app-banner>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <h3>1. INTRODUCTION</h3>
            <p>
                1.1 You will be able to access most areas of this Website without registering your details with us. Certain areas of this Website are only open to you if you register.
            </p>
            <p> 1.2 We may revise these terms and conditions at any time by updating this page. You should check this Website from time to time to review the then current terms and conditions, because they are binding on you. Certain provisions of these terms
                and conditions may be superseded by expressly designated legal notices or terms located on particular pages of this Website. If you do not wish to accept any new terms and conditions after we have posted them on the website, you should
                not continue to use this Website.</p>
            <h3>2. LICENCE</h3>
            <p>
                2.1 The “Partner Area” of our Website allows customers and prospective customers to view our financial and commercial information. This area of our site may only be accessed by registered users and the information contained within this area is our confidential
                information. Users with access to this area must safeguard and keep confidential all such confidential information and any such users must not use or disclose such confidential information to any third party. The obligations set out in
                this clause 2.1 shall not apply to any information to the extent it is publicly available or becomes publicly available through no act or omission of that party or to the extent that it is required to be disclosed by law or by order of
                a court of competent jurisdiction or other competent authority.
            </p>
            <p>
                2.2 You may not print and download extracts from the Vision Area or the Partner Area. You are otherwise permitted to print and download extracts from this Website for your own use on the following basis:
            </p>
            <ul>
                <li>
                    no documents or related graphics on this Website (whether physical or digital copies) are modified in any way;

                </li>
                <li> no graphics, photographs, video or audio sequences on this Website are used separately from accompanying text;
                </li>
                <li> you must not use any part of the content on our Website for commercial purposes without obtaining our prior written permission to do so;
                </li>
                <li> if you print off, copy or download any part of our Website in breach of these terms, without prejudice to any other right or remedy available to us, your right to use our Website will cease immediately and you must, at our option, return
                    or destroy any copies of the materials you have made; and
                </li>
                <li> any of our copyright and trademark notices and this permission notice appear in all copies and our status (and that of any identified contributors) as the authors of content on our Website must always be acknowledged.
                </li>
            </ul>
            <p>2.3 Unless otherwise stated, the copyright and other intellectual property rights in all material on this Website (including without limitation photographs and graphical images) are owned by us or our licensors. For the purposes of these terms
                and conditions, any use of extracts from this Website other than in accordance with this clause 2 for any purpose is prohibited. If you breach any of the terms in these terms and conditions, your permission to use this Website automatically
                terminates and you must immediately destroy any downloaded or printed extracts from this Website. </p>
            <p>2.4 Subject to clause 2.3, no part of this Website may be reproduced or stored in any other website or included in any public or private electronic retrieval system or service without our prior written permission.</p>
            <p> 2.5 Any rights not expressly granted in these terms are reserved.</p>

            <h3>3. SERVICE ACCESS</h3>
            <p> 3.1 While we endeavour to ensure that this Website is normally available 24 hours a day, we will not be liable if for any reason this Website is unavailable at any time or for any period. 3.2 We do not guarantee that our Website (or any part
                of it) will always be available or that access to it will be uninterrupted. Access to our Website is permitted on a temporary basis. We may suspend, withdraw, change or discontinue all or any part of our Website without notice including
                in the case of system failure, maintenance or repair or for reasons beyond our control. 3.3 You are responsible for making all arrangements necessary for you to have access to our Website. 3.4 You are responsible for ensuring that all
                persons who access our Website through your internet connection are aware of these terms and conditions and other applicable terms and conditions, and that they comply with them.</p>

            <h3> 4. VISITOR MATERIAL AND CONDUCT</h3>
            <p> 4.1 Other than personally identifiable information, which is covered under the Privacy Policy, any material you transmit or post to this Website will be considered non-confidential and non-proprietary. We will have no obligations with respect
                to such material. We and our nominees will be free to copy, disclose, distribute, incorporate and otherwise use such material and all data, images, sounds, text and other things embodied therein for any and all commercial or non-commercial
                purposes.
            </p>
            <p> 4.2 Whenever you make use of a feature that allows you to upload content to our site, or to make contact with other users of our Website, you must not post or transmit to or from this Website any material:</p>
            <ul>
                <li> that is threatening, defamatory, obscene, indecent, seditious, offensive, pornographic, abusive, liable to incite racial hatred, discriminatory, menacing, scandalous, inflammatory, blasphemous, in breach of confidence, in breach of privacy
                    or which may cause annoyance or inconvenience;</li>
                <li>for which you have not obtained all necessary licences and/or approvals;</li>
                <li> which constitutes or encourages conduct that would be considered a criminal offence, give rise to civil liability, or otherwise be contrary to the law of or infringe the rights of any third party, in the UK or any other country in the
                    world; or
                </li>
                <li> which is technically harmful (including, without limitation, computer viruses, logic bombs, Trojan horses, worms, harmful components, corrupted data or other malicious software or harmful data).</li>
            </ul>

            <p> 4.3 You warrant that any material you post or transmit to or from our Website will comply with the standards set out in these terms and conditions and you will be liable to us and indemnify us for any breach of that warranty. If you are a
                consumer user, this means you will be responsible for any loss or damage we suffer as a result of your breach of the relevant warranty.</p>

            <p> 4.4 You may not misuse the Website (including, without limitation, by hacking) or knowingly introduce viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. </p>
            <p>4.5 We will fully co-operate with any law enforcement authorities or court order requesting or directing us to disclose the identity or locate anyone posting any material in breach of this clause 4. We have the right to disclose your identity
                to any third party who is claiming that any content posted or uploaded by you to our Website constitutes a violation of their intellectual property rights, or of their right to privacy.</p>
            <p> 4.6 We will not be responsible, or liable to any third party, for the content or accuracy of any content posted by you, or any other user of our Website. The views expressed by other users on our Website do not represent our views or values.</p>
            <p> 4.7 We have the right to remove any posting you make on our Website if, in our opinion, your post does not comply with the content standards set out in these terms and conditions.</p>

            <h3>5. LINKS TO AND FROM OTHER WEBSITES</h3>
            <p> 5.1 Links to third party websites on this Website are provided solely for your convenience. If you use these links, you leave this Website. We have not reviewed all of these third party websites and do not control and are not responsible for
                these websites or their content or availability. We therefore do not endorse or make any representations about them, or any material found on them, or any results that may be obtained from using them. If you decide to access any of the
                third party websites linked to on this Website, you do so entirely at your own risk. </p>
            <p>5.2 If you would like to link to this Website, you may only do so on the basis that you link to, but do not replicate, the home page of this Website, you do so in a way that is fair and legal and does not damage our reputation or take advantage
                of it , and subject to the following conditions:</p>
            <ul>
                <li> you do not remove, distort or otherwise alter the size or appearance of the Edubex logo;
                </li>
                <li>
                    you do not create a frame or any other browser or border environment around this Website;

                </li>
                <li> you do not in any way imply that we are endorsing any products or services other than our own;
                </li>
                <li> you do not misrepresent your relationship with us nor present any other false information about us;
                </li>
                <li> you do not otherwise use any trade marks displayed on this Website without our express written permission;
                </li>
                <li> you do not link from a website that is not owned by you; and your website (in which you are linking) complies with all applicable laws and regulations and does not contain content that is distasteful, offensive or controversial, or infringes
                    any intellectual property rights or other rights of any other person.</li>
            </ul>

            <p> 5.3 We expressly reserve the right to revoke the right granted in this clause 5.2 (the right to link to this Website) without notice. </p>
            <p>5.4 You shall fully indemnify us for any loss or damage we or any of our group companies may suffer or incur as a result of your breach of clause 5.2. If you are a consumer user, this means you will be responsible for any loss or damage we
                suffer as a result of your breach of Clause 5.2.</p>
            <h3> 6. REGISTRATION</h3>
            <p> 6.1 To register to use those areas of our Website open only to registered users you must be over eighteen years of age and you must apply in writing (or by e-mail) using the addresses detailed in these terms and conditions. We reserve the
                right, in our sole discretion, to reject any such applications for registration.
            </p>
            <p> 6.2 Each registration is for a single user only. We do not permit you to share your user name and password with any other person nor with multiple users on a network.</p>
            <p> 6.3 Responsibility for the security of any passwords issued rests with you and if you know or suspect that someone else knows your password, you should contact us immediately.</p>
            <p> 6.4 We may suspend or cancel your registration immediately at our reasonable discretion or if you breach any of your obligations under these terms and conditions.</p>
            <h3> 7.INTERNET CONNECTION</h3>
            <p>
                The Edubex material requires a correctly configured and functional internet data connection, both for the initial Purchase, and for use. Data charges may be charged to you by your network provider depending on your individual tariff. You are responsible
                for any such costs. We are unable to provide any warranties as to the levels of connectivity you will receive via your personal media device. This may depend upon your tariff, your network provider or your corporate policy if you have
                a work-issued device. We will not accept any responsibility for any connectivity issues you may experience. Please contact your network provider or visit their website if you require assistance configuring a data connection for your phone.
                If, however the Edubex website is down and it is believed to be our fault we agree to compensate users how we see fit, this would usually comprise of a credit note being issued for unfinished assessments. Edubex will deal with claims on
                a case by case basis.

            </p>


            <h3> 8. CONTENT AND REFUNDS</h3>
            <p> Once payment is received and products have been delivered to the student’s dashboard, or have been downloaded, refunds are not available. This does not affect your statutory rights. Goods will only be dispatched to the customer when payment
                has been made in full and cleared funds and in any variance of this term shall be upon such terms as Edubex dictates. Access to modules and assessments is limited to 1(One) years , from the date of enrolment. In exceptional circumstances
                this may be extended. However short programs and webinars will not be eligible for this. Assessments can be re-sit as many times as required within the 1 Year period as long payments have been cleared. Failure to keep up with your agreed
                payments may incur a £50 admin fee and suspension of your online account. If students fail to keep up with payments, any free items received will need to returned to Edubex. All Student payments need to be completed before the final awarding
                body certificate is provided to the student.
            </p>

            <h3>9. DISCOUNT CODES</h3>
            <p> Account discount codes – Discount codes may from time to time be offered to account holders; such codes may only be applied to purchases made through the account in respect of which the discount code was offered and registered. Promotional
                discount codes – We may from time to time offer promotional discount codes which may apply in respect of any, or certain specified, purchases made though this Website. All Prices and course fees are listed in British Pound Sterling (GBP),
                United States Dollars (USD), Indian Rupees (INR), United Arab Emirates Dirhams (AED) and therefore we are not responsible for any exchange rate changes, nor any bank charges that arise from this or any other payments made to Edubex.
            </p>
            <h3> 10. DISCLAIMER</h3>
            <p>
                10.1 While we endeavour to ensure that the information on this Website is correct, we do not warrant the accuracy and completeness of the material on this Website. We may make changes to the material on this Website, or to the products and prices described
                in it, at any time without notice. The material on this Website may be out of date, and we make no commitment to update such material. The Content on our Website is provided for general information only and it is not intended to be advice
                on which you should rely. You must obtain professional or specialist advice before taking, or refraining from taking, any action on the basis of the content of our Website.

            </p>
            <p> 10.2 The material on this Website is provided “as is” without any conditions, warranties or other terms of any kind. Accordingly, to the maximum extent permitted by law, we provide you with this Website on the basis that we exclude all representations,
                warranties, conditions and other terms (including, without limitation, the conditions implied by law of satisfactory quality, fitness for purpose, and the use of reasonable care and skill) which but for these terms and conditions might
                have effect in relation to this Website. 11.3 We do not guarantee that our site will be secure or free from bugs or viruses. You are responsible for configuring your information technology, computer programmes and platform in order to
                access our Website. You should use your own virus protection software.</p>


            <h3>11. LIABILITY</h3>
            <p> 11.1 We, any other party (whether or not involved in creating, producing, maintaining or delivering this Website), and any of our group companies and the officers, directors, employees, shareholders or agents of any of them, exclude all liability
                and responsibility for any amount or kind of loss or damage that may result to you or a third party (including, without limitation, any </p>
            <p> 11.2. indirect, special, punitive or consequential loss or damages; or
            </p>
            <p> 11.3. whether direct or indirect, any (i) loss of income, (ii) loss of profits, (iii) loss of or damage to goodwill, (iv) loss of or damage to data, (v) loss of contracts, (vi) loss of use of money, or (vii) loss or damage arising from or
                connected in any way to business interruption; in each case whether in tort (including without limitation negligence), contract or otherwise) in connection with this Website in any way or in connection with the use, inability to use or
                the results of use of this Website, any websites linked to this Website or the material on such websites, including but not limited to loss or damage due to viruses that may infect your computer equipment, software, data or other property
                on account of your access to, use of, or browsing this Website or your downloading of any material from this Website or any websites linked to this Website.
            </p>
            <p> 11.4 Nothing in these terms and conditions shall exclude or limit our liability for (i) death or personal injury caused by negligence (as such term is defined by the Unfair Contract Terms Act 1977); (ii) fraud or fraudulent misrepresentation;
                (iii) misrepresentation as to a fundamental matter; or (iv) any liability which cannot be excluded or limited under applicable law. </p>
            <p>11.5 If your use of material on this Website results in the need for servicing, repair or correction of equipment, software or data, you assume all costs thereof.</p>
            <p> 11.6 You agree to indemnify us fully, defend and hold us, and our officers, directors, employees and agents, harmless from and against all claims, liability, damages, losses, costs (including reasonable legal fees) arising out of any breach
                of the terms and conditions by you, or your use of this Website, or the use by any other person using your registration details. If you are a consumer user, this means you will be responsible for any loss or damage we suffer as a result
                of your breach of any of these terms and conditions, you use of this Website, or the use by any other person of your registration details.</p>

            <h3>12. GOVERNING LAW AND JURISDICTION</h3>

            <p>12.1 If you are a consumer, please note that these terms and conditions, their subject matter and the formation of an agreement with you, are governed by and shall be construed in accordance with English law. If you are a consumer you and
                we both agree that the courts of England and Wales will have non-exclusive jurisdiction over any dispute arising out of or in connection with these terms and conditions. However if you are a resident in Northern Ireland you may also bring
                proceedings in Northern Ireland, and if you are a resident in Scotland, you may also bring proceedings in Scotland. If you are a business these terms and conditions, their subject matter and the formation of an agreement with you, are
                governed by and shall be construed in accordance with English law. If you are a business disputes arising out of or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the English courts.</p>
            <p> 12.2 We do not warrant that materials/items for sale on the Website are appropriate or available for use outside the United Kingdom. It is prohibited to access the Website from territories where its contents are illegal or unlawful. If you
                access this Website from locations outside the United Kingdom, you do so at your own risk and you are responsible for compliance with local laws.</p>
            <h3>13. MISCELLANEOUS</h3>
            <p> 13.1 You may not assign, sub-license or otherwise transfer any of your rights under these terms and conditions 15.2 If any provision of these terms and conditions is found by any court of competent jurisdiction to be invalid, the invalidity
                of that provision will not affect the validity of the remaining provisions which shall continue to have full force and effect.
            </p>
            <p> 13.2 Only the parties to these terms and conditions may seek to enforce them under the Contracts (Rights of Third Parties) Act 19.
            </p>
        </div>
    </div>
</div>