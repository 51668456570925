import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  private historySubject = new BehaviorSubject<Array<string>>([]);
  history$ = this.historySubject.asObservable();
  history: Array<any> = [];
  allHistory:Array<any>=[] ;

  constructor(private userService:UserService) {
  
    this.load();
  
  }
  load(){
    this.history=[];
    this.allHistory=[];
    this.userService.getTransactionHistory()
    .subscribe(result=>{
      this.allHistory = result;
      this.getNextItems();
      this.historySubject.next(this.history);
    })
  }

  loadMore(): void {
    if (this.getNextItems()) {
      this.historySubject.next(this.history);
    }
  }

  getNextItems(): boolean {
    if (this.history.length >= this.allHistory.length) {
      return false;
    }
    const remainingLength = Math.min(10, this.allHistory.length - this.history.length);
    this.history.push(...this.allHistory.slice(this.history.length, this.history.length + remainingLength));

    return true;
  }
}
