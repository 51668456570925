<div class="container-fluid caro-section" style="margin-bottom:30px">

    <div class="col-12">
        <h3 class="text-center learn" *ngIf="!slug">Featured Free Courses for you </h3>

        <h3 class="text-center learn" *ngIf="slug">Featured Free Courses for you - {{freecourses[0]?.category?.title}}</h3>
        <!-- <div class="underline"></div> -->
        <div class="text-center ">
            Access free online courses from leading institutions worldwide. Gain new skills and earn a certificate of completion. Join today.
        </div>
        <h5 class="text-center sub-topic">
            Topics Related to Featured Free Courses
        </h5>
        <div class="row mlist-contr m-0">
            <div *ngFor="let course of all">
                <span class="cat">
                    <button type="button" (click)="goTo(course.slug)" class="btn btn-outline-secondary">{{course?.title}}</button>
                </span>
            </div>
        </div>
        <div class="col-12 p-0">
            <div class="row free-course__container no-gutters" *ngFor="let course of freecourses | paginate: config">
                <div class="col-md-4 img-col">
                    <img [src]="course.image" alt="" class="">
                </div>
                <div class="col-md-8 con-col">
                    <h1 class="card-title font-demi">{{course?.title}}</h1>
                    <p [innerHTML]="course?.description"></p>
                    <div class="bottom-sec row no-gutters">
                        <div class="col-6">
                            <p>Duration</p>
                            <p>{{course?.duration}}</p>
                        </div>
                        <div class="col-6">
                            <a *ngIf="!course.is_applied" (click)="enterOtp(course.slug)" class="regster-btn font-demi float-right">Register</a>
                            <a *ngIf="course.is_applied"  routerLink="/freecourses/dashboard" class="regster-btn font-demi float-right">Registered</a>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row  m-20" *ngIf="freecourses.length>config.itemsPerPage">
            <div class="col-12">
                <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="config.currentPage = $event">
                    <div class="custom-pagination" style="display: flex; float: right">
                        <div *ngIf="!p.isFirstPage()" class="pagination-previous" [class.disabled]="p.isFirstPage()">
                            <a (click)="p.previous()"> <i class="fa fa-angle-left" aria-hidden="true" style="font-size: 25px;"></i>
                                </a>
                        </div>

                        <div *ngFor="let page of p.pages" class="pages" [class.current]="p.getCurrent() === page.value">
                            <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                <span>{{ page.label }}</span>
                            </a>
                            <div *ngIf="p.getCurrent() === page.value">
                                <span>{{ page.label }}</span>
                            </div>
                        </div>
                        <div *ngIf="!p.isLastPage()" class="pagination-next" [class.disabled]="p.isLastPage()">
                            <a (click)="p.next()"> <i class="fa fa-angle-right" aria-hidden="true" style="font-size: 25px;"></i> </a>
                        </div>
                    </div>
                </pagination-template>
            </div>

        </div>

    </div>

  
    <div class="col-12 m-20">
        <h3 class="text-center learn">Explore More Free Courses</h3>
        <div class="underline"></div>
        <owl-carousel-o [options]="termOptions" class="customOwl nmn-cn">
            <ng-template carouselSlide *ngFor="let course of all;  ">
                <div class="col-md-12 float-left">
                    <div class="card pgm-card">
                        <div class="card-image" style="position:relative">
                            <img src="{{course.image}}" class="card-img-top" alt="{{course.image_alt}}">
                        </div>
                        <div class="card-body pgm-body">
                            <h6 class="card-title pgm-tit font-demi"><span routerLink="/free-courses/{{course.slug}}"><b>{{course?.title}}</b></span></h6>
                            <div>
                                <p class="card-text terms-text">{{course?.description}}</p>
                            </div>
                            <div class="float-right">
                                <a class="view  float-right font-demi free-course-know" (click)="goTo(course.slug)">Know More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="row">
        <div class="side-box" (click)="goToRefer()" style="margin: auto;">
            <img [src]="gift?.image" style="cursor: pointer;">
        </div>
    </div>
 
</div>