import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {faMobile,faClock, faUsers,faCog,faLaptop,faLightbulb} from '@fortawesome/free-solid-svg-icons';
import { CmsService } from 'src/app/core/services/cms/cms.service';
// import{faPieChart,} from '@fortawesome/free-regular-svg-icons';
    
        
@Component({
  selector: 'app-become-instructor',
  templateUrl: './become-instructor.component.html',
  styleUrls: ['./become-instructor.component.scss'],
 // encapsulation: ViewEncapsulation.None,
})
export class BecomeInstructorComponent implements OnInit {
  faClock = faClock;
  faMobile = faMobile;
  faUsers = faUsers;
  faCog = faCog;
  faLaptop = faLaptop;
  faLightbulb =faLightbulb;

  page :String ='instructor';
  instructor: any;
  expect: any;
  subject: any;
  options: any;
  // encapsulation: ViewEncapsulation.None
  constructor(public sanitizer: DomSanitizer,private cmsService:CmsService) { }

  ngOnInit(): void {
    this.getSubjects();
    this.getWhatWeExpect();
    this.getTeachingOptions();
  }

  getWhatWeExpect(){
    this.cmsService.getPageInro('what-we-expect').subscribe(res=>{     
      this.expect= res['cms'];  
      console.log("expect",res)
		})
  }
  getSubjects(){
    this.cmsService.getPageInro('subjects-you-can-teach').subscribe(res=>{     
      this.subject= res['cms'];  
      console.log("teach",res)
		})
  }
  getTeachingOptions(){
    this.cmsService.getPageInro('flexible-teaching-options').subscribe(res=>{     
      this.options= res['cms'];  
      console.log("options",res)
		})
  }

}
