import { Component, Input, OnInit } from '@angular/core';
import { CmsService } from 'src/app/core/services/cms/cms.service';
declare var bootbox: any;

@Component({
  selector: 'app-signup-edubex',
  templateUrl: './signup-edubex.component.html',
  styleUrls: ['./signup-edubex.component.scss']
})
export class SignupEdubexComponent implements OnInit {
  @Input('page') page: string;
  model ={
    first_name:'',
    second_name:'',
    email :'',
    linkedin:'',
    info:'',
    // campus:'',
    discipline:'',
    message:''
  };
  campus: any;
  discipline: any;
  campus_err: string;
  discipline_err:string
    constructor(private cmsService:CmsService) { }
  
    ngOnInit(): void { 
    }
  
  onSubmit(form){
    console.log("select-->",this.campus,this.discipline,form.value);
    // if(!this.campus) this.campus_err="Select Campus" ;
    // if(!this.discipline) this.discipline_err="Select Discipline"
    if(form.valid ){
      let data = form.value;
      data.campus = this.campus;
      data.discipline= this.discipline
      this.cmsService.signupEdubex(data).subscribe(
        res => {
          console.log("res",res)  
          var dialog = bootbox.dialog({
            message: "Application Submitted!",
            closeButton: true
          });
          this.campus_err="";
          this.discipline_err="";
          form.resetForm(); 
          this.campus="";
          this.discipline='';           
        });
    }
  }

   onCampusChange(event): void { 
    this.campus = event.target.value;  
    this.campus_err="";  
  }

  onDisciplineChange(event): void { 
    this.discipline = event.target.value;
    this.discipline_err="";
  }

}
