import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { UserService } from 'src/app/core/services/user/user.service';
declare var bootbox;
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  @Input('show') show: boolean;
  signup: boolean = false;
  banner = "login"
  faTimesCircle = faTimesCircle;
  message ='';
  
  //showModal:boolean;
  constructor(private router: Router, private userService: UserService) { }
  showErrorMessage = "";
  ngOnInit(): void {
  
  }


  onSubmit(form:NgForm) {
    console.log("form",form.value)
    this.userService.changePassword({     
      old_password: form.value.current_password,
      password:form.value.new_password
    }).subscribe(
      res => {
        if(res.status){
          console.log("change",res)
          var dialog = bootbox.dialog({
            message: "Password changed Successfully!!",
            closeButton: true
          });
          this.show = false;
          this.router.navigateByUrl('/')
        }else{
          this.message=res.message;
        }
  
       // this.router.navigate(['/dashboard']);
      },
      err => {
        console.log("err", err)
        if (err.status === 400) {
          form.resetForm()
          this.showErrorMessage = "Incorrect Password";
        }

      }
    );
  }
  showForm() {
    this.show = true;

  }
  //Bootstrap Modal Close event
  hide(form) {
    this.show = false;
  form.resetForm();
    console.log("show", this.show);
    
  }


}
