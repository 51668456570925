import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HistoryService } from 'src/app/core/services/common/history.service';
import { MultiSelectService } from 'src/app/core/services/common/multiselect.service';
import { CourseService } from 'src/app/core/services/course/course.service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent implements OnInit {
 
  
  history: any=[];
  dues:any = [];
  bootcamp_history:any=[];
  webinar_history:any=[];
  freecourse_history:any=[];

  dues$: Observable<Array<string>>;
  history$: Observable<Array<string>>;

  constructor(public userService:UserService,private dataService: MultiSelectService,
     public courseService:CourseService,public router:Router,private historyService:HistoryService) { 
    dataService.load();
    this.dues$ = dataService.dues$;
    historyService.load();
    this.history$ =historyService.history$;
    console.log("details",this.dues$)

  }

  ngOnInit(): void {
    this.getTransactionHistory();
    this.getPaymentDues();
    this.userService.getBootcampHistory()
    .subscribe(result=>{
      this.bootcamp_history = result;
      console.log("result-bootcamp",result)
     
    })
    this.userService.getWebinarHistory()
    .subscribe(result=>{
      this.webinar_history = result;
      console.log("result-bootcamp",result)
     
    })
    this.userService.getFreeCourseHistory()
    .subscribe(result=>{
      this.freecourse_history = result;
      console.log("result-bootcamp",result)
     
    })
  }

  onScrollingFinished() {
    console.log('load more');
    this.dataService.loadMore();
  }
  onScrolling() {
    console.log('load more');
    this.historyService.loadMore();
  }

  getTransactionHistory(){
    this.userService.getTransactionHistory()
    .subscribe(result=>{
      console.log("history",result)
      this.history = result
    })
  }
    
  getPaymentDues(){
    this.userService.getPaymentDues()
    .subscribe(result=>{
      console.log("history",result)
      this.dues = result
    })
  }

  makePayment(due){
    this.courseService.makeOrder(due.id)
    .subscribe(result=>{
      console.log("result",result)
      this.router.navigate(['/course-payment/'+result.order_id]);    
    })
  }
}
