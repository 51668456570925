import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { UtilService } from './../util/util.service';
import { Observable } from 'rxjs';

@Injectable()
export class ApiService {

  private readonly url: string = environment.apiBaseUrl;

  constructor(private http: HttpClient, public utils: UtilService) { }

  // create get method
  get(endpoint: string, params?: any, reqOpts?: any): any {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }
    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (params.hasOwnProperty(k)) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }
    return this.http.get(this.url + '/' + endpoint, reqOpts);
  }

  post(endpoint: string, body: any, reqOpts?: any): any {
    // Using the POST method
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    body = this.utils.cleanParams(body);
    return this.http.post<any>(this.url + '/' + endpoint, body, { responseType: 'json', headers, withCredentials: false });
  }

  put(endpoint: string, body: any, reqOpts?: any): any {
    return this.http.put(this.url + '/' + endpoint, body, reqOpts);
  }

  delete(endpoint: string, reqOpts?: any): any {
    return this.http.delete(this.url + '/' + endpoint, reqOpts);
  }

  patch(endpoint: string, body: any, reqOpts?: any): any {
    return this.http.patch(this.url + '/' + endpoint, body, reqOpts);
  }

  // postFile(endpoint: string, fileToUpload: File): any {
  //   // Using the POST method
  //   const formData: FormData = new FormData();
  //   formData.append('file', fileToUpload, fileToUpload.name);
  //   const headers = new HttpHeaders();
  //   return this.http.post<any>(this.url + '/' + endpoint, formData, { responseType: 'json', headers, withCredentials: false });
  // }
 

  postApply(endpoint: string,body:any): any {
    // Using the POST method
    console.log("data",body)
    const formData: FormData = new FormData();
    if(body.resume){
      formData.append('resume', body.resume, body.resume?.name);
    }
    if(body.cover_letter){
      formData.append('cover_letter', body.cover_letter, body.cover_letter?.name);
    }

    formData.append('first_name',body.first_name);
    formData.append("last_name",body.last_name);
    formData.append("phone",body.phone);
    formData.append("email",body.email);   
    formData.append("linkedin",body.linkedin);
    formData.append("location",body.location);
    formData.append("website",body.website);
    
    
    const headers = new HttpHeaders();
    return this.http.post<any>(this.url + '/' + endpoint, formData, { responseType: 'json', headers, withCredentials: false });
  }
  postProfile(endpoint: string,body:any, fileToUpload?: File): any {
    // Using the POST method
    console.log("data",body,fileToUpload)
    const formData: FormData = new FormData();
    if(fileToUpload){
      formData.append('image', fileToUpload, fileToUpload?.name);
    }
    formData.append('first_name',body.first_name);
    formData.append("last_name",body.last_name);
    formData.append("phone",body.phone);
    formData.append("email",body.email);
    formData.append("address",body.address);
    formData.append("dob",body.dob);
    formData.append("gender",body.gender);
    formData.append("city",body.city);
    formData.append("state",body.state);
    formData.append("referred_by",body.referred_by);
    formData.append("hear_about",body.hear_about);
    formData.append("month_graduation",body.month_graduation);
    formData.append("country",body.country);
    formData.append("institute",body.institute);
    formData.append("qualification",body.qualification);
    formData.append("degree_mark",body.degree_mark);
    formData.append("year_graduation",body.year_graduation);
    formData.append("work_experince_time",body.work_experince_time);
    
    
    const headers = new HttpHeaders();
    return this.http.post<any>(this.url + '/' + endpoint, formData, { responseType: 'json', headers, withCredentials: false });
  }

  postFile(endpoint: string,body:any): any {
    // Using the POST method
    console.log("data",body)
    const formData: FormData = new FormData();
    formData.append("course_id",body.course_id);
    formData.append("user_id",body.user_id);
    if(body.referral_code)
    formData.append("referral_code",body.referral_code);
    
    body.fields.forEach((item) =>{
      console.log("item,,,",item)
      formData.append("fields["+item.field+"]", item.value);
      // formData.append("fields[value]", item.value);
   
    });
    for (var i = 0; i < body.documents.length; i++) { 
      if(body.documents[i])
      formData.append(body.documents[i].slug, body.documents[i].file, body.documents[i].slug );
   }
   console.log("formData",formData);
    
    const headers = new HttpHeaders();
    return this.http.post<any>(this.url + '/' + endpoint, formData, { responseType: 'json', headers, withCredentials: false });
  }
}
