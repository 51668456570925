import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/core/services/user/user.service';
import { environment } from 'src/environments/environment';
import { RegisterComponent } from '../register/register.component';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { CourseApplyComponent } from '../course-apply/course-apply.component';
declare var bootbox:any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('modal') private modalContent: TemplateRef<LoginComponent>
  @Input() fromParent;

  private modalRef: NgbModalRef
  loading:any =false;
  signup: boolean = false;
  showModal1=false;
  banner = "login"
  faTimesCircle = faTimesCircle;
  model = {
    email: '',
    password: ''
  };
  showErrorMessage: string='';
  user: any;

  constructor(
    public activeModal: NgbActiveModal,
    public userService:UserService,
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    console.log("parent",this.fromParent);

    /* Output:
     {prop1: "Some Data", prop2: "From Parent Component", prop3: "This Can be anything"}
    */
  }
  openModal() {
    this.closeModal('dismiss')
    const modalRef = this.modalService.open(RegisterComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
      });

    let data = {
      prop1: 'Some Data',
      prop2: 'From Parent Component',
      prop3: 'This Can be anything'
    }
    if(this.fromParent.prop1==='course' ||this.fromParent.prop1==='program-details'|| this.fromParent.prop1==='program' || this.fromParent.prop1==='home'|| this.fromParent.prop1==='bootcamp'||this.fromParent.prop1==='bootcamp-details'){
      data.prop1=this.fromParent.prop1;
      data.prop2 = this.fromParent.prop2;
    }
  console.log("prop---->",data);
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  }
  
  onSubmit(form:NgForm,sendData) {
    console.log(form.value,this.fromParent)
    let user_auth={
      grant_type: 'password',
      client_id: environment.appClientId,
      client_secret: environment.appClientSecret,
      username: form.value.email,
      password: form.value.password,
      scope: '*'
    }
    if(this.fromParent.prop1==='course' ){
      user_auth['order_id']=this.fromParent.prop2;
    }
    if(this.fromParent.prop1==='bootcamp-payment' ){
      user_auth['bootcamp_order_id']=this.fromParent.prop2;
    }
    if(this.fromParent.prop1==='webinar-payment' ){
      user_auth['webinar_order_id']=this.fromParent.prop2;
    }
    if(this.fromParent.prop1==='freecourse-payment' ){
      user_auth['freecourse_order_id']=this.fromParent.prop2;
    }
    this.loading = true;
    this.userService.login(user_auth).subscribe(
      res => {
        this.showErrorMessage = "";
        console.log("res", res)
        this.loading = false;
        if(res.status){         
          if(res.user.status){
            if(res.user.is_otp_verified===1){
              this.userService.setToken(res.token); 
              this.user = res.user_id;       
              this.activeModal.close(sendData);
              if(this.fromParent.prop1==='course' ){  
                window.location.href = '/course-payment/'+this.fromParent.prop2;
              }
              else if(this.fromParent.prop1==='bootcamp-payment' ){
                window.location.href = '/bootcamp-payment/'+this.fromParent.prop2;

              }
              else if(this.fromParent.prop1==='webinar-payment' ){
                window.location.href = '/webinar-payment/'+this.fromParent.prop2;

              }
              else if(this.fromParent.prop1==='freecourse-payment' ){
                window.location.href = '/freecourse-payment/'+this.fromParent.prop2;

              }
              else if(this.fromParent.prop1==='program-details'){
                window.location.href = '/program-details/'+this.fromParent.prop2;
              }
              else if(this.fromParent.prop1==='program' ){  
                window.location.href = '/program-details/'+this.fromParent.prop2;
              }
              else if(this.fromParent.prop1==='home'){
                window.location.href = '/program-details/'+this.fromParent.prop2;
              }  
              else if(this.fromParent.prop1==='bootcamp'){
                window.location.href = '/bootcamps';
              }    
              else if(this.fromParent.prop1==='webinar'){
                window.location.href = '/webinars';
              }   
              else if(this.fromParent.prop1==='bootcamp-details'){
                window.location.href = '/bootcamp-details/'+this.fromParent.prop2;;
              }   
              else if(this.fromParent.prop1==='webinar-details'){
                window.location.href = '/webinar-details/'+this.fromParent.prop2;;
              } 
              else if(this.fromParent.prop1==='refer-earn'){
                window.location.href =  '/refer&earn';
              }
              else{     
                if(res.$latest_bookmark_course)          
                this.router.navigate(['/scromcontent/'+res.$latest_bookmark_course]); 
                else this.router.navigate(['/free-courses'])   
              }
            }else{
              this.user = res.user_id;
              // this.showErrorMessage = "Invalid Username/Password";
              this.enterOtp(form,"")
            }   
            }else{
              var dialog = bootbox.dialog({
                    className: 'block',
                message: "User Blocked! Contact admin",
            
                closeButton: true
              }) 
            } 
        }else{
          this.showErrorMessage = res.message;
        }
         
      },
      err => {
        console.log("err", err)
        if (err.status === 400) {
          form.resetForm()
          this.showErrorMessage = "Invalid Username/Password";
        }
      }
    );
  }
 
  enterOtp(form,err){
    let that = this;
    console.log("user",this.user)
    bootbox.prompt({
      title: "Enter Verification Code", 
      message:err?err:'Please check your email for the verification code!',
      inputType: 'number',  
      centerVertical: true,
      callback: function(result){ 
          console.log(result); 
          if(result){
            that.userService.verifyOtp({
              otp:result,
              user_id:that.user
            }).subscribe(
              res => {
                console.log("res--->",res)
                 if(res.status==1){  
                   that.onSubmit(form,'close')              
                  var dialog = bootbox.dialog({
                    message: "Success!!",
                    closeButton: true
                  });               
                }else if(res.status==2){
                  var dialog = bootbox.dialog({
                    className: 'block',
                    message: "You have exceeded the limit of verification code tries. Please contact Admin !!",
                    closeButton: true
                  });     
                }else
                     that.enterOtp(form,"<span style='color: red;'>Verification failed. Try again!</span>");
  
              },
              err => {
                console.log("err", err)
              });
          }else{
            bootbox.hideAll();
            
            if(result!==null)
            that.enterOtp(form,"<span style='color: red;'>Enter valid verification code. Try again!</span>");
            return false;
          }
    
      }
  });
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
    
  }
}
