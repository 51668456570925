<div class="container-delivery mnc-bottom webnar-detail-contr">
        <div class="banner row">
            <div class="col-12">
                <h2>Upcoming Webinar</h2>
                <div class="heading">{{webinar?.title}}</div> 
                
                <span>
                    <a *ngIf="webinar.is_applied"  class="btn join">Applied</a>
                    <a *ngIf="!webinar.is_applied" (click)="enterOtp(slug,'')" class="btn join">Apply Now</a></span>
                <p *ngIf="webinar?.date">{{webinar?.date|date}},{{webinar?.start_time}},{{webinar?.time_zone}}</p>
            </div>
            <div class="col-4">
               
            </div>
            
        </div>
        <div class="addm-col">
            <p [innerHTML]="webinar?.description"></p>
            
        </div>
     
        
            <h4 style="font-weight: 800 !important; font-size: 28px; color: #000;">Agenda for the sessions</h4>
            <div [innerHTML]="webinar?.agenda">
             
            </div>
            <div *ngIf="webinar?.speakers.length">
                <h3 class="text-center learn" *ngIf="webinar.speakers.length===1">About Speaker</h3>
                <h3 class="text-center learn" *ngIf="webinar.speakers.length>1">About Speakers  </h3>
            </div>
        

            <div class="underline"></div>
            <owl-carousel-o [options]="speakerOptions" class="customOwl speaker-slider">
                <ng-template carouselSlide *ngFor="let speaker of webinar?.speakers">



                    <div class="row">
                        <div class="col-md-4 space-adjust">
                            <div class="card">
                                <div class="row detail no-gutters" style="height: 100%;">
                                    <div class="col-sm-4 d-none" >
                                        
                                        <!-- <div class="col-exp-img exp-img" style="background: url('');">
                                        
                                        </div> -->
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="detail">
                                            <div class="img-speak">
                                                <img [src]="speaker?.image" alt="">
                                            </div>
                                            <div class="speaker-content">
                                                <h2>{{speaker?.name}}</h2>
                                                <p>{{speaker?.designation.name}}</p>
                                                <p>{{speaker?.university.name}}</p>
                                                <a target="_blank" href="{{speaker?.linkedin_link}}">
                                                    <img  src="../../../../assets/images/linkedin.png" alt="" class="linkedin-icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="speaker-contr">
                                <p [innerHTML]="speaker?.description"></p>
                            </div>
                        </div>
                    </div>
                    </ng-template>
                    </owl-carousel-o>
           
            <h3 class="text-center learn">You May Also Like</h3>
            <div class="underline"></div>
            <owl-carousel-o [options]="termOptions" class="customOwl likeslider">
                <ng-template carouselSlide *ngFor="let course of others">
                    <div class="col-md-12 float-left ex-card" style="cursor:pointer">
                        <div class="card pgm-card" routerLink="/webinar-details/{{course.slug}}">
                            <div class="card-image" style="position:relative" (click)="goToThis(course.slug)">
                                <img [src]="course.image" class="card-img-top" alt="">
                            </div>
                            <div class="card-body pgm-body">
                                <h6 class="card-title pgm-tit font-demi"><b>{{course?.title}}</b></h6>
                                <div>
                                    <p class="card-text" >{{course.date| date:'MMMM dd, yyy'}}</p>
                                </div>
      
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
</div>

