<div class="row " *ngIf="showModal1">   
    <div class="col-md-3 main">
    </div>
  </div>
  <div [style.display]="showModal1 ? 'block' : 'none'" class="modal apply-mod" id="imagemodal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style="padding:0px">
      <div class="modal-body" style="padding:0px!important">
        <div class="close" (click)="hide()" style="top: 4px;z-index: 999;right: 0px;">
          <fa-icon style=" margin:5px;cursor:pointer;color: #000" [icon]="faTimesCircle"></fa-icon>
        </div>
        <div class="row">
          <div class="col-sm-12" style="padding:0px"> 
            <div class="card">
              <div class="card-body">
                <div class="col-md-12 box">  
                    <form class="form" #courseForm="ngForm"
                    (ngSubmit)="courseForm.form.valid && onSubmit(courseForm)" novalidate>
                    <div class="row" style="margin:0px -15px">
                        <div class="col-md-6 ">
                            <input type="text" class=" text" [(ngModel)]="model.first_name" name="first_name"
                                #first_name="ngModel" placeholder="First Name" required pattern="[a-zA-Z\s]*$" (change) ="model.first_name = model.first_name.trim()">
                            <span class="text-danger"
                                *ngIf="(first_name.touched || courseForm.submitted) && first_name.errors?.required">
                                First Name is required
                            </span>
                            <span class="text-danger"
                            *ngIf="(first_name.touched || courseForm.submitted) && first_name.errors?.pattern">
                            Only Alphabets are allowed
                        </span>
                        </div>
                        <div class=" col-md-6 ">
                            <input type="text" class=" text" [(ngModel)]="model.second_name" 
                                name="second_name" #second_name="ngModel" placeholder="Second Name" pattern="[a-zA-Z\s]*$"
                                required  (change) ="model.second_name = model.second_name.trim()">
                            <span class="text-danger"
                                *ngIf="(second_name.touched || courseForm.submitted) && second_name.errors?.required">
                                Second Name is required
                            </span>
                            <span class="text-danger"
                            *ngIf="(second_name.touched || courseForm.submitted) && second_name.errors?.pattern">
                            Only Alphabets are allowed
                        </span>
                        </div>

                        <div class=" col-md-12 ">
                            <input type="text" class=" text" [(ngModel)]="model.email" name="email"
                                #email="ngModel" placeholder="Email" required email  (change) ="model.email = model.email.trim()">
                            <span class="text-danger"
                                *ngIf="(email.touched || courseForm.submitted) && email.errors?.required">
                                Email is required
                            </span>
                            <span class="text-danger" *ngIf="email.touched && email.errors?.email">
                                Enter a valid email address
                            </span>
                        </div>
                        <div class=" col-md-12 bannerform" style="margin-top: 10px;
                        ">
                            
                            <!-- <input type="text" class=" text" [(ngModel)]="model.phone" name="phone"
                                #phone="ngModel" placeholder="+91" required phone pattern="[0-9]+"> -->
                            <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
[enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
[selectedCountryISO]="CountryISO.India" [maxLength]="15"
[phoneValidation]="true" [separateDialCode]="separateDialCode"
[numberFormat]="PhoneNumberFormat.National" name="phone" #phone="ngModel" required   [(ngModel)]="model.phone">
</ngx-intl-tel-input>
                                <span class="text-danger"
                                *ngIf="(phone.touched || courseForm.submitted) && phone.errors?.required">
                                Phone is required
                            </span>
                            <!-- <span class="text-danger"
                                *ngIf="(phone.touched || courseForm.submitted) && phone.errors?.pattern">
                                Only numbers are allowed
                            </span> -->
                            <span class="text-danger"
                            *ngIf="(phone.touched || courseForm.submitted) &&!phone.errors?.required &&  phone.errors?.validatePhoneNumber?.valid===false">
                            Invalid Phone number
                        </span>
                        </div>
                    </div>
                    <div>
                        <b><p class="terms" style="font-size: 9px; color:#898f87;margin-left:0px">By Clicking "View Course Package",
                                you accept our terms
                        </p></b>
                        <div>
                            <input type="submit" class="btn download" id="download" value="View Course Package">
                              <!-- <input type="button" class="btn apply" *ngIf="userService.isLoggedIn() && !course?.is_applied && (course?.documents.length||course?.formfields.length)" (click)="apply.show(course.documents,course.formfields,course?.encrypted_id)" value="Apply Now">
                <input type="button" class="btn apply" *ngIf="userService.isLoggedIn() && !course?.is_applied && !course?.formfields.length  &&  !course?.documents.length " (click)="goToCheckout(course)" value="Apply Now">
                <input type="button" class="btn apply" disabled *ngIf="userService.isLoggedIn() && course?.is_applied" value="Applied">
                <input type="button" class="btn apply" *ngIf="!userService.isLoggedIn()" (click)="openModal()" value="Apply Now"> -->

                        </div>
                    </div>
                </form>
                </div> 
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  