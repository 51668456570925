import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { CourseService } from 'src/app/core/services/course/course.service';
import { UserService } from 'src/app/core/services/user/user.service';
@Component({
  selector: 'app-instructor',
  templateUrl: './instructor.component.html',
  styleUrls: ['./instructor.component.scss']
})
export class InstructorComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  @Input('course') course: any;

  err: string='';
  course_id: any;
  user_id: any;
  advance:any;
  instructor:any;
  unsubcribe: any;

  separateDialCode = false;

 
  constructor(private courseService:CourseService,private userService:UserService) { 

  }


  ngDistroy() {
    this.unsubcribe();
  }

  list = [];
 
  faTimesCircle=faTimesCircle;
  showModal1:boolean
  ngOnInit(): void {  

  }



  
  
  

  show(instructor){
    console.log("data",instructor);
    this.instructor= instructor;
    this.list = [];
    this.showModal1 =true;
    console.log("course",this.course)
  }
  hide(){
    this.showModal1 = false;
    this.err='';
    this.list=[];
  }
}



