<div class="container-fluid" style="padding:0px">
    <app-banner [page]="page" class="career-page"></app-banner>
    <div class="container-fluid">
        <div class="container-delivery">
            <div class="row">
                <div class="col-md-12">
                    <app-page-details [page]='page' (message)="messageHandler($event)"></app-page-details>             
                </div>
            </div>
            <div class="row" style="margin-top: 30px;">
                <div class="col-md-5">
                    <img style=" width:100%" src="{{whywork?.image}}" alt="{{whywork?.image_alt}}">
                </div>
                <div class="col-md-7 cols-padcar">
                    <h4 class="text-left">{{whywork?.title}}</h4>
                    <div class="text-left underline" style="margin-left:0px!important"></div>
                    <div class="" [innerHTML]="whywork?.description">
                    </div>
                </div>
            </div>
            <div class="row carerow" style="margin-top:60px;  position: relative;">
                <div class="col-md-12 ">
                    <div class="col-md-12 benefits"  [ngStyle]="{ 'background-image': 'url(' + perks?.image + ')'}">
                        <!-- <h1 class="perks">{{perks?.title}}</h1> -->
                        <h4 class="text-left">{{perks?.title}}</h4>

                        <div class="underline" style="margin:25px 0px 20px!important"></div>
                        <div class="details">
                          <div [innerHTML]="perks?.description"></div> 
                        </div>                        
                        <div class="row" style="margin:0px">
                            <div class="col-md-6"></div>
                            <div class="col-md-6">
                                <div class="box1">
                                    <p style="color:#1c6543;line-height:1.1">It's not how much you do, what matters is how different you do things to provide an
                                        effective learning experience to the students. At Edubex we encourage creativity, teamwork
                                        and willingness to learn- the cornerstones of success.
                                    </p>
                                </div> 
                            </div> 
                        </div>
                    </div>
                </div>

            </div>
            <div class="container-fluid contain-career">
            <div class="row ">
                <div class="col-md-12">
                    <h3 class="text-center " >Career Opportunities</h3>
                    <div class="underline" style="margin: 0 auto;"></div>
                </div>              
            </div>
            <div class="row" id="career">
                <div class="col-md-12">
                    <ngb-tabset>
                        <ngb-tab >
                            <ng-template ngbTabTitle><span class="tab-title">All</span>
                            </ng-template>
                            <ng-template ngbTabContent>
                                <div class="box" >
                                    <div *ngFor="let item of careerlist | keyvalue">
                                        <!-- <div class="row" style="margin:0px">
                                            <h5 class="tabhead"><b>{{item.key}}</b></h5>
                                        </div>   -->
                                        <div  *ngFor="let list of item.value" class="all-list">
                                            <div class="row" >
                                                <div class="col-6 col-md-8 terms" (click)="goCareerDetails(list.id)" >
                                                    <p >{{list.title}}
                                                    </p>
                                                </div>
                                                <div class="col-6 col-md-4 float-left terms"  (click)="goCareerDetails(list.id)">
                                                    {{list.location}}
                                                </div>
                                            </div>
                                        </div> 
                                    </div>                                                                            
                                </div>                              
                            </ng-template>                             
                        </ngb-tab>
                        <ngb-tab *ngFor="let items of careers | keyvalue" >
                            <ng-template ngbTabTitle><span class="tab-title">{{items.key}}</span>
                            </ng-template>    
                            <ng-template ngbTabContent>                                
                                <div class="box">
                                    <div  *ngFor="let item of items.value| keyvalue">
                                        <div class="row" style="margin:0px">
                                            <h5 class="tabhead"><b>{{item.key}}</b></h5>
                                        </div>   
                                        <div  *ngFor="let list of item.value">
                                            <div class="row">
                                                <div class="col-md-9 terms"  (click)="goCareerDetails(list.id)">
                                                    <p >{{list.title}}
                                                    </p>
                                                </div>
                                                <div class="col-md-2 float-left terms"  (click)="goCareerDetails(list.id)">
                                                    {{list.location}}
                                                </div>
                                            </div>
                                        </div> 
                                    </div>                                    
                                </div>                              
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>     
            </div>
        </div>
        </div>
    </div>
</div>
<div class="container-delivery">
<div class="col-fixed">
    <div class="bottom" >
        <app-bottom-bar></app-bottom-bar>

    </div>
</div>
</div>

