<!-- <div class="container-fluid ">
    <div class="container "> -->
        <div class="row banner">
            <div class="col-md-12">
                <div class="card-image">
                     <app-banner [page]="'scheduled-courses'"></app-banner>
                </div>
            </div>
        </div>
        <!-- <div class="banner-item">
            <div  class="picsum-img-wrapper" style="background-color: #095e37; height :150px;color:#fff;padding:20px">
                <img src="../../../../assets/images/banner/blog.jpg" alt=""> 
              <h3 >Scheduled Programs</h3>
                
            </div>
        </div> -->
        <!-- <div class="row" >-->
            <div class="container-delivery" > 
                <div class="d-flex justify-content-center div-cls">                  
                    <app-course-apply (childEvent)="reload($event)" #apply></app-course-apply>

                    <!-- <select  class="text form-control form-sizes"  (change)=selectCat($event.target.value,cat) style="font-size:17px!important">
                        <option selected value="">All Categories</option>
                        <option  *ngFor="let cat of allCategories" [value]="cat.id" >{{cat.title}}</option>
                    </select>   -->
                    <!-- <select  class="text form-control"  (change)=selectCountry($event,country)>
                        <option selected value="">All Countries</option>
                        <option  *ngFor="let country of allCountries" [value]="country.id" >{{country.short_name}}</option>
                    </select>  -->
                    <!-- <div class="texts form-sizes"  style="font-size:inherit!important">
                        Live Online
                    </div>  -->
                    <!-- <div class="texts"  *ngIf="live_online===1"  style="font-size:inherit" (click)="setLive()">
                        Live Online
                    </div> -->
                </div>
            </div>
            <div class="container-delivery position-relative title-section">
                <ul ngbNav #nav="ngbNav" class="nav-tabs justify-content-center">
                    <li ngbNavItem="1">
                        <a ngbNavLink (click)="selectCat('','')">
                          <span class="icons"> <i class="fa fa-bars mr-2" aria-hidden="true"></i></span>
    
                          <span class="tab-title">All</span>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="jumbotron" style="margin:0px;">
                                <div class="container-fluid ">
                                    <div class="container-delivery">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="head">
                                                    <h4 class="text-left">All</h4>
                                                    <div class="underline"></div>
                                                </div>
                                                <div  *ngFor="let list of courses ">
                                                    <h3>{{getMonthName(list.month)}} {{list.year}}</h3>
                                                    <div class="row none767" >
                                                         <div  *ngFor="let course of list.dates.dates" class="col-lg-4 col-md-6 float-left" >
                                                            <div class="flip-card">
                                                                <div class="flip-card-inner">
                                                                  <div class="flip-card-front">
                                                                    <div class="border-light schd-col">  
                                                                        <div class="date-shcd">
                                                                            <div>{{course.start_date|date:'MMM dd yyyy'}} -{{ course.end_date| date:'MMM dd yyyy' }}</div>
                
                                                                            <!-- <h4>{{course.start_date|date:'dd'}} -{{ course.end_date| date:'dd' }}</h4>
                                                                            <p *ngIf="chckMonth(course.start_date,course.end_date)">{{course.start_date|date:'MMM'}}</p>
                                                                            <p *ngIf="!chckMonth(course.start_date,course.end_date)">{{course.start_date|date:'MMM'}}-{{ course.end_date| date:'MMM' }}</p> -->
                                                                        </div>
                                                                        
                                                                           
                                                                            <div *ngIf="course.is_offer"  class="box float-left" style="padding: 10px;background: #10CFBD; color:#fff;margin-right:10px">
                                                                              
                                                                            </div>
                                                                      
                                                                            <div *ngIf="course.soldout_placelimited=='sold_out'"  class="box float-left" style="padding: 10px;background:#F55753;color:#fff">
                                                                               
                                                                            </div>
                                                                          
                                                                            <div *ngIf="course.soldout_placelimited=='places_limitted'"  class="box float-left" style="padding: 10px;background: #F8D053;color:#fff">
                                                                            
                                                                            </div>
                    
                    
                                                                      <div class="overview">
                                                                        <h4  routerLink="/program-details/{{course.master_slug}}">{{course.title}}</h4>
                                                                        <div [style.display]="course.is_online==='1' ? 'block' : 'none'"><span><i class="fa fa-map-marker" aria-hidden="true"></i></span ><span> Live Online</span></div>
                                                                        <div [style.display]="course.is_online==='2' ? 'block' : 'none'"><span><i class="fa fa-map-marker" aria-hidden="true"></i></span ><span> Online</span></div>
                  
                                                                    </div>
                                                                    <div class="price-schd">
                                                                       <b></b>{{course.currency}}
                                                                        <b >{{course.price}}</b>   
                                                                    </div>                                                   
                                                                </div>
                                                                  </div>
                                                                  <div class="flip-card-back">
                                                                    <div class="border-light schd-hvr-col" style="padding:15px;color:#fff;">                                                            
                                                                        <div class="schd-hvr">
                                                                        <div>{{course.start_date|date:'MMM dd yyyy'}} -{{ course.end_date| date:'MMM dd yyyy' }}</div>
                                                                        <h5 style="color:rgb(255 255 255 / 82%)">{{course.duration}}</h5>   
                                                                                <p class="overview">
                                                                                {{course.overview}}
                                                                                </p> 
                                                                            <div class="schd-apply">
                                                                                <a class="btn start  float-left" routerLink="/program-details/{{course.master_slug}}" >View More</a>
                                                                                <a class="btn start float-right" *ngIf="!userService.isLoggedIn()" (click)="openModal(course.master_slug)" >Apply Now</a>
                                                                                <a class="btn start float-right" *ngIf="userService.isLoggedIn() && !course.is_applied && (course.documents.length||course.formfields.length)" (click)="apply.show(course.documents,course.formfields,course.id)" >Apply Now</a>
                                                                                <a class="btn start float-right" *ngIf="userService.isLoggedIn() && !course.is_applied &&  !course.documents.length && !course.formfields.length" (click)="goToCheckout(course)" >Apply Now</a>
                                                                                <a class="btn start float-right" *ngIf="userService.isLoggedIn() && course.is_applied">Applied</a>                                                            
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>                                        
                                                        </div> 
                                                    </div>                                    
                                                        <div class="accordion dis767"  id="accordionExample" style="display:none;">
                                                            <div class="cards" *ngFor="let course of list.dates.dates">
                                                                <div class="card-header cardheader" id="{{course.id}}" style="padding: 15px 15px 0px;">
                                                                    <div class="row schedule collapsed top-schd"  data-toggle="collapse" [attr.data-target]="'#news' + course.id"
                                                                    aria-expanded="false" aria-controls="{{course.id}}">
                                                                    <div class="col-2 mob-schd-date" >
                                                                        <div><span style="text-align: center;font-size:30px;line-height: 28px;"> {{course.start_date|date:'dd'}}</span><br> {{course.start_date|date:'MMM'}}</div>                                                       
                                                                    </div>
                                                                        <div class="col-9 float-left pad-scd schd-col" >
                                                                            <h4  routerLink="/program-details/{{course.master_slug}}" style="margin:0px 0px 5px">{{course.title}}</h4>
                                                                            <div [style.display]="course.is_online==='1' ? 'block' : 'none'"><span><i class="fa fa-map-marker" aria-hidden="true"></i></span> <span > Live Online</span></div>
                                                                            <div [style.display]="course.is_online==='2' ? 'block' : 'none'"><span><i class="fa fa-map-marker" aria-hidden="true"></i></span> <span > Online</span></div>
                                                                        </div>
                
                                                                        <div class="col-1 " style="cursor: pointer;text-align:right;padding:0px">
                                                                        <span class=" float-right plus1" data-toggle="collapse" [attr.data-target]="'#new' + course.id"
                                                                        aria-expanded="false" aria-controls="{{course.id}}" style="font-size:16px; position: absolute;
                                                                        bottom: 0;right:15px;">
                                                                        <i class="fa fa-chevron-down"></i>
                                                                        <i class="fa fa-chevron-up" style="display:none"></i>
                                                                    </span>
                                                                  
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div [id]="'news'+course.id" class="panel-collapse  collapse " aria-labelledby="{{course.id}}"  style="width: 100%;">
                                                                    <div class="card-body">
                                                                        <div class="schd-hvr">
                                                                            <div>{{course.start_date|date:'MMM dd yyyy'}} -{{ course.end_date| date:'MMM dd yyyy' }}</div>
                                                                          <h5 style="color:rgb(255 255 255 / 82%)">{{course.duration}}</h5>   
                                                                          <div [style.display]="course.is_online==='1' ? 'block' : 'none'"><span >Live Online</span></div>
                
                                                                          <div style="margin-bottom: 10px;">
                                                                            <b></b>{{course.currency}}
                                                                             <b >{{course.price}}</b>   
                                                                         </div>    
                                                                                <a class="btn views " style="background-color: #ff8533;" routerLink="/program-details/{{course.master_slug}}" >View More</a>
                                                                                <a class="btn views " *ngIf="!userService.isLoggedIn()" (click)="openModal(course.master_slug)" >Apply Now</a>
                                                                                <a class="btn views" *ngIf="userService.isLoggedIn() && !course.is_applied && (course.documents.length||course.formfields.length)" (click)="apply.show(course.documents,course.formfields,course.id)" >Apply Now</a>
                                                                                <a class="btn views" *ngIf="userService.isLoggedIn() && !course.is_applied &&  !course.documents.length && !course.formfields.length" (click)="goToCheckout(course)" >Apply Now</a>
                                                                                <a class="btn views" *ngIf="userService.isLoggedIn() && course.is_applied">Applied</a>                                                            
                                                                               
                                                                           </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>                                        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                      </ng-template>
                      </li>
                    <li [ngbNavItem]="cat.slug"  *ngFor="let cat of imageArrayToDisplay  ">
                        <a ngbNavLink (click)="selectCat(cat.id,cat)">
                            <!-- <span class="icons" [innerHTML]="sanitizer.bypassSecurityTrustHtml(course?.value.icon)"></span> -->
                            <span class="tab-title">{{cat.title}}</span>
                        </a>
                      <ng-template ngbNavContent>
                            <div class="jumbotron" style="margin:0px;">
                                <div class="container-fluid ">
                                    <div class="container-delivery">
                                        <div class="row">
                                            <div class="col-md-12">
                                                
                                                <div class="head">
    
                                                    <h4 class="text-left">{{cat.title}}</h4>
                                                    <div class="underline"></div>
                                                </div>
                                                <div  *ngFor="let list of courses ">
                                                    <h3>{{getMonthName(list.month)}} {{list.year}}</h3>
                                                    <div class="row none767" >
                                                         <div  *ngFor="let course of list.dates.dates" class="col-lg-4 col-md-6 float-left" >
                                                            <div class="flip-card">
                                                                <div class="flip-card-inner">
                                                                  <div class="flip-card-front">
                                                                    <div class="border-light schd-col">  
                                                                        <div class="date-shcd">
                                                                            <div>{{course.start_date|date:'MMM dd yyyy'}} -{{ course.end_date| date:'MMM dd yyyy' }}</div>
                
                                                                            <!-- <h4>{{course.start_date|date:'dd'}} -{{ course.end_date| date:'dd' }}</h4>
                                                                            <p *ngIf="chckMonth(course.start_date,course.end_date)">{{course.start_date|date:'MMM'}}</p>
                                                                            <p *ngIf="!chckMonth(course.start_date,course.end_date)">{{course.start_date|date:'MMM'}}-{{ course.end_date| date:'MMM' }}</p> -->
                                                                        </div>
                                                                        
                                                                           
                                                                            <div *ngIf="course.is_offer"  class="box float-left" style="padding: 10px;background: #10CFBD; color:#fff;margin-right:10px">
                                                                              
                                                                            </div>
                                                                      
                                                                            <div *ngIf="course.soldout_placelimited=='sold_out'"  class="box float-left" style="padding: 10px;background:#F55753;color:#fff">
                                                                               
                                                                            </div>
                                                                          
                                                                            <div *ngIf="course.soldout_placelimited=='places_limitted'"  class="box float-left" style="padding: 10px;background: #F8D053;color:#fff">
                                                                            
                                                                            </div>
                    
                    
                                                                      <div class="overview">
                                                                        <h4  routerLink="/program-details/{{course.master_slug}}">{{course.title}}</h4>
                                                                        <div [style.display]="course.is_online==='1' ? 'block' : 'none'"><span><i class="fa fa-map-marker" aria-hidden="true"></i></span ><span> Live Online</span></div>
                                                                        <div [style.display]="course.is_online==='2' ? 'block' : 'none'"><span><i class="fa fa-map-marker" aria-hidden="true"></i></span ><span> Online</span></div>
                  
                                                                    </div>
                                                                    <div class="price-schd">
                                                                       <b></b>{{course.currency}}
                                                                        <b >{{course.price}}</b>   
                                                                    </div>                                                   
                                                                </div>
                                                                  </div>
                                                                  <div class="flip-card-back">
                                                                    <div class="border-light schd-hvr-col" style="padding:15px;color:#fff;">                                                            
                                                                        <div class="schd-hvr">
                                                                        <div>{{course.start_date|date:'MMM dd yyyy'}} -{{ course.end_date| date:'MMM dd yyyy' }}</div>
                                                                        <h5 style="color:rgb(255 255 255 / 82%)">{{course.duration}}</h5>   
                                                                                <p class="overview">
                                                                                {{course.overview}}
                                                                                </p> 
                                                                            <div class="schd-apply">
                                                                                <a class="btn start  float-left" routerLink="/program-details/{{course.master_slug}}" >View More</a>
                                                                                <a class="btn start float-right" *ngIf="!userService.isLoggedIn()" (click)="openModal(course.master_slug)" >Apply Now</a>
                                                                                <a class="btn start float-right" *ngIf="userService.isLoggedIn() && !course.is_applied && (course.documents.length||course.formfields.length)" (click)="apply.show(course.documents,course.formfields,course.id)" >Apply Now</a>
                                                                                <a class="btn start float-right" *ngIf="userService.isLoggedIn() && !course.is_applied &&  !course.documents.length && !course.formfields.length" (click)="goToCheckout(course)" >Apply Now</a>
                                                                                <a class="btn start float-right" *ngIf="userService.isLoggedIn() && course.is_applied">Applied</a>                                                            
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>                                        
                                                        </div> 
                                                    </div>                                    
                                                        <div class="accordion dis767"  id="accordionExample" style="display:none;">
                                                            <div class="cards" *ngFor="let course of list.dates.dates">
                                                                <div class="card-header cardheader" id="{{course.id}}" style="padding: 15px 15px 0px;">
                                                                    <div class="row schedule collapsed top-schd"  data-toggle="collapse" [attr.data-target]="'#news' + course.id"
                                                                    aria-expanded="false" aria-controls="{{course.id}}">
                                                                    <div class="col-2 mob-schd-date" >
                                                                        <div><span style="text-align: center;font-size:30px;line-height: 28px;"> {{course.start_date|date:'dd'}}</span><br> {{course.start_date|date:'MMM'}}</div>                                                       
                                                                       
                                                                    </div>
                                                                        <div class="col-9 float-left pad-scd schd-col" >
                                                                            <h4  routerLink="/program-details/{{course.master_slug}}" style="margin:0px 0px 5px">{{course.title}}</h4>
                                                                            
                                                                            <div [style.display]="course.is_online==='1' ? 'block' : 'none'"><span><i class="fa fa-map-marker" aria-hidden="true"></i></span> <span > Live Online</span></div>
                                                                            <div [style.display]="course.is_online==='2' ? 'block' : 'none'"><span><i class="fa fa-map-marker" aria-hidden="true"></i></span> <span > Online</span></div>
                                                                         
                                                                        </div>
                
                                                                        <div class="col-1 " style="cursor: pointer;text-align:right;padding:0px">
                                                                        <span class=" float-right plus1" data-toggle="collapse" [attr.data-target]="'#new' + course.id"
                                                                        aria-expanded="false" aria-controls="{{course.id}}" style="font-size:16px; position: absolute;
                                                                        bottom: 0;right:15px;">
                                                                        <i class="fa fa-chevron-down"></i>
                                                                        <i class="fa fa-chevron-up" style="display:none"></i>
                                                                    </span>
                                                                  
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div [id]="'news'+course.id" class="panel-collapse  collapse " aria-labelledby="{{course.id}}"  style="width: 100%;">
                                                                    <div class="card-body">
                                                                        <div class="schd-hvr">
                                                                            <div>{{course.start_date|date:'MMM dd yyyy'}} -{{ course.end_date| date:'MMM dd yyyy' }}</div>
                                                                          <h5 style="color:rgb(255 255 255 / 82%)">{{course.duration}}</h5>   
                                                                          <div [style.display]="course.is_online==='1' ? 'block' : 'none'"><span >Live Online</span></div>
                                                                          
                
                                                                          <div style="margin-bottom: 10px;">
                                                                            <b></b>{{course.currency}}
                                                                             <b >{{course.price}}</b>   
                                                                         </div>    
                                                                                <a class="btn views " style="background-color: #ff8533;" routerLink="/program-details/{{course.master_slug}}" >View More</a>
                                                                                <a class="btn views " *ngIf="!userService.isLoggedIn()" (click)="openModal(course.master_slug)" >Apply Now</a>
                                                                                <a class="btn views" *ngIf="userService.isLoggedIn() && !course.is_applied && (course.documents.length||course.formfields.length)" (click)="apply.show(course.documents,course.formfields,course.id)" >Apply Now</a>
                                                                                <a class="btn views" *ngIf="userService.isLoggedIn() && !course.is_applied &&  !course.documents.length && !course.formfields.length" (click)="goToCheckout(course)" >Apply Now</a>
                                                                                <a class="btn views" *ngIf="userService.isLoggedIn() && course.is_applied">Applied</a>                                                            
                                                                               
                                                                           </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                   </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                      </ng-template>
                    </li>          

                  </ul>
                  <i class="fa fa-chevron-left fa-lg leftside-btn" (click)="prev()" style="cursor: pointer;"></i>
                  <i class="fa fa-chevron-right fa-lg rightside-btn" (click)="next()" style="cursor: pointer;"></i> 

            </div>
            
            
                <div class="jumbotron mt-2" style="margin-bottom:0px">
                    <div class="container-fluid ">
                        <div class="container-delivery">
                            <div class="row">
                                <div class="col-md-12" style="margin:0 auto;">
                                   <div class="row">
                                    <div class="head col-md-4">
                                        <!-- <h4 class="text-left">{{title}}</h4>
                                        <div class="underline" style="margin-left: 0px!important;"></div> -->
                                    </div>  
                                    <div class="side-head col-md-8">                                                      
                                        <div class="row" style="justify-content: flex-end;margin:0px">
                                         <span style="margin-top: 5px;"> Live online events </span> 
                                            <!-- <fa-icon style=" margin: 5px 0px 0px 5px;color: #00000042;position: relative;top: -4px;" [icon]="faChevronCircleDown"></fa-icon> -->
                                          <!-- <select class="live form-control" name="" id="">
                                              <option value="" *ngFor="let time of offsetTmz">{{time}}</option>
                                          </select>
                                            -->
                                        </div>
                                        <div class="row" style="margin:0px;align-items: center; justify-content: flex-end;">
                                            <div class="box" style="background: #10CFBD;" ></div> Discount  <div  class="box" style="background:  #F55753;" ></div>Sold Out
                                            <div  class="box" style="background: #F8D053;" > </div>Places Limited
                                        </div>
                                    </div>
                                   </div> 
                                   <div  *ngFor="let list of courses ">
                                    <h3>{{getMonthName(list.month)}} {{list.year}}</h3>
                                    <div class="row none767" >
                                         <div  *ngFor="let course of list.dates.dates" class="col-lg-4 col-md-6 float-left" >
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                  <div class="flip-card-front">
                                                    <div class="border-light schd-col">  
                                                        <div class="date-shcd">
                                                            <div>{{course.start_date|date:'MMM dd yyyy'}} -{{ course.end_date| date:'MMM dd yyyy' }}</div>

                                                            <!-- <h4>{{course.start_date|date:'dd'}} -{{ course.end_date| date:'dd' }}</h4>
                                                            <p *ngIf="chckMonth(course.start_date,course.end_date)">{{course.start_date|date:'MMM'}}</p>
                                                            <p *ngIf="!chckMonth(course.start_date,course.end_date)">{{course.start_date|date:'MMM'}}-{{ course.end_date| date:'MMM' }}</p> -->
                                                        </div>
                                                        
                                                           
                                                            <div *ngIf="course.is_offer"  class="box float-left" style="padding: 10px;background: #10CFBD; color:#fff;margin-right:10px">
                                                              
                                                            </div>
                                                      
                                                            <div *ngIf="course.soldout_placelimited=='sold_out'"  class="box float-left" style="padding: 10px;background:#F55753;color:#fff">
                                                               
                                                            </div>
                                                          
                                                            <div *ngIf="course.soldout_placelimited=='places_limitted'"  class="box float-left" style="padding: 10px;background: #F8D053;color:#fff">
                                                            
                                                            </div>
    
    
                                                      <div class="overview">
                                                        <h4  routerLink="/program-details/{{course.master_slug}}">{{course.title}}</h4>
                                                        <div [style.display]="course.is_online==='1' ? 'block' : 'none'"><span><i class="fa fa-map-marker" aria-hidden="true"></i></span ><span> Live Online</span></div>
                                                        <div [style.display]="course.is_online==='2' ? 'block' : 'none'"><span><i class="fa fa-map-marker" aria-hidden="true"></i></span ><span> Online</span></div>
  
                                                    </div>
                                                    <div class="price-schd">
                                                       <b></b>{{course.currency}}
                                                        <b >{{course.price}}</b>   
                                                    </div>                                                   
                                                </div>
                                                  </div>
                                                  <div class="flip-card-back">
                                                    <div class="border-light schd-hvr-col" style="padding:15px;color:#fff;">                                                            
                                                        <div class="schd-hvr">
                                                        <div>{{course.start_date|date:'MMM dd yyyy'}} -{{ course.end_date| date:'MMM dd yyyy' }}</div>
                                                        <h5 style="color:rgb(255 255 255 / 82%)">{{course.duration}}</h5>   
                                                                <p class="overview">
                                                                {{course.overview}}
                                                                </p> 
                                                            <div class="schd-apply">
                                                                <a class="btn start  float-left" routerLink="/program-details/{{course.master_slug}}" >View More</a>
                                                                <a class="btn start float-right" *ngIf="!userService.isLoggedIn()" (click)="openModal(course.master_slug)" >Apply Now</a>
                                                                <a class="btn start float-right" *ngIf="userService.isLoggedIn() && !course.is_applied && (course.documents.length||course.formfields.length)" (click)="apply.show(course.documents,course.formfields,course.id)" >Apply Now</a>
                                                                <a class="btn start float-right" *ngIf="userService.isLoggedIn() && !course.is_applied &&  !course.documents.length && !course.formfields.length" (click)="goToCheckout(course)" >Apply Now</a>
                                                                <a class="btn start float-right" *ngIf="userService.isLoggedIn() && course.is_applied">Applied</a>                                                            
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                        
                                        </div> 
                                    </div>                                    
                                        <div class="accordion dis767"  id="accordionExample" style="display:none;">
                                            <div class="cards" *ngFor="let course of list.dates.dates">
                                                <div class="card-header cardheader" id="{{course.id}}" style="padding: 15px 15px 0px;">
                                                    <div class="row schedule collapsed top-schd"  data-toggle="collapse" [attr.data-target]="'#news' + course.id"
                                                    aria-expanded="false" aria-controls="{{course.id}}">
                                                    <div class="col-2 mob-schd-date" >
                                                        <div class="center-content"><span style="text-align: center;font-size:30px;line-height: 28px;"> {{course.start_date|date:'dd'}}</span><br> {{course.start_date|date:'MMM'}}</div>                                                       
                                                    </div>
                                                        <div class="col-9 float-left pad-scd schd-col" >
                                                            <h4  routerLink="/program-details/{{course.master_slug}}" style="margin:0px 0px 5px">{{course.title}}</h4>
                                                            <div [style.display]="course.is_online==='1' ? 'block' : 'none'"><span><i class="fa fa-map-marker" aria-hidden="true"></i></span> <span > Live Online</span></div>
                                                            <div [style.display]="course.is_online==='2' ? 'block' : 'none'"><span><i class="fa fa-map-marker" aria-hidden="true"></i></span> <span > Online</span></div>
                                                            <div *ngIf="course.is_offer"  class="box float-left" style="background: #10CFBD; color:#fff; position: absolute; right: 0; top: 5px;">
                                                                              
                                                            </div>
                                                      
                                                            <div *ngIf="course.soldout_placelimited=='sold_out'"  class="box float-left" style="background:#F55753;color:#fff; position: absolute; right: 0; top: 5px;">
                                                               
                                                            </div>
                                                          
                                                            <div *ngIf="course.soldout_placelimited=='places_limitted'"  class="box float-left" style="background: #F8D053;color:#fff; position: absolute; right: 0;top: 5px;">
                                                            
                                                            </div>
                                                        </div>

                                                        <div class="col-1 " style="cursor: pointer;text-align:right;padding:0px">
                                                        <span class=" float-right plus1" data-toggle="collapse" [attr.data-target]="'#new' + course.id"
                                                        aria-expanded="false" aria-controls="{{course.id}}" style="font-size:16px; position: absolute;
                                                        bottom: 0;right:15px;">
                                                        <i class="fa fa-chevron-down"></i>
                                                        <i class="fa fa-chevron-up" style="display:none"></i>
                                                    </span>
                                                  
                                                    </div>
                                                    </div>
                                                </div>
                                                <div [id]="'news'+course.id" class="panel-collapse  collapse " aria-labelledby="{{course.id}}"  style="width: 100%;">
                                                    <div class="card-body">
                                                        <div class="schd-hvr">
                                                            <div>{{course.start_date|date:'MMM dd yyyy'}} -{{ course.end_date| date:'MMM dd yyyy' }}</div>
                                                          <h5 style="color:rgb(255 255 255 / 82%)">{{course.duration}}</h5>   
                                                          <div [style.display]="course.is_online==='1' ? 'block' : 'none'"><span >Live Online</span></div>

                                                          <div style="margin-bottom: 10px;">
                                                            <b></b>{{course.currency}}
                                                             <b >{{course.price}}</b>   
                                                         </div>    
                                                                <a class="btn views " style="background-color: #ff8533;" routerLink="/program-details/{{course.master_slug}}" >View More</a>
                                                                <a class="btn views " *ngIf="!userService.isLoggedIn()" (click)="openModal(course.master_slug)" >Apply Now</a>
                                                                <a class="btn views" *ngIf="userService.isLoggedIn() && !course.is_applied && (course.documents.length||course.formfields.length)" (click)="apply.show(course.documents,course.formfields,course.id)" >Apply Now</a>
                                                                <a class="btn views" *ngIf="userService.isLoggedIn() && !course.is_applied &&  !course.documents.length && !course.formfields.length" (click)="goToCheckout(course)" >Apply Now</a>
                                                                <a class="btn views" *ngIf="userService.isLoggedIn() && course.is_applied">Applied</a>                                                            
                                                               
                                                           </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                   </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>             
            <!-- </div>
        </div>    -->
<div class="row container-delivery" style="    background-color: #e9ecef;">
    <div class="col-md-12">
        <app-find-career [page]="'programs'"></app-find-career>
    </div>
</div>

<div class="container-delivery">
    <div class="col-fixed">
        <div class="bottom" >
            <app-bottom-bar></app-bottom-bar>    
        </div>
    </div>
    </div>