import { Component, OnInit,HostListener } from '@angular/core';
import { faPhone, faCaretDown,faBell, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Router } from "@angular/router";
import { UserService } from '../services/user/user.service';
import { RegisterComponent } from 'src/app/modules/pages/register/register.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from 'src/app/modules/pages/login/login.component';
import { MasterService } from '../services/master/master.service';
import { CourseService } from '../services/course/course.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  faPhone = faPhone;
  faCaretDown = faCaretDown;
  faTimesCircle=faTimesCircle
  signin=false;
  click=false;
  login = false;
  show=true;
  notifications:any=[];
  user;
  user_details;
  user_click =false;
  country="US";
  news:any=[];
  faBell = faBell;
  constructor(public router: Router, private userService:UserService,
    private modalService: NgbModal,private masterService:MasterService,
    public courseService:CourseService) {
    this.login = this.userService.isLoggedIn();
    console.log("login",this.country)
  }
  public get width() {
    return window.innerWidth;
  }
  ngOnInit(): void {
    this.getUser();
    console.log("ser",this.courseService.country)
      this.masterService.getFlashNews().subscribe(res=>{
       this.news=res.news;
      })

  }
  @HostListener('mouseover',  ["$event"]) onMouseOver(event) {
  if (event.target.classList.contains('country')) {
    this.click=true;
  }
}

@HostListener('mouseleave',  ["$event"]) onMouseLeave(event) {
  if (!event.target.classList.contains('country')) {
    this.click=false;
  }
  // this.renderer.removeClass(this.el.nativeElement, 'open');
}
  hide(){
    this.show=false;
  }
  goToProfile=()=>{
    this.user_click =false;
    console.log("url",this.router.url);

    if(this.router.url==='/dashboard' || this.router.url==='/freecourses/dashboard')
      window.location.reload();
    else
    this.router.navigate(['/dashboard']);

   }
   goToFree = ()=>{
    this.user_click =false;
     this.router.navigate(['/freecourses/dashboard']);
     if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
      // window.location.href ="/applied-courses";

   }
   goToAppliedFree =()=>{
    this.user_click =false;
    this.router.navigate(['/applied-freecourses']);
    if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle

   }
   goToTransaction = ()=>{
    this.user_click =false;
     this.router.navigate(['/transaction_history']);
     if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
    //  window.location.href ="/transaction_history";

   }
   goToApplied = ()=>{
    this.user_click =false;
     this.router.navigate(['/applied-courses']);
     if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
      // window.location.href ="/applied-courses";

   }
   goToApproved = ()=>{
    this.user_click =false;
     this.router.navigate(['/approved-courses']);
     if(this.width<991)  this.courseService.showToggle= ! this.courseService.showToggle
    //  window.location.href ="/approved-courses";
   }
   goToChange=()=>{
    this.router.navigate(['/change-password']);
    this.user_click =false;
  }
  getUser(){
    this.user =this.userService.isLoggedIn();
    // console.log("user",this.user,this.user_click,customObject);
    if(this.user){

      let unread = 1;
      this.masterService.getNotifications(unread).subscribe(not=>{

        this.notifications = not.data;
        console.log("notiy",this.notifications,this.notifications.length);
        let unread = 1;
      })
      this.userService.getUser().subscribe(data=>{
        console.log(data)
        this.user_details = data.profile;
      })
    }
  }
  getDetails(e){
    e.stopPropagation();
//  this.courseService.showToggle= true;
  this.user_click =  !this.user_click;
  console.log("userclick",this.user_click)
}
  getNotification(){
    this.notifications=[];
    this.router.navigateByUrl('/notifications')

}
  logout(){

    this.userService.logout().subscribe(res=>{
      console.log("resp",res);
      this.user_click=false;
      // this.dataService.allDues=[];
      // this.dataService.dues=[];
      // console.log("logout",this.dataService.dues$)
      this.userService.deleteToken();
      console.log(this.router.url);
      this.router.navigateByUrl('/')
    })

  }
  countryClick=()=>{
    this.click = !this.click;
  }
  selectCountry=(country)=>{
this.country=country;
let slug,char,new_slug;
if(this.router.url.includes('program-details') ){
  console.log(this.courseService.programTitle);
  slug=this.courseService.programTitle;
  char= '-'
  new_slug = slug.split(char).pop();
}
if(country=='US'){
  this.courseService.country=236;
  localStorage.setItem('COUNTRY_CODE', '236');
  localStorage.setItem('COUNTRY','US')

  if(slug)
  slug=slug.replace(char+new_slug,'-usd')
}
if(country=='UK'){
  this.courseService.country=235;
  localStorage.setItem('COUNTRY_CODE', '235');
  localStorage.setItem('COUNTRY','UK')

  if(slug)
  slug=slug.replace(char+new_slug,'-gbp')
}
if(country=='UAE') {
  this.courseService.country=234;
  localStorage.setItem('COUNTRY_CODE', '234');
  localStorage.setItem('COUNTRY','UAE')

  if(slug)
  slug=slug.replace(char+new_slug,'-aed')
}
if(country=='IN'){
  this.courseService.country=102;
  localStorage.setItem('COUNTRY_CODE', '102');
  localStorage.setItem('COUNTRY','IND')

  if(slug)
  slug=slug.replace(char+new_slug,'-inr')

}
if(this.router.url.includes('program-details') ){
  // this.router.navigateByUrl('/program-details/'+slug);
 window.location.href='/program-details/'+slug;
}else  window.location.reload();

this.click = false;
  }

  openRegister() {
    const modalRef = this.modalService.open(RegisterComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
        // keyboard: false,
        // backdrop: 'static'
      });

    let data = {
      prop1: 'Some Data',
      prop2: 'From Parent Component',
      prop3: 'This Can be anything'
    }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  }

  openModal() {
    const modalRef = this.modalService.open(LoginComponent,
      {
        scrollable: true,
        windowClass: 'myModalClass',
        // keyboard: false,
        // backdrop: 'static'
      });

    let data = {
      prop1: 'Some Data',
      prop2: 'From Parent Component',
      prop3: 'This Can be anything'
    }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  }
  bookCall(){
    this.router.navigateByUrl('/book-call')
  }
}
