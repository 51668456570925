import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PaginationInstance } from 'ngx-pagination';
import { CourseService } from 'src/app/core/services/course/course.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { GetRegisteredComponent } from '../../components/get-registered/get-registered.component';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CmsService } from 'src/app/core/services/cms/cms.service';
declare var bootbox:any;


@Component({
  selector: 'app-free-courses',
  templateUrl: './free-courses.component.html',
  styleUrls: ['./free-courses.component.scss']
})
export class FreeCoursesComponent implements OnInit {
all:any=[];
freecourses:any=[];
slugArg:any=[];
public config: PaginationInstance = {
  id: "custom",
  itemsPerPage: 6,
  currentPage: 1,
};
termOptions: OwlOptions = {
  loop: false,
  autoplay:true,
  autoWidth:true,
  mouseDrag: true,
  touchDrag: true,
  pullDrag: true,
  dots: false,
  navSpeed: 700,
  navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
  responsive: {
    0: {
    items: 1
    },
    400: {
      items:1
    // items:this.scheduled_courses.length<3?this.scheduled_courses.length:5
    },
    740: {
    //items: 3
    // items: this.scheduled_courses.length<3?this.scheduled_courses.length:5,
    },
    940: {
    //items: 3,
    loop:false
    }
  },
  nav: true
  }
slug;
  user: any;
  user_id: any;
  gift: any;
  constructor(private courseService:CourseService, private modalService:NgbModal,private cmsService:CmsService,
    private userService:UserService,private _activatedRoute:ActivatedRoute, private router :Router) { }

  ngOnInit(): void {
    this.cmsService.getPageInro('usd50-image').subscribe(res=>{ 
      this.gift = res['cms'];    
      console.log("why",res)
    })
    this.getFreecourseCategories();
    this._activatedRoute.params.subscribe(params => {
      console.log(params);
      this.slug = params.id
      if(this.slug)
      this.slugArg.push(this.slug)
      this.getFreecourses()  

    })
  }
  handlePageChange(event) {
    this.config.currentPage = event;
  }
  enterOtp(form,err){
    console.log("form-----------------",form)
    if(this.userService.isLoggedIn()){
      this.userService.getUser().subscribe(data=>{
        console.log(data)        
        this.user = data.profile;
        let details={
          email:this.user.email,
          course_slug:form
        }
        this.courseService.getOtp(details).subscribe(
          res => {
            console.log("otpppppp",res)
            if(res.status===3){ 
              this.router.navigateByUrl('/freecourses/dashboard');
              bootbox.dialog({ message:"Course registered successfully",
                closeButton: true})
            }
          })
      });
      

    }else{
      let that = this;
      const modalRef = this.modalService.open(GetRegisteredComponent,
        {
        scrollable: false,
        windowClass: 'myCustomModalClass',
        // keyboard: false,
        // backdrop: 'static'
        });
    
      let data = {
        prop1: 'freecourse',
        prop2: form.slug,
        // course: course,
      }
    
      modalRef.componentInstance.fromParent = data;
      modalRef.componentInstance.childEvent.subscribe((emmitedValue) => {
        // do sth with emmitedValue
        console.log("emmitedValue",emmitedValue)
        let details={
          ...emmitedValue,
          course_slug:form
        }
        this.courseService.getOtp(details).subscribe(
                    res => {
                      this.user_id=res.user_id
                      console.log("otpppppp",res)
                      if(res.status===3){
                        // that.userService.setToken(res.token); 
                        localStorage.setItem('JWT_TOKEN', res.token);
        
                        //  that.onSubmit(form,'close')
                        that.router.navigateByUrl('/freecourses/dashboard');
                        bootbox.dialog({ message:"Course registered successfully",
                        closeButton: true})
                      }else{
                     this.reenterOtp(form,err)
                      }
                    
        
                    },
                    err => {
                      console.log("err", err)
                    });
      })

    }
  
    //   bootbox.prompt({
    //     title: "Enter Email id",
    //     message:err?err:'Please enter email!',
    //     centerVertical: true,
    //     callback: function(result){
    //       if(result){
    //         console.log(result);
    //       
  
    //       }else{
  
    //         bootbox.hideAll();
  
    //         if(result!==null)
    //         that.enterOtp(form,"<span style='color: red;'>Enter valid Mail id. Try again!</span>");
    //         return false;
    //         }
  
  
    //     }
    // });
    }
  
  
  reenterOtp(form,err){
    let that =this;
    bootbox.prompt({
      title: "Enter Verification Code",
      message:err?err:'Please check your email for the verification code!',
      inputType: 'number',
      centerVertical: true,
      callback: function(result){
          console.log(result);
          if(result){

            that.courseService.verifyOtp({
              otp:result,
              user_id:that.user_id,
              course_slug:form
            }).subscribe(
              res => {
                console.log("res--->",res)
                 if(res.status==1){
                  localStorage.setItem('JWT_TOKEN', res.token);

                  //  that.onSubmit(form,'close')
                  that.router.navigateByUrl('/freecourses/dashboard');

                }else if(res.status==2){
                  var dialog = bootbox.dialog({
                    className: 'block',
                    message: "You have exceeded the limit of verification code tries. Please contact Admin !!",
                    closeButton: true
                  });
                }else
                     that.reenterOtp(form,"<span style='color: red;'>Verification failed. Try again!</span>");

              },
              err => {
                console.log("err", err)
              });
          }else{
            bootbox.hideAll();

            if(result!==null)
            that.reenterOtp(form,"<span style='color: red;'>Enter valid verification code. Try again!</span>");
            return false;
          }

      }
  });
  }

  goTo(slug){
    this.slugArg =[];
    this.slugArg.push(slug)
    this.router.navigateByUrl('/free-courses/'+slug);

  }
  goToRefer =()=> {
    this.router.navigate(['/refer&earn']);
    

  };
  getFreecourses(){
    console.log("getFreee",this.slugArg,this.slug)
    this.courseService.getFreeCourses(...this.slugArg).subscribe( (resp:any)=>{
			console.log("free",resp)
			this.freecourses = resp.freecourse;
      this.config = {
        id: "basicPaginate",
        itemsPerPage: 6,
        currentPage: 1,
        totalItems: this.freecourses.length,
      };
			}	, (err: any) => {
        console.log(err);
      });
  }
  getFreecourseCategories(){
		this.courseService.getFreeCourseCategory().subscribe( (resp:any)=>{
			// this.courseService.getFreeCourses().subscribe( (resp:any)=>{
			console.log("longterm",resp)

			this.all = resp.categories;
			}	, (err: any) => {
        console.log(err);
      });
  }
}
