<div *ngIf="router.url !== '/login'" class="container-fluid navmenu" style="position:relative">
    <!-- <div class="container"> -->

    <div class="row position-relative m-00" style="margin-left:0px">
        <div class="col-2 col-lg-9 dis991" style="display:none">
            <div class="float-right">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <button class="navbar-toggler" type="button" (click)="goCollapse($event)">
            <span class="navbar-toggler-icon"></span>
          </button>

                    <div class="collapse navbar-collapse" id="collapsibleNavbar" [ngClass]="{ 'show': courseService.showToggle }">
                        <ul ngbNav class="nav-tabs main-navigation ">
                            <!-- <li class="nav-item dropdown">
                                <a #toggleProgram (click)="getProgram()" class="nav-link menu" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown">
                                    Programs
                                    <fa-icon [icon]="faChevronDown" *ngIf="!program" class="down"></fa-icon>
                                    <fa-icon [icon]="faChevronUp" *ngIf="program" class="down"></fa-icon>
                                </a>
                                <div id="borderBottom"></div>
                            </li> -->
                            <li class="nav-item dropdown">
                                <a class="nav-link " #toggleButton (click)="getEnterprise()" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown">
                  Enterprise
                  <fa-icon [icon]="faChevronDown" *ngIf="!enterprise" class="down"></fa-icon>
                  <fa-icon [icon]="faChevronUp" *ngIf="enterprise" class="down"></fa-icon>
                </a>
                                <div id="borderBottom"></div>
                            </li>
                            <!-- <li class="nav-item dropdown events">
              <a class="nav-link" href="" (click)="getEvent()" #toggleEvent id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
              >
                Upcoming Events
                <fa-icon [icon]="faChevronDown" class="angle-down"></fa-icon>
              </a>
              <div id="borderBottom"></div>
            </li> -->
                            <li class="nav-item dropdown ">
                                <a class="nav-link" (click)="goToSuccess()" #toggleSuccess id="navbarDropdownMenuLink" role="button" data-toggle="dropdown">
               About Us
               <fa-icon [icon]="faChevronDown" *ngIf="!success" class="down"></fa-icon>
               <fa-icon [icon]="faChevronUp" *ngIf="success" class="down"></fa-icon>
              </a>
                                <!-- <div id="borderBottom"></div> -->
                            </li>
                            <li class="nav-item dropdown ">
                                <a class="nav-link" (click)="goToMore()" #toggleMore id="navbarDropdownMenuLink" role="button" data-toggle="dropdown">
                        More
                        <fa-icon [icon]="faChevronDown" *ngIf="!more" class="down"></fa-icon>
                        <fa-icon [icon]="faChevronUp" *ngIf="more" class="down"></fa-icon>
                    </a>
                                <div id="borderBottom"></div>
                            </li>
                            <span class="border"></span>
                            <li class="nav-item">
                                <button *ngIf="!userService.isLoggedIn()" class="btn start" (click)="openBookcall()">Get Started</button>
                                <modal #modal [modalConfig]="modalConfig">
                                    <!--  body of the modal  -->
                                </modal>
                                <a *ngIf="userService.isLoggedIn()" class="dropdown-item " role="button" (click)="logout()">Sign Out</a>

                            </li>
                            <li class="nav-item dropdown events userpic">

                                <!-- <span *ngIf="notifications.length" (click)="getNotification()" class="badge badge-warning" style="cursor: pointer;position: absolute;left: auto;background-color: #e86a23;color: #fff;width: 20px;line-height: 20px;height: 20px;top: -10px;padding: 0px;text-align: center;font-size: x-small;border-radius: 100%;">{{notifications.length}}</span>
                                <fa-icon *ngIf="userService.isLoggedIn()" style="cursor: pointer" (click)="getNotification()" [icon]="faBell" class="bell">

                                </fa-icon>
                                <img *ngIf="userService.isLoggedIn() && width<991" #toggleUser (click)="getDetails($event)" src="{{user_details?.image|| '../../../../assets/images/blank-profile-picture.png'}}" class="pic" alt=""> -->
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="user_click && userService.isLoggedIn()" #usermenu class="details">
                        <div style="margin-top:0px">
                            <a class="dropdown-item" role="button" (click)="goToProfile()">My Profile</a>
                            <a class="dropdown-item" role="button" (click)="goToFree()"> My Free courses</a>
                            <a class="dropdown-item" role="button" (click)="goToTransaction()">Transaction History</a>
                            <a class="dropdown-item" role="button" (click)="goToApproved()">Approved Courses</a>
                            <a class="dropdown-item" role="button" (click)="goToApplied()">Applied Courses</a>
                            <a class="dropdown-item" role="button" (click)="goToAppliedFree()">Applied Free Courses</a>

                            <a class="dropdown-item" role="button" (click)="goToChange()">Change Password</a>
                            <a class="dropdown-item" role="button" (click)="logout()">Sign Out</a>
                        </div>
                    </div>


                </nav>

            </div>


        </div>
        <div class="col-7 col-lg-2 logo" (click)="goHome()">
            <img width="30%" src="../../../assets/images/logo_main.svg">

        </div>

        <div class="col-3 col-lg-12 dis991" style="display:none">
            <button class="btn crsbtn" type="button" data-toggle="collapse" (click)="programshow.show(longTerm)">
        Courses
      </button>
        </div>


        <div class="col-3 col-lg-10 none991">

            <div class="float-right">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="collapsibleNavbar">

                        <ul ngbNav class="nav-tabs main-navigation float-right">
                            <li class="nav-item dropdown float-right">
                                <a class="nav-link " #toggleButton (click)="getEnterprise()" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown">
                  Enterprise
                  <fa-icon [icon]="faChevronDown" *ngIf="!enterprise" class="down"></fa-icon>
                  <fa-icon [icon]="faChevronUp" *ngIf="enterprise" class="down"></fa-icon>

                </a>
                                <div id="borderBottom"></div>
                            </li>
                            <!-- <li class="nav-item dropdown events">
                    <a class="nav-link" href="" (click)="getEvent()" #toggleEvent id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
                    >
                        Upcoming Events
                        <fa-icon [icon]="faChevronDown" class="angle-down"></fa-icon>
                    </a>
                    <div id="borderBottom"></div>
                    </li> -->
                            <li class="nav-item dropdown ">
                                <a class="nav-link" (click)="goToSuccess()" #toggleSuccess id="navbarDropdownMenuLink" role="button" data-toggle="dropdown">
                        About Us
                        <fa-icon [icon]="faChevronDown" *ngIf="!success" class="down"></fa-icon>
                        <fa-icon [icon]="faChevronUp" *ngIf="success" class="down"></fa-icon>
                    </a>
                                <div id="borderBottom"></div>
                            </li>
                            <!-- <span class="border"></span> -->
                            <li class="nav-item dropdown ">
                                <a class="nav-link" (click)="goToMore()" #toggleMore id="navbarDropdownMenuLink" role="button" data-toggle="dropdown">
                        More
                        <fa-icon [icon]="faChevronDown" *ngIf="!more" class="down"></fa-icon>
                        <fa-icon [icon]="faChevronUp" *ngIf="more" class="down"></fa-icon>
                            </a>
                                <div id="borderBottom"></div>
                            </li>
                            <span class="border"></span>
                            <li>
                                <button *ngIf="!userService.isLoggedIn()" class="btn start" (click)="openBookcall()">Get Started</button>
                                <modal #modal [modalConfig]="modalConfig">
                                    <!--  body of the modal  -->
                                </modal>
                            </li>
                            <li class="nav-item dropdown events userpic">
                                <fa-icon *ngIf="userService.isLoggedIn()" style="cursor: pointer" (click)="getNotification()" [icon]="faBell" class="bell"></fa-icon>
                                <span *ngIf="userService.isLoggedIn() && notifications.length" class="badge badge-warning" style="cursor: pointer;position: absolute;left: 3px;background-color: #e86a23;color: #fff;width: 20px;line-height: 20px;height: 20px;top: -6px;padding: 0px;text-align: center;font-size: x-small;border-radius: 100%;"
                                    (click)="getNotification()">{{notifications.length}}</span>
                                <img *ngIf="userService.isLoggedIn() && width>=991" #toggleUser (click)="getUserDetails($event)" src="{{user_details?.image|| '../../../../assets/images/blank-profile-picture.png'}}" class="pic" alt="{{user_details?.image_alt }}">
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="user_click && userService.isLoggedIn()" #usermenu class="details">
                        <div style="margin-top:0px">
                            <a class="dropdown-item" role="button" (click)="goToProfile()">My Profile</a>
                            <a class="dropdown-item" role="button" (click)="goToFree()"> My Free courses</a>

                            <a class="dropdown-item" role="button" (click)="goToTransaction()">Transaction History</a>
                            <a class="dropdown-item" role="button" (click)="goToApproved()">Approved Courses</a>
                            <a class="dropdown-item" role="button" (click)="goToApplied()">Applied Courses</a>
                            <a class="dropdown-item" role="button" (click)="goToAppliedFree()">Applied Free Courses</a>

                            <a class="dropdown-item" role="button" (click)="goToChange()">Change Password</a>
                            <a class="dropdown-item" role="button" (click)="logout()">Sign Out</a>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
    <div (mouseenter)="getPrograms()" (mouseleave)="closeProgram()">
        <!-- <div  (click)="getPrograms()"> -->
        <div class="frt-menu">
            <div class="navbar-collapse" id="collapsibleNavbar1">
                <ul ngbNav class="nav-tabs main-navigation ">
                    <li class="nav-item dropdown">
                        <div #toggleProgram class="menu class-menu" role="button">
                            Programs
                            <fa-icon [icon]="faChevronDown" *ngIf="!program" class="down"></fa-icon>
                            <fa-icon [icon]="faChevronUp" *ngIf="program" class="down"></fa-icon>
                        </div>
                        <div id="borderBottom"></div>
                    </li>
                </ul>
            </div>

        </div>
        <div *ngIf="program " #programmenu class="list pgm-list" aria-labelledby="dropdownMenuLink1">
            <div class="row navmenu">
                <div class="col-xl-3 col-lg-3 col-md-4">
                    <div class="col-drop col-drop-ul">
                        <ul style="float: left;margin-bottom:10px">
                            <!-- <li (click)="getCategory('Trending Courses')">
                                <span class="men-li">Trending Courses</span>
                            </li> -->
                            <li (mouseenter)="getCategory(course.key)" *ngFor="let course of longTerm |keyvalue:unsorted" [ngClass]="{
                                'selectedCat': course.key === cat}">
                                <span class="men-li">
                                    <!-- <span class="cat-icons"  [innerHTML]="sanitizer.bypassSecurityTrustHtml(course?.value.icon)"></span> --> {{course.key}} <span *ngIf="course.key === cat"><i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></span>
                                </span>
                            </li>
                        </ul>
                        <!-- <h5 (click)="goToPrograms()" style="cursor: pointer;">Courses</h5>
                        <div>Edubex helps companies prepare for the future of work through cutting-edge digital skills training, top talent recruitment, and more.</div>
                        <div class="col-md-12 crse-col-link" style="padding:0px;margin-top:15px">
                            <a style="display: block;color: #e86a22;cursor: pointer;" (click)="goToPrograms()"><b>Browse All Course <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></b></a>
                        </div> -->
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-4 col-drops">
                    <div>{{cat}}</div>
                    <div class="col-drop">
                        <div class="dropdown">
                            <div class="row">
                                <!-- <ul *ngFor="let course of longTerm |keyvalue" class="col-xl-6 col-lg-6 col-6" style="float: left;margin-bottom:10px">
                                    <li> -->
                                <!-- <span class="men-li">
                <span class="icons"  [innerHTML]="sanitizer.bypassSecurityTrustHtml(course?.value.icon)"></span>
                                        <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></span> -->

                                <ul class="dropdown-menu col-lg-6" *ngFor="let course_det of  longTerm[cat]" style="padding:8px 8px;display:block;background: transparent;border: 0px;float: none;position: relative;">
                                    <li style="box-shadow: 0 0 9px 0 rgba(128, 128, 128, 0.52);border-radius: 3px;cursor: pointer;">
                                        <div class="card-body" (click)="goToProgramDetails(course_det.master_slug)">
                                            <div style="height: 50px;">
                                                <div style="width: 70px;margin-bottom:10px;height: 50px;" *ngIf="course_det.university_data">
                                                    <img [src]="course_det.university_data?.logo" alt="{{course_det.university_data?.logo_alt}}" style="width: 100%;height:100%;object-fit:contain;float: left;">
                                                </div>
                                            </div>

                                            <!-- <p class="card-title-nav">{{course_det.university_data?.university_name}}</p> -->

                                            <div style="text-align: end;">
                                                <span class="badge badge-danger bdg-col" *ngIf="course_det.label==='New'" style="width:auto;margin-bottom: 20px;">NEW</span>
                                                <span class="badge badge-warning bdg-col" *ngIf="course_det.label==='Trending'" style="width: auto;margin-bottom: 20px;background-color: rgb(255 153 51);color: #ffffff;padding: 5px;">TRENDING</span> </div>

                                            <a class="card-title-det" style="font-size: small;color: #000000;">{{course_det.title}}</a>

                                            <div class="card-points" style="font-size: 10px;
                                                color: #9d9696;margin-top:8px">
                                                <span class="footer">{{course_det.duration}}</span><i class="fa fa-circle" aria-hidden="true"></i>
                                                <span class="footer" *ngIf="course_det?.is_online==='1'"> Live Online</span>
                                                <span class="footer" *ngIf="course_det?.is_online==='2'"> Online</span>
                                                <i class="fa fa-circle" aria-hidden="true"></i>
                                                <span class="footer"><fa-icon style="margin-right: 4px; position: relative; top: -1px;" [icon]="faCalendarAlt"></fa-icon>: <b>{{ course_det.start_date| date:'MMM dd, yyy' }}</b>
                                                    </span>
                                            </div>


                                        </div>
                                    </li>
                                    <!-- </ul>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-4">
                    <div class="col-drop">
                        <div>Quick Links</div>
                        <span class="men-li" (click)="goToScheduledPrograms()">Browse All Scheduled Course <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></span>
                        <span class="men-li" (click)="goToWebinars()">Webinar on Demand <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></span>
                        <span class="men-li" (click)="goToBootcamps()">Upcoming Bootcamps <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></span>
                        <span class="men-li" (click)="goToRefer()">Gift USD 50 and get USD 50<i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></span>
                        <span class="men-li" (click)="goToRefer()">
                      <img [src]="gift?.image" class="quickLinkImage">
                  </span>

                        <!-- <a style="display: block;cursor: pointer;" ><b>Browse All Scheduled Course <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></b></a>
                  <a style="display: block;cursor: pointer;" (click)="goToWebinars()"><b> <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></b></a>

                  <a style="display: block;cursor: pointer;" (click)="goToScheduledPrograms()"><b>Upcoming Bootcamps <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></b></a>
                  <a style="display: block;cursor: pointer;" (click)="goToScheduledPrograms()"><b>Gift USD 50 and get USD 50 <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></b></a> -->

                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-show-program (childEvent)="reload($event)" #programshow></app-show-program>

    <div *ngIf="enterprise " #menu class="list enter-list">
        <div class="row navmenu">
            <div class="col-lg-4 col-md-12">
                <div class="col-drop">
                    <h5>Enterprise</h5>
                    <div>Edubex helps companies prepare for the future of work through cutting-edge digital skills training, top talent recruitment, and more.</div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12 col-drop">
                <div class="col-drop">
                    <div class="dropdown">
                        <ul class="row" style="margin:0px">
                            <li class="col-md-6 col-6">
                                <a (click)="goToHiring()" style="display:inline-block;margin: 5px 0px;width:100%; cursor:pointer">
                                    <!-- <img src="../../../../assets/images/hire-edubex-graduates-2.svg" style="width: 15px;margin-top: -4px;margin-right: 10px;"> -->
                                    Hire Edubex Graduates
                                    <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></a>
                            </li>
                            <li class="col-md-6 col-6">
                                <a (click)="goToCorporate()" style="display:inline-block;margin: 5px 0px;width:100%;cursor:pointer">
                                    <!-- <img src="../../../../assets/images/corporate-training.svg" style="width: 20px;margin-top: -4px;margin-right: 10px;"> -->
                                    Corporate Training
                                    <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="event " #eventmenu class="list event-list">
        <div class="row navmenu">
            <div class="col-lg-4 col-md-4">
                <div class="col-drop">
                    <h5 (click)="goToScheduledPrograms()" style="cursor: pointer;">Upcoming Courses</h5>
                    <div>Edubex helps companies prepare for the future of work through cutting-edge digital skills training, top talent recruitment, and more.</div>
                    <div class="col-md-12 crse-col-link" style="padding:0px;margin-top:15px">
                        <a style="display: block;color: #e86a22;cursor: pointer;" href="https://www.edubex.com/w/us/webinars/"><b>Webinars <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></b></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-8 col-drops">
                <div class="col-drop">
                    <div class="dropdown row" style="margin:0px">
                        <ul *ngFor="let course_det of scheduled_courses" class="col-xl-4 col-lg-4 col-md-6 col-6" style="margin:0px;background: transparent;border: 0px;float: none;position: relative;">
                            <li style="margin-bottom:3px"><a style="cursor: pointer;margin-bottom:3px" (click)="goToScheduledProgramDetails(course_det.master_slug)">{{course_det.title}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="more" #moremenu class="list more-list">
        <div class="row navmenu">
            <div class="col-lg-4 col-md-4">

            </div>
            <div class="col-lg-8 col-md-8 col-drops">
                <div class="col-drop">
                    <div class="col-lg-12 col-md-12 col-drop">
                        <div class="col-drop">
                            <div class="dropdown">
                                <ul class="row" style="margin:0px">
                                    <li class="col-md-6 col-6">
                                        <a style="display:inline-block;margin: 5px 0px;width:100%;cursor: pointer;" (click)="goToRefer()">
                                            <!-- <img src="../../../../assets/images/times.png" style="width: 15px;margin-top: -4px;margin-right: 10px;"> -->
                                            Refer & Earn
                                            <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></a>
                                    </li>
                                    <li class="col-md-6 col-6">
                                        <a style="display:inline-block;margin: 5px 0px;width:100%;cursor: pointer;" (click)="goToBlog()">
                                            <!-- <img src="../../../../assets/images/i.png" style="width: 15px;margin-top: -4px;margin-right: 10px;"> -->
                                            Blog
                                            <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></a>
                                    </li>
                                    <li class="col-md-6 col-6">
                                        <a style="display:inline-block;margin: 5px 0px;width:100%;cursor: pointer;" (click)="goToWebinars()">
                                            <!-- <img src="../../../../assets/images/f.png" style="width: 15px;margin-top: -4px;margin-right: 10px;"> -->
                                            Webinars
                                            <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></a>
                                    </li>
                                    <li class="col-md-6 col-6">
                                        <a style="display:inline-block;margin: 5px 0px;width:100%;cursor: pointer;" (click)="goToBootcamps()">
                                            <!-- <img src="../../../../assets/images/f.png" style="width: 15px;margin-top: -4px;margin-right: 10px;"> -->
                                            Bootcamps
                                            <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></a>
                                    </li>
                                    <li class="col-md-6 col-6">
                                        <a style="display:inline-block;margin: 5px 0px;width:100%;cursor: pointer;" (click)="goToCalendar()">
                                            <!-- <img src="../../../../assets/images/f.png" style="width: 15px;margin-top: -4px;margin-right: 10px;"> -->
                                            Calendar
                                            <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></a>
                                    </li>
                                    <li class="col-md-6 col-6">
                                        <a style="display:inline-block;margin: 5px 0px;width:100%;cursor: pointer;" (click)="goToScholarship()">
                                            <!-- <img src="../../../../assets/images/f.png" style="width: 15px;margin-top: -4px;margin-right: 10px;"> -->
                                            Scholarships
                                            <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="success" #successmenu class="list success-list">
        <div class="row navmenu">
            <div class="col-lg-4 col-md-12">
                <div class="col-drop">
                    <h5>About Us</h5>
                    <div>Edubex helps companies prepare for the future of work through cutting-edge digital skills training, top talent recruitment, and more.</div>
                    <div class="col-md-12 crse-col-link" style="padding:0px;margin-top:15px">
                        <a style="display: block;color: #e86a22;cursor: pointer;" (click)="goToContact()"><b>Contact Us<i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></b></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12 col-drop">
                <div class="col-drop">
                    <div class="dropdown">
                        <ul class="row" style="margin:0px">
                            <li class="col-md-6 col-6">
                                <a style="display:inline-block;margin: 5px 0px;width:100%;cursor: pointer;" (click)="goToOurStory()">
                                    <!-- <img src="../../../../assets/images/times.png" style="width: 15px;margin-top: -4px;margin-right: 10px;"> -->
                                    Our Story
                                    <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></a>
                            </li>
                            <li class="col-md-6 col-6">
                                <a style="display:inline-block;margin: 5px 0px;width:100%;cursor: pointer;" (click)="goToServices()">
                                    <!-- <img src="../../../../assets/images/i.png" style="width: 15px;margin-top: -4px;margin-right: 10px;"> -->
                                    Our Services
                                    <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></a>
                            </li>
                            <li class="col-md-6 col-6">
                                <a style="display:inline-block;margin: 5px 0px;width:100%;cursor: pointer;" (click)="goToStandApart()">
                                    <!-- <img src="../../../../assets/images/f.png" style="width: 15px;margin-top: -4px;margin-right: 10px;"> -->
                                    How we stand apart
                                    <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></a>
                            </li>
                            <li class="col-md-6 col-6">
                                <a style="display:inline-block;margin: 5px 0px;width:100%;cursor: pointer;" (click)="goToCareers()">
                                    <!-- <img src="../../../../assets/images/f.png" style="width: 15px;margin-top: -4px;margin-right: 10px;"> -->
                                    Careers
                                    <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></a>
                            </li>
                        </ul>
                        <div class="row look-col">
                            <p>
                                <span>Looking to become an instructor or guest speaker?</span>
                                <button class="btn" (click)="getInvolved()">Get Involved</button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>