
<div class="container-delivery addm-col">
    <div class="row no-gutters"  *ngIf= "page=='instructor'||page==='career'||page==='progression-option';else other">    
    
    <div class="col-md-6 col-sm-7 progression-img dis575" *ngIf="page==='progression-option'" style="display:none">
        <img src="{{page_details?.image}}" class="card-image" alt="{{page_details?.image_alt}}">
    </div>
    <div class="col-md-6 col-sm-7 page-img dis575" *ngIf="page!=='progression-option'" style="display:none">
        <img src="{{page_details?.image}}" class="card-image" alt="{{page_details?.image_alt}}">
    </div>
    <div class="col-md-6 col-sm-5">
        <div  class="card-body" *ngIf= "page=='instructor'||page==='career'" >
            <div class="card-text" >
                <div *ngIf="page==='instructor'">
                    <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(page_details?.description)"></div> 
                </div> 
                <div *ngIf="page==='career'" style="font-size: large;">
                    <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(page_details?.description)"></div> 
                    <a class="btn start" (click)="goToCareers()">Job Openings</a>
                </div>  
            </div>
        </div>
        <div  class="card-body3 progression-cnt" *ngIf="page==='progression-option'">
            <div class="card-text" >
                <div >
                    <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(page_details?.description)"></div> 
                </div> 
             
            </div>
        </div>
    </div>
    <div class="col-md-6 col-sm-7 progression-img none575" *ngIf="page==='progression-option'">
        <img src="{{page_details?.image}}" class="card-image" alt="{{page_details?.image_alt}}">
    </div>
    <div class="col-md-6 col-sm-7 none575" *ngIf="page!=='progression-option'">
        <img src="{{page_details?.image}}" class="card-image" alt="{{page_details?.image_alt}}">
    </div>
</div>
<ng-template #other>
    <div class="row no-gutters"> 
        <div class="col-md-6 col-sm-7 page-img dis575" style="display:none">
            <img src="{{page_details?.image}}" class="card-img" alt="{{page_details?.image_alt}}">
        </div>
        <div class="col-md-6 col-sm-5" >
            <div   class="card-body">
                <div class="card-text" >
                        <h4 >{{page_details?.title}}</h4>
                        <div  class="underline"  style="margin-left:0px!important"></div>                           
                    <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(page_details?.description)"></div>                  
                    <div *ngIf="page==='program-details'">
                        <a  class="btn join">Flexible Payment Available</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-7 none575">
            <img src="{{page_details?.image}}" class="card-img" alt="{{page_details?.image_alt}}">
        </div>
    </div>
</ng-template></div>

