<div class="container-fluid" style="padding:0px">
    <app-banner [page]="page"></app-banner>
</div>
<div class="container-fluid" style="padding:0px">
    <div class="row container-delivery">
        <div class="col-md-5 logo">
            <!-- <img width="30%" src="../../../../assets/images/logo_main.svg"> -->
        </div>
    </div>
    <div class="row container-delivery title">
        <div class="col-md-12">
            <h3 style="margin-bottom:5px!important;margin-top:20px!important">{{details?.title}}</h3>
            <p>Edubex, {{details?.location}}</p>
            <h5>{{details?.title}}</h5>
        </div>
        <div class="col-md-7 float-left" [innerHTML]="details?.description">
        </div>
        <div class="col-md-4 float-left career-col" style="border-left: 2px solid #b5afab26;    margin-bottom: 25px;">
            <!-- <div class="row">
                <div class="col-md-12">
                    <a class="btn get-start float-right">Apply</a>
                </div>
            </div> -->
            <div class="row apply">
                <div class="col-md-12">
                    <h6 *ngIf="details?.seniority_level"><b>Seniority Level</b></h6>
                    <p>{{details?.seniority_level}}</p>
                    <h6 *ngIf="details?.industry"><b>Industry</b> </h6>
                    <p>{{details?.industry}}
                    </p>
                    <h6 *ngIf="details?.employment_type"><b>Employment Type</b> </h6>
                    <p>{{details?.employment_type}}</p>
                    <h6 *ngIf="details?.job_functions"><b>Job Functions</b> </h6>
                    <p>{{details?.job_functions}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 container-delivery">
        <div class="row" style="width:100%;margin:auto;">
            <div class="col-md-5">
                <img width=100% src="../../../../assets/images/job-detail-man.png" class="jb-det-img">
            </div>
            <div class="col-md-7">
                <div class="card-sec">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-8">
                                <h6 class="card-title"><b>Apply for this job</b></h6>
                                <!-- <p>Please sigin to apply with Linkedin</p>
                            <div>
                                <a (click)="login()" class="btn join">Sign in with Linkedin</a>
                            </div> -->
                            </div>
                            <div class="col-md-4" style="text-align:right">
                                <span style="color:#dc873efa">*</span><span>Required</span>
                            </div>
                        </div>
                        <div class="underline-"></div>
                        <form class="form" #signupform="ngForm" (ngSubmit)="signupform.form.valid && onSubmit(signupform)" novalidate>
                            <div class="row">
                                <div class="col-sm-6 ">
                                    <label for="">First Name</label> <span style="color:#dc873efa">*</span>
                                    <input type="text" class=" text textbox form-control" [(ngModel)]="model.first_name" name="first_name" #first_name="ngModel" required (change)="model.first_name = model.first_name.trim()">
                                    <span class="text-danger error-text" *ngIf="(first_name.touched || signupform.submitted) && first_name.errors?.required">
                                    First Name is required
                                </span>
                                </div>
                                <div class=" col-sm-6 ">
                                    <label for="">Last Name</label> <span style="color:#dc873efa">*</span>
                                    <input type="text" class=" text textbox form-control" [(ngModel)]="model.last_name" name="last_name" #last_name="ngModel" required (change)="model.last_name = model.last_name.trim()">
                                    <span class="text-danger error-text" *ngIf="(last_name.touched || signupform.submitted) && last_name.errors?.required">
                                    Last Name is required
                                </span>
                                </div>
                                <div class=" col-sm-6 ">
                                    <label for="">Email</label> <span style="color:#dc873efa">*</span>
                                    <input type="text" class=" text textbox form-control" [(ngModel)]="model.email" name="email" #email="ngModel" required email (change)="model.email = model.email.trim()">
                                    <span class="text-danger error-text" *ngIf="(email.touched || signupform.submitted) && email.errors?.required">
                                    Email is required
                                </span>
                                    <span class="text-danger error-text" *ngIf="email.touched && email.errors?.email">
                                    Enter a valid email address
                                </span>
                                </div>
                                <div class=" col-sm-6 ">
                                    <label for="">Phone</label> <span style="color:#dc873efa">*</span>

                                    <input type="text" class=" text textbox form-control" pattern="[0-9]+" [(ngModel)]="model.phone" name="phone" #phone="ngModel" required phone (change)="model.phone = model.phone.trim()">
                                    <span class="text-danger error-text" *ngIf="(phone.touched || signupform.submitted) && phone.errors?.required">
                                    Phone is required
                                </span>
                                    <span class="text-danger error-text" *ngIf="phone.touched && phone.errors?.pattern">
                                Invalid number
                                </span>
                                </div>
                                <div class="col-sm-12">
                                    <label for="">Location(City)</label> <span style="color:#dc873efa">*</span>

                                    <input type="text" class=" text textbox form-control" required [(ngModel)]="model.location" name="location" #location="ngModel" location (change)="model.location = model.location.trim()">
                                    <span class="text-danger error-text" *ngIf="(location.touched || signupform.submitted) && location.errors?.required">
                                    Location is required
                                </span>
                                </div>
                                <div class="col-sm-6">
                                    <label for="">Resume/CV </label> <span style="color:#dc873efa">*</span>
                                    <span class="file" (click)="UploadCVInput.click()" style="cursor: pointer;">Attach,Dropbox,Google Drive,
                                    Paste</span> {{resume?.name}}
                                    <div class="text-danger error-text" *ngIf="(signupform.submitted && !resume)">
                                        Resume/CV is required
                                    </div>
                                    <input type="file" class="custom-file-input" id="customFile1" name="uploadedcv" (change)="onCVSelect($event)" #UploadCVInput value="">
                                </div>
                                <div class="col-sm-6">
                                    <label for="">Cover Letter </label> <span style="color:#dc873efa">*</span>
                                    <span class="file" (click)="UploadFileInput.click()" style="cursor: pointer;">Attach,Dropbox,Google Drive,Paste</span> {{cover_letter?.name}}
                                    <div class="text-danger error-text" *ngIf="(signupform.submitted && !cover_letter)">
                                        Coverletter is required
                                    </div>
                                    <input type="file" class="custom-file-input" id="customFile" name="uploadedCover" (change)="onCoverSelect($event)" #UploadFileInput value="">
                                </div>
                            </div>
                            <hr>
                            <div class="row ">
                                <div class="col-sm-6 ">
                                    <label for="">Linkedin Profile</label><span style="color:#dc873efa">*</span>
                                    <input type="text" class=" text textbox form-control" [(ngModel)]="model.linkedin" name="linkedin" #linkedin="ngModel" required linkedin (change)="model.linkedin = model.linkedin.trim()">
                                    <span class="text-danger error-text" *ngIf="(linkedin.touched || signupform.submitted) && linkedin.errors?.required">
                                    Linkedin is required
                                </span>
                                    <!--  -->
                                    <!-- <div>
                                    <span class="text-danger error-text" *ngIf="linkedin.errors?.apppattern && linkedin.touched">
                                    URL is Invalid </span>
                                </div> -->
                                </div>
                                <div class="col-sm-6 ">
                                    <label for="">Website </label><span style="color:#dc873efa">*</span><span>(eg:https://www.example.com)</span>
                                    <input type="url" class=" text textbox form-control" [(ngModel)]="model.website" name="website" #website="ngModel" required (change)="model.website = model.website.trim()" website appPattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)">
                                    <span class="text-danger error-text" *ngIf="(website.touched || signupform.submitted) && website.errors?.required">
                                    Website is required
                                    </span>
                                    <div>
                                        <span class="text-danger error-text" *ngIf=" !website.errors?.required && website.errors?.apppattern && website.touched"> URL
                                        is Invalid </span>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <!-- <div class="underline-"></div> -->
                            <div>
                                <input type="submit" class="btn submit" value="Submit Application">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row container-delivery">
    <div class="col-md-12">
        <app-find-career></app-find-career>
    </div>
</div>
<div class="container-delivery">
    <div class="col-fixed">
        <div class="bottom">
            <app-bottom-bar></app-bottom-bar>
        </div>
    </div>
</div>