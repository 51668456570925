import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
import { CmsService } from 'src/app/core/services/cms/cms.service';
import { MasterService } from 'src/app/core/services/master/master.service';
declare var bootbox:any;

@Component({
  selector: 'app-hiring-partner',
  templateUrl: './hiring-partner.component.html',
  styleUrls: ['./hiring-partner.component.scss'],
  providers: [NgbTabsetConfig]  
})
export class HiringPartnerComponent implements OnInit {

  hiring_partner:any;
  page :String ='hiring-partner';
  partners:any = [];
  message='';
  services:any = [];

    constructor(private masterService:MasterService, private title: Title, private meta: Meta) { }
  
    ngOnInit(): void {
      this.getHiringPartners();
     

    }
  
    getHiringPartners(){
      this.masterService.getHiringPartners().subscribe(resp=>{
        console.log("resp",resp)
        this.partners = resp.partners;
        this.services = resp.services;
      })
    }
    // messageHandler(message){
    //   console.log("message",message)
    //   this.message = "Joined successfully!!";
    // }
    messageHandler(message){
      console.log("message",message);
      // this.message = message;
      var dialog = bootbox.dialog({
        message: message,
        closeButton: true
      });
      // document.getElementById('successMessage').style.display='block';
      // setTimeout(function(){
      //   // this.message="hi everybody";
      //   document.getElementById('successMessage').style.display='none';
      // },3000);    
    }
  
}
