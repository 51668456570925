<div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
    <div class="chat" *ngIf="click">
        <div><b>Chat with our counsellor</b></div>
        <a style="color: #095e37; font-size: 13px;font-weight: bold;" href="https://api.whatsapp.com/send?phone=919995865090&text=Hi%20There!">CHAT NOW</a>
    </div>
    <ul style="float:right;   list-style-type: none;">
        <!-- <li>
            <a type="button" class="btn active up" data-toggle="button" aria-pressed="true" (click)="clickChat();">
                <img class="phone" src="../../../assets/images/whatsapp_chat.png">
                <i _ngcontent-ips-c128="" aria-hidden="true" class="fa fa-whatsapp" style=" font-size: 32px;
                margin-top: 5px;color: #095e37;"></i> 
            </a>
        </li> -->
        <li> <button type="button" class="btn active up" data-toggle="button" aria-pressed="true" (click)="scrollToTop()">
        <fa-icon style="color: #095e37; font-size: 20px;" [icon]="faChevronUp" ></fa-icon>
    </button></li>
    </ul>
</div>