import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CmsService } from 'src/app/core/services/cms/cms.service';
import { MasterService } from 'src/app/core/services/master/master.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  page :String ='our-story';
  partners:any = [];
  message='';
  story:any = [];
  vision:any = [];
  mission:any=[];

    constructor(private masterService:MasterService,private cmsService:CmsService,
      public sanitizer: DomSanitizer) { }
  
    ngOnInit(): void {
      this.getVision();
      this.getMission();
      this.getStory();
    }
  
getStory(){
  this.cmsService.getPageInro('our-story').subscribe(res=>{     
    this.story= res['cms'];  
    console.log("story",res)
  })
}
    getVision(){
      this.cmsService.getPageInro('our-vision').subscribe(res=>{     
        this.vision= res['cms'];  
        console.log("vision",res)
      })
    }

    getMission(){
      this.cmsService.getPageInro('our-mission').subscribe(res=>{     
        this.mission= res['cms'];  
        console.log("mission",res)
      })
    }  
}
