<app-banner [page]="'privacy-policy'"></app-banner>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <h3>Purpose</h3>
            <p>Edubex ("Edubex", "We", or "Us") is committed to protecting your right to privacy as a user of our online documents. It is our policy to respect the privacy of private communication.
            </p>
            <p> Our site is primarily operated and managed on servers located and operated within the UK and India. The information you provide to us will be held for Edubex on servers based in the UK and India, and, except as explained below, we will not
                transfer it, or authorise its transfer, outside the UK or India.</p>
            <p>Personal data collected by Edubex may be stored and processed in your region, and in any other country where Edubex or its affiliates, data sub-processors operate facilities. To facilitate Edubex global operations, we may transfer and access
                such information from around the world, including from other countries in which the Company has operations. This Privacy Statement shall apply even if Edubex transfers Data to other countries.</p>
            <p>The data we hold may also be processed by employees operating in countries listed above, such staff may be engaged in, among other things, product development and customer and technical support. The data protection and other laws of these
                countries might not be as comprehensive as those in your country. By submitting your data and/or using Our Services, you consent to the transfer, storing, and processing of Your data in these countries.</p>
            <p>We collect information about our users in order to help us continually improve the products and facilities we offer and so that we can enter into commercial arrangements, including the sale of advertising space. Edubex will always adhere to
                UK Data Protection Legislation which, from 25 May 2018, includes EU Regulation 2016/679 General Data Protection Regulation ("GDPR").</p>
            <p>Other than as stated below, we do not hold or use any information that you provide, or which we collect, outside the European Economic Area (“EEA”), nor do we transfer it to, or share it with, others within or outside the EEA (except when
                we believe in good faith that the law requires it).</p>
            <p>This Privacy Policy only relates to the Edubex site and does not extend to your use of the Internet outside of the company’ site.</p>
            <h3>What Data Do We Collect?</h3>
            <p>We will collect personal data as provided to us during the registration process, which you agree to supply us as accurate. We do not monitor your use of the Internet, but we do use cookie technology to monitor your use of CANVAS. This information
                is not stored alongside your personal data and will only be used on an anonymous, aggregated basis. We may process your personal data in combination with the course/assignment documents and forms downloaded in order to maintain and improve
                the facilities we offer and to send you alerts about important updates to such content. </p>
            <h3>What Do We Use Your Data For? </h3>
            <p>We use your personal data for the following purposes:</p>
            <ul>
                <li>Providing and managing your account and/or Registration(s).</li>
                <li>To provide you with information about services we consider similar to those that you are already using, or have enquired about, or may interest you such as promotional offers. If you are a registered user, we will contact you by electronic
                    means (e-mail or push notifications or SMS or telephone) with information about these services; You can control your email preferences by contacting our datacontroller@edubex.com to opt out of our newsletters and bulletins when you
                    register (or at any time thereafter). </li>
                <li> Please note that some emails are an integral part of the Edubex service you sign up for when registering with us and cannot be opted out of and that by registering, you are consenting to us using your personal data to send such emails.
                    <li>To administer and enhance the security of our Edubex platform and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes.</li>
                    <li>Communicating with you. This may include responding to emails or calls from you.</li>
                    <li>Building up a profile of your interests and preferences based on your download history.</li>
                    <li>To remind you of completing payments to obtain course access and remind you about class timings or class reschedule or class cancellations if any</li>
            </ul>

            <p>Our processing of your personal data is in our legitimate interests and is necessary to provide the Edubex to you and to continually improve it and the content available through it.</p>
            <p>Our use of your personal data is limited to that which is reasonably required in order to provide our service to you and to make improvements to that service which benefit both us and you. We do not use your personal data for any reasons not
                stated in this Privacy Policy and never use it in an excessive manner that is disproportionate to our aims of providing and improving our service or in a way that is contrary to your interests.</p>
            <p>In addition, when you register with us, your personal data is necessary for the formation of the contract between you and Edubex Ltd and the completion of your registration.</p>
            <h3>What Cookies Do We Use?</h3>
            <p>There are a few different types of cookies, however, our website uses:</p>
            <ul>
                <li>Persistent Cookies. We use persistent Cookies to improve your experience of using the Sites. This includes recording your acceptance of our Cookie Policy to remove the cookie message which first appears when you use the Sites.</li>
                <li>Session Cookies. Session Cookies are temporary and deleted from your machine when your web browser closes. We use session Cookies to help us track internet usage as described above.</li>
                <li>Analytical/Performance Cookies. Analytical cookies allow us to recognize and count the number of visitors and see how many visitors move around our website while they are using it. This helps us improve the way our website works, for example,
                    by ensuring the users find what they are looking for.</li>
                <li>Functionality Cookies. Functionality Cookies recognize when you return to the website. This enables the company to create greater content for you and remember your likes and dislikes and other preferences.</li>
                <li>Targeting Cookies. Targeting Cookies records the visit to our website, the pages navigated to and the links clicked upon. It helps to formulate information relevant to the user’s area of interests.</li>
            </ul>
            <p>Edubex uses the following cookies and related technologies for the following purposes:

            </p>
            <ul>
                <li>Purpose Name, course, contact details Purpose :This cookie is used to store and display your name on our site.
                </li>
                <li> Canvas Purpose :This cookie is used to check the collection of cookies. Canvas Admin These cookies are used by our “back office”
                </li>
                <li>("canvasAdmin") ("AdminEmail") admin system and are not related to your use of our site</li>
                <li>SuperAdmin ("SuperAdmin")("SuperAdminEmail") Google Tag Manager Purpose:This is a tool that we use for tracking and analytics on our site. We use tracking and analytics to better understand how you use our site in order to improve our
                    service. Tracking and analytics are not used for any direct marketing purposes.</li>
            </ul>
            <h3>How Long Do We Keep Your Personal Data?</h3>
            <p>We keep your Personal Data for no longer than necessary for the purposes for which the Personal Data is collected and processed. The length of time we retain Personal Data for depends on the purposes for which we collect and use it and/or
                as required to comply with applicable laws and to establish, exercise, or defend our legal rights. If you wish to delete your account, you can do so by mailing to datacontroller@edubex.com</p>
            <h3>How and Where Do We Store or Transfer Your Personal Data?

            </h3>
            <p>We only store your personal data within the countries mentioned above in the Privacy Policy. This means that it will be fully protected including under the GDPR. As explained below, certain personal data will be made accessible to our third-party
                IT contractor, based outside of the European Economic Area. Additional steps have therefore been taken to ensure that your personal data will be treated just as securely and safely as it would be in the UK and under the GDPR, as embodied
                in a data processing agreement between us and our contractor based on model contractual clauses provided by the European Commission, which impose suitable data protection standards on a contractual basis. </p>
            <h3>Do We Share Your Personal Data?</h3>
            <p>We share your personal data with selected third parties exclusively to provide services to you, only with the following parties for the following purposes:

            </p>
            <ul>
                <li>Awarding bodies for certification and awards for the qualified learners.</li>
                <li>Potential employers for employment opportunities.</li>
                <li>Service providers</li>
                <li>Associate and business transfers</li>
                <li>Internal marketing team to keep you updated with new offers, events and courses</li>
                <li>Partner universities</li>
            </ul>
            <p>To the extent necessary for the purpose of providing our service, the personal data which you give us during the registration process, and data relating to the courses, may be transferred to third parties within the EEA who we engage from
                time to time to provide us with any services or facilities which we use for the purpose of administering our business and providing our products and services to customers.</p>
            <p>We may also transfer any of that data to our third-party IT contractor outside the EEA which provides us with IT services facilitating our provision of products and services via our website and email communications to those who register as
                customers with us. Any such transfer will be subject to terms agreed with our IT contractor which comply with those prescribed for such a purpose by the European Commission, thereby ensuring that your personal data will be protected to
                standards equivalent to those specified by the GDPR.</p>

            <h3>What Are Your Rights As A Data Subject?</h3>
            <p>Individual data subjects have the following rights under the GDPR which we will always work to uphold: </p>

            <ul>
                <li>The right to be informed about our collection and use of your personal data (as described in this Privacy Policy).</li>
                <li>The right to access your personal data by means of a subject access request (see below). </li>
                <li>The right to have your personal data rectified if any of your personal data held by us is inaccurate or incomplete. You can do this either by contacting us. • The right to erasure (also known as the right to be forgotten). You can exercise
                    this right either by contacting us.</li>
                <li>The right to restrict or object to our processing of your personal data for particular purposes. Please contact us</li>
                <li>The right to data portability. This means that you can ask us for a copy of your personal data to re-use with another service or business. Please note, however, that this right applies only if you have provided personal data to us directly,
                    we are using it with your consent or for performance with a contract, and your data is processed using automated means. </li>
                <li> Rights relating to automated decision-making and profiling. We do not, however, use your personal data in this way. For more information about our use of your personal data or exercising your rights as outlined above, please contact us.</li>
            </ul>

            <p>Further information about your rights can be obtained from the Information Commissioner’s Office.You also have the right to lodge a complaint with the Information Commissioner’s Office if you feel that your rights have been breached.</p>
            <h3>How Can You Access Your Personal Data?</h3>
            <p>All personal data provided by you during registration, can be accessed via ‘email request. If you wish to make a data subject access request, please do so in writing, sent to the email or postal address shown below, clearly marking your correspondence
                as a subject access request. We do not normally charge for subject access requests unless they are ‘manifestly unfounded or excessive’ (e.g. repetitive). We will respond to your subject access request within one month of receiving it.
                In the unlikely event that your request is particularly complex, a further two months may be required but we will keep you informed if this is the case.</p>
            <h3>How To Contact Us?</h3>
            <p> To contact Edubex about anything to do with your personal data and data protection, including to make a subject access request, please use the following details and we will respond as soon as possible: Email: datacontroller@edubex.com Telephone:+44
                208 151 8188 ----------- Postal Address: ----- UK Office: 1 & 3 Kings Meadow Osney Mead Oxford OX2 0DP India Office: Drishya Building, Kinfra Film and Media Park Animation & Gaming SEZ, Near Sainik School PO Kazhakoottom, Thiruvananthapuram,
                Kerala
            </p>
            <h3>Changes to This Privacy Policy</h3>
            <p>We may change our Privacy Policy from time to time. This may be necessary, for example, if the law changes, or if we change our business in a way that affects personal data protection. Any new changes will be notified to you via email.</p>
        </div>
    </div>
</div>