import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CmsService } from 'src/app/core/services/cms/cms.service';
import { MasterService } from 'src/app/core/services/master/master.service';
 declare var bootbox: any;
@Component({
  selector: 'app-career-list',
  templateUrl: './career-list.component.html',
  styleUrls: ['./career-list.component.scss']
})
export class CareerListComponent implements OnInit {
  
  banners: any = [];
  _id: any;
  imageUrl: string;
  cover_letter: any='';
  resume:any;
  constructor(private masterService:MasterService, private cmsService:CmsService,
  	private titleService: Title,
    private metaService: Meta,
  private _activatedRoute: ActivatedRoute,) { }
  page :String ='career-list';
  details:any;
  model ={
    first_name:'',
    last_name:'',
    email :'',
    linkedin:'',
    phone:'',
    website:'',
    location:'',
    cv:'',
    cover:''
  };
  ngOnInit(): void {
    this._activatedRoute.params.subscribe(params => {
      console.log(params);
      
        this._id = params.id
        this.masterService.getCareerDetails({id:this._id}).subscribe(res=>{          
         this.details = res['data']
         console.log("details",this.details)
         this.titleService.setTitle(res.data.title); // <-- Update the title
  
         this.metaService.updateTag({ 
         name: 'description',
         content: res.data.meta_description
       });
       this.metaService.updateTag({ 
        name: 'title',
        content: res.data.meta_title
      });
        })
    })
  }
  onSubmit(form){
    console.log("form",form.value)
    if(form.valid && this.resume && this.cover_letter){
      let data = form.value;
      data.resume=this.resume;
      data.cover_letter = this.cover_letter
      this.cmsService.careerApply(data).subscribe(
      res => {
        console.log("res",res)
        var dialog = bootbox.dialog({
          message: res.message,
          closeButton: true
        });
        form.resetForm();
        this.cover_letter="";
        this.resume="";
        
      });
    }
   
  }
  linkedInCredentials = {
    clientId: "782k48us3mvzki",
    redirectUrl: "http://localhost:4200/events",
    scope:'r_liteprofile'
  };  
  login(){
    window.location.href = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${
      this.linkedInCredentials.clientId
    }&redirect_uri=${this.linkedInCredentials.redirectUrl}&state=987654321&scope=${this.linkedInCredentials.scope}`;
  }
  onCVSelect(event) {
    if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        this.resume = file;
        console.log("file",file)
        const reader = new FileReader();
        reader.onload = e => this.imageUrl = reader.result as string;
        reader.readAsDataURL(file);
    }
}
onCoverSelect(event){
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.cover_letter = file;
      console.log("file",file)
      const reader = new FileReader();
      reader.onload = e => this.imageUrl = reader.result as string;
      reader.readAsDataURL(file);
  }
}

}
