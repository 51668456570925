<div class="row " *ngIf="showModal1">   
    <div class="col-md-3 main">
    </div>
  </div>
  <div [style.display]="showModal1 ? 'block' : 'none'" class="modal apply-mod" id="imagemodal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg " style="margin: 0px auto;">
    <div class="modal-content" style="padding:0px">
      <div class="modal-body" style="padding:0px!important">
        <div class="close" (click)="hide()" style="top: 5px;z-index: 999;right: 0px;">
          <fa-icon style=" margin:5px;cursor:pointer;color: #000" [icon]="faTimesCircle"></fa-icon>
        </div>
        <div class="row">
          <div class="col-sm-12" style="padding:0px"> 
            <div class="card content">
              <div class="card-body">
                <div class="col-md-12 box" style="padding:15px 0px!important">  
                    <h5>
                        <i class="fas fa-piggy-bank"></i>Payment Options !
                    </h5>
                    <div class="col-flexpop"> 
                      <p>
                          <b>{{course?.title}}</b>
                      </p>
                      <p [innerHTML]="course?.payment_details"></p>
                    <!-- <table class="table" style="background:#fff;">
                      
                      <tbody>       
        
                        <tr >
                          <td> Payment Mode</td>
                          <td> {{course?.flexible_payment.payment_mode}}</td>
                       
                        </tr>
                        <tr>
                          <td>Course Fees</td>
                          <td> {{course?.currency}} {{course?.fees}}</td>
                       
                        </tr>
                        <tr *ngIf="course?.flexible_payment.payment_mode!=='Onetime'">
                          <td> Advance Amount</td>
                          <td> {{course?.currency}} {{advance}}</td>
                       
                        </tr>
                        <tr *ngIf="course?.flexible_payment.payment_mode!=='Onetime' && course?.payments.length">
                          <td>Installment Amount</td>
                          <td> {{course?.currency}} {{course?.payments[0].fee}}</td>
                       
                        </tr>
                        <tr *ngIf="course?.flexible_payment.payment_mode!=='Onetime' && course?.payments.length">
                          <td>No of Installments</td>
                          <td> {{course?.payments.length}}</td>
                       
                        </tr>
                      </tbody>
                    </table> -->
                  </div>
                </div> 
               <div *ngIf="course?.flexible_payment.payment_mode=='Onetime'" style="font-style: italic;">Note: No EMI available</div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
