<div>
    <app-popup [popups]="popups"> </app-popup>

    <div class="container-deliverys">
        <div class="row no-gutters" style="height: 100%;">
            <div class="col-8 col-exp-img">
                <img src="{{ blog?.banner_image }}" class="img-fluid" alt="">

                <!-- <img src={{longterm.poster}} style="" class="card-img" alt="..."> -->
            </div>
            <div class="col-4 col-exp-cnt">
                <div class="form-header font-demi">Start Your Upskilling Journey Now</div>

                <form class="form" #enquiryForm="ngForm" (ngSubmit)=" enquirySubmit(enquiryForm)" novalidate>
                    <div class="row">

                        <div class="col-md-12 ">
                            <input type="text" class=" text" [(ngModel)]="enquiry.full_name" name="full_name" #full_name="ngModel" placeholder="Name" required pattern="[a-zA-Z\s]*$" (change)="enquiry.full_name = enquiry.full_name.trim()">
                            <span class="text-danger" *ngIf="(full_name.touched || enquiryForm.submitted) && full_name.errors?.required">
                        Name is required
                        </span>
                            <span class="text-danger" *ngIf="(full_name.touched || enquiryForm.submitted) && full_name.errors?.pattern">
                        Only alphabets are allowed
                    </span>
                        </div>
                        <div class=" col-md-12 ">
                            <select [(ngModel)]="enquiry.course" class="text
                        " name="course" (change)=onCourseChange($event)>
                            <option selected disabled  value="" >Select Course </option>
                            <option  *ngFor="let course of all_courses" [value]="course.id" >{{course.title}}</option>
                        </select>
                            <span class="text-danger" *ngIf="!selectedCourse">
                            {{course_err}}
                        </span>
                        </div>
                        <div class=" col-md-12 ">
                            <input type="text" class=" text" [(ngModel)]="enquiry.email" name="email" #email="ngModel" placeholder="Email" required email (change)="enquiry.email = enquiry.email.trim()">
                            <span class="text-danger" *ngIf="(email.touched || enquiryForm.submitted) && !email_err && email.errors?.required">
                        Email is required
                        </span>
                            <span class="text-danger" *ngIf="email.touched && email.errors?.email">
                        Enter a valid email address
                        </span>
                            <span class="text-danger" *ngIf="email.touched && email_err">
                        The email has already been taken.
                        </span>
                        </div>
                        <div class=" col-md-12">
                            <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                [selectedCountryISO]="CountryISO.India" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National" name="phone" class="margin-bottom:20px;" #phone="ngModel" required
                                [(ngModel)]="enquiry.phone">
                            </ngx-intl-tel-input>
                            <span class="text-danger" *ngIf="(phone.touched || enquiryForm.submitted) && phone.errors?.required">
                            Phone is required
                        </span>
                        </div>

                    </div>
                    <div>

                        <div class="text-center">
                            <input type="submit" class="btn join font-demi" value="Submit">
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- <div class="float-left">
            <img src="{{ blog?.banner_image }}" style="width: 60%;" alt="">
        </div>
        <div class="float-right">

        </div>  -->
    </div>
</div>
<div class="container-deliverys" style="margin-bottom:20px">
    <div class="row">
        <div class="col-md-7">
            <div class="row" style="margin: 0px;">
                <h3 class="font-demi">{{blog?.title}}</h3>
            </div>
            <div class="row auth-rw blg-body" style="color: rgba(0, 0, 0, 0.53);">
                <div class="col-md-6">
                    <p class="auther">by:- {{blog?.author}}</p>
                </div>
                <div class="col-md-6">
                    <p class="date">{{blog?.date|date}}</p>
                </div>
            </div>
            <div class="row" style="margin:20px 0px 10px" [innerHTML]="blog?.description">
            </div>
        </div>
        <div class="col-md-5">
            <div class="side-box" *ngIf="courses">
                <h4 class="our-trending-courses font-demi">Our Trending Courses</h4>
                <div *ngFor="let course of courses|slice:0:4">
                    <div routerLink="/program-details/{{course.master_slug}}" class="course"> {{course.title}}
                        <div>Duration:{{course.duration}}</div>

                    </div>
                </div>
            </div>
            <div class="side-box">
                <h4 style="color: #223f31 !important;" class="learning-advisor font-demi"><b>Speak with a Learning Advisor</b></h4>
                <form class="form learning-advisor-form" #contactForm="ngForm" (ngSubmit)=" onSubmit(contactForm)" novalidate>
                    <p>Select a date and time and a Learning Advisor will be in touch.</p>
                    <div class="row">
                        <div class="col-md-12 ">
                            <input type="date" class="text name" [(ngModel)]="model.date" name="date" #date="ngModel" required>
                            <span class="text-danger" *ngIf="(date.touched || contactForm.submitted) && date.errors?.required">
                       Date is required
                        </span>
                        </div>
                        <div class=" col-md-12 ">
                            <select [(ngModel)]="model.time" class="text  name
                        " name="time" (change)=onTimeChange($event)>
                            <option selected disabled  value="" >Select Time </option>
                            <option  *ngFor="let time of allotted_time" [value]="time" >{{time}}</option>
                        </select>
                            <span class="text-danger" *ngIf="!selectedTime">
                            {{time_err}}
                        </span>
                        </div>
                        <div class=" col-md-12 ">
                            <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                [selectedCountryISO]="CountryISO.India" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National" name="phone" #phone="ngModel" required [(ngModel)]="model.phone">
                            </ngx-intl-tel-input>
                            <span class="text-danger" *ngIf="(phone.touched || contactForm.submitted) && phone.errors?.required">
                    Phone is required
                    </span>
                        </div>
                        <div class="col-md-12 ">
                            <input type="text" class=" text first-name" [(ngModel)]="model.first_name" name="first_name" #first_name="ngModel" placeholder="First Name" required pattern="[a-zA-Z\s]*$" (change)="model.first_name = model.first_name.trim()">
                            <span class="text-danger" *ngIf="(first_name.touched || contactForm.submitted) && first_name.errors?.required">
                    First Name is required
                    </span>
                            <span class="text-danger" *ngIf="(first_name.touched || contactForm.submitted) && first_name.errors?.pattern">
                    Only alphabets are allowed
                </span>
                        </div>
                        <div class=" col-md-12 ">
                            <input type="text" class=" text" [(ngModel)]="model.last_name" name="last_name" #last_name="ngModel" placeholder="Second Name" required pattern="[a-zA-Z\s]*$" (change)="model.last_name = model.last_name.trim()">
                            <span class="text-danger" *ngIf="(last_name.touched || contactForm.submitted) && last_name.errors?.required">
                    Second Name is required
                    </span>
                            <span class="text-danger" *ngIf="(last_name.touched || contactForm.submitted) && last_name.errors?.pattern">
                    Only alphabets are allowed
                </span>
                        </div>
                        <div class=" col-md-12 ">
                            <input type="text" class=" text" [(ngModel)]="model.email" name="email" #email="ngModel" placeholder="Email" required email (change)="model.email = model.email.trim()">
                            <span class="text-danger" *ngIf="(email.touched || contactForm.submitted) && !email_err && email.errors?.required">
                    Email is required
                    </span>
                            <span class="text-danger" *ngIf="email.touched && email.errors?.email">
                    Enter a valid email address
                    </span>
                            <span class="text-danger" *ngIf="email.touched && email_err">
                    The email has already been taken.
                    </span>
                        </div>
                    </div>

                    <div>
                        <p class="terms">By Clicking 'Submit' I confirm that i have reviewed and I agree to Edubex's terms
                        </p>
                        <div>
                            <input type="submit" class="btn join book-a-call font-demi" value="Book a Call">
                        </div>
                    </div>
                </form>
            </div>
            <div>
                <!-- <h3 class="gift font-demi" (click)="goToRefer()">
                    Gift USD 50 and get USD 50
                </h3> -->
                <div class="side-box text-center" (click)="goToRefer()">
                    <img [src]="gift?.image" style="width: auto;max-width:100%;cursor: pointer;">
                </div>
            </div>
        </div>
    </div>

</div>
<div class="container-delivery">
    <div class="row">
        <div class="col-6 s-full">
            <h3 class="share-content">
            Want to share this article?
            </h3>
        </div>
        <div class="col-6 s-full" style="font-size:35px;">
            <div class="social-shares">
                <span class="instagram" (click)="shareOnInstagram(baseUrl+'/'+router.url)">
                    <img src="assets/images/logo/instagram.png"  alt="">
                    <!-- <fa-icon [icon]="faInstagram"  (click)="shareOnInstagram(baseUrl+'/'+router.url)"></fa-icon> -->
                   </span>
                   <span  class="instagram" (click)="shareOnFacebook(baseUrl+'/'+router.url)">
                    <img src="assets/images/logo/fb.png" (click)="shareOnInstagram(baseUrl+'/'+router.url)" alt="">

                       <!-- <fa-icon [icon]="faFacebook"></fa-icon> -->
                      
                   </span>
                   <span class="instagram"(click)="shareOnTwitter(baseUrl+'/'+router.url)">
                    <img src="assets/images/logo/twitter.png"  alt="">
                    </span>           
                <span  class="instagram" (click)="shareOnLinkedin(baseUrl+'/'+router.url)" >
                    <img src="assets/images/logo/linkedin.png"  alt="">
                </span>
            </div>
         
           
        </div>     
    </div>
</div>
<div class="container-fluid caro-section" style="margin-bottom:30px">
    <div class="container">
        <h3 class="text-center learn">Related Articles</h3>
        <div class="underline"></div>
        <owl-carousel-o [options]="termOptions" class="customOwl">
            <ng-template carouselSlide *ngFor="let course of related_blogs;  ">
                <div class="col-md-12 float-left">
                    <div class="card pgm-card">
                        <div class="card-image">
                            <img src="{{course.image}}" class="card-img-top" alt="{{course.image_alt}}">
                        </div>
                        <div class="card-body ">
                            <div class="blog-card-title font-demi"><span routerLink="/blog/{{course.slug}}"><b>{{course.name}}</b></span></div>
                            <div>
                                <p class="card-text terms-text" [innerHTML]="course.description"></p>
                            </div>
                            <div class="float-right" style=" width: 100%; margin: 0px -1%; float: none!important;display: flex; ">
                                <a class="blog-view-more font-demi" routerLink="/blog/{{course.slug}}">Know More</a>

                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>
<div class="container-fluid caro-section" style="margin-bottom:30px">
    <div class="container">
        <h3 class="text-center learn">Explore Our Free Courses</h3>
        <div class="underline"></div>
        <owl-carousel-o [options]="termOptions" class="customOwl">
            <ng-template carouselSlide *ngFor="let course of all;  ">
                <div class="col-md-12 float-left">
                    <div class="card pgm-card free-course-card" style="box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px; border-radius: 15px !important; overflow: hidden;">
                        <div class="card-image free-course-card-card">
                            <img src="{{course.image}}" class="card-img-top" alt="{{course.poster_alt}}">
                        </div>
                        <div class="card-body ">
                            <div class="blog-card-title font-demi"><span routerLink="/scromcontent//{{course.slug}}">{{course.title}}</span></div>
                            <div>
                                <p class="card-text terms-text" [innerHTML]="course.description"></p>
                            </div>
                            <div class="float-right" style=" width: 100%; margin: 0px -1%; float: none!important;display: flex; ">
                                <a class="blog-view-more font-demi" routerLink="/scromcontent/{{course.slug}}">Know More</a>

                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>


<div class="container-delivery">
    <div class="col-fixed">
        <div class="bottom">
            <app-bottom-bar></app-bottom-bar>
        </div>
    </div>
</div>
<div class="how-colpad apply" style="padding-bottom:0px">
    <div class="col-md-12 container-delivery">
        <div class="row discuss-section" style="margin:0px">
            <div class="col-lg-6 col-md-12" style="margin-top: 25px;">
                <p class="discuss" style="margin-left:0px">Discuss how we can help. </p>
            </div>
            <div class="col-lg-1 col-md-1"></div>
            <div class="col-lg-5 col-md-12 ">
                <div class="signup">
                    <div class="message" id="successMessage" style="color:green">{{message}}</div>
                    <div class="card-body">
                        <h6>
                            <b>  Get in Touch</b>
                        </h6>
                        <app-join-network [page]="'blog-details'" (message)="messageHandler($event)"></app-join-network>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="how-colpad apply" style="padding-bottom:0px;background: #34495e;">
    <div class="col-md-12 container-delivery">
        <div class="change" style="margin-top:0px">
            <app-find-career></app-find-career>
        </div>
    </div>
</div>
