<app-banner [page]="'gdpr'"></app-banner>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <h3>BACKGROUND</h3>
            <p>Edubex understands that your privacy is important to you and that you care about how your personal data is used. We respect and value the privacy of all of our clients, learners, students and staffs and will only collect and use personal data
                in ways that are described here, and in a way that is consistent with our obligations and your rights under the law.</p>
            <ol>
                <li>
                    <b>Information About Us</b>
                    <p>Edubex registered in : India</p>
                    <p>Registered address : Owle Interactive Media Ltd</p>
                    <p> Data Protection Officer : Sibi Bhaskaran</p>
                    <p> Email address : datacontroller@edubex.com</p>
                    <p>Telephone number : +44 208 151 8188</p>
                    <p>
                        Postal Address : UK Office: 1 & 3 Kings Meadow Osney Mead Oxford OX20DP India Office: Drishya Building, Kinfra Film and Media Park Animation & Gaming SEZ, Near Sainik School PO Kazhakoottom, Thiruvananthapuram, Kerala

                    </p>
                </li>
                <li><b>What Does This Notice Cover?</b>


                    <p> This Privacy Information explains how we use your personal data: how it is collected, how it is held, and how it is processed. It also explains your rights under the law relating to your personal data.
                    </p>
                </li>
                <li>
                    <b>What is Personal Data?</b>
                    <p>Personal data is defined by the General Data Protection Regulation (EU Regulation 2016/679) (the “GDPR”) as „any information relating to an identifiable person who can be directly or indirectly identified in particular by reference
                        to an identifier‟. Personal data is, in simpler terms, any information about you that enables you to be identified. Personal data covers obvious information such as your name and contact details, but it also covers less obvious
                        information such as identification numbers, electronic location data, and other online identifiers. The personal data that we use is set out in Part 5, below.
                    </p>
                </li>
                <li>
                    <b>What Are My Rights?</b>
                    <p>Under the GDPR, you have the following rights, which we will always work to uphold:</p>
                    <ul>
                        <li>The right to be informed about our collection and use of your personal data. This Privacy Notice should tell you everything you need to know, but you can always contact us to find out more or to ask any questions using the details
                            in Part 11.</li>
                        <li>The right to access the personal data we hold about you. Part 10 will tell you how to do this.</li>
                        <li>The right to have your personal data rectified if any of your personal data held by us is inaccurate or incomplete. Please contact us using the details in Part 11 to find out more.</li>
                        <li>The right to be forgotten, i.e. the right to ask us to delete or otherwise dispose of any of your personal data that we have. Please contact us using the details in Part 11 to find out more.</li>
                        <li>The right to restrict (i.e. prevent) the processing of your personal data.</li>
                        <li>The right to object to us using your personal data for a particular purpose or purposes.</li>
                        <li>The right to data portability. This means that you can ask us for a copy of your personal data held by us to re-use with another service or business in many cases.</li>
                        <li>Rights relating to automated decision-making and profiling. we use your personal data for profiling purpose which solely for the legal and contractual purpose.</li>
                    </ul>
                    <p>For more information about our use of your personal data or exercising your rights as outlined above, please contact us using the details provided in Part 11.</p>
                    <p>Further information about your rights can also be obtained from the Information Commissioner‟s Office or your local Citizens Advice Bureau.</p>
                    <p>If you have any cause for complaint about our use of your personal data, you have the right to lodge a complaint with the Information Commissioner‟s Office.</p>
                </li>
                <li><b>What Personal Data Do You Collect?</b>
                    <p>We may collect some or all the following personal data (this may vary according to your relationship with us:</p>
                    <ul>
                        <li>[Name;]</li>
                        <li>[Date of birth;]</li>
                        <li>[Gender;]</li>
                        <li>[Address;]</li>
                        <li>[Email address;]</li>
                        <li>[Telephone number;]</li>
                        <li>[Business name;]

                        </li>
                        <li>[Job title;]</li>
                        <li>[Profession;]</li>
                        <li>[Information about your preferences and interests;]</li>
                        <li>[Sensitive/special category data such as ethnic data, disability etc]</li>
                    </ul>
                </li>
                <li><b>How Do You Use My Personal Data?</b>
                    <p>We use your personal data for the following purposes:</p>
                    <ul>
                        <li>Providing and managing your account and/or Registration(s).</li>
                        <li>To provide you with information about services we consider similar to those that you are already using, or have enquired about, or may interest you such as promotional offers. If you are a registered user, we will contact you by
                            electronic means (e-mail or push notifications or SMS or telephone) with information about these services; You can control your email preferences by contacting our datacontroller@edubex.com to opt out of our newsletters and
                            bulletins when you register (or at any time thereafter).</li>
                        <li>Please note that some emails are an integral part of the Edubex service you sign up for when registering with us and cannot be opted out of and that by registering, you are consenting to us using your personal data to send such
                            emails.
                        </li>
                        <li>To administer and enhance the security of our Edubex platform and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</li>
                        <li>Communicating with you. This may include responding to emails or calls from you.</li>
                        <li>Building up a profile of your interests and preferences based on your download history.</li>
                        <li>To remind you of completing payments to obtain course access and remind you about class timings or class reschedule or class cancellations if any</li>
                    </ul>
                    <p>Our processing of your personal data is in our legitimate interests and is necessary to provide the Edubex to you and to continually improve it and the content available through it. </p>
                    <p>Our use of your personal data is limited to that which is reasonably required in order to provide our service to you and to make improvements to that service which benefit both us and you. We do not use your personal data for any reasons
                        not stated in this Privacy Policy and never use it in an excessive manner that is disproportionate to our aims of providing and improving our service or in a way that is contrary to your interests.</p>
                    <p>In addition, when you register with us, your personal data is necessary for the formation of the contract between you and Edubex Ltd and the completion of your registration.</p>
                </li>
                <li><b>How Long Will You Keep My Personal Data?</b>
                    <p>We keep your Personal Data for no longer than necessary for the purposes for which the Personal Data is collected and processed. The length of time we retain Personal Data for depends on the purposes for which we collect and use it
                        and/or as required to comply with applicable laws and to establish, exercise, or defend our legal rights. If you wish to delete your account, you can do so by mailing to datacontroller@edubex.com</p>
                </li>

                <li><b>How and Where Do You Store or Transfer My Personal Data?</b>

                    <p>We only store your personal data within the countries mentioned above in the Privacy Policy. This means that it will be fully protected including under the GDPR. The security of your personal data is essential to us, and to protect
                        your data, we take a number of important measures, including the following:</p>
                    <ul>
                        <li>Administrative managed Login in sharepoint.

                        </li>
                    </ul>
                </li>
                <li><b>Do You Share My Personal Data?</b>
                    <p>We share your personal data with selected third parties exclusively to provide services to you, only with the following parties for the following purposes:</p>
                    <ul>
                        <li>Awarding bodies for certification and awards for the qualified learners</li>
                        <li>Associate and business transfers</li>
                        <li>Potential employers for employment opportunities</li>
                        <li>Service providers</li>
                        <li>Internal marketing team to keep you updated with new offers, events and courses.</li>
                        <li>Partner universities</li>
                    </ul>
                    <p>To the extent necessary for the purpose of providing our service, the personal data which you give us during the registration process, and data relating to the courses, may be transferred to third parties within the EEA who we engage
                        from time to time to provide us with any services or facilities which we use for the purpose of administering our business and providing our products and services to customers. </p>
                    <p>We may also transfer any of that data to our third-party IT contractor outside which provides us with IT services facilitating our provision of products and services via our website and email communications to those who register as
                        customers with us. Any such transfer will be subject to terms agreed with our IT contractor which comply with those prescribed for such a purpose by the European Commission, thereby ensuring that your personal data will be protected
                        to standards equivalent to those specified by the GDPR.</p>

                </li>
                <li><b>How Do You Use My Personal Data?</b>
                    <p>All personal data provided by you during registration, can be accessed via ‘email request. If you wish to make a data subject access request, please do so in writing, sent to the email or postal address shown below, clearly marking
                        your correspondence as a subject access request.</p>
                    <p>We do not normally charge for subject access requests unless they are ‘manifestly unfounded or excessive’ (e.g., repetitive). We will respond to your subject access request within one month of receiving it. In the unlikely event that
                        your request is particularly complex, a further two months may be required but we will keep you informed if this is the case. </p>
                </li>
                <li><b>How Do I Contact You?</b>
                    <p>To contact Edubex about anything to do with your personal data and data protection, including to make a subject access request, please use the following details and we will respond as soon as possible: </p>
                    <p>Email: datacontroller@edubex.com


                    </p>
                    <p>Telephone: +44 208 151 8188</p>
                    <p> Postal Address: UK Office: 1 & 3 Kings Meadow Osney Mead Oxford OX2 0DP India Office: Drishya Building, Kinfra Film and Media Park Animation & Gaming SEZ, Near Sainik School PO Kazhakoottom, Thiruvananthapuram, Kerala
                    </p>
                </li>
                <li><b>Changes to this Privacy Notice</b>
                    <p>We may change our Privacy Policy from time to time. This may be necessary, for example, if the law changes, or if we change our business in a way that affects personal data protection. Any new changes will be notified to you via email.</p>
                </li>

            </ol>
        </div>
    </div>
</div>