import { Component, Input,Output, OnInit, EventEmitter } from '@angular/core';
import { CmsService } from 'src/app/core/services/cms/cms.service';
declare var bootbox: any;

@Component({
  selector: 'app-join-network',
  templateUrl: './join-network.component.html',
  styleUrls: ['./join-network.component.scss']
})
export class JoinNetworkComponent implements OnInit {
  @Input('page') page: string;
  @Output() message: EventEmitter<number> =   new EventEmitter();
  model ={
    name:'',
    last_name:'',
    company:'',
    email :''
  };
  email_err=''
  constructor(private cmsService:CmsService) { }
  
  ngOnInit(): void {
    
  }
  
  onSubmit(form,page){
    console.log("page",page,form.value)
    if(page==='hiring-partner'){
      let data={
        first_name:form.value.name , 
        last_name: form.value.last_name, 
        company:  form.value.company, 
        email: form.value.email,
      }
      this.email_err ="";
      this.cmsService.addSponserContact(data).subscribe(
        res => {
          console.log("res",res)
          this.message.emit(res.message);
          form.resetForm();
        },err=>{
          if (err.status === 422) {
            if(err.error.errors.email )
            this.email_err = err.error.errors.email[0];
          }
        });
    }else{
      this.cmsService.addContact(form.value).subscribe(
        res => {
          console.log("res",res)
          this.message.emit(res.message);
          form.resetForm();         
      });
    }
  }
}
