import { Component, OnInit } from '@angular/core';
import { CmsService } from 'src/app/core/services/cms/cms.service';
import { MasterService } from 'src/app/core/services/master/master.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

declare var bootbox;
@Component({
  selector: 'app-scholarship',
  templateUrl: './scholarship.component.html',
  styleUrls: ['./scholarship.component.scss']
})
export class ScholarshipComponent implements OnInit {
 list :any;
 model ={
  first_name:'',
  last_name:'',
  email :'',
  phone:'', 
  message:''
};

separateDialCode = false;
SearchCountryField = SearchCountryField;
CountryISO = CountryISO;
PhoneNumberFormat = PhoneNumberFormat;
preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
 
onSubmit(form){
  console.log("select-->",form.value);
  
  if(form.valid ){
    let data = form.value;
    data.phone = form.value.phone?.e164Number;
    this.cmsService.scholarshipApply(data).subscribe(
      res => {
        console.log("res",res)  
        var dialog = bootbox.dialog({
          message: "Application Submitted!",
          closeButton: true
        });
        
        form.resetForm(); 
             
      });
  }
}
  constructor(private masterService:MasterService,private cmsService:CmsService) { }

  ngOnInit(): void {
    this.masterService.getScholarshipAll().subscribe(res=>{    
      this.list =res.scholarships[0]; 
      console.log("details",res);
    }) 
  }

}
