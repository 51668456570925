<div class="home" #top>

    <app-banner [page]="page"></app-banner>
    <!-- <div class="banner-item">
        <div class="news-bar">
             <marquee>
                <div  *ngFor="let notification of news"  > <p [innerHTML]="notification.title"></p></div>	
            </marquee> 
        </div>
    </div> -->
    <div class="container-fluid p-0">
        <div class="list-crs">
            <div class="row">
                <div class="col-md-12">
                    <app-popup [popups]="popups"> </app-popup>

                    <!-- <app-register #register></app-register> -->
                    <app-course-apply #apply (childEvent)="reload($event)"></app-course-apply>
                    <!-- <div id="calendar" ></div> -->
                    <h3 class="text-center" style="margin-top: 50px;color: #374D63;">Upcoming Courses</h3>
                    <div class="underline"></div>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-md-1"></div> -->
                <div class="col-md-12 course-list p-0">
                    <!-- <div class=" time">(UTC+01:00) Windhoek</div> -->
                    <ngb-tabset>
                        <ngb-tab *ngFor="let course of long | keyvalue">
                            <ng-template ngbTabTitle>
                                <span class="tab-title" (click)="termSelect('short')">
                                    {{course.key}}
                                </span>
                            </ng-template>
                            <ng-template ngbTabContent>
                                <div>
                                    <owl-carousel-o [options]="shorttermOptions">
                                        <ng-template carouselSlide *ngFor="let coursedet of course.value">
                                            <div class="float-left">
                                                <div class="card course-card">
                                                    <div class="card-image">
                                                        <!-- <ik-image [path]="short_term.poster" class="card-img-top" alt="upcoming-course-01.jpg" ></ik-image>               -->

                                                        <img src={{coursedet.poster}} alt="{{coursedet.poster_alt}}" class="course-card_img">
                                                    </div>
                                                </div>
                                                <div class="card-sec">
                                                    
                                                    <div class="card-body term-body">
                                                        <div class="card-date">
                                                            <div style="text-align: end;">
                                                                <span class="badge badge-danger bdg-col" *ngIf="coursedet.label==='New'" style="width:auto;">NEW</span>
                                                                <span class="badge badge-warning bdg-col" *ngIf="coursedet.label==='Trending'">TRENDING</span>
                                                            </div>
                                                            <div> <fa-icon style="color: #212121 ; margin-right:10px;" [icon]="faCalendarAlt"></fa-icon>{{ coursedet.start_date| date:'dd MMM yyy' }} - <fa-icon style="color: #212121 ; margin-right:10px;" [icon]="faCalendarAlt"></fa-icon> {{coursedet.end_date | date:'dd MMM yyy'}}</div>
                                                           <div>  <fa-icon style=" margin-right:10px;color: #212121" [icon]="faClock">
                                                        </fa-icon>{{coursedet.duration}}</div>
                                                            <div *ngIf="coursedet.is_online==='1'" style="font-size: 14px;color: #005832;font-weight: 600;">LIVE ONLINE</div>
                                                            <div *ngIf="coursedet.is_online==='2'" style="font-size: 14px;color: #005832;font-weight: 600;">ONLINE</div>
                                                        </div>
                                                        <h5 class="card-title">
                                                            <div routerLink="/program-details/{{coursedet.master_slug}}" class="font-demi">{{coursedet.title}}</div>
                                                        </h5>
                                                        <div class="col-pheight">
                                                            <div class="card-text term-text">{{coursedet.overview}}</div>
                                                        </div>
                                                        <div style="display: block;">
                                                            <div style="display: inline-block;float: left;">
                                                                <p style="font-size:17px;margin-bottom: 5px;">
                                                                    {{coursedet.currency}}
                                                                    <!-- <fa-icon style="color: #000" [icon]="faRupeeSign"></fa-icon> -->
                                                                    {{coursedet.price}}
                                                                </p>
                                                            </div>
                                                            <!-- <div style="width: 50px;float: right;height: 50px;" *ngIf="coursedet.university_data">
                                                                <img [src]="coursedet.university_data?.logo" style="width: 100%;height:100%;object-fit:contain;float: right;" alt="{{coursedet.university_data?.logo_alt}}">

                                                            </div> -->
                                                        </div>
                                                        <div class="float-right justify-content-end" style=" width: 100%; float: none!important;display: flex; ">
                                                            <!-- <app-register [page]="home"></app-register> -->
                                                            <a *ngIf="userService.isLoggedIn()&& (coursedet.documents.length||coursedet.formfields.length)  && !coursedet.is_applied" (click)="apply.show(coursedet.documents,coursedet.formfields,coursedet.id)" class="btn start">Apply</a>
                                                            <a class="btn start " *ngIf="userService.isLoggedIn() && !coursedet.documents.length && !coursedet.formfields.length && !coursedet.is_applied" (click)="goToCheckout(coursedet)">Apply</a>

                                                            <a class="btn start font-demi" disabled *ngIf="userService.isLoggedIn()  && coursedet.is_applied"> Applied</a>

                                                            <a (click)="openModal(coursedet.master_slug)" *ngIf="!userService.isLoggedIn()" class="btn start font-demi">Register</a>
                                                            <a routerLink="/program-details/{{coursedet.master_slug}}" class="btn more font-demi">View More</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </owl-carousel-o>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <!-- <ngb-tab>
                            <ng-template ngbTabTitle><b class="btm-side"></b><span class="tab-title" (click)="termSelect('long')">
                                Long Term</span></ng-template>
                            <ng-template ngbTabContent>
                                <div>
                                    <owl-carousel-o [options]="termOptions">
                                        <ng-template carouselSlide *ngFor="let longterm of scheduled_courses">
                                            <div class="float-left">
                                                <div class="card">
                                                    <div class="card-image">
                                                        <img src={{longterm.poster}} class="card-img-top" alt="{{longterm.poster_alt}}">
                                                    </div>
                                                </div>
                                                <div class="card-sec">
                                                    <div class="card-date">
                                                        <div style="text-align: end;">
                                                            <span class="badge badge-danger bdg-col" *ngIf="longterm.label==='New'" style="width:auto;">NEW</span>
                                                            <span class="badge badge-warning bdg-col" *ngIf="longterm.label==='Trending'" style="width: auto;background-color: rgb(207 230 222);color: #095e37;padding: 5px;">TRENDING</span>
                                                        </div>
                                                        <div>{{ longterm.start_date| date:'dd MMM yyy' }} - {{longterm.end_date | date:'dd MMM yyy'}}</div>
                                                        <div>{{longterm.duration}}</div>
                                                        <div *ngIf="longterm.is_online==='1'">LIVE ONLINE</div>
                                                        <div *ngIf="longterm.is_online==='2'">ONLINE</div>
                                                    </div>
                                                    <div class="card-body term-body">
                                                        <h5 class="card-title">
                                                            <div routerLink="/program-details/{{longterm.slug}}">{{longterm.title}}</div>
                                                        </h5>
                                                        <div class="col-pheight">
                                                            <div class="card-text term-text" style="color:#000">{{longterm.overview}}</div>
                                                        </div>
                                                        <div style="display: block;">
                                                            <div style="display: inline-block;float: left;">
                                                                <p style="font-weight:900;height:35px;margin:0px">
                                                                    {{longterm.currency}}
                                                                    {{longterm.price}}
                                                                </p>
                                                            </div>
                                                            <div style="width: 50px;float: right;height: 50px;" *ngIf="longterm.university_data">
                                                                <img [src]="longterm.university_data?.logo" alt="{{longterm.university_data.logo_alt}}" style="width: 100%;height:100%;object-fit:contain;float: right;">
                                                            </div>
                                                        </div>
                                                        <div class="float-right" style=" width: 100%; margin: 0px -1%; float: none!important;display: flex; ">
                                                            <a *ngIf="userService.isLoggedIn()&& (longterm.documents.length||longterm.formfields.length)  && !longterm.is_applied" (click)="apply.show(longterm.documents,longterm.formfields,longterm.id)" class="btn start">Apply</a>
                                                            <a class="btn start " *ngIf="userService.isLoggedIn() && !longterm.documents.length && !longterm.formfields.length && !longterm.is_applied" (click)="goToCheckout(longterm)">Apply</a>
                                                            <a class="btn start " disabled *ngIf="userService.isLoggedIn()  && longterm.is_applied"> Applied</a>
                                                            <a (click)="openModal(longterm.slug)" *ngIf="!userService.isLoggedIn()" class="btn start">Register</a>
                                                            <a routerLink="/program-details/{{longterm.slug}}" class="btn more">View More</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </owl-carousel-o>
                                </div>
                            </ng-template>
                        </ngb-tab> -->
                    </ngb-tabset>
                </div>
            </div>
            <div class="row" style="text-align: center;">
                <div class="col-md-12">
                    <a class="btn courses font-demi" routerLink="/schedule" *ngIf="term">View all Courses and Events</a>
                    <a class="btn courses font-demi" routerLink="/schedule" *ngIf="!term">View all Courses and Events</a>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid delivery core-offer p-0">
        <div class="row">
            <div class="col-md-12" style="margin-bottom: 20px;">
                <h3 class="text-center" style="color: #374D63;">Our Core Offerings</h3>
                <div class="underline"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-6 col-del full-width">
                <div class="card text-white" style="background-color: #095e37;">
                    <div class="card-img-overlay" style="position: relative;z-index: 1;">
                        <h4 class="card-titflex font-demi" style="margin-bottom:15px">Market Development and Growth</h4>
                        <div [innerHTML]="liveOnline?.description"></div>

                        <!-- <p class="card-text text-white">Live and interactive online sessions run over a webinar. Everyone on the session can talk to each other, share their screens, and if they have a webcam, share their video</p> -->

                    </div>
                    <img src="../../../../assets/images/bg-ar.png" class="arr-bg">
                    <a class="btn float-right">
                        <img src="../../../../assets/images/core-arrow.png" class="core-arrow">
                    </a>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 col-del full-width">
                <div class="card text-white" style="background-color: #095e37;">
                    <div class="card-img-overlay" style="position: relative;z-index: 1;">
                        <h4 class="card-titflex font-demi" style="margin-bottom:15px">Program Development and Delivery</h4>
                        <div [innerHTML]="privateLearning?.description"></div>
                        <!-- <p class="card-text text-white">Courses run for a specific group of people which have been customized to meet their learning outcomes
                        </p> -->
                    </div>
                    <img src="../../../../assets/images/bg-ar.png" class="arr-bg">
                    <a class="btn float-right">
                        <img src="../../../../assets/images/core-arrow.png" class="core-arrow">
                    </a>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 col-del full-width">
                <div class="card text-white" style="background-color: #095e37;">
                    <div class="card-img-overlay" style="position: relative;z-index: 1;">
                        <h4 class="card-titflex font-demi" style="margin-bottom:15px">Student Support and Success</h4>
                        <div [innerHTML]="eLearning?.description"></div>
                        <!-- <p class="card-text text-white">Self placed modules that are completed online, at the learner's own pace </p> -->
                    </div>
                    <img src="../../../../assets/images/bg-ar.png" class="arr-bg">
                    <a class="btn float-right">
                        <img src="../../../../assets/images/core-arrow.png" class="core-arrow">
                    </a>
                </div>
            </div>
        </div>
        <div class="row programs-online">
            <div class="col-12 float-right set-border full-width" >
                <h4 class="font-demi text-right m-0" style="color:#374D63;">
                    {{online_program?.title}}
                </h4>
                <div class="content center-cn-content text-justify" style="color: #374D63;" [innerHTML]="online_program?.description"></div>
            </div>
        </div>
    </div>
    <div class="container-fluid online-program p-0">
        <div class="exp-fluid">
            <div class="col-md-12" style="margin-top: 15px;">
                <h3 class="text-center" style="color: #374D63;">Explore Our Courses</h3>
                <div class="underline explore-line"></div>
            </div>
            <div class="row none767">
                <div class="col-md-6 float-left padd-767" style="margin-bottom:30px">
                    <div class=" course div-height" style="position: relative; display: flex;border-radius: 0%;">
                        <div class="card-body data">
                            <div class="row" *ngFor="let longterm of categories| slice:0:4;let i= index " [ngClass]="{'active': selectedIdx == i}">
                                <div class="col-md-12 col-sm-12 card-tab">
                                    <h5 class="card-title course-title"   style="cursor: pointer;padding-top: 10px; color: #374D63;" (click)="getCourse(longterm,longterm.slug,i)">
                                        <!-- <i class="fa {{longterm.icon}}"></i> -->
                                     <img   [src]="longterm.icon"   class="left-img">
                                        <!-- <fa-icon style="color: #000 ; margin:2px;" [icon]="faCalendarAlt"></fa-icon> -->
                                        <!-- <fa-icon style=" margin:10px;" [icon]="checkIcon(longterm.icon)"
                                            [ngClass]="{'active': selectedIdx == i,'inactive':selectedIdx !=i}"></fa-icon> -->
                                        {{longterm.title}}
                                        <img src="../../../../assets/images/ar-normal.png" class="ar-normal right-arrow">
                                        <img src="../../../../assets/images/ar-hover.png" class="ar-hover right-arrow">
                                    </h5>
                                </div>
                                <div class="col-md-2 float-right d-none" (click)="getCourse(longterm,longterm.slug,i)" >
                                    <!-- <span style="font-size: xx-large;cursor: pointer;" [ngClass]="{'active': selectedIdx == i,'inactive':selectedIdx !=i}">></span> -->
                                    <!-- <img style="cursor: pointer;height:15px"  src="../../../../assets/images/arrow.svg"  [ngClass]="{'active': selectedIdx == i,'inactive':selectedIdx !=i}" alt="">                                -->
                                    <!-- <i _ngcontent-vkk-c145="" class="fa fa-arrow-right"></i> -->
                                    <img src="../../../../assets/images/arrow-crse.png" class="arr-crse">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 float-left" style="margin-bottom:30px" *ngFor="let longterm of courses| slice:0:3">
                    <div class="card explore-course_card div-height" style="max-width: 100%; box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.32);border-radius: 10px!important;">
                        <div class="row no-gutters" style="height:100%">
                            
                            <div class="col-12 col-exp-cnt course-padding">
                                <div class="div-height width-cn" style="background: url({{longterm.poster}});background-size: cover; background-position: center; background-repeat: no-repeat;border-radius: 10px 10px 10px 10px;">

                                    <div class="crs-tite">
                                        <div>{{ longterm.category}} </div>
                                    </div>
                                    <!--<img src={{longterm.poster}} style="" class="card-img" alt="...">-->
                                </div>
                                <div class="card-body">
                                    <div style="text-align: end;">
                                        <span class="badge badge-danger bdg-col" *ngIf="longterm.label==='New'" style="width:auto;margin-bottom: 20px;">NEW</span>
                                        <span class="badge badge-warning bdg-col" *ngIf="longterm.label==='Trending'">TRENDING</span> </div>
                                    <div class="card-date1">
                                        <div>{{longterm.duration}}
                                            <fa-icon style=" margin-left:10px;color: #212121" [icon]="faClock">
                                            </fa-icon>
                                        </div>
                                        <div>Start Date: <b>{{ longterm.start_date| date:'MMMM dd, yyy' }}</b>
                                            <fa-icon style="color: #212121 ; margin-left:10px;" [icon]="faCalendarAlt"></fa-icon>
                                        </div>
                                    </div>
                                    <h5 class="card-title">
                                        <div routerLink="/program-details/{{longterm.master_slug}}" class="font-demi">{{longterm.title}}</div>
                                    </h5>
                                    <div class="col-pheight">
                                    <!-- <p class="card-text terms-text">{{longterm.university_data?.university_name}}</p> -->

                                        <p class="card-text terms-text" style="color:#414141;">{{longterm.overview}}</p>
                                    </div>
                                    <div *ngIf="longterm.university_data">
                                        <img [src]="longterm.university_data?.logo" alt="{{longterm.university_data?.logo_alt}}" style="max-width:50px; width: 100%;max-height:45px;object-fit:contain;float: left;">
                                    </div>
                                    <a routerLink="/program-details/{{longterm.master_slug}}" class="know-more float-right" >View More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ngb-accordion class="dis767 card-collrn" style="display:none" #myaccordion="ngbAccordion" closeOthers="true" type="'success'" (panelChange)="beforeChange($event)">
                <ngb-panel *ngFor="let det of  exp | keyvalue" class="explore-cat" title="{{det.key}}" id="{{det.key}}">
                    <ng-template ngbPanelContent>
                        <owl-carousel-o [options]="exploreOptions" class="">
                            <ng-template carouselSlide *ngFor="let longterm of det.value ">
                                <div class="card">
                                    <div class="row no-gutters" style="height:100%">
                                        <div class="col-sm-6" style="padding:0px;">
                                            <div class="col-exp-img exp-img" style="background: url({{longterm.poster}});">
                                                <div class="crs-tite">
                                                    <div>{{ longterm.category}} </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-exp-cnt">
                                            <div class="card-body">
                                                <div style="text-align: end;height: 35px;">
                                                    <span class="badge badge-danger bdg-col" *ngIf="longterm.label==='New'" style="width:auto;margin-bottom: 20px;">NEW</span>
                                                    <span class="badge badge-warning bdg-col" *ngIf="longterm.label==='Trending'">TRENDING</span>
                                                </div>
                                                <div class="card-date1">
                                                    <div>{{longterm.duration}}
                                                        <fa-icon style=" margin-left:10px;color: #212121" [icon]="faClock">
                                                        </fa-icon>
                                                    </div>
                                                    <div>Batch Start Date: <b>{{ longterm.start_date| date:'MMM dd, yyy' }} </b>
                                                        <fa-icon style="color: #212121 ; margin-left:10px;" [icon]="faCalendarAlt"></fa-icon>
                                                    </div>
                                                </div>
                                                <h5 class="card-title">
                                                    <div routerLink="/program-details/{{longterm.master_slug}}" class="font-demi">{{longterm.title}}</div>
                                                </h5>
                                                <div class="col-pheight">
                                                    <p class="card-text terms-text" style="color: #414141;">{{longterm.overview}}</p>
                                                </div>
                                                <div style="width: 50px;float: left;height: 30px;" *ngIf="longterm.university_data">
                                                    <img alt="{{longterm.university_data?.logo_alt}}" [src]="longterm.university_data?.logo" style="width: 100%;height:100%;object-fit:contain;float: left;">
                                                </div>
                                                <a routerLink="/program-details/{{longterm.master_slug}}" class="know-more float-right" style="">View More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <div class="row">
                <div class="col-md-12">
                    <div class="col-6 float-left">
                        <a class="btn view float-right" *ngIf="courses?.length && category=='working'" routerLink="/programs">View Catalogue</a>

                        <a class="btn view float-right" *ngIf="courses?.length && category!=='working'" routerLink="/programs">View Catalogue</a>
                        <a class="btn view float-right" *ngIf="!courses?.length" routerLink="/programs">View Catalogue</a>
                    </div>
                    <div class="col-6 float-left">
                        <a class="btn view float-left" routerLink="/schedule">View Schedule</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="container-fluid p-0 avail-main-contr">
        <h3 class="text-center" style="margin-top: 50px;color: #374D63;">Avail a career transition with our exceptional bootcamps</h3>
        <div class="underline"></div>
        <div class="row">
            <div class="col-3 avail-main" *ngFor="let bootcamp of bootcamps">
                <div class="avail-list" >
                    <img [src]="bootcamp?.icon" alt="bootcamp?.icon_alt">
                    <h1 style="color: #374D63;"><span>{{bootcamp?.title}}</span></h1>
                </div>
            </div>
            <!-- <div class="col-3 avail-main">
                <div class="avail-list">
                    <img src="../../../../assets/images/Skill_Icon_01.gif" alt="">
                    <h1>Learn new skills in a short time</h1>
                </div>
            </div>
            <div class="col-3 avail-main">
                <div class="avail-list">
                    <img src="../../../../assets/images/Bound_Icon_01.gif" alt="">
                    <h1>Convenience beyond boundaries </h1>
                </div>
            </div>
            <div class="col-3 avail-main">
                <div class="avail-list">
                    <img src="../../../../assets/images/World_Icon_01.gif" alt="">
                    <h1>Prepare you for the real world</h1>
                </div>
            </div> -->
        </div>
        <a routerLink="/bootcamps" class="explore-c">Explore Bootcamp <i aria-hidden="true" class="fa fa-angle-right"></i></a>
    </div>
    <div class="container-fluid caro-section p-0" style="margin-bottom:30px">
        <div class="exp-fluid">
            <h3 class="text-center learn" style="color: #374D63;">Explore Our Free Courses</h3>
            <div class="underline"></div>
            <div class="explore-free-course" style="margin-top: 65px;">
                <owl-carousel-o [options]="termOptions" class="customOwl">
                    <ng-template carouselSlide *ngFor="let course of freecourses;  ">
                        <div class="col-md-12 float-left ex-card">
                            <div class="card pgm-card">
                                <div class="card-image" style="position:relative">
                                    <img src="{{course.image}}" class="card-img-top" alt="{{course.image_alt}}">
                                </div>
                                <div class="card-body pgm-body">
                                    <h6 class="card-title pgm-tit font-demi"><span ><b>{{course.title}}</b></span></h6>
                                    <div>
                                        <p class="card-text limit-p" [innerHTML]="course.description"></p>
                                    </div>
                                    <div class="float-right" style=" width: 100%; margin-top:10px; float: none!important;">
                                        <a class="know-more  float-right" routerLink="/free-courses/{{course.slug}}">Know More</a>
    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
    <div class="row" style="text-align: center;">
        <div class="col-md-12">
            <a routerLink="/free-courses" class="btn view mb-bottom" style="margin-top:0px!important">View All</a>
        </div>
    </div>

    <div class="learning-experience" style="/*background-image: url('../../../../assets/images/home/gry-bnr.jpg');*/    background: #ffffff;color: #212121;">
        <h3 class="text-center learn" style="color: #374D63;">Learning Experience</h3>
        <div class="underline"></div>
        <div class="row my-top" style="margin-top: 55px;">
            <div class="col-md-4 col-sm-6 float-left padd-learn" style="margin-bottom: 35px;" *ngFor="let experience of learning_experiences">
                <div class="col-img-lrn">
                    <img class="exp-image" alt="{{experience?.image_alt}}" src="{{experience.image}}">
                </div>
                <h4 class="exp font-demi" style="color: #374D63;">{{experience.title}}</h4>
            </div>

        </div>
    </div>
    <div class="container-fluid p-0">
        <div class="gp top-width">
            <div class="row">
                <div class="col-md-12 p-0">
                    <div class="col-md-6 float-left leader">
                        <h3 class="text-left" style="font-weight: 100;color: #374D63;font-family: 'Inter-Regular'">What We Offer at<br>
                            <span style="font-weight: 900;color: #374D63;font-family: 'Inter-Bold'">Edubex</span></h3>
                        <div class="underline" style="margin-left:0px!important"></div>
                        <div [innerHTML]="whatWeOffer?.description"></div>

                        <!-- <ul>
                            <li>
                                Immensive learning using worlds best technology
                            </li>
                            <li>
                                Personalised and data-driven learning
                            </li>
                            <li>
                                Industry stalwarts working round the clock
                            </li>
                            <li>
                                Dedicated student support team to guide you through the nuances of online learning
                            </li>
                            <li>
                                World class instructors
                            </li>
                        </ul> -->
                        <!-- <a  *ngIf="userService.isLoggedIn()" routerLink="/programs" class="btn get-start">Get Started</a>  -->
                        <!-- <a class="btn get-start offer-start" (click)="openBookcall()">Get Started</a> -->
                        <a class="btn get-start" (click)="openBookcall()">Get Started</a>
                    </div>
                    <div class="col-md-6 float-left">
                        <div class="country-status">
                            <div class="row m-padding" style="padding-top:100px;color: #374D63;background-image: url('../../../../assets/images/home/map.png'); background-size:contain;background-repeat: no-repeat;">
                                <div class="col-md-3 col-6">{{whatweoffers[0]?.title}}
                                    <h1 class="font-demi offer-count">{{whatweoffers[0]?.count}}</h1>
                                    <div>{{whatweoffers[0]?.sub_label}}</div>

                                </div>
                                <div class="col-md-3 col-6 s-none"></div>
                                <div class="col-md-3 col-6">
                                    <h1 class="font-demi offer-count">{{whatweoffers[1]?.count}}</h1>
                                    <div>{{whatweoffers[1]?.sub_label}}</div>
                                </div>
                                <div class="col-md-3 col-6 s-none"></div>
                                <div class="col-md-3 col-6 s-none"></div>
                                <div class="col-md-3 col-6 space-contr">
                                    <h1 class="font-demi offer-count">{{whatweoffers[2]?.count}}</h1>
                                    <div>{{whatweoffers[2]?.sub_label}}</div>
                                </div>
                                <div class="col-md-3 col-6 s-none"></div>
                                <div class="col-md-3 col-6 space-contr">
                                    <h1 class="font-demi offer-count">{{whatweoffers[3]?.count}}</h1>
                                    <div>{{whatweoffers[3]?.sub_label}}</div>
                                </div>
                            </div>
                        </div>
                        <!-- <img src="../../../../assets/images/home/map.png"> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <h3 class="text-center" style="margin-top: 50px;color: #374D63;">Student Testimonial</h3>
    <div class="underline"></div>
        <div class="container-fluid p-0">
            <div class="row student-testimonial">
                <!-- <p>
                    <i class="fa fa-quote-left" aria-hidden="true"></i> I value my studies with Edubex for many reasons. First, I am able to study at a reasonable cost and at a reasonable pace, allowing me to work and study simultaneously. Second,
                    the professors are really motivating, they speak in a very simple manner, and they are very accessible. I've also got the opportunity to network with students from other African countries.<i class="fa fa-quote-right" aria-hidden="true"></i>

                </p> -->
                <img routerLink="/reviews-and-testimonials/{{list[0]?.id}}" src="{{list[0]?.image}}" alt="{{list[0]?.image_alt}}" style="cursor:pointer; margin-bottom: 15px; width: 230px; height: 250px; object-fit: cover;" class="img-fluid test-frt-img h-none">
                <h2 style="font-family: Inter-Bold; font-size: 24px; color: #494949; margin-bottom: 15px;">FEATURED STORY</h2>
                <p class="position-relative" style="color: #494949;">
                    <img src="../../../../assets/images/quote1.png" class="quote1">
                    {{list[0]?.description}}
                    <img src="../../../../assets/images/quote2.png" class="quote2">
                </p>
                
                <a routerLink="/reviews-and-testimonials/{{list[0]?.id}}" class="btn get-start story-btn s-none">Read full story</a>
                <div class="ts-img">
                    <img routerLink="/reviews-and-testimonials/{{list[0]?.id}}" src="{{list[0]?.image}}" alt="{{list[0]?.image_alt}}" style="cursor:pointer; margin-bottom: 15px; width: 230px; height: 250px; object-fit: cover;" class="img-fluid test-frt-img s-none">
                    <div style="color: #494949;width:230px;text-align: left;">
                        <p style="font-family: Inter-Bold; font-size: 18px; text-transform: uppercase;">{{list[0]?.name}}</p>
                        <p>Administrator,</p>
                        <p>AMX Academy, Nigeria.</p>
                        <img src="../../../../assets/images/dl-logo.png" class="dl-logo">
                    </div> 
                    <a routerLink="/reviews-and-testimonials/{{list[0]?.id}}" class="btn get-start story-btn h-none">Read full story</a> 
                </div>
            </div> 
            <div class="students-testimonial-main">
                <owl-carousel-o [options]="termsOptions" class="customOwl ts-caro">
                    <ng-template carouselSlide *ngFor="let stand of list let i= index" style="margin:0px 0px 20px;cursor:pointer">
                        <!-- <div  class="row" style="margin:20px 0px" > -->
                        <div >
                            <div class="col-test default-height">
                                <img  *ngIf="!stand.name" src="{{stand.image}}" alt="{{stand?.image_alt}}" [ngClass]="stand.name ? 'testimonial-img' : 'static-img'" >

                                <img *ngIf="stand.name" routerLink="/reviews-and-testimonials/{{stand.id}}" src="{{stand.image}}" alt="{{stand?.image_alt}}" style="cursor:pointer"  [ngClass]="stand.name ? 'testimonial-img' : 'static-img'" >
                                <div class="col-md-12 " *ngIf="stand.name"  routerLink="/reviews-and-testimonials/{{stand.id}}" style="display: inline-block;padding:15px;height:195px;">
                                    <h4 class="test-h4" routerLink="/reviews-and-testimonials/{{stand.id}}" style="margin:0px 0px 5px!important;cursor:pointer;color:#494949;text-transform: uppercase;font-family: 'Inter-Bold'; font-size:15px;">{{stand?.name}} <span style="font-size:22px"> 
                                        <img src="../../../../assets/images/story-arrow.png" class="story-arrow">
                                    </span></h4>
                                   <div>
                                    <div class="test-designation"><p style="color:#494949;">{{stand.designation}}</p></div>
                                    <div class="test-company"><p style="color:#494949;">{{stand.company_name}}</p></div>
                                    <div class="test-logo-hm">
                                        <img src="{{stand.company_logo}}" class="img-fluid">
                                    </div>
                                   </div>
                                   
                                    <!-- <p routerLink="/reviews-and-testimonials/{{stand.id}}" style="cursor:pointer">{{stand?.description}}</p> -->
                                </div>
                                <div class="col-md-12 " *ngIf="!stand.name" style="height: 195px;">
    
                                    <p style="cursor:pointer;text-align: center;
                                font-weight: 600;
                                font-size: 16px"></p>
                                </div>
    
                            </div>
                        </div>
                        <!-- </div > -->
                    </ng-template>
                </owl-carousel-o>
            </div> 
        </div>
    <div class="row" style="text-align: center;">
        <div class="col-md-12">
            <a routerLink="/stories" class="btn view my-tl">View All</a>
        </div>
    </div>
 

    <h3 class="text-center" style="margin-top: 50px;color: #374D63;">Our Financing Partner</h3>
    <div class="underline"></div>
    <div class="container-delivery exp-fluid" style="margin-top: 65px;">
        <div class="container-fluid">
            <div *ngIf="finance_partners.length===1">
                <div class="slide text-center imagelogo msc-im" *ngFor="let partner of finance_partners">
                    <img [src]="partner?.image" alt="" >    
                </div>
            </div>
           
            <div  *ngIf="finance_partners.length!=1">
                <owl-carousel-o [options]="aluminiOptions">
                    <ng-template carouselSlide *ngFor="let partner of finance_partners">
                        <div class="slide float-left imagelogo">
                            <img style="width: 100%;" src={{partner.image}} alt="{{partner?.image_alt}}">
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
            
        </div>
    </div>
    <div class="container-fluid learn-section p-0" style="margin-bottom:30px">
        <div class="exp-fluid">
            <h3 class="text-center learn" style="color: #374D63;">Learn from Expert Instructors</h3>
            <div class="underline"></div>
            <div class="students-testimonial-main shades-cn position-relative" style="margin-top: 65px;">
                <img src="../../../../assets/images/shade-left.png" class="shade-left">
                <img src="../../../../assets/images/shade-right.png" class="shade-right">
                <owl-carousel-o [options]="instrOptions" class="customOwl ts-caro">
                    <ng-template carouselSlide *ngFor="let stand of instructor let i= index" style="margin:0px 0px 20px;cursor:pointer">
                        <!-- <div  class="row" style="margin:20px 0px" > -->
                        <div >
                            <div class="learn-expert">
                                <div class="expert-image position-relative">
                                    <img [src]="stand?.image">
                                    <img src="../../../../assets/images/im-icon.png" class="image-style">
                                </div>
                                <h1 class="font-demi" style="font-size: 15px; color: #494949;">{{stand?.name}}</h1>
                                <p style="font-size: 15px; color: #494949;">{{stand?.designation}}</p>
                                <div class="w-auto logo-image">
                                    <img [src]="stand?.logo">
                                </div>
                            </div>
                        </div>
                        <!-- </div > -->
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>

    <h3 class="text-center" style="margin-top: 50px;color: #374D63;">A Quick Guidance from Edubex</h3>
    <div class="underline"></div>
    <div class="tab-container mobile-hide">
        <div class="container-fluid p-0" style="margin-top: 65px;">
            <div class="row position-relative tab-main-content">
                <div class="tab">
                    <!-- <button class="tablinks" (click)="openTab($event, '1')" id="defaultOpen">What Are Coding Bootcamps?</button>
                <button class="tablinks" (click)="openTab($event, '2')">How Do Coding Bootcamps Work?</button>
                <button class="tablinks" (click)="openTab($event, '3')">How Do I Choose my Tech Career Path?</button>
                <button class="tablinks" (click)="openTab($event, '4')"> How Much Do Coding Bootcamps Cost?</button> -->
                    <div *ngFor="let guidance of newGuidance ;let i=index">
                        <button class="tablinks font-demi accordian-tab" #allTheseThings [ngClass]="{
                        'active': (i+1) === clicked}" [attr.id]="'guid' + -(i+1)" (mouseenter)="openTab($event, i+1)">{{guidance?.title}} <i class="fa fa-angle-right" aria-hidden="true" style="padding-left:10px"></i></button>
                    </div>
                    <div class="row bottom-cn" style="margin:0px">
                        <div class="col-6 tab-arrow csd" (click)="setnewGuidance(guidanceIndex)">
                            <!-- <fa-icon style=" margin-left:10px;color: #212121" [icon]="faArrowLeft"></fa-icon> -->
                            <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <rect width="25" height="17" fill="url(#pattern0)"/>
                                <defs>
                                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                <use xlink:href="#image0_1205_12" transform="scale(0.04 0.0588235)"/>
                                </pattern>
                                <image id="image0_1205_12" width="25" height="17" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAARCAYAAAAougcOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAOtJREFUeNq0lL0RgkAQhQ+1AEoguRw7ML3M60A6gAq0A+0AO9CMUEIzMSewBO3At85jBlGiW3Zm2b0L+Obd/hgzYs65jVGyaASQI+zhNdxXVfUMgcz+AFICPhYK+IEAECOUPMrPvcZzDZVs4SnzTEPFFwQq1gg5jwcAzqqFByBBuMHluRoAlkbROsgFYcU7UXBXhQCwYy0mswVnoQ+ptSFRb7q71j2iJpkmZC6ftm0ba23C9k2Rv3B3nWStDBrAa7XxcBhlwh/MS64YXQgn3HOlxATF2koE1MhK4VGUnNQhBEktCioqQiFvAQYABIVI4j4nMhwAAAAASUVORK5CYII="/>
                                </defs>
                            </svg> 
                            <!-- <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <rect width="25" height="17" fill="url(#pattern0)"/>
                                <defs>
                                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                <use xlink:href="#image0_1205_13" transform="scale(0.04 0.0588235)"/>
                                </pattern>
                                <image id="image0_1205_13" width="25" height="17" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAARCAYAAAAougcOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPJJREFUeNpiYMAFIowSGKgEGHFYUAAk+4H4ABAHMqw494ESS5iwWGAAtQACKLQA05IIIwEgOR/K+wD2BRUAuk/qgdgAyk6khi9QLYkwCgCSBVDeBKAFG6gb8RFGCkDyPBCDgusC0AJDBioCmCX7gaQDVAzkg4vUtSTCqAEaFzQDLNC8gGzJAWpbwoiUu2FJdwEwThKpaQkzmLzy/AKDjqQCNPkaANkfgWInaFOsoCaAQGolY/TMCMrhD6Ds+dAihsqWQHJ4ILRIEYBaJEBtn4AsugAuUiAA5JP11Il4dHDl+Q1w5DMwWABxJJD/ghJLAAIMALToP0CLhNaWAAAAAElFTkSuQmCC"/>
                                </defs>
                            </svg>                                                                                                                            -->
                        </div>
                        <div class="col-6 tab-arrow" (click)="setnewGuidance(guidanceIndex)">
                            <!-- <fa-icon style=" margin-left:10px;color: #212121" [icon]="faArrowRight"></fa-icon> -->
                            <svg style="transform: rotate(180deg);" width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <rect width="25" height="17" fill="url(#pattern0)"/>
                                <defs>
                                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                <use xlink:href="#image0_1205_14" transform="scale(0.04 0.0588235)"/>
                                </pattern>
                                <image id="image0_1205_14" width="25" height="17" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAARCAYAAAAougcOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO9JREFUeNpiYKAS8PT0FABiB2xyTFSyAGT4fSBeD2Qr0MQSIPgApQWAeD66JDM1bLhz584LVVXVn0CmBxArANmMQLEDMHlGBioCYFCtB1IBUK7h9u3bL1AzuGAgESnoQPEjQHVLgC4HWRAI5SrA4ocRmhoUqOyjfKRgS2QBEglAXM9AO9DPxEB78ICR2iZCI/s8NApAceRIC5/sR4rjQFAyZqKyL0CpyQAW4UALDlA1CQMtKIAmIhBYALRgAVVzPNACUHJdD+VeAFpgSNUCEprPYIXiA1BEU72ABBaEH4AFogSQqQGyE+iLB+hqAAIMAB00QX/P/OQtAAAAAElFTkSuQmCC"/>
                                </defs>
                            </svg>  
                            <!-- <svg style="transform: rotate(180deg);" width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <rect width="25" height="17" fill="url(#pattern0)"/>
                                <defs>
                                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                <use xlink:href="#image0_1205_15" transform="scale(0.04 0.0588235)"/>
                                </pattern>
                                <image id="image0_1205_15" width="25" height="17" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAARCAYAAAAougcOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNpiYKAWiDASAGIHbFJMVLIAZPh9IF4PZCvQxhIGhg9QWgCI56NLMlPFiivPXzDoSP4EsjyAWAHIZgSKHYBJMzJQE0QYrQeSAVCeIcOKcxeoGVwwkIgUdOvBiYHqlqw4B7IgEMpTgMUPIzQ1KFDZR/lIwZbIAiQSgLiegXagn4mB9uABI9WNhET2eWgUgOLIkRY+2Y8Ux4GgZMxEZV+AUpMBPDmvOHeAukk4wqgAmohAYAHQggXUzfERRgHgzAcBF4AWGFK3gITkM1ih+AAU0dQvIK88/wAsECWALA0g9gT64gG6EoAAAwBEDzXhtW34AQAAAABJRU5ErkJggg=="/>
                                </defs>
                            </svg>                                                               -->
                        </div>
                    </div>
    
                </div>
                <div *ngFor="let guidance of newGuidance ;let i=index">
                    <div [attr.id]="i+1" class="tabcontent" *ngIf="clicked==(i+1)">
                        <h4 class="font-demi">{{guidance?.title}}</h4>
                        <p [innerHTML]="guidance?.description"></p>
                    </div>
                </div>
            </div>
            

        </div>
    </div>
    <div class=" container container-fluid mobile-visible">
        <div class="row border-left-right">
            <div class="col-6 tab-arrow csd" (click)="setGuidance(mobile_guidance)">
                <!-- <fa-icon style=" margin:2px;color: #212121" [icon]="faArrowLeft"></fa-icon> -->
                <img src="../../../../assets/images/p-ar.png" class="p-ar">
                <!-- <img src="../../../../assets/images/p-arh.png" class="p-arh"> -->
            </div>
            <div class="col-6 tab-arrow" (click)="setGuidance(mobile_guidance)">
                <!-- <fa-icon style=" margin:2px;color: #212121" [icon]="faArrowRight"></fa-icon> -->
                <img src="../../../../assets/images/n-ar.png" class="n-ar">
                <!-- <img src="../../../../assets/images/n-arh.png" class="n-arh"> -->
            </div>
        </div>
        <div class="mc-space" *ngFor="let guid of guidance ;let i=index">
            <div [attr.id]="i+1" *ngIf="mobile_guidance==(i)">
                <h4 class="font-demi lines-bottom position-relative">{{guid?.title}}</h4>
                <p [innerHTML]="guid?.description"></p>
            </div>
        </div> 
      
    </div>
 <!-- <div style="background: red;width: 1465px;height: 100px;margin: auto;position: fixed;top: 50%;left: 0;right: 0;"></div> -->
    <div class="container-fluid stry-fluid p-0" style="display: inline-block;">
        <div class="success-stories text-center">
            <!-- <owl-carousel-o [options]="storyOptions">
            <ng-template carouselSlide *ngFor="let story of stories let i= index;let isOdd=odd;let isEven=even"> -->
            <div class=" cardblock stry-card">
                <div class="card">
                    
                    <!-- <div class="sty-img"  [ngStyle]="{ 'background-image': 'url(' + stories?.story?.image + ')'}"></div> -->
                    <div class="position-relative" style="height: 216px;">
                        <div class="center-cn">
                            <h5 style="padding:0px 20px;color: #374D63;" class="card-title story font-demi">{{ stories.story?.name }} </h5>
                            <div class="card-body" style="padding:0px 20px">
                                <p class="card-text" style="color: #414141;">{{ stories.story?.description }}</p>
                                <!-- <a routerLink="/stories" class="btn get-start-squ story-btn">Visit Now</a> -->
                                <a routerLink="/university-pathway" class="btn view">Visit Now</a>
                            </div>
                        </div>
                        <img src="../../../../assets/images/coment1.png" class="coment1">
                    </div>
                    <img class="sty-img" [src]="stories.story?.image" alt="{{stories?.story?.image_alt}}">
                </div>
            </div>
            <div class=" cardblock stry-card">
                <div class="card">
                    <img class="sty-img dis430 order1" [src]="stories?.blog?.image" alt="{{stories?.blog?.image_alt}}" style="display:none">
                    <img class="sty-img none430" alt="{{stories?.blog?.image_alt}}" [src]="stories?.blog?.image">
                    <div class="position-relative" style="height: 216px;">
                        <div class="center-cn">
                            <h5 style="padding:0px 20px" class="card-title story font-demi">{{ stories.blog?.name }} </h5>
                            <div class="card-body" style="padding:0px 20px">
                                <p class="card-text">{{ stories.blog?.description }}</p>
                                <a routerLink="/blog" class="btn view">View More</a>
                            </div>
                        </div>
                        <img src="../../../../assets/images/coment2.png" class="coment2">
                    </div>
                    

                    <!-- <div class="sty-img" [ngStyle]="{ 'background-image': 'url(' + stories?.blog?.image + ')'}"></div> -->
                </div>
            </div>
            <div class=" cardblock stry-card">
                <div class="card">
                    
                    <!-- <div class="sty-img"  [ngStyle]="{ 'background-image': 'url(' + stories?.news?.image + ')'}"></div> -->
                    <div class="position-relative" style="height: 216px;">
                        <div class="center-cn">
                            <h5 style="padding:0px 20px" class="card-title story font-demi">{{ stories.news?.name }} </h5>
                            <div class="card-body" style="padding:0px 20px">
                                <p class="card-text">{{ stories.news?.description }}</p>
                                <a routerLink="/support-services" class="btn view">Learn More</a>
                            </div>
                        </div>
                        <img src="../../../../assets/images/coment1.png" class="coment1">
                    </div>
                    <img class="sty-img" [src]="stories?.news?.image" alt="{{stories?.news?.image_alt}}">
                </div>
            </div>
            <div class=" cardblock stry-card">
                <div class="card">
                    <img class="sty-img dis430 order1" [src]="stories?.scholarship?.image" alt="{{stories?.scholarship?.image_alt}}" style="display:none">
                    <img class="sty-img none430" alt="{{stories?.scholarship?.image_alt}}" [src]="stories?.scholarship?.image">
                    <div class="position-relative" style="height: 216px;">
                        <div class="center-cn">
                            <h5 style="padding:0px 20px" class="card-title story font-demi">{{ stories.scholarship?.name }} </h5>
                            <div class="card-body" style="padding:0px 20px">
                                <p class="card-text">{{ stories.scholarship?.description }}</p>
                                <a routerLink="/scholarship" class="btn view">Read More</a>
                            </div>
                        </div>
                        <img src="../../../../assets/images/coment2.png" class="coment2">
                    </div>

                    

                    <!-- <div class="sty-img"  [ngStyle]="{ 'background-image': 'url(' + stories?.scholarship?.image + ')'}"></div> -->
                </div>
            </div>
            <!-- </ng-template>
        </owl-carousel-o>   -->

        </div>
    </div>
    <div class="row container-fluid m-0 p-0">
        <h3 class="text-center alumni-title w-100">Where Our Alumni Work</h3>
        <div class="underline"></div>
    </div>
    <div class="text-center alumni-logos row container-delivery container-fluid">
        <div class="img-logo">
            <owl-carousel-o [options]="aluminiOptions">
                <ng-template carouselSlide *ngFor="let group of alumini;  ">
                    <div class="slide float-left imagelogo">
                        <img style="width: 100%;" src={{group.image}} alt="{{group?.image_alt}}">
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>
<div class="row container-delivery">
    <div class="col-md-12">
        <app-find-career [page]="'home'"></app-find-career>
    </div>
</div>
<div class="container-delivery">
    <div class="col-fixed">
        <div class="bottom">
            <app-bottom-bar></app-bottom-bar>

        </div>
    </div>
</div>
