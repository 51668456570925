import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { CourseService } from 'src/app/core/services/course/course.service';
import { MasterService } from 'src/app/core/services/master/master.service';
import { DomSanitizer } from '@angular/platform-browser';
declare var bootbox;
@Component({
  selector: 'app-webinar-recording',
  templateUrl: './webinar-recording.component.html',
  styleUrls: ['./webinar-recording.component.scss']
})
export class WebinarRecordingComponent implements OnInit {
  slug:any;
  webinar:any;
  model ={
    first_name:'',
    last_name:'',
    phone:'',
    email :'',
    country:'',
    exp_year:'',
    industries:''
  };
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  email_err=''
  pdf: any;
  countries:any=[]
  country: any;
  termOptions: OwlOptions = {
		loop: false,
		autoplay:true,
		autoWidth:true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
		navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
		responsive: {
		0: {
			items: 1
		},
		400: {
			items:1
			// items:this.scheduled_courses.length<3?this.scheduled_courses.length:5
		},
		740: {
			items: 2
			// items: this.scheduled_courses.length<3?this.scheduled_courses.length:5,
		},
		940: {
			items: 2,
			loop:false
		}
		},
		nav: true
	  }
  short_desc: any;
    getMore() {
      this.short_desc = this.webinar.description;
    }
    getLess() {
      let wordCount = this.webinar.description.split(/\s+/);
      wordCount.splice(
        50,
        0,
        `<span  id='innerHtmlClick' class='more' (click)='getMore()' >Read More..</span></p>`
      );
      this.short_desc = wordCount.slice(0, 51).join(" ");
    }
  ngOnInit(): void {
    this._activatedRoute.params.subscribe(params => {
      console.log("params--------------------------------",params);      
      this.slug = params.id
      this.courseService.getWebinarDetails({slug:this.slug}).subscribe((res) => {
        console.log('res', res);
        this.webinar=res.webinar;
        // this.allCategories = res.Dcategories;
        if(this.webinar.speakers.length){
          this.webinar.speakers.forEach(element => {
            element.desc = element.short_description;
            element.setDesc=false;
            
          });

        }
        this.getCountries();
      
        });
      }) 
  }
  constructor( private _activatedRoute:ActivatedRoute,
    private sanitizer: DomSanitizer, private courseService:CourseService,public masterService:MasterService) { }

  selectCountry(event){
    
    this.country = event.target.value;
  }
  viewMore(speaker){
    speaker.desc = speaker.description;
    speaker.setDesc=true
  }
  viewLess(speaker){
    speaker.desc = speaker.short_description;
    speaker.setDesc=false
  }
  getCountries(){
		this.masterService.getCountries().subscribe(async (resp: any) =>{     
    this.countries = resp.countries
		}, (err: any) => {
			console.log(err);
		})
  }
  onSubmit(form){

    let data = form.value;
    data.webinar_id = this.webinar.id;
    data.phone = form.value.phone?.e164Number;


  this.courseService.saveWebinarWatch(data).subscribe(async (resp: any) =>{
  
    var dialog = bootbox.dialog({
      message: resp.message,
      closeButton: true
    });
    form.resetForm();
    
    });		
  }
}
