<div class="container-fluid" style="padding:0px">
        <div class="row">
            <div class="col-md-12">
                <app-banner [page]="'home'"></app-banner>
            </div>
        </div>
</div>
<div class="row">
    <div class="col-md-12">
        <app-page-details [page]="'hiring-partner'"></app-page-details>  
    </div>
</div>  