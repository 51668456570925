import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/shared/models/user/user.model';
import { Country } from 'src/app/shared/models/country/country.model';

import { ApiService } from '../api/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MultiSelectService } from '../common/multiselect.service';

@Injectable()
export class UserService {

selectedUser =  new User();
@Output() refreshUser: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: ApiService, private http: HttpClient) { }

  // noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  register(user: User){
    const data = this.api.post('api/v1/user/register',user);
    return data;
  }

  getUser() {
    const data = this.api.get('api/v1/user/my_profile');
    // this.refreshUser.emit("token")
    return data;
  }
  getAppliedCourses() {
    const data = this.api.get('api/v1/user/applied_courses');
    return data;
  }

getAppliedFreeCourses() {
  const data = this.api.get('api/v1/user/applied_freecourses');
  return data;
}
  getApprovedCourses() {
    const data = this.api.get('api/v1/user/approved_courses');
    return data;
  }

  login(authCredentials) {
  //  return this.api.post('oauth/token', authCredentials);
  return this.api.post('api/v1/login', authCredentials);
  
  }

 setToken(token) {
   console.log("token",token)
  localStorage.setItem('JWT_TOKEN', token.access_token);
  localStorage.setItem('REFRESH_TOKEN', token.refresh_token);
  this.refreshUser.emit(token)
  }

  getToken() {
    return localStorage.getItem('JWT_TOKEN');
  }
  getRefreshToken() {
    return localStorage.getItem('REFRESH_TOKEN');
  }

  deleteToken() {
    localStorage.removeItem('JWT_TOKEN');
    localStorage.removeItem('REFRESH_TOKEN');
  }

  getUserPayload() {
    var token = this.getToken();
    if (token) {
      var userPayload = atob(token.split('.')[1]);
      return JSON.parse(userPayload);
    }
    else
      return null;
  }

  isLoggedIn() {
    var userPayload = this.getUserPayload();  
    if (userPayload)
      return userPayload.exp > Date.now() / 1000;
    else
      return false;
  }
  logout() {
    return  this.api.get('api/v1/logout', {
      'refreshToken': this.getRefreshToken()
    })
  }
  changeImage(data) {
    console.log("image data",data)
    return  this.api.get('api/v1/user/changeImage',data)
  }
  updateUser(data,image?) {
    console.log("dataa",image,data)
    return this.api.postProfile('api/v1/user/update/profile',data,image);    
  }
  sendCode(data) {
    console.log("dataa")
    return data   ; 
  }
  verifyOtp(data): any {
    return this.api.post('api/v1/user/otpCheck',data);    
  }
  getTransactionHistory(){
    return this.api.get('api/v1/user/transaction_history');   
  }
  getBootcampHistory(){
    return this.api.get('api/v1/user/bootcamp_transaction_history');   
  }
  getWebinarHistory(){
    return this.api.get('api/v1/user/webinar_transaction_history');   
  }
  getFreeCourseHistory(){
    return this.api.get('api/v1/user/freecourse_transaction_history');   

  }
  getPaymentDues(){
    return this.api.get('api/v1/user/payment_dues');
  }
  getEmitter() { 
    console.log("getEmit",this.refreshUser)
    return this.refreshUser; 
  }
  forgotPassword(email){
    return this.api.get('api/v1/forget_password/'+email);
  }
  resetPassword(data){
    return this.api.post('api/v1/reset',data);
  }
  changePassword(data){
    return this.api.post('api/v1/user/change_password',data);

  }
  checkExpiry(data){
    return this.api.post('api/v1/reset/validate_link',data);
  }

}
