<!-- <div class="container-fluid ">
    <div class="container "> -->
<div class="row banner d-none">
    <div class="col-md-12">
        <div class="card-image">
            <app-banner [page]="'boot-camp'"></app-banner>
        </div>
    </div>
</div>
<!-- <div class="row" >-->
<div class="container-delivery">
    <div class="d-flex justify-content-center div-cls">
        <app-course-apply (childEvent)="reload($event)" #apply></app-course-apply>

        <!-- <select  class="text form-control "  (change)=selectCat($event,cat)>
                                <option selected value="">All Categories</option>
                                <option  *ngFor="let cat of allCategories" [value]="cat.id" >{{cat.title}}</option>
                            </select>   -->
    </div>
</div>










<div class="banner-item">
    <div class="row inner-banner">
        <div class="col-md-6">
            <h1>Bootcamp Online</h1>
        </div>
        <div class="col-md-6">
            <h2>Find Answers to Your Questions on Upskilling with Our Bootcamp online</h2>
            <p>What are the Latest Technologies? Which skills will drive the future? How will this Program help your career goals?</p>
            <p>Learn it all with Faculty Master Classes, Success Stories of Past Learners and Insights from Industry Experts</p>
        </div>
    </div>
</div>
<div class="container-delivery">
    <h3 class="text-center learn w-100">Bootcamp Experience</h3> 
    <div class="underline"></div>
    <p class="text-center">Edubex is more than a Data Science bootcamp: it's a career transformation experience. Learn essential data science skills and gain
        hands-on, industry experience through unique real-world projects where you'll work alongside industry professionals on real
        business problems and collaborate with designers, web developers, and digital marketers to deliver a real-world solution. See why
        thousands of graduates have launched their new careers with Edubex through this Data Science bootcamp.</p>
</div>

<div class="container-delivery online-event" style="margin-top:2rem;margin-bottom:30px">
    
    <div class="container-fluid ">
        <div class="">
            <div class="row">
                <h3 class="text-center learn w-100" style="color:#fff">Upcoming Bootcamps</h3> 
                <div class="underline"></div>
                <div class="col-md-12" style="margin:0 auto;">
                    <!-- <owl-carousel-o [options]="aluminiOptions">
                        <ng-template carouselSlide *ngFor="let cat of allCategories">

                            <div class="category" [ngClass]="{'active':cat.active }" (click)="selectCat(cat)">{{cat.title}}</div>

                        </ng-template>
                    </owl-carousel-o> -->
                    <div class="row">
                        <div class="col-12">
                            <!-- <div style="margin-top: 5px; color: #fff;" class="float-right"> Live online events </div> -->

                        </div>

                    </div>
                    <div class="row">
                        <div class="head col-md-4 p-0">
                            <!-- <h4 class="text-left">{{title}}</h4>
                                        <div class="underline" style="margin-left: 0px!important;"></div> -->
                                        <select class="select-contr" (change)="selectCat($event.target.value)">
                                            <option value="">All Categories</option>
                                            <option  *ngFor="let cat of allCategories" [value]="cat.slug">{{cat?.title}}</option>
                                            <!-- <option value="">2</option>
                                            <option value="">3</option> -->
                                        </select>
                        </div>
                        <div class="side-head col-md-8">
                            <div class="row" style="justify-content: flex-end;margin:0px">
                                <div class="row" style="justify-content: flex-end;margin:0px">
                                    <div class="row" style="margin:0px;align-items: center; justify-content: flex-end; color: #fff;">
                                        <div class="box" style="background: #10CFBD;"></div> Discount
                                        <div class="box" style="background:  #F55753;"></div>Sold Out
                                        <div class="box" style="background: #F8D053;"> </div>Places Limited
                                    </div>
                                    <!-- <fa-icon style=" margin: 5px 0px 0px 5px;color: #00000042;position: relative;top: -4px;" [icon]="faChevronCircleDown"></fa-icon> -->
                                    <!-- <select class="live form-control" name="" id="">
                                                  <option value="" *ngFor="let time of offsetTmz">{{time}}</option>
                                              </select> -->
                                </div>
                                <!-- <span style="margin-top: 5px;"> Live online events </span> -->
                                <!-- <fa-icon style=" margin: 5px 0px 0px 5px;color: #00000042;position: relative;top: -4px;" [icon]="faChevronCircleDown"></fa-icon> -->
                                <!-- <select class="live form-control" name="" id="">
                                              <option value="" *ngFor="let time of offsetTmz">{{time}}</option>
                                          </select> -->
                            </div>
                        </div>
                    </div>
                    <div class="row country-row">
                        
                        <div class="col-sm-2 country-col p-0">
                            <div class="country" [ngClass]="{ 'selected': clicked=='ind' }" (click)="changeCountry('ind')">
                                India
                            </div>
                            <div class="country" [ngClass]="{ 'selected': clicked=='uk' }" (click)="changeCountry('uk')">
                                UK
                            </div>
                            <div class="country" [ngClass]="{ 'selected': clicked=='us' }" (click)="changeCountry('us')">
                                USA
                            </div>
                            <div class="country" [ngClass]="{ 'selected': clicked=='uae' }" (click)="changeCountry('uae')">
                                UAE
                            </div>
                        </div>
                        <div class="col-sm-10 p-0">
                            <div >
                                <!-- <h3 class="d-none">{{list.month}}</h3> -->
                                <div class="accordion dis767" id="accordionExample">
                                    <div class="cards" *ngFor="let course of courses; let i=index">
                                        <div class="card-header cardheader" id="{{course.id}}">
                                            <div class="row schedule collapsed top-schd m-0" data-toggle="collapse" [attr.data-target]="'#news' + course.id" aria-expanded="false" aria-controls="{{course.id}}">
                                                
                                                <div class="col-12 pad-scd schd-col webinar-list">
                                                    <div class="month-date"> {{course.date|date:'MMM'}}
                                                        <div> {{course.date|date:'dd'}}</div>
                                                    </div>
                                                    <h4 routerLink="/bootcamp-details/{{course.slug}}" style="margin:0px 0px 5px">{{course.title}}</h4>
                                                    <span><fa-icon style=" margin:5px;" [icon]="faClock"></fa-icon>{{course.start_time}}-{{course.end_time}}</span>
                                                    <span *ngIf="!course.is_free_bootcamp"  style="font-size: 14px;color: #919293; padding-left: 20px;">
                                                       <span *ngIf="course.offer_percentage">
                                                        <b class="discount-price"> {{course.currency}}{{course.amount}}</b>
                                                        {{course.currency}}
                                                             <b>{{course.offer_amount}}</b> </span>
                                                             <span *ngIf="!course.offer_percentage">
                                                                {{course.currency}}
                                                                     <b>{{course.amount}}</b> </span>
                                                       </span>
                                                     
                                                            <span *ngIf="course.is_free_bootcamp"  style="font-size: 14px;color: #095e37; padding-left: 20px;"> Free Bootcamp
                                                                </span>
                                                           <div *ngIf="course.offer_percentage" class="box " style="display: inline-block;background: #10CFBD; color:#fff;margin-right:10px">
                                                                         
                                                        </div>
                                                      
                                                            <div *ngIf="course.sold_placelimed=='Sold Out'"  class="box " style="display: inline-block;background:#F55753;color:#fff">
                                                               
                                                            </div>
                                                          
                                                            <div *ngIf="course.sold_placelimed=='Limited'"  class="box " style="display: inline-block;background: #F8D053;color:#fff">
                                                            
                                                            </div>
                                                            <div class="col-12 p-0">
                                                                <a class="btn views" *ngIf="(course.other_dates.length && myIndex==-1)"  (click)="selectIndex(i)">See other dates</a>
                                                                <a   class="btn views" routerLink="/bootcamp-details/{{course.slug}}">View More <img src="../../../../assets/images/view-arrow.png" alt=""></a> 
                                                                <!-- <a *ngIf="course.is_free_bootcamp"   class="btn views" (click)="enterOtp(course.slug)">View More</a>  -->

                                                            </div>
                                                            <div class="other-dates" [ngClass]="( myIndex == i)?'active':'inactive'" > 
                                                                <div *ngFor="let dates of course.other_dates">
                                                    <p style="color: #375e37"><img src="../../../../assets/images/date.png" alt=""> {{dates.date|date}} <a class="btn views" routerLink="/bootcamp-details/{{dates.slug}}">View More</a></p>

                                                                </div>
                                                                <a (click)="selectIndex(-1)" class="view-less">View Less <svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" class="svg-inline--fa fa-w-14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg></a>


                                                            

                                                    <!-- <div [style.display]="course.is_online==='1' ? 'block' : 'none'"><span><i class="fa fa-map-marker" aria-hidden="true"></i></span> <span> Live Online</span></div>
                                                    <div [style.display]="course.is_online==='2' ? 'block' : 'none'"><span><i class="fa fa-map-marker" aria-hidden="true"></i></span> <span> Online</span></div> -->
                                                </div>
                                                <!-- <a class="btn apply-now" *ngIf="course.is_free_bootcamp" (click)="enterOtp(course.slug)">Apply Now</a> -->
                                                <a class="btn apply-now" *ngIf=" !userService.isLoggedIn()" (click)="openModal()">Apply Now</a>
                                                <a class="btn apply-now" *ngIf=" userService.isLoggedIn() && course.is_applied" routerLink="/bootcamp-details/{{course.slug}}">Applied</a>

                                                <a class="btn apply-now" *ngIf=" userService.isLoggedIn() && !course.is_applied" (click)="openApplyModal(course )">Apply Now</a>
                                                <div class="offer" *ngIf="course.offer_percentage">
                                                    {{course?.offer_percentage}}% Off
                                                </div>
                                            </div>
                                        </div>                     
                                    </div>
                                </div>
                                <div *ngIf="!courses.length" class="text-center">
                                    <h3>No Data to Display!!</h3>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
</div>

</div>

<div class="container-delivery">
    <h3 class="text-center learn w-100">Expertise your skills through our guest experts</h3> 
    <div class="underline"></div>
    <p class="text-center">BrainStation students get access to exclusive guest lectures and panel discussion events led by some of the world's leading subject matter experts and industry professionals.</p>
    <div class="row">
        <div class="col-4 smc" *ngFor="let guest of guest_experts">
            <div class="expert-list">
                <img [src]="guest.image" class="expert-img">
                <h1>{{guest?.name}}</h1>
                <p>{{guest?.designation}}, {{guest?.company}}</p>
                <p class="years">Exp: {{guest?.experience}} Years</p>
                <a target="_blank" href="{{guest?.linkedin}}"><img  src="../../../../assets/images/linkedin.png" alt="" class="linkedin-icon"></a>
            </div>
        </div>
      
    </div>
</div>



<div class="container-delivery">
    <h3 class="text-center learn w-100">100+ Hiring partners around the globe</h3> 
    <div class="underline"></div>
    <div class="row">
        <div class="logo-list" *ngFor="let part of partner">
                <img [src]="part?.file" alt="">
        </div>
      
     
    </div>
</div>



<!-- <div class="row container-delivery" style="padding-top: 2rem;background-color: #e9ecef;">
    <div class="col-md-12">
        <app-find-career [page]="'programs'"></app-find-career>
    </div>
</div> -->
    <div #container class="row masonry-with-flex masonry-with-columns" style="margin-top:40px;margin-bottom: 60px;" > 
<div class="col-3 p-0" *ngFor="let image of gallery">
    <img [src]="image.file" style="width: 100%;height: 100%;object-fit: cover;" />
</div>
       
  </div>



<div class="container-delivery">
    <div class="col-fixed">
        <div class="bottom">
            <app-bottom-bar></app-bottom-bar>
        </div>
    </div>
</div>
