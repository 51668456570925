<!-- <div class="container-fluid ">
    <div class="container "> -->
<div class="row banner">
    <div class="col-md-12">
        <div class="card-image">
            <app-banner [page]="page"></app-banner>
        </div>
        <div class="row container-delivery hirpt">
            <div class="card-sec">
                <div class="message" id="successMessage">{{message}}</div>
                <div class="card-body">
                    <h6 class="card-title" style="margin-top: 20px;"><b>Join Our Talent Network</b></h6>
                    <app-join-network [page]="page" (message)="messageHandler($event)"></app-join-network>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- </div>
</div> -->
<div class="container-fluid service">
    <div class="container-delivery">
        <h4 class="text-center head" style="margin-top:0px">Plug into Edubex Career Services Network and You Get to</h4>
        <div class="underline"></div>
        <div class="row">
            <div class="col-md-4" style="margin: 10px auto 0px" *ngFor="let service of services ">
                <div class="box">
                    <p class="terms">{{service.service}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <app-page-details [page]="page"></app-page-details>
    </div>
</div>
<div class="container-delivery">
    <div class=" partner">
        <h4 class="text-center head">Where Our Alumni Work </h4>
        <div class="underline"></div>
        <div class="row logo">
            <div class="col-lg-2 col-md-3 col-sm-4 col-6" *ngFor="let partner of partners">
                <img class="exp-image" src="{{partner.logo}}" alt="{{partner.logo_alt}}">
            </div>
        </div>
    </div>
</div>
<div class="row container-delivery">
    <div class="col-md-12">
        <app-find-career></app-find-career>
    </div>
</div>
<div class="container-delivery">
    <div class="col-fixed">
        <div class="bottom">
            <app-bottom-bar></app-bottom-bar>
        </div>
    </div>
</div>