
  <div [style.display]="showModal1 ? 'block' : 'none'" class="modal apply-mod" id="imagemodal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg popup-mnc" style="margin: 0px auto;">
    <div class="modal-content" style="padding:0px">
      <div class="modal-body" style="padding:0px!important">
        <div class="close" (click)="hide()" style="top: 5px;z-index: 999;right: 0px;">
          <fa-icon style=" margin:5px;cursor:pointer;color: #000" [icon]="faTimesCircle"></fa-icon>
        </div>
        <div class="row">
          <div class="col-sm-12" style="padding:0px"> 
            <div class="card content">
              <div class="card-body">
                <div class="col-md-12 box p-0" style="height:100%;">  
                   
                  
                  <!-- <div *ngFor="let offer of popups">

                    <h2>{{offer?.title}}</h2>
                    <div>{{offer?.description}}</div>
                    <button *ngIf="offer?.button_text" class="submit btn upload" routerLink="{{offer?.button_link}}">{{offer?.button_text}} </button>

                  </div> -->
                  <ngb-carousel *ngIf="popups" [showNavigationArrows]="true">
                    <ng-template *ngFor="let banner of popups" ngbSlide>
                        <div >
                            <div  class="picsum-img-wrapper box">
                                <!-- <ik-image [path]="banner.image" alt="{{banner?.image_alt}}"></ik-image>               -->
                                <img src="{{ banner?.image }}" alt="">
                                <div class="col-md-12 p-0" style="position: unset;">
                                    <div class="head">
                                        <h1><b><div>{{banner?.title}}</div></b></h1>
                                        <div [innerHTML]="banner.description" class="none767"></div>
                                        <div >
                                            <div class="started">
                                                <button *ngIf="banner?.button_text"  class="btn start start-style" routerLink="{{banner?.button_link}}">{{banner?.button_text}}</button>
                    
                                                <!-- <app-register #reg></app-register> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    
                            </div>
                          
                        </div>
                        </ng-template>
                    </ngb-carousel>
               
                 
                </div> 
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>