import { Component, OnInit } from '@angular/core';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Routes, RouterLink, Router, ActivatedRoute } from "@angular/router";
import { CourseService } from '../services/course/course.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  faCopyright = faCopyright;
  faInstagram = faInstagram;
  faFacebook = faFacebook;
  faTwitter = faTwitter;
  faWhatsapp = faWhatsapp;
  courses:any=[];
  year:any;

  constructor(public router: Router,public courseService:CourseService) {
    this.year = new Date().getFullYear();
    this.courseService.longTermCourses().subscribe(res=>{
      console.log("footer",res);
      this.courses= res.data;
      console.log("footercourse",this.courses)
    })

   }

  ngOnInit(): void {
    console.log('footer loaded');
  
  }
  goToCareers =()=> this.router.navigate(['/careers']);
  goToUniversityPathway =()=> this.router.navigate(['/university-pathway']);
  goToBecomeInstructor=()=> this.router.navigate(['/become-instructor']);
  goToOurStory=()=> this.router.navigate(['/our-story']);
  goToContactUs=()=> this.router.navigate(['/contact-us']);
  goToOnDemand=()=> this.router.navigate(['/on-demand']);  
  goToPresenters=()=> this.router.navigate(['/presenters']);  
  goToCatalog=()=> this.router.navigate(['/programs']);
  goToPrivacyPolicy=()=> this.router.navigate(['/privacy-policy']);
  goToCookiePolicy=()=> this.router.navigate(['/cookie-policy']);
  goToGdpr=()=> this.router.navigate(['/gdpr']);
  goToTermsOfService=()=> this.router.navigate(['/terms-of-service']);
  goToSchedule=()=> this.router.navigate(['/schedule']);
  goToEvents=()=> this.router.navigate(['/events']);
  goToAcademicPolicies=()=> this.router.navigate(['/academic-policies']);
  goToExamGrades=()=> this.router.navigate(['/examination-grades']);
  goToNetworkBenefits=()=> this.router.navigate(['/network-benefits']);
  goToParticipantSpeaks=()=> this.router.navigate(['/participant-speaks']);
  goHome=()=> window.location.href ="/";
  goToPrograms=()=> this.router.navigate(['/programs']);
  goToStandApart=()=> this.router.navigate(['/how-we-stand-apart']);
  goToServices=()=> this.router.navigate(['/our-services']);
  goToPartners=()=> this.router.navigate(['/hiring-partner']);
  goToSynapse=()=>this.router.navigate(['/synapse'])
  goToFb=()=>window.location.href="https://www.facebook.com";
  goToTwitter=()=>window.location.href="https://www.twitter.com";
  goToInsta=()=>window.location.href="https://www.instagram.com";
  goToWhatsapp=()=>window.location.href="https://www.whatsapp.com";
  goToProgram=(slug)=>this.router.navigate(['/program-details/'+slug])
  goToBlog=()=>this.router.navigate(['/blog']);
  goToCalendar=()=> this.router.navigate(['/calendar']);
  goToWebinars=()=> this.router.navigate(['/webinars']);
}
