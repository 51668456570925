import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChartComponent } from "ng-apexcharts";
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexDataLabels
} from "ng-apexcharts";
import { CourseService } from 'src/app/core/services/course/course.service';
import { UserService } from 'src/app/core/services/user/user.service';
declare var bootbox;

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels:   any

};

@Component({
  selector: 'app-free-dashboard',
  templateUrl: './free-dashboard.component.html',
  styleUrls: ['./free-dashboard.component.scss']
})
export class FreeDashboardComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  active = 1;
  contact:any=0;
  message;
 
  free_courses:any=[];
  paid_courses:any=[];
  course_offer;
  freecourse_certificate:any=[];
  point_summary: any;
  constructor(public router:Router,public userService:UserService, public courseService:CourseService) {
    this.chartOptions = {
      series: [1],
      //  labels: ["a"],
      chart: {
        type: "donut"
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
   }
   onChange(event){
     console.log(event)
     if(event) this.message = "";
   }
   progress: number = 0;
   noOfFiles: number = 0;
   completed: boolean = false;
   user:any;
   user_details:any
   total:any=0;
   free_list:any=[];
   paid_list:any=[];
   credit:any=0;
   applied:any=0;
   email:any;
   phone:any;
   whatsapp:any;
 
   public ngOnInit(): void {
     this.userService.getUser().subscribe(data=>{
      this.freecourse_certificate=data.freecourse_certificate
      this.user = data.user_id;
      this.user_details=data.profile;
      this.free_list=data.applied_freecourse;
      this.course_offer= data.offer_freecourse[0];
      this.credit=data.freecourse_credit_points;
      console.log("credit",this.credit)

      this.point_summary = data.credit_point_summary;
      this.applied=data.free_course_count.applied_freecourses_count;
      this.free_courses =data.free_course_count.completed_freecourses_count;
        this.paid_list = data.applied_courses;

        this.noOfFiles=data.bookmark_progress;
        // this.updateProgress();

        console.log("data",data.bookmark_progress,data)
      this.courseService.getCoursesByUser(this.user).subscribe(data=>{
        console.log("courses",data)
        // this.free_courses=data.free_course;
        this.paid_courses = data.paid_course;
        this.total= data.total_completed_course;
      })
    })  
  
   }
 
  delay(ms: number) {
    return new Promise((resolve, reject) => setTimeout(resolve, ms));
  }
  goToCourse(){

  }
  goFreecourse(course){
    this.router.navigateByUrl('/scromcontent/'+course.slug);
    this.courseService.articulate = course.articulate_content
    // localStorage.setItem('articulate',course.articulate_content);

  }
  async updateProgress() {
    this.completed = false;
    console.log("files",this.noOfFiles)
    // let n =this.noOfFiles;
      for (let i = 0; i <=this.noOfFiles; i++) {
        await this.delay(100);
        this.progress = i;
        console.log("progress",this.progress)

      }
      this.progress==100&&(this.completed = true);
  }
  sendData(form){
    console.log(this.contact,this.message,this.email,this.phone,this.whatsapp,form.valid)
    if(this.contact==='1') this.message=this.email;
    if(this.contact==='2') this.message=this.whatsapp;

    if(this.contact==='3') this.message=this.phone;
  
    // console.log(this.contact===1,this.contact==='1',this.message , form.valid)
    if(this.message && form.valid)
    this.courseService.sendDataFreecourse(this.contact,this.message).subscribe(data=>{
      console.log("courses",data)
      bootbox.dialog({ message:"Details sent successfully!!",
      closeButton: true})
    })
  }
  open(link){
    var file = new Blob([link], { type: 'application/pdf' })
          var fileURL = URL.createObjectURL(file);

    var a = document.createElement("a");
    let fileName = 'brochure';
    a.href = link;
    a.target='_blank'
    a.download = link;
    document.body.appendChild(a);
    a.click();
    // window.URL.revokeObjectURL(link);
    // a.remove();
   ////////////////////////////////////
  }

}
