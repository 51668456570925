import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'field-builder',
  template: `
  <div class="form-group row" [formGroup]="form">
    <label class="col-md-4 form-control-label field" [attr.for]="field.label">
      {{field.label}}
      <strong class="text-danger" *ngIf="field.required">*</strong>
    </label>
    <div class="col-md-8" [ngSwitch]="field.type" style="padding:0px 0px 5px">
      <textbox *ngSwitchCase="'text'" [field]="field" [form]="form"></textbox>
      <date *ngSwitchCase="'date'" [field]="field" [form]="form"></date>
      <text-area *ngSwitchCase="'textarea'" [field]="field" [form]="form"></text-area>
      <dropdown *ngSwitchCase="'select'" [field]="field" [form]="form"></dropdown>
      <checkbox *ngSwitchCase="'checkbox'" [field]="field" [form]="form"></checkbox>
      <radio *ngSwitchCase="'radio'" [field]="field" [form]="form"></radio>
      <file *ngSwitchCase="'file'" [field]="field" [form]="form"></file>
      <span style="color:red;display:block; font-size:smaller" *ngIf="field?.required && !isValid && (isTouched || isSubmit)">{{field.label}} is required</span>
    </div>
  </div>
  `,
  styles:[
    ` .field{
        margin:10px 0px;
      }
`
]
})
export class FieldBuilderComponent implements OnInit {
  @Input() field:any;
  @Input() form:any;
  @Input() submitted:any;
  
  get isValid() { return this.form.controls[this.field?.name]?.valid; }
  get isDirty() { return this.form.controls[this.field?.name]?.dirty; }
  get isTouched(){ return this.form.controls[this.field?.name]?.touched; }
  get isSubmit(){ return this.form['submitted'];}

  constructor() {
  }

  ngOnInit() {
    this.form['submitted']= false;
    // this.submitted = false;

  }

}
