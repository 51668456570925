import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faArrowRight, faCalendarAlt, faChevronRight, faClock, faRupeeSign, faTimesCircle, faUsers } from '@fortawesome/free-solid-svg-icons';
import { NgbAccordion, NgbCarousel, NgbPanelChangeEvent, NgbSlideEvent, NgbSlideEventSource, NgbTabsetConfig } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CourseService } from 'src/app/core/services/course/course.service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-show-program',
  templateUrl: './show-program.component.html',
  styleUrls: ['./show-program.component.scss']
})
export class ShowProgramComponent implements OnInit {

  @Output() childEvent = new EventEmitter();
  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  err: string='';
  course_id: any;
  user_id: any;
  unsubcribe: any

 faTimesCircle = faTimesCircle;
 faRupeeSign = faRupeeSign;
 faArrowRight = faArrowRight;
 faClock = faClock;
 faUsers= faUsers;
 faChevronRight= faChevronRight;
 faCalendarAlt = faCalendarAlt;

  constructor(configure: NgbTabsetConfig,private courseService:CourseService,private userService:UserService, private router:Router) { 
    console.log("field",this.fields);
    configure.justify = 'center';
		configure.type = 'pills';	
  }

  onUpload(e) {
    console.log(e);
  }

  getFields() {
    return this.fields;
  }

  ngDistroy() {
    this.unsubcribe();
  }
  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }
  
  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }
  exploreOptions: OwlOptions = {
    loop: true,
    autoplay:true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    items:1,
    responsive: {
      0: {
        items: 1
        },		
      400: {
      items: 1
      },

      740:{
        items:2
      },
      940:{
        items:2
      },
    
    },
    nav: false
    }
  longTerm = [];
  documents=[];
  fields = [];
  formFields = [];
  showModal1:boolean
  ngOnInit(): void {
    // this.getEnroll();
   if(this.userService.isLoggedIn()) {
      this.userService.getUser().subscribe(data=>{
        console.log("user",data)
        this.user_id = data.profile.id
      })
    }   
  }
 
  public get height() {
    return window.innerHeight;
  }
  
  public get width() {
    return window.innerWidth;
  }

  show(longTerm){
    console.log("longterm",longTerm)
    this.longTerm=longTerm;
    // for (const key in this.longTerm) {
    //   for(const long of longTerm[key]){
    //   long.duration = this.courseService.getWeken(long.start_date,long.end_date);

    //   }
    // }
    this.showModal1 =true;
  }

  hide(){
    this.showModal1 = false;
    this.err='';
    this.fields=[];
  }
  getProgram(slug){
    console.log("slug----",slug)
    this.router.navigate(['/program-details/'+slug]);

   this.hide();
    // if(this.width<991) window.location.href ="/program-details/"+slug;
  }
    @ViewChild('myaccordion', {static : true}) accordion: NgbAccordion;

    beforeChange($event: NgbPanelChangeEvent) {
      console.log($event.panelId);
      // if ($event.panelId === 'panelOne') {
      //   $event.preventDefault();
      // }
  
      // if ($event.panelId === 'panelTwo' && $event.nextState === false) {
      //   $event.preventDefault();
      // }
    }
  

}
