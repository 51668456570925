import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faFacebook, faInstagram, faLinkedin, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CourseService } from 'src/app/core/services/course/course.service';
import { MasterService } from 'src/app/core/services/master/master.service';

@Component({
  selector: 'app-success-story',
  templateUrl: './success-story.component.html',
  styleUrls: ['./success-story.component.scss']
})
export class SuccessStoryComponent implements OnInit {
_id:any;
story:any
list:any =[];
all:any=[];
baseUrl='';
faInstagram = faInstagram;
faFacebook = faFacebook;
faTwitter = faTwitter;
faWhatsapp = faWhatsapp;
faLinkedin = faLinkedin;
termOptions: OwlOptions = {
  loop: true,
  autoplay:true,
  mouseDrag: true,
  touchDrag: true,
  pullDrag: true,
  dots: false,
  navSpeed: 700,
  navText: ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
  responsive: {
    0: {
    items: 1 
    },
    400: {
    items: 2
    },
    740: {
    items: 3
    },
    940: {
    items: this.all.length<3?this.all.length:3,
    loop:false
    }
  },
  nav: true
  }
  constructor(private _activatedRoute:ActivatedRoute,private courseService:CourseService, private masterService:MasterService,public router: Router) { 
    this._activatedRoute.params.subscribe(params => {
     this.baseUrl=this.router['location']._platformLocation.location.origin;
      console.log(params);      
        this._id = params.id;
      
          this.masterService.getSuccessStory(this._id).subscribe(res=>{ 
            this.story = res.story;
           
            console.log("details",res);
          })  
          this.masterService.getStoriesAll().subscribe(res=>{ 
            let list =res.stories; 
            let new_list = list.filter(l=>{
              return l.id!=this._id
            })
            this.list = new_list.sort(() => 0.5 - Math.random());
            console.log("details",res);
          })  
      });    
  }
  goToStory(id){
    this.router.navigate(['/reviews-and-testimonials/'+id]);
    window.scrollTo(0, 0);
    // window.location.reload();
    
  }
  ngOnInit(): void {
    this.courseService.getHomeLongTermCourses().subscribe(async (resp: any) =>{
      this.all = resp.data;  
     
		}, (err: any) => {
			console.log(err);
		})
  }
    // Facebook share won't work if your shareUrl is localhost:port/abc, it should be genuine deployed url 
    shareOnFacebook(shareUrl: string) {
      shareUrl = encodeURIComponent(shareUrl);
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, 'sharer');
    }
  

    shareOnTwitter(shareUrl: string) {
      shareUrl = encodeURIComponent(shareUrl);
      // desc = encodeURIComponent(desc);
      window.open(`https://twitter.com/intent/tweet?url=${shareUrl}`, 'sharer');
    }
  
    shareOnInstagram(shareUrl: string) {
      shareUrl = encodeURIComponent(shareUrl);      
      window.open(`https://www.instagram.com/?url=${shareUrl}`, 'sharer');    
      // window.open(`https://plus.google.com/share?url=${shareUrl}`, 'sharer');
      // window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`, 'sharer');     

    }
    shareOnLinkedin(shareUrl: string) {
      shareUrl = encodeURIComponent(shareUrl);
      // window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`, 'sharer');     
      window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`, 'sharer');     

    }
   
    
    // LinkedIn share won't work if your shareUrl is localhost:port/abc, it should be genuine deployed url


  

}
