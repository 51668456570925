import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import {faChevronUp,faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss']
})
export class BottomBarComponent implements OnInit {
  windowScrolled: boolean;
  faChevronUp = faChevronUp;
  faPhone = faPhone;
  click = false;
  constructor(@Inject(DOCUMENT) private document: Document) {}
  @HostListener("window:scroll", [])
  onWindowScroll() {
      if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
          this.windowScrolled = true;
      } 
     else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
          this.windowScrolled = false;
      }
  }
  scrollToTop() {
      (function smoothscroll() {
                        window.scrollTo(0, 0);
// 
        //   var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        //   console.log("current",currentScroll)
        //   if (currentScroll > 0) {
        //       window.requestAnimationFrame(smoothscroll);
        //       window.scrollTo(0, 0);
        //   }
      })();
  }
  ngOnInit() {}
  clickChat(){
    this.click=!this.click;
  }

}
