<div class="container-delivery cert-col skills-container">
    <div *ngIf="bootcamp?.skills.length">
        <h4 class="text-center">Bootcamp Overview</h4>
        <div class="underline"></div>
            <div class="row project-list">
                <div class="col-8 s-gh">
                        <p [innerHTML]="bootcamp?.description"></p>
                </div>
                <div class="col-4 s-gh">


                    <a class="apply-c" *ngIf=" !userService.isLoggedIn()" (click)="openModal(bootcamp.slug)">Apply Now</a>
                    <a class="apply-c" *ngIf=" userService.isLoggedIn() && bootcamp?.is_applied" >Applied</a>

                    <a class="apply-c" *ngIf=" userService.isLoggedIn() && !bootcamp?.is_applied" (click)="openApplyModal(bootcamp )">Apply Now</a>
                </div>
                <div class="row col-12 text-center m-0"> 
                    <div class="col-4 s-gh">
                        <div class="tarts-cn">
                            <div class="center-div">
                                <h1>Starts On</h1>
                                <p>{{bootcamp?.date|date}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 s-gh">
                        <div class="tarts-cn">
                            <div class="center-div">
                                <h1>Duration</h1>
                                <p>{{bootcamp?.duration}} <span  *ngIf="bootcamp?.is_online ">Online</span> </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 s-gh">
                        <div class="tarts-cn">
                            <div class="center-div">
                                <h1 *ngIf="!bootcamp?.free_bootcamp">Fee</h1>
                                <h1 *ngIf="bootcamp?.free_bootcamp">Free</h1>
                                <!-- <p *ngIf="!bootcamp?.free_bootcamp"></p> -->
                                <p *ngIf="!bootcamp?.free_bootcamp">{{bootcamp?.currency}} {{bootcamp?.amount}}</p>
                                <!-- <p>Flexible Payment Available</p> -->
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
    </div>
</div>
<div class="container-delivery learning-path" *ngIf="bootcamp?.learning_paths && bootcamp.learning_paths.length">
    <h4 class="text-center">Learning Path</h4>
    <div class="underline"></div>
    <p>{{bootcamp?.learning_path_description}}</p>
    <div class=" position-relative">
        <!-- <div class="line-top"></div> -->
        <!-- <div class="col-2"> -->
            <owl-carousel-o [options]="pathOptions" class="customOwl">
                <ng-template carouselSlide *ngFor="let path of bootcamp.learning_paths; let i=index">
            <div class="path-list">
                <span>{{i+1}}</span>
                <h1><b>{{path?.title}}</b></h1>
                <p class="position-relative"><img [src]="path?.image">
                    <img src="../../../../assets/images/path-arrow.png" alt="" class="path-arrow">
                    {{path?.description}}
            </div>
            </ng-template>
            </owl-carousel-o>
        <!-- </div> -->
        <!-- <div class="col-2">
            <div class="path-list">
                <span>2</span>
                <h1><b>Strategic Leadership</b></h1>
                <p><img src="../../../../assets/images/l-arrow.png">
                    This unit provides an in-depth understanding of the key principles and practice of leadership, which will enhance the individuals’ knowledge, skills, role and responsibilities required of an effective team player and leader of a team.</p>
            </div>
        </div>
        <div class="col-2">
            <div class="path-list">
                <span>3</span>
                <h1><b>Strategic Human Resource management</b></h1>
                <p><img src="../../../../assets/images/l-arrow.png">
                    This unit develops an understanding of how the effective strategic management of human resources supports the achievement of organisational objectives in different contexts.</p>
            </div>
        </div>
        <div class="col-2">
            <div class="path-list">
                <span>4</span>
                <h1><b>Advanced Business Research Methods</b></h1>
                <p><img src="../../../../assets/images/l-arrow.png">
                    This unit develops an ability to prepare for various types of research through the development and design of a research proposal within the context of the business environment.</p>
            </div>
        </div>
        <div class="col-2">
            <div class="path-list">
                <span>5</span>
                <h1><b>Globalisation and corporate Governance</b></h1>
                <p><img src="../../../../assets/images/l-arrow.png">
                    This unit develops learners’ understanding of the effect of legislation, ethics, and global integration on decisions, corporate governance, policies, processes and activities undertaken by organizations</p>
            </div>
        </div>
        <div class="col-2">
            <div class="path-list">
                <span>6</span>
                <h1><b>Strategic Marketing</b></h1>
                <p><img src="../../../../assets/images/l-arrow.png">
                    This unit develops learners’ understanding of the concepts, methods and technical aspects of strategic marketing and the challenges associated with marketing in today’s business environment.</p>
            </div>
        </div> -->
    </div>
</div>
<div class="container-delivery about-instructor">
    <h4 class="text-center">About the instructors</h4>
        <div class="underline"></div>
        <div class="row">
            <div class="col-7 s-gh" *ngIf="bootcamp?.instructors && bootcamp.instructors.length">
                <owl-carousel-o [options]="speakerOptions" class="customOwl instructors-slide">
                    <ng-template carouselSlide *ngFor="let speaker of bootcamp?.instructors">
    
                <div class="img-lp">
                    <img [src]="speaker?.image" alt="">
                    <h1>{{speaker?.name}}</h1>
                    <h2>{{speaker?.designation}},</h2>
                    <p>Univ of Alabama</p>
                </div>
                <p [innerHTML]="speaker?.description"></p>
                    </ng-template>
                    </owl-carousel-o>
            </div>
            <div class="col-1 cv2"></div>
            <div class="col-4 s-gh">
                <div class="follow">
                    <h1>Can't Make It?</h1>
                    <p>Follow this workshop to get an email the next time it’s scheduled.</p>
                    <span>Sign-up not required</span>
                    <div (click)="sendEmail(bootcamp?.slug)" class="apply-c follow-btn">Follow</div>
                   
                    <h2>Spread the Word</h2>
                    
                    <div class="spred-world">
                        <a (click)="shareOnLinkedin (baseUrl+'/'+router.url)"><img src="../../../../assets/images/linkedin.svg" alt=""></a>
                        <a (click)="shareOnTwitter(baseUrl+'/'+router.url)"><img src="../../../../assets/images/twtr.svg" alt=""></a>
                        <a (click)="shareOnFacebook(baseUrl+'/'+router.url)"><img src="../../../../assets/images/fb.svg" alt=""></a>
                        <a (click)="shareOnInstagram(baseUrl+'/'+router.url)"><img src="../../../../assets/images/instagram.svg" alt=""></a>
                    </div>
                </div>
            </div>
        </div>
</div>

<div class="container-delivery cert-col skills-container"  id="skills" #skills>
    <div *ngIf="bootcamp?.skills.length">
        <h4 class="text-center">Skills Covered</h4>
        <div class="underline"></div>


        <div style="height:auto;padding: 20px;">
            <div class="row">
                <div class="col-4 skills-col ln-height" *ngFor="let skill of bootcamp?.skills">
                    <i class="fa fa-check icon-font"></i> {{skill.title}} 
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-delivery cert-col"  id="tools" #tools>
    <div *ngIf="bootcamp?.tools.length">
        <h4 class="text-center">Tools Covered</h4>
        <div class="underline"></div>


        <div style="height:auto;padding: 20px;">
            <div class="row blp-btm">
                <div class="col-lg-2 col-md-3 col-sm-6 col-6 tools-logo" *ngFor="let tool of bootcamp?.tools">
                    <img src="{{tool?.file}}" alt="" class="img-fluid tools-covered">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-delivery cert-col skills-container">
    <div *ngIf="bootcamp?.projects.length">
        <h4 class="text-center">Projects</h4>
        <div class="underline"></div>
        <p class="text-center">Build professional projects like the top 1% of Data Scientists and create a solid, job-worthy portfolio worthy of Tier 1
            companies. Land your dream job as a Data Scientist with ease. Here’s a peek at some of the projects you’ll build:</p>
            <div class="row project-list">
                <div class="col-6 s-gh" *ngFor="let projects of bootcamp?.projects">
                    <div class="projects">
                        <div class="imb-sec">
                            <img [src]="projects?.image" alt="" class="st-img">
                            <h1>{{projects?.title}}</h1>
                            <h2>{{projects?.category_id}}</h2>
                        </div>
                        <p>{{projects?.description}}</p>
                    </div>
                </div>
                <!-- <div class="col-6">
                    <div class="projects">
                        <div class="imb-sec">
                            <img src="../../../../assets/images/b01.jpg" alt="" class="st-img">
                            <h1>Recommender</h1>
                            <h2>Shopping</h2>
                        </div>
                        <p>Build intelligence on variable of user behaviour based on transactions and selections to identify and recommend products.</p>
                    </div>
                </div> -->
                <!-- <div class="col-6">
                    <div class="projects">
                        <div class="imb-sec">
                            <img src="../../../../assets/images/b01.jpg" alt="" class="st-img">
                            <h1>Recommender</h1>
                            <h2>Shopping</h2>
                        </div>
                        <p>Build intelligence on variable of user behaviour based on transactions and selections to identify and recommend products.</p>
                    </div>
                </div> -->
            </div>
    </div>
</div>
<div class="container-delivery cert-col skills-container">
    <div *ngIf="bootcamp?.highlights.length">
        <h4 class="text-center">Highlights</h4>
        <div class="underline"></div>


        <div style="height:auto;padding: 20px;">
            <div class="row">
                <div class="col-6 skills-col ln-height ncp" *ngFor="let highlights of bootcamp?.highlights">
                    <img [src]="highlights.image" alt=""> {{highlights?.title}}
                </div>
                <!-- <div class="col-6 skills-col ln-height ncp">
                    <img src="../../../../assets/images/h01.jpg" alt=""> 175 Hours Live Instructor-Led Sessions
                </div>
                <div class="col-6 skills-col ln-height ncp">
                    <img src="../../../../assets/images/h01.jpg" alt=""> 175 Hours Live Instructor-Led Sessions
                </div>
                <div class="col-6 skills-col ln-height ncp">
                    <img src="../../../../assets/images/h01.jpg" alt=""> 175 Hours Live Instructor-Led Sessions
                </div> -->
            </div>
        </div>
    </div>
</div>
<div class="container-delivery">
    <h3 class="text-center learn">You May Also Like</h3>
    <div class="underline"></div>
    <owl-carousel-o [options]="termOptions" class="customOwl you-may-also">
        <ng-template carouselSlide *ngFor="let course of others">
            <div class="col-md-12 float-left ex-card" style="cursor:pointer">
                <div class="card pgm-card" routerLink="/bootcamp-details/{{course.slug}}">
                    <div class="card-image" style="position:relative" (click)="goToThis(course.slug)">
                        <img [src]="course.image" class="card-img-top" alt="">
                    </div>
                    <div class="card-body pgm-body">
                        <h6 class="card-title pgm-tit font-demi"><b>{{course?.title}}</b></h6>
                        <div>
                            <p class="card-text" >{{course.date| date:'MMMM dd, yyy'}}</p>
                        </div>

                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>

<div class="container-delivery faq-col faq-container">
    <div class="row faq" id="faq" #faq *ngIf="bootcamp?.faqs.length">
        <div class="col-md-12">
            <div>
                <h4 style="margin-top:30px; text-align: center;">Frequently Asked Questions</h4>
                <div class="underline-" style="margin: 40px auto !important;"></div>
            </div>
            <div class="accordion faq-contr" id="accordionExample">
                <div class="cards" *ngFor="let faq of bootcamp?.faqs">
                    <div class="card-header cardheader" id="{{faq.id}}">
                        <div class="row m-0 faqt collapsed" data-toggle="collapse" [attr.data-target]="'#news' + faq.id" aria-expanded="false" aria-controls="collapseTwo">
                            <div class="col-11 float-left" style="padding:0px">
                                <h5 class="quest" style="cursor: pointer;">{{faq.question}}</h5>
                            </div>
                            <div class="col-1" style="cursor: pointer;text-align:right;padding:0px;margin-top: 5px;">
                                <span class=" float-right plus1" data-toggle="collapse" [attr.data-target]="'#new' + faq.id" aria-expanded="false" aria-controls="collapseTwo" style="font-size:16px">
                                +
                            </span>
                                <span class=" float-right minus1" data-toggle="collapse" [attr.data-target]="'#new' + faq.id" aria-expanded="false" aria-controls="collapseTwo" style="font-size:16px;">
                            -
                        </span>
                            </div>
                        </div>
                        <!-- <p class="faq-under-line"> </p> -->
                    </div>
                    <div [id]="'news'+faq.id" class="collapse " aria-labelledby="headingOne" data-parent="#accordionExample" style="width: 100%;">
                        <div class="card-body">
                            <div [innerHTML]="faq.answer"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>