import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CmsService } from 'src/app/core/services/cms/cms.service';
@Component({
  selector: 'app-page-details',
  templateUrl: './page-details.component.html',
  styleUrls: ['./page-details.component.scss']
})
export class PageDetailsComponent implements OnInit {
  @Output() message: EventEmitter<number> =   new EventEmitter();
  click = 0;
  @Input('page') page: string;
  page_details: any;
  constructor(private cmsService:CmsService, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.cmsService.getPageInro(this.page).subscribe(res=>{  
      console.log(this.page,res)   
      this.page_details = res['cms'];  
     
		})
  }
  goToCareers(){
    this.click=1
    this.message.emit(this.click)
  }
 

}
