import { Component, OnInit, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { faFacebook, faInstagram, faLinkedin, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CmsService } from 'src/app/core/services/cms/cms.service';
import { CourseService } from 'src/app/core/services/course/course.service';
import { MasterService } from 'src/app/core/services/master/master.service';
import { PopupComponent } from '../popup/popup.component';
declare var bootbox;

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  @ViewChild(PopupComponent) child:PopupComponent;

  faInstagram = faInstagram;
faFacebook = faFacebook;
faTwitter = faTwitter;
faWhatsapp = faWhatsapp;
faLinkedin = faLinkedin;
baseUrl='';
  _id: any;
  all:any=[];
  email_err;
  status=0;
  blog:any;
   list:any;
   related_blogs:any=[]
   courses:any =[];
   allotted_time=["10:00 am","11:00 am","12:00 pm","1:00 pm","2:00 pm","3:00 pm", "4:00 pm", "5:00 pm"]
   message:'';
   all_courses:any=[];
   model ={
     date:'',
     time:'',
     first_name:'',
     last_name:'',
     phone :'',
     email:''
   };
   enquiry={
     full_name:'',
     course:'',
     email:'',
     phone:''
   }
   termOptions: OwlOptions = {
    loop: false,
    autoplay:true,
    autoWidth:true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
    responsive: {
      0: {
      items: 1
      },
      400: {
        items:1
      // items:this.scheduled_courses.length<3?this.scheduled_courses.length:5
      },
      740: {
      //items: 3
      // items: this.scheduled_courses.length<3?this.scheduled_courses.length:5,
      },
      940: {
      //items: 3,
      loop:false
      }
    },
    nav: true
    }

   separateDialCode = false;
   SearchCountryField = SearchCountryField;
   CountryISO = CountryISO;
   PhoneNumberFormat = PhoneNumberFormat;
   preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
   selectedCourse;
   course_err;
  selectedTime: any;
  time_err: string;
  gift: any;
  popups: any;
  ngAfterViewInit() {
    // child is set
    // this.child.show('open');
  }
  constructor(private _activatedRoute:ActivatedRoute, private masterService:MasterService,
    private courseService:CourseService, 	private titleService: Title,public router:Router,
    private metaService: Meta,private cmsService:CmsService) {
    this._activatedRoute.params.subscribe(params => {
      console.log(params);
        this._id = params.id;
    this.masterService.getBlogDetail(this._id)
      .subscribe(res=>{
        console.log("response",res)
      this.blog =res.blog;
      this.related_blogs= res.related_blogs
      this.titleService.setTitle(res.blog.title); // <-- Update the title
    this.metaService.updateTag({
      name: 'description',
      content: res.blog.meta_description
    });
    this.metaService.updateTag({
      name: 'title',
      content: res.blog.meta_title
    });
     });
     this.courseService.getTrendingCourse()
     .subscribe(res=>{
       console.log("courses",res)
       this.courses= res.data;
     })
     this.courseService.getHomeLongTermCourses()
     .subscribe(res=>{
       console.log("------------courses",res)
       this.all_courses= res.data;
     })
     this.masterService.getPopup().subscribe((resp: any) => {
      console.log("resp",resp)
      this.popups=resp.popupoffers;
      if(this.popups.length) this.child.show('open')
      
  
     
      }, (err: any) => {
      console.log(err);
      });

    })
  }
  onCourseChange(event){
    this.selectedCourse=event;
    this.course_err='';
  }
  goToRefer =()=> {
    this.router.navigate(['/refer&earn']);
    

  };
  getFreecourses(){
		// this.courseService.getHomeLongTermCourses().subscribe( (resp:any)=>{
			this.courseService.getFreeCourses().subscribe( (resp:any)=>{
			this.all = resp.freecourse;
      			console.log("longterm",resp.data)

			}	, (err: any) => {
        console.log(err);
      });
  }

    ngOnInit(): void {
      this.baseUrl=this.router['location']._platformLocation.location.origin;

      this.getFreecourses();
      this.cmsService.getPageInro('usd50-image').subscribe(res=>{ 
        this.gift = res['cms'];    
        console.log("why",res)
      })
    }
    onTimeChange(event){
      this.selectedTime = event.target.value;
      this.time_err="";

    }
    onSubmit(form){
      console.log("form",form,this.selectedTime,this.enquiry)
       if(!this.selectedTime) this.time_err="Select Time" ;
      if(form.valid){
        let data = form.value;
      
        data.phone = form.value.phone?.e164Number?form.value.phone.e164Number:form.value.phone;

        this.courseService.bookACall(data)
        .subscribe(result=>{
          console.log("result",result);
          if(result.status){
            var dialog = bootbox.dialog({
              message: "Call booked successfully",
              closeButton: true
            });

            form.resetForm()
            // this.router.navigate(['/calendly']);
          }

          // this.router.navigate(['/course-payment/'+result.order_id]);

        })

      }

}
enquirySubmit(form){
  console.log("form",form)
  // if(form.val)
  if(form.valid){
    let data = form.value;
    data.phone = form.value.phone?.e164Number;

    this.masterService.blogenquiry(data)
    .subscribe(result=>{
      console.log("result",result);
      if(result.status){
        var dialog = bootbox.dialog({
          message: "Enquiry submitted successfully",
          closeButton: true
        });

        form.resetForm()
      }

      // this.router.navigate(['/course-payment/'+result.order_id]);

    })

  }

}
messageHandler(message){
  console.log("message",message);
  // this.message = message;
  var dialog = bootbox.dialog({
    message: message,
    closeButton: true
  });
  // document.getElementById('successMessage').style.display='block';
  // setTimeout(function(){
  //   // this.message="hi everybody";
  //   document.getElementById('successMessage').style.display='none';
  // },3000);
}

    // Facebook share won't work if your shareUrl is localhost:port/abc, it should be genuine deployed url 
    shareOnFacebook(shareUrl: string) {
      shareUrl = encodeURIComponent(shareUrl);
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, 'sharer');
    }
  

    shareOnTwitter(shareUrl: string) {
      shareUrl = encodeURIComponent(shareUrl);
      // desc = encodeURIComponent(desc);
      window.open(`https://twitter.com/intent/tweet?url=${shareUrl}`, 'sharer');
    }
  
    shareOnInstagram(shareUrl: string) {
      shareUrl = encodeURIComponent(shareUrl);      
      window.open(`https://www.instagram.com/?url=${shareUrl}`, 'sharer');    
      // window.open(`https://plus.google.com/share?url=${shareUrl}`, 'sharer');
      // window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`, 'sharer');     

    }
    shareOnLinkedin(shareUrl: string) {
      shareUrl = encodeURIComponent(shareUrl);
      // window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`, 'sharer');     
      window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`, 'sharer');     

    }

}
