import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'checkbox',
    template: `
      <div [formGroup]="form">
        <div [formControlName]="field.name" *ngIf="field.options.length" ngDefaultControl>
          <div *ngFor="let opt of field.options" class="form-check form-check">
          <label class="form-check-label">
             <input (change)="onClicked(opt, $event)" class="form-check-input" type="checkbox" [id]="opt" [value]="opt" />
             {{opt}}</label>
          </div>
        </div>

      </div> 
    `
})
export class CheckBoxComponent {
    @Input() field:any = {};
    @Input() form:FormGroup;
   values =[]
    get isValid() { return this.form.controls[this.field?.name]?.valid; }
    get isDirty() { return this.form.controls[this.field?.name]?.dirty; }
    onClicked(opt,evnt){
      if(evnt.target.checked)
     this.values.push(evnt.target.value);
     else{
      this.values=this.values.filter(res=>{
        return res!=evnt.target.value
      })
     }
     let value = "";
     this.values.forEach(element => {
       value += element+", "
     });
     this.form.controls[this.field.name]?.setValue(value)
      console.log("event",evnt,this.values,this.form.value)
    }
}