import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { CourseService } from 'src/app/core/services/course/course.service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-flexible-payment',
  templateUrl: './flexible-payment.component.html',
  styleUrls: ['./flexible-payment.component.scss']
})
export class FlexiblePaymentComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  @Input('course') course: any;

  err: string='';
  course_id: any;
  user_id: any;
  advance:any;
  unsubcribe: any;
  model ={
    first_name:'',
    second_name:'',
    phone:'',
    email :''
  };
  separateDialCode = false;

 
  constructor(private courseService:CourseService,private userService:UserService) { 
     console.log("field",this.fields)
    // this.unsubcribe = this.form.valueChanges.subscribe((update) => {
    //   console.log(update);
    //   this.fields = JSON.parse(update.fields);
    // });
  }


  ngDistroy() {
    this.unsubcribe();
  }

  list = [];
  documents=[];
  fields = [];
  faTimesCircle=faTimesCircle;
  formFields = [];
  showModal1:boolean
  ngOnInit(): void {
    // this.getEnroll();
   
   if(this.userService.isLoggedIn()) {
      this.userService.getUser().subscribe(data=>{
        console.log("user",data,this.course)
        this.user_id = data.profile.id
        // this.advance =  this.course.
      })
    }   
  }



  
  
  

  show(event){
    console.log("data");
    
    this.list = [];
    this.showModal1 =true;
    
    this.advance =(this.course.fees*this.course.flexible_payment.advance_amount/100).toFixed(2);
    console.log("course",this.course)
    // this.course_id =id;
  }
  hide(){
    this.showModal1 = false;
    this.err='';
    this.fields=[];
    this.list=[];
  }
}
