import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { CourseService } from 'src/app/core/services/course/course.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

declare var bootbox;
@Component({
  selector: 'app-course-package',
  templateUrl: './course-package.component.html',
  styleUrls: ['./course-package.component.scss']
})
export class CoursePackageComponent implements OnInit {

  @Output() childEvent = new EventEmitter();
  @Input('course') course: any;

  err: string='';
  course_id: any;
  user_id: any;
  public form: FormGroup;
  unsubcribe: any;
  model ={
    first_name:'',
    second_name:'',
    phone:'',
    email :''
  };
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
 
  constructor(private courseService:CourseService,private userService:UserService) { 
     console.log("field",this.fields)
    // this.unsubcribe = this.form.valueChanges.subscribe((update) => {
    //   console.log(update);
    //   this.fields = JSON.parse(update.fields);
    // });
  }


  ngDistroy() {
    this.unsubcribe();
  }

  list = [];
  documents=[];
  fields = [];
  faTimesCircle=faTimesCircle;
  formFields = [];
  showModal1:boolean
  ngOnInit(): void {
    // this.getEnroll();
   if(this.userService.isLoggedIn()) {
      this.userService.getUser().subscribe(data=>{
        console.log("user",data)
        this.user_id = data.profile.id
      })
    }   
  }



  
  onSubmit(form){
    console.log("form.value",form.value,this.course)
    let data = form.value;
    data.course_id = this.course.id;
    data.phone = form.value.phone?.e164Number;
    let element = document.getElementById('download') as HTMLElement;
    element.setAttribute('disabled', 'true');
    this.courseService.submitEnquiry(data).subscribe(async (resp: any) =>{
      console.log("response",resp);
      var dialog = bootbox.dialog({
        message: "We have send 'Edubex Course Package Details' to your mail, Please check your mail.",
        closeButton: true
      });
      element.removeAttribute('disabled');
      form.resetForm();
    });
    console.log("submitted")		
  }

  

  show(event){
    console.log("data");
    
    this.list = [];
    this.showModal1 =true;
    // this.course_id =id;
  }
  hide(){
    this.showModal1 = false;
    this.err='';
    this.fields=[];
    this.list=[];
  }

}
