import { Component,  ElementRef,  Input,  OnInit,  SimpleChanges,  ViewChild } from '@angular/core';

@Component({
  selector: 'app-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss']
})
export class CircleProgressComponent implements OnInit {

  @Input() progress: number;
  @Input() id: any;

  completed=true;
  getProgress;

  ngOnInit(): void {
    // this.getProgress=this.progress
    this.updateProgress();
  }
  loadData() {
    console.log("------------------------",this.progress)
    var scrollProgress = document.querySelectorAll('.'+this.id);
    scrollProgress.forEach((link, index) => {
      (link as HTMLElement).style.background = `conic-gradient(rgb(8 94 55)  ${this.getProgress}%, ${this.getProgress}%,rgb(215 240 229) ${this.getProgress}%)`;
  });
    // scrollProgress.style.background = `conic-gradient(rgb(8 94 55)  ${this.getProgress}%, ${this.getProgress}%, #f2f2f4 ${this.getProgress}%)`;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.updateProgress();

  }
  async updateProgress() {
    this.completed = false;
    // let n =this.progress;
      for (let i = 0; i <=this.progress; i++) {
        await this.delay(100);
        this.getProgress = i;
        this.loadData()

      }
      this.getProgress==100&&(this.completed = true);
  }
  delay(ms: number) {
    return new Promise((resolve, reject) => setTimeout(resolve, ms));
  }

}
