import { Component, OnInit,Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbCarouselConfig ,NgbModal,NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { CmsService } from 'src/app/core/services/cms/cms.service';
import { CourseService } from 'src/app/core/services/course/course.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { LoginComponent } from '../../pages/login/login.component';
import { RegisterComponent } from '../../pages/register/register.component';
declare var bootbox: any;

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  providers: [NgbCarouselConfig,NgbTabsetConfig]  
})
export class BannerComponent implements OnInit {
  @Input('page') page: string;
  @Input('course') course: any;
  @Input('slug') slug: any;

  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef; 
  @Output() childBanner = new EventEmitter();
  banners: any = [];
  home="banner";
  model ={
    first_name:'',
    second_name:'',
    phone:'',
    email :''
  };
  page_load=false;
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  constructor(config: NgbCarouselConfig,configure: NgbTabsetConfig, public sanitizer: DomSanitizer,
    private cmsService: CmsService,  private modalService: NgbModal,public router:Router,
    private courseService:CourseService,private userService:UserService, private title: Title, private meta: Meta) {
    config.interval = 5000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = true;
    configure.justify = 'center';
    configure.type = 'pills';
    
  }
  openModal() {
    const modalRef = this.modalService.open(RegisterComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
        // keyboard: false,
        // backdrop: 'static'
      });

    let data = {
      prop1: 'Some Data',
      prop2: 'From Parent Component',
      prop3: 'This Can be anything'
    }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }
  openBookcall(){
    // this.router.navigate(['/calendly']);

    this.router.navigateByUrl('/book-call')
  }
  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }
  goToCheckout(course){
    var _this = this;
    bootbox.confirm({
      message:"<h5>Confirm Payment!</h5><h6>Course Fees:"+course.currency+ ' '+course.fees+"</h6>", 
        buttons: {
          confirm: {
            label: 'Submit',
            className: 'success'
            },
            cancel: {            
                className: 'fail'
            }         
      },
        callback:function(result){    
          console.log("result",result) 
          if(result)        
          _this.saveOrder(course)
         
      }   
    });
  }
 

  saveOrder(course){
    this.userService.getUser().subscribe(data=>{
      this.courseService.saveEnrollDetails({
        course_id:course.encrypted_id,
        documents:[],
        user_id:data.profile.id,
        fields:[]  
      }).subscribe(async (resp: any) =>{
        console.log("res",resp) 
        var dialog = bootbox.dialog({
          message: "Thank you for applying to the course.The enrollment will be completed once the admin verifies your request. You will receive an email confirmation once the admin approves your request.",
          closeButton: true
        }); 
        this.getCourseDetails();
        this.childBanner.emit("success")
      }, (err: any) => {
        console.log(err);
      })
    })
  }
  openLoginModal() {
		const modalRef = this.modalService.open(LoginComponent,
		  {
			scrollable: true,
			windowClass: 'myModalClass',
			// keyboard: false,
			// backdrop: 'static'
		  });
	
		let data = {
		  prop1: 'program-details',
		  prop2: this.slug,
		  prop3: 'This Can be anything'
		}
	
		modalRef.componentInstance.fromParent = data;
		modalRef.result.then((result) => {
		  console.log(result);
		}, (reason) => {
		});
    }
    openReferLoginModal() {
      const modalRef = this.modalService.open(LoginComponent,
        {
        scrollable: true,
        windowClass: 'myModalClass',
        // keyboard: false,
        // backdrop: 'static'
        });
    
      let data = {
        prop1: 'refer-earn',
        prop2: '',
        prop3: 'This Can be anything'
      }
    
      modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
        console.log(result);
      }, (reason) => {
      });
      }
    openAppliedModal() {
      var dialog = bootbox.dialog({
        message:"This course is already applied!",
        closeButton: true
      });
      }
    getCourseDetails(){
      this.cmsService.getCourseDetails({slug:this.slug}).subscribe(res=>{   
        this.course = res['course'];
          // this.course.duration = this.courseService.getWeken(this.course.start_date,this.course.end_date)
               console.log("course-banner",this.course)
      })
    }
  ngOnInit(): void {


    if(this.slug){
    this.getCourseDetails();
    }
    this.page_load =true;

    this.cmsService.getBanner(this.page).subscribe((resp: any) => {
      this.banners = resp.banners;
      this.page_load = false;			

      console.log(this.banners);
      
    }, (err: any) => {
      console.log(err);
    });
  }

  onSubmit(form){
    let data = form.value;
    data.course_id = this.course.id;
    data.phone = form.value.phone?.e164Number;
    let element = document.getElementById('download') as HTMLElement;
    element.setAttribute('disabled', 'true');
    this.courseService.submitEnquiry(data).subscribe(async (resp: any) =>{
      console.log("response",resp);
      var dialog = bootbox.dialog({
        message: "We have send 'Edubex Course Package Details' to your mail, Please check your mail.",
        closeButton: true
      });
      element.removeAttribute('disabled');
      form.resetForm();
    });
  }

  reload($event){
   this.getCourseDetails();
   this.childBanner.emit("success")
  }
  getPrograms(){
    this.router.navigateByUrl('/programs')
  }
}
