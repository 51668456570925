import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { HiringPartnerComponent } from './hiring-partner/hiring-partner.component';
import { ProgramsComponent } from './programs/programs.component';
import { ProgramDetailComponent } from './program-detail/program-detail.component';
import { CareersComponent } from './careers/careers.component';
import { CareerListComponent } from './career-list/career-list.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { HowWeStandApartComponent } from './how-we-stand-apart/how-we-stand-apart.component';
import { BecomeInstructorComponent } from './become-instructor/become-instructor.component';
import { UniversityPathwaysComponent } from './university-pathways/university-pathways.component';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from 'src/app/core/services/auth/auth.guard';
import { OurStoryComponent } from './our-story/our-story.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { OnDemandComponent } from './on-demand/on-demand.component';
import { CalendarComponent } from './calendar/calendar.component';
import { PresentersComponent } from './presenters/presenters.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { GdprComponent } from './gdpr/gdpr.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { WebinarsComponent } from './webinars/webinars.component';
import { EventsComponent } from './events/events.component';
import { AcademicPoliciesComponent } from './academic-policies/academic-policies.component';
import { ExaminationGradesComponent } from './examination-grades/examination-grades.component';
import { NetworkBenefitsComponent } from './network-benefits/network-benefits.component';
import { ParticipantSpeaksComponent } from './participant-speaks/participant-speaks.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { AppliedCoursesComponent } from './applied-courses/applied-courses.component';
import { ApprovedCoursesComponent } from './approved-courses/approved-courses.component';
import { CourseApplyComponent } from './course-apply/course-apply.component';
import { CoursePaymentComponent } from './course-payment/course-payment.component';
import { SuccessPaymentComponent } from './success-payment/success-payment.component';
import { SynapseComponent } from './synapse/synapse.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { BookCallComponent } from './book-call/book-call.component';
import { CorporateTrainingComponent } from './corporate-training/corporate-training.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { ScholarshipComponent } from './scholarship/scholarship.component';
import { SuccessStoryComponent } from './success-story/success-story.component';
import { SupportServiceComponent } from './support-service/support-service.component';
import { CalendarListComponent } from './calendar-list/calendar-list.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { FreeCoursesComponent } from './free-courses/free-courses.component';
import { BootcampComponent } from './bootcamp/bootcamp.component';
import { FreeDashboardComponent } from './free-dashboard/free-dashboard.component';
import { ReferEarnComponent } from './refer-earn/refer-earn.component';
import { ArticulateComponent } from './articulate/articulate.component';
import { WebinarDetailComponent } from './webinar-detail/webinar-detail.component';
import { WebinarRecordingComponent } from './webinar-recording/webinar-recording.component';
import { BootcampDetailComponent } from './bootcamp-detail/bootcamp-detail.component';
import { BootcampPaymentComponent } from './bootcamp-payment/bootcamp-payment.component';
import { WebinarPaymentComponent } from './webinar-payment/webinar-payment.component';
import { FreecoursePaymentComponent } from './freecourse-payment/freecourse-payment.component';
import { BootcampHistoryComponent } from './bootcamp-history/bootcamp-history.component';
import { WebinarHistoryComponent } from './webinar-history/webinar-history.component';
import { AppliedFreecoursesComponent } from './applied-freecourses/applied-freecourses.component';

const routes: Routes = [
  // {path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: '', component: HomeComponent },

  { path: 'hiring-partner', component: HiringPartnerComponent },
  { path: 'corporate-training', component: CorporateTrainingComponent },
  // canActivate:[AuthGuard]
  { path: 'programs', component: ProgramsComponent },
  { path: 'programs/:id', component: ProgramsComponent },
  { path: 'program-details/:id', component: ProgramDetailComponent },

  { path: 'program-details/:id/:code', component: ProgramDetailComponent },
  { path: 'careers', component: CareersComponent },
  { path: 'career-list', component: CareerListComponent },
  { path: 'career-list/:id', component: CareerListComponent },
  { path: 'our-services', component: OurServicesComponent },
  { path: 'how-we-stand-apart', component: HowWeStandApartComponent },
  { path: 'become-instructor', component: BecomeInstructorComponent },
  { path: 'university-pathway', component: UniversityPathwaysComponent },
  // { path: 'login', component:LoginComponent},
  // { path: 'register', component:RegisterComponent},
  { path: 'reviews-and-testimonials/:id', component: SuccessStoryComponent },

  { path: 'our-story', component: AboutUsComponent },
  { path: 'scholarship', component: ScholarshipComponent },
  // { path: 'our-story', component:OurStoryComponent},
  { path: 'stories', component: OurStoryComponent },
  { path: 'support-services', component: SupportServiceComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/:id', component: BlogDetailsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'on-demand', component: OnDemandComponent },
  { path: 'calendly', component: CalendarComponent },
  { path: 'calendar', component: CalendarListComponent },
  { path: 'presenters', component: PresentersComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'cookie-policy', component: CookiePolicyComponent },
  { path: 'gdpr', component: GdprComponent },
  { path: 'terms-of-service', component: TermsOfServiceComponent },
  { path: 'schedule', component: ScheduleComponent },
  { path: 'schedule/:id', component: ScheduleComponent },
  { path: 'webinars', component: WebinarsComponent },
  { path: 'webinar-details/:id', component: WebinarDetailComponent },
  { path: 'webinar/unlock/:id', component: WebinarRecordingComponent },

  { path: 'free-courses', component: FreeCoursesComponent },
  { path: 'free-courses/:id', component: FreeCoursesComponent },
  { path: 'bootcamps', component: BootcampComponent },
  { path: 'bootcamp-details/:id', component: BootcampDetailComponent },

  { path: 'events', component: EventsComponent },
  { path: 'academic-policies', component: AcademicPoliciesComponent },
  { path: 'examination-grades', component: ExaminationGradesComponent },
  { path: 'network-benefits', component: NetworkBenefitsComponent },
  { path: 'participant-speaks', component: ParticipantSpeaksComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'transaction_history',
    component: TransactionHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'applied-courses',
    component: AppliedCoursesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'applied-freecourses',
    component: AppliedFreecoursesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'applied-bootcamps',
    component: BootcampHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'applied-webinars',
    component: WebinarHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'approved-courses',
    component: ApprovedCoursesComponent,
    canActivate: [AuthGuard],
  },
  { path: 'pay-enroll', component: CourseApplyComponent },
  { path: 'success-payment', component: SuccessPaymentComponent },
  { path: 'course-payment/:id', component: CoursePaymentComponent },
  { path: 'bootcamp-payment/:id', component: BootcampPaymentComponent },
  { path: 'webinar-payment/:id', component: WebinarPaymentComponent },
  { path: 'freecourse-payment/:id', component: FreecoursePaymentComponent },

  { path: 'synapse', component: SynapseComponent },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
  },
  { path: 'reset-password/:id', component: ResetPasswordComponent },
  { path: 'book-call', component: BookCallComponent },
  {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'freecourses/dashboard',
    component: FreeDashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: 'refer&earn', component: ReferEarnComponent },
  { path: 'scromcontent/:id', component: ArticulateComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
