import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/core/services/common/common.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  linkedInToken = "";
  auth;
  
  constructor(private route: ActivatedRoute,private commonService:CommonService) {}
  
  ngOnInit() {
    this.linkedInToken = this.route.snapshot.queryParams["code"];
    console.log("this",this.linkedInToken);
    {
    this.auth={
      grant_type: "authorization_code",  // value of this field should always be: 'authorization_code'
      code: this.linkedInToken,
      redirect_uri:"http://localhost:4200/events",  // The same redirect_url used in step 2.1 (in login.component.ts)
      client_id: '782k48us3mvzki', // Follow step 1.2
      client_secret:'rd8FouevD1rqOiFM'   // Follow step 1.2
    } 
   this.commonService.getLinkedinToken(this.auth)
          .subscribe(result => {
            console.log("result",result);
        });
  }
}
}
