import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { UserService } from 'src/app/core/services/user/user.service';
import { environment } from 'src/environments/environment';
declare var bootbox:any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  @Output() newItemEvent = new EventEmitter<boolean>();
  @Input('show') show: boolean;
  signup: boolean = false;
  banner = "login"
  faTimesCircle = faTimesCircle;
  email='';
  ngOnChanges(changes) {
    console.log("changes", changes)
  }
  //showModal:boolean;
  constructor(private router: Router, private userService: UserService) { }
  showErrorMessage = "";
  ngOnInit(): void {
    console.log("show", this.show)
  }
  updateValue(value) {
    console.log("value-login",value)
    this.show = true;
  }

  onSubmit(form:NgForm) {
    console.log(form.value)
    this.userService.forgotPassword(     
      form.value.email,
    ).subscribe(
      res => {
        console.log("res",res)
        if(res.status){
          var dialog = bootbox.dialog({
            message: "Verification Email Sent. Check email to reset the password!!",
            closeButton: true
          });
          this.show = false;
        }else{
          var dialog = bootbox.dialog({
            className: 'block',
            message: "Email address you entered is not assigned to a registered user!!",
            closeButton: true
          });
          form.resetForm()
        }
   
       // this.router.navigate(['/dashboard']);
      },
      err => {
        console.log("err", err)
        if (err.status === 400) {
          form.resetForm()
       
        }

      }
    );
  }
  showForm() {
    this.show = true;
    this.newItemEvent.emit(this.show);// Show-Hide Modal Check

  }
  //Bootstrap Modal Close event
  hide(form) {
    this.show = false;
  form.resetForm();
    console.log("show", this.show);
    
  }


}
