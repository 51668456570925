import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Observable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { CourseService } from 'src/app/core/services/course/course.service';
import { Router } from '@angular/router';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green:{
    primary:'#004d00',
    secondary:'#99ff99'
  }
};
@Component({
  selector: 'app-calendar-list',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar-list.component.html',
  styleUrls: ['./calendar-list.component.scss']
})
export class CalendarListComponent implements OnInit{

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  
  refresh: Subject<any> = new Subject();
  events :any=[];
  courses :any=[];
  allCategories:any =[];
  today=true;
  //  [
  //   {
  //     start: subDays(startOfDay(new Date()), 1),
  //     end: addDays(new Date(), 1),
  //     title: 'Coding Techniques',
  //     color: colors.red,
  //    //actions: this.actions,
  //     allDay: true,
  //     resizable: {
  //       beforeStart: true,
  //       afterEnd: true,
  //     },
  //     draggable: true,
  //   },
  //   {
  //     start: startOfDay(new Date()),
  //     title: 'PHP Frameworks',
  //     color: colors.yellow,
  //     // actions: this.actions,
  //   },
  //   {
  //     start: subDays(endOfMonth(new Date()), 3),
  //     end: addDays(endOfMonth(new Date()), 13),
  //     title: 'QA methodologies',
  //     color: colors.blue,
  //     // allDay: true,
  //   },
  //   {
  //     start: addHours(startOfDay(new Date()), 2),
  //     end: addHours(new Date(), 2),
  //     title: 'Jira Tools',
  //     color: colors.green,
  //     // actions: this.actions,
  //     resizable: {
  //       beforeStart: true,
  //       afterEnd: true,
  //     },
  //     draggable: true,
  //   },
  // ];

  activeDayIsOpen: boolean = true;
ngOnInit(){
  this.courseService.getHomeLongTermCourses().subscribe(async (resp: any) =>{
    this.courses = resp.data; 
    this.events= []; 
    this.allCategories=resp.categories;
    console.log("this.all",resp.data)

    var keys = Object.keys(colors);
  
    this.courses.forEach(element => {
       let color= colors[keys[ keys.length * Math.random() << 0]];
      this.events.push({
        start: subDays(startOfDay(new Date(element.start_date)), 0),
        end:  subDays(startOfDay(new Date(element.start_date)), 0),
        title: element.title,
        color: color,
        slug:element.slug,
       //actions: this.actions,
        allDay: true,
      })
      console.log("event")
    });
  }, (err: any) => {
    console.log(err);
  })
}
  constructor(private modal: NgbModal,private courseService:CourseService,public router:Router) {
   

  }
getToday(today){
  console.log("today",today)
  let check = this.isToday(today)
  console.log("check",check)
  if(check)
  this.today= true
  else
  this.today=false

}
isToday = (someDate) => {
  const today = new Date();
  console.log(today,someDate,this.CalendarView.Month)
  if(this.view === this.CalendarView.Month){

    return  someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  }
  if(this.view===this.CalendarView.Week){
    return someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  }
  if(this.view===this.CalendarView.Day){
    return someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  }
 
 
}
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
  filterByCategory(event){
    console.log("event")
    let selected =[]
    if(event.target.value!=""){
      selected = this.courses.filter(course=>{
        return course.category_slug===event.target.value
      })
    }else selected = this.courses;
       
      var keys = Object.keys(colors);
    this.events=[];
    selected.forEach(element => {
       let color= colors[keys[ keys.length * Math.random() << 0]];
      this.events.push({
        start: subDays(startOfDay(new Date(element.start_date)), 0),
        end:  subDays(startOfDay(new Date(element.start_date)), 0),
        title: element.title,
        color: color,
        slug:element.slug,
       //actions: this.actions,
        allDay: true,
      })
    });
  }
getLive(event){
  let selected = []
  if(event.target.value==='live'){
    selected = this.courses.filter(course=>{
      return course.is_online==='1'
    })
  }else {
    selected =this.courses;
  }
 
  var keys = Object.keys(colors);
  this.events=[];
  selected.forEach(element => {
     let color= colors[keys[ keys.length * Math.random() << 0]];
    this.events.push({
      start: subDays(startOfDay(new Date(element.start_date)), 0),
      end:  subDays(startOfDay(new Date(element.start_date)), 0),
      title: element.title,
      color: color,
      slug:element.slug,
     //actions: this.actions,
      allDay: true,
    })
  });
}
  handleEvent(action: string, event: CalendarEvent): void {
    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
    this.router.navigateByUrl('/program-details/'+event['slug'])
  }



  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

}
