import {  ElementRef,Renderer2,Component, OnInit,NgModule, ViewEncapsulation, ChangeDetectorRef, EventEmitter, ViewChild, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { NgbCarouselConfig, NgbTabsetConfig,NgbModule, NgbModal, NgbAccordion, NgbPanelChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import { faRupeeSign, faUsers, faArrowRight, faCog, faRobot, faCloud,  faCalendar,  faCalendarPlus, faCalendarTimes, faCalendarWeek, faCalendarCheck, faCalendarDay, faChevronRight, faGreaterThan, faLessThan, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faClock,faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { MasterService } from 'src/app/core/services/master/master.service';
import { CmsService } from 'src/app/core/services/cms/cms.service';
import * as _ from 'lodash';
import { CourseService } from 'src/app/core/services/course/course.service';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { UserService } from 'src/app/core/services/user/user.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { RegisterComponent } from '../register/register.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { TawkService } from 'src/app/core/services/common/tawk.service';
import { Meta, Title } from '@angular/platform-browser';
import { fromEvent, Subscription } from 'rxjs';
import { PopupComponent } from '../popup/popup.component';

declare var bootbox:any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NgbCarouselConfig, NgbTabsetConfig,NgbModule,

		{ provide: CarouselConfig, useValue: { interval: 1500, noPause: false, showIndicators: true } }

]  // add NgbCarouselConfig to the component providers
})
export class HomeComponent implements OnInit {
	@ViewChildren('allTheseThings') things: QueryList<any>;
    @ViewChild(PopupComponent) child:PopupComponent;


	clickedElement: Subscription = new Subscription();
	clicked=1;
	imgSrc;
	noWrapSlides = false;
	showIndicator = true;
	fas = fas;
	all:any=[];
	list:any=[];
	alumini:any =[];
	stories: any = {};
	longTerm:any = [];
	newGuidance:any = [];
	bootcamps:any=[];
	guidance:any=[];
	scheduled_courses:any =[];
	shortTerm:any=[];
	term:boolean =false;
	whatWeOffer;
	liveOnline;
	privateLearning;
	freecourses:any=[];
	eLearning;
	guidanceIndex=0;
	mobile_guidance=0
	instructor:any=[]
	storyOptions: OwlOptions = {
		loop: true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
		navText: ['', ''],
		slideBy:4,
		autoplay:true,
		responsive: {
		  0: {
			items: 1
		  },
		  400: {
			items: 2
		  },
		  740: {
			items: 4
		  },
		  940: {
			 items: 4,
			 loop:false
		  }
		},
		nav: false
	  }
	  category="working";
	  termOptions: OwlOptions = {
		loop: false,
		autoplay:true,
		autoWidth:true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
		navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
		responsive: {
		0: {
			items: 1.2,
			nav: false
		},
		400: {
			items:1.2,
			nav: false
			// items:this.scheduled_courses.length<3?this.scheduled_courses.length:5
		},
		740: {
			items: 3
			// items: this.scheduled_courses.length<3?this.scheduled_courses.length:5,
		},
		940: {
			//items: 3,
			loop:false
		}
		},
		nav: true
	  }
	  termsOptions: OwlOptions = {
		loop: false,
		autoplay:true,
		autoWidth:true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
		navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
		responsive: {
		0: {
			items: 1.2,
			nav: false
		},
		400: {
			items:1.2,
			nav: false
			// items:this.scheduled_courses.length<3?this.scheduled_courses.length:5
		},
		740: {
			items: 4
			// items: this.scheduled_courses.length<3?this.scheduled_courses.length:5,
		},
		940: {
			items: 4,
			loop:false
		}
		},
		nav: true
	  }
	  instrOptions: OwlOptions = {
		loop: false,
		autoplay:true,
		autoWidth:true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 1500,
		navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
		responsive: {
		0: {
			items: 1.2,
			nav: false
		},
		400: {
			items:1.2,
			nav: false
			// items:this.scheduled_courses.length<3?this.scheduled_courses.length:5
		},
		740: {
			items: 4
			// items: this.scheduled_courses.length<3?this.scheduled_courses.length:5,
		},
		940: {
			items: 4,
			loop:false
		}
		},
		nav: true
	  }
	  shorttermOptions: OwlOptions = {
		loop: false,
		autoplay:true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: true,
		navSpeed: 700,
		navText: ['', ''],
		responsive: {
		  0: {
			items: 1
		  },
		  400: {
			items: 1,
		  },
		  740: {
			//items: 3
			// items: this.shortTerm.length<3?this.shortTerm.length:2,
		  },
		  940: {
			 //items:3,
			// items: this.shortTerm.length<3?this.shortTerm.length:2,
			loop:false
		  }
		},
		nav: false
	  }
	  exploreOptions: OwlOptions = {
		loop: true,
		autoplay:true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
		navText: ['', ''],
		items:1,
		responsive: {
			0: {
				items: 1
			  },
		  400: {
			items: 1
		  },

		},
		nav: false
	  }
	  aluminiOptions: OwlOptions = {
		loop: true,
		autoplay:true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
autoplayTimeout:1000,
		navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>',
		'<i class="fa fa-angle-right" aria-hidden="true"></i>'],
		responsive: {
		  0: {
			items: 2
		  },
		  400: {
			items: 3
		  },
		  740: {
			items: 5
		  },
		  940: {
			items: 7,
			loop:false
		  }
		},
		nav: false
	  }

	banners: any = [];
	faRupeeSign = faRupeeSign;
	faArrowRight = faArrowRight;
	faClock = faClock;
	faUsers = faUsers;
	faCloud = faCloud;
	faRobot = faRobot;
	faCog = faCog;
	faArrowLeft=faArrowLeft;
	faLessThan=faLessThan;
	faChevronRight = faChevronRight;
	faCalendarAlt = faCalendarAlt;
	icons:any=[];

	categories: any=[];

	home='home';
	page: String = 'home';
	login =false;
	long:any;
	courses: any;
	keys: string[];
	selectedIdx = 0;
	calendar: any;
	learning_experiences: any;
	signup=false;
	finance_partners: any;
	whatweoffers: any;
	news:any =[];
	explore_all: any;
	onlinre_program: any;
	online_program: any;
	exp: _.Dictionary<any[]>;
	popups: any;


	constructor( configure: NgbTabsetConfig,
		private courseService:CourseService,
		private cd: ChangeDetectorRef,
		private cmsService:CmsService, private masterService: MasterService,
		public userService:UserService,private commonService:CommonService,
		public modalService:NgbModal,
		public _activatedRoute:ActivatedRoute,
		public router:Router,
		private titleService: Title,
   		private metaService: Meta,
		 public renderer: Renderer2) {

		configure.justify = 'center';
		configure.type = 'pills';
		// config.interval = 2000;
		// config.keyboard = true;
		// config.pauseOnHover = true;

 }

//  ngAfterViewInit() {
// 	this.things.changes.subscribe(t => {
// 		console.log("id");
// 		alert("hi");
// 		document.getElementById("guid-1").click();
// 	  })


//   }
call(){
}
	ngOnInit(): void {

		this.masterService.getPopup().subscribe((resp: any) => {
			console.log("resp--------------------",resp)
			this.popups=resp.popupoffers;
			if(this.popups.length) {
				// alert(this.child.showModal1)
				this.child.show('open')

			}
		   
			}, (err: any) => {
			console.log(err);
			});
// 		this.guidance=[
// 			{id:1,
// 			value:"What Are Coding Bootcamps?"},
// 			{id:2,
// 			value:"How Do Coding Bootcamps Work?"},
// 			{id:3,
// 			value:"How Do I Choose my Tech Career Path?"},
// 			{id:4,
// 			value:"What Are Coding Bootcampsbbb?"},
// 			{id:5,
// 			value:"How Do Coding Bootcamps Workhgghgh?"},
// 			{id:6,
// 			value:"How Do I Choose my Tech Career Pathbhg?"},
// 			{id:7,
// 				value:"How Do Coding Bootcamps Workhgghgh?"},
// 				{id:8,
// 				value:"How Do I Choose my Tech Career Pathbhg?"},
// ]

		this.getHomeStories();
		this.getLongTermCourses();
		this.getShortTermHomeCourses();
		this.getExploreCourses();
		// this.getShortTermCourses();
		this.getAlumini();
		this.getBootcampLatest();
		this.getLearningExperiences();
		this.getSchedule();
		this.getFreeCourses();

		this.masterService.whatWeOffer().subscribe(res=>{
			console.log("finance",res)
			this.whatweoffers = res['whatweoffers'];
		})

		this.masterService.bannerNotifications().subscribe(res=>{
			console.log("notify",res)
			this.news = res['banner_notifications'];
		})
		this._activatedRoute.queryParams

      .subscribe(params => {
		console.log("params------>",params);
		if(params.act==="login"){
			if(this.userService.isLoggedIn()){
				this.router.navigateByUrl('/free-courses')
			}else
			this.openLoginModal();
		}
      }
    );
	this.masterService.getQuickGuidance().subscribe(res=>{
		console.log("quick",res)
		this.guidance = res['quickguidance'];
		let guidanceCopy=[...this.guidance]
	this.newGuidance=guidanceCopy.splice(0,4);
	(0<this.guidance.length && (0+4)<this.guidance.length) ?this.guidanceIndex=4:this.guidanceIndex=0;
	})
	this.masterService.getSeo('home').subscribe((resp: any) => {
		console.log("resp",resp)
		if(resp.status)
		  this.titleService.setTitle(resp.data.title); // <-- Update the title

		  this.metaService.updateTag({
			name: 'description',
			content: resp.data.meta_description
		});
		this.metaService.updateTag({
			name: 'title',
			content: resp.data.meta_title
		});
	  }, (err: any) => {
		console.log(err);
	  });
	  this.cmsService.getPageInro('what-we-offer').subscribe(res=>{
		console.log(this.page,res)
		this.whatWeOffer = res['cms'];
		})
		this.cmsService.getPageInro('online-program-mangement').subscribe(res=>{
			console.log(this.page,res)
			this.online_program = res['cms'];
			})
		this.masterService.getInstructors().subscribe(res=>{
			console.log(this.page,res)
			this.instructor = res['instructors'];
			})
		this.cmsService.getPageInro('live-online').subscribe(res=>{
		console.log(this.page,res)
		this.liveOnline = res['cms'];
		})
		this.cmsService.getPageInro('private-learning').subscribe(res=>{
			console.log(this.page,res)
			this.privateLearning = res['cms'];
		})
		this.cmsService.getPageInro('e-learning').subscribe(res=>{
			console.log(this.page,res)
			this.eLearning = res['cms'];
		})
		this.masterService.getFinancePartners().subscribe(res=>{
			console.log("finance",res)
			this.finance_partners = res['financepartner'];
		})

			this.masterService.getStoriesAll().subscribe(res=>{
				this.list =res.stories;
				let my_pic ={
					image:"assets/images/profile.png"
				}
				this.list.push(my_pic)
				console.log("details",res);
			  })
	}
	images=[
		{
		 img:"../../../../assets/images/course/upcoming-course-01.jpg"
	  },
		{
		img:"../../../../assets/images/course/upcoming-course-02.jpg"
	  },
		{
		img:"../../../../assets/images/course/upcoming-course-03.jpg"
	  },
	]
	termSelect(term){
		console.log("clck",term)
		if(term==='short'){
			this.term = false;
		}
		if(term==='long'){
			this.term =true
		}
	}
	setnewGuidance=(id)=>{
		console.log("enter",id,this.guidance.length);
		let guidanceCopy=[...this.guidance]
		this.newGuidance=guidanceCopy.splice(id,4);
		(id<this.guidance.length && (id+4)<this.guidance.length) ?this.guidanceIndex=id+4:this.guidanceIndex=0;
		console.log("newArray",this.newGuidance);
		this.clicked=1;
		// document.getElementById("guid-1").click();


	  }
	  setGuidance=(id)=>{
		(id<this.guidance.length-1 ) ?this.mobile_guidance=id+1:this.mobile_guidance=0;
		console.log(this.mobile_guidance,id,this.guidance.length);
  
	}


	imgRand(){
		let img = this.images[Math.floor(Math.random() *    this.images.length)];
		return img.img;
	 }

	 openLoginModal() {
		const modalRef = this.modalService.open(LoginComponent,
		  {
			scrollable: true,
			windowClass: 'myCustomModalClass',
		  });

		let data = {
		  prop1: 'Some Data',
		  prop2: 'From Parent Component',
		  prop3: 'This Can be anything'
		}

		modalRef.componentInstance.fromParent = data;
		modalRef.result.then((result) => {
		  console.log(result);
		}, (reason) => {
		});
	  }

	 openModal(slug) {
		const modalRef = this.modalService.open(LoginComponent,
		  {
			scrollable: true,
			windowClass: 'myCustomModalClass',
			// keyboard: false,
			// backdrop: 'static'
		  });

		let data = {
		  prop1: 'home',
		  prop2:slug,
		  prop3: 'This Can be anything'
		}

		modalRef.componentInstance.fromParent = data;
		modalRef.result.then((result) => {
		  console.log(result);
		}, (reason) => {
		});
	  }
	  getSchedule(){
		this.courseService.getSchedule({trending:1,new:1}).subscribe(res=>{

			this.scheduled_courses =res.data;
			// this.scheduled_courses.forEach(ele => {

			// 	ele.duration = this.courseService.getWeken(ele.start_date,ele.end_date);
			// });
			console.log("dtaa",this.scheduled_courses);
		})
	  }
	  openBookcall(){
		// this.router.navigate(['/calendly']);

		this.router.navigateByUrl('/book-call')
	  }
	 checkIcon(icon){
		 switch(icon){
			 case 'faRupeeSign':return faRupeeSign;
			 break;
			 case 'faArrowRight' :return faArrowRight;
			 break;
			 case'faUsers':return faUsers;
			 break;
			 case 'faCog':return faCog;
			 break;
			 case 'faRobot' :return faRobot;
			 break;
			 case'faCloud':return faCloud;
			 break;
			 case 'faRupeeSign':return faRupeeSign;
			 break;
			 case 'faArrowRight' :return faArrowRight;
			 break;
			 case'faUsers':return faUsers;
			 break;
			 case 'faRupeeSign':return faRupeeSign;
			 break;
			 case 'faArrowRight' :return faArrowRight;
			 break;
			 case'faUsers':return faUsers;
			 break;
			 default: return faCloud;
		}
	 }
	getShortTermCourses(){
		console.log("short term--->")
		const accessToken = this.userService.getToken();
		this.commonService.getHomeShortTermCourses(accessToken).subscribe( (resp:any)=>{
			this.shortTerm=resp.Items;
			let i =0;
			this.shortTerm.forEach(element => {

				element['image']=this.images[i].img;
				i++;
				if(i==3) i=0;

			});
			// this.shortTerm.forEach(ele => {

			// 	ele.duration = this.courseService.getWeken(ele.StartDateTime,ele.EndDateTime);
			// });
		}, (err: any) => {
			console.log(err);
		})
	}
	getShortTermHomeCourses(){
		this.courseService.shortTermCourses().subscribe( (resp:any)=>{
			console.log("response->shorttt",resp)
			this.shortTerm = resp.data;
			// this.shortTerm.forEach(ele => {

			// 	ele.duration = this.courseService.getWeken(ele.start_date,ele.end_date);
			// });
		}, (err: any) => {
			console.log(err);
		})
	}


	getHomeStories(){
		this.masterService.getHomeAll().subscribe((resp: any) => {
			this.stories = resp;
			console.log("stories--------->",resp)
		}, (err: any) => {
			console.log(err);
		});
	}
	reload($event){
		this.getSchedule();
		this.getShortTermHomeCourses()
	  }



	getLongTermCourses(){
		// this.courseService.getHomeLongTermCourses().subscribe( (resp:any)=>{
			this.courseService.getUpcomingCourses(2).subscribe( (resp:any)=>{
			this.longTerm = resp.data;
			console.log("longterm",resp.data)
			let all = resp.data;
			this.all = resp.data;
			this.long = _.groupBy(all, function (course) {
			return course.category;
			});


		}, (err: any) => {
			console.log(err);
		})
	}
	getFreeCourses(){
		this.courseService.getFreeCourseCategory().subscribe( (resp:any)=>{

			// this.longTerm = resp.data;
			console.log("freecourse",resp.categories)
			this.freecourses = resp.categories;

			// this.courses = Object.values(this.long)[0];
			console.log("courses---------->",this.courses,this.long)
		}, (err: any) => {
			console.log(err);
		})
	}
	getExploreCourses(){
			this.courseService.getUpcomingCourses(1).subscribe( (resp:any)=>{

				// this.longTerm = resp.data;
				console.log("longterm",resp.data)
				let all = resp.data;
				this.explore_all =resp.data
				this.categories =  resp.categories
				let long = _.groupBy(all, function (course) {
					return course.category;
				});
				this.categories.forEach(cat=>{
					cat.icon=cat.icon_green
				})
				this.categories.unshift({title:"For Working Professionals",icon_green: 'assets/images/logo/wh1.png',icon_white: 'assets/images/logo/wh1h.png',slug: 'For Working Professionals',icon:'assets/images/logo/wh1h.png'})

				let working=resp.data.filter(a=>a.working_proffessional=="1")
				// console.log("working----------",working)
				long=Object.assign({'For Working Professionals':working}, long);
				console.log("long-------",long,)
				this.exp =long
				// for (const key in long) {
				// 	if(this.categories[0].title === key){
						this.courses = long['For Working Professionals']
				// 	}
				// }

				// this.courses = Object.values(this.long)[0];
				console.log("courses---------->",this.courses,this.long)
			}, (err: any) => {
				console.log(err);
			})
	}
	getLearningExperiences(){
		this.courseService.getHomeLearningExperiences().subscribe(res=>{
			console.log("dtaa",res);
			this.learning_experiences =res['learningexperience'];
		})
	}
	goToRegister =()=> {
		this.signup=true;
		console.log("sign",this.signup)
		this.cd.detectChanges();
	}

	getCourse(longterm,category,i){
		console.log("longterm",longterm,category,i)
		longterm.icon = longterm.icon_green
		
		if(category==='For Working Professionals'){
		this.courses = this.explore_all.filter(res=>{
			return res.working_proffessional === "1"
		});
		this.category="working"
	}else{
		this.courses = this.explore_all.filter(res=>{
			return res.category_slug === category
		});
		this.category="";
	}
	 
	  this.selectedIdx = i;
	  for(let j=0;j<this.categories.length;j++){
		  if(this.selectedIdx==j) this.categories[j].icon = this.categories[j].icon_white
		  else this.categories[j].icon = this.categories[j].icon_green
	  }
	}

	getAlumini(){
		this.cmsService.getAlumini().subscribe((resp: any) => {
			this.alumini = resp.alumini;
		}, (err: any) => {
			console.log(err);
		});
	}
	getBootcampLatest(){
		this.courseService.bootcampLatest().subscribe((resp: any) => {
			console.log("bootcamp",resp)
			this.bootcamps=resp.bootcamp_features
		}, (err: any) => {
			console.log(err);
		});
	}

	saveOrder(course){
		this.userService.getUser().subscribe(data=>{
		console.log("user",data)
		this.courseService.saveEnrollDetails({
			course_id:course.id,
			documents:[],
			user_id:data.profile.id,
			fields:[]
		}).subscribe(async (resp: any) =>{
			console.log("res",resp)
			var dialog = bootbox.dialog({
				message: "Thank you for applying to the course.The enrollment will be completed once the admin verifies your request. You will receive an email confirmation once the admin approves your request.",
				closeButton: true
			});
			this.getSchedule();
			this.getShortTermHomeCourses();
		  }, (err: any) => {
			console.log(err);
		  })
		})
	  }

	  goToCheckout(course){
		  console.log("currency",course)
		var _this = this;
		bootbox.confirm({
			message:"<h5>Confirm Payment!</h5><h6>Course Fees:"+course.currency+ ' '+course.price+"</h6>",
			  buttons: {
				confirm: {
					label: 'Submit',
					className: 'success'
				},
					cancel: {
						className: 'fail'
				}
			},
			  callback:function(result){
			  if(result){
				_this.saveOrder(course)
			  }
			}
		  });
	  }

	   @ViewChild('myaccordion', {static : true}) accordion: NgbAccordion;

  beforeChange($event: NgbPanelChangeEvent) {
    console.log($event.panelId);
    // if ($event.panelId === 'panelOne') {
    //   $event.preventDefault();
    // }

    // if ($event.panelId === 'panelTwo' && $event.nextState === false) {
    //   $event.preventDefault();
    // }
  }

  togglePanel(id){
    this.accordion.toggle(id);
  }
  openTab(evt, id) {
	  this.clicked =id;
	//   console.log("openTab----",id, id.toString())
	// var i, tabcontent, tablinks;
	// tabcontent = document.getElementsByClassName("tabcontent");
	// for (i = 0; i < tabcontent.length; i++) {
	//   tabcontent[i].style.display = "none";
	// }
	// tablinks = document.getElementsByClassName("tablinks");
	// for (i = 0; i < tablinks.length; i++) {
	//   tablinks[i].className = tablinks[i].className.replace(" active", "");
	// }
	// document.getElementById(id.toString()).style.display = "block";
	// evt.currentTarget.className += " active";
  }

}
