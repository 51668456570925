

  

   
<div class="row container-delivery" style="background: #f1f1f1;">
    
        <div class="cah-pswd align-self-center">
    
          <div class="loginbox">
            <h4 style="color: #223f31 !important; font-size: 24px;" class="font-demi">Change Password</h4>  
            <div class="text-danger">   
              {{message}}
            </div>           
            <form #forgetForm="ngForm" (ngSubmit)="forgetForm.valid &#038;&#038; onSubmit(forgetForm)" [appMatchPassword]="['new_password', 'confirm_password']">
               <input type="password"  (ngModel)="current_password" name="current_password" placeholder="Current Password"
               #current_password="ngModel" required >
             <div class="text-danger" *ngIf="(current_password.touched || forgetForm.submitted) && current_password.errors?.required">
               Current password is required
             </div>

             <input type="password"  (ngModel)="new_password" name="new_password" placeholder="New Password"
             #new_password="ngModel" required appPasswordPattern>
           <div class="text-danger" *ngIf="(new_password.touched || forgetForm.submitted) && new_password.errors?.required">
            New password  is required
           </div>
           <div class="text-danger" *ngIf="new_password.touched && new_password.errors?.invalidPassword">
            Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
            letter, 1 special character and 1 number
          </div>
           <input type="password"  (ngModel)="confirm_password" name="confirm_password" placeholder="Confirm Password"
           #confirm_password="ngModel" required >
         <div class="text-danger" *ngIf="(confirm_password.touched || forgetForm.submitted) && confirm_password.errors?.required">
           Confirm Password is required
         </div>
         <div class="text-danger"
         *ngIf="confirm_password.touched && confirm_password.errors?.passwordMismatch">
         Passwords doesnot match
       </div>
        
               <input type="submit"  class="btn btn-login"  value="Reset Password">
             </form>
           
          </div>
      
        </div>
      </div>
 



