import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarouselConfig, NgbTabsetConfig } from '@ng-bootstrap/ng-bootstrap';
import * as _ from "lodash";
import { CmsService } from 'src/app/core/services/cms/cms.service';

import { MasterService } from 'src/app/core/services/master/master.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss'],
  providers: [NgbCarouselConfig, NgbTabsetConfig]
})
export class CareersComponent implements OnInit {
  page: String = 'career';
  careers: any = [];
  careerlist: any = [];
  careerIntro:any;
  whywork:any;
  perks:any ;
  constructor( configure: NgbTabsetConfig,
    private cmsService:CmsService,
    private router:Router,    
    private masterService: MasterService) {

    configure.justify = 'center';
    configure.type = 'pills';
  }

  ngOnInit(): void {
    this.getCareerList();
    this.getWhyWork();
    this.getPerks();
  }
  goCareerDetails(id){
    this.router.navigate(['/career-list/'+id]);
  }
  getWhyWork(){
    this.cmsService.getPageInro('why-work-with-us').subscribe(res=>{ 
      this.whywork = res['cms'];    
      console.log("why",res)
		})
  }
  getPerks(){
    this.cmsService.getPageInro('perks-benefits').subscribe(res=>{ 
      this.perks = res['cms'];   
      console.log("perks",res)
		})
  }
  getCareerList() {
    this.masterService.getCareerList().subscribe(res => {
      let all = [];
      this.careerlist = _.groupBy(res['careers'], function (career) {
        return career.category_name;
      });;
      
      this.careers = _.groupBy(res['careers'], function (career) {
        return career.location;
      });
      for (const country in  this.careers) {
        this.careers[country] = _.groupBy(this.careers[country],function(coun) {
          return coun.title;
        })      
       
      }
   
    })
  }
  messageHandler(event){
    document.getElementById("career").scrollIntoView();
  }
}