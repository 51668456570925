import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  @Output() scrolling = new EventEmitter<void>();
  @Input() history: Array<string> = [];

  constructor() {}

  onScrollingFinished() {
    this.scrolling.emit();
  }
  ngOnInit(){

  }
 
}
